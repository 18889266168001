import React from "react";
import { FormattedMessage } from "react-intl";

import { ModalDialog, useForm } from "@sportal/cdk";
import { useIntlTranslate } from "../../../../../hooks";
import { makeProfilePickerItems, ProfilesPicker } from "../deviceForm";
import {
  CommonModalFooter,
  CommonModalHeader,
} from "../../../../../components";

export const MoveDeviceDialog = ({ modal, profiles, device }) => {
  const translate = useIntlTranslate();
  const profileSelectItems = makeProfilePickerItems(profiles, translate).map(
    item => ({
      ...item,
      disabled: item.value === device.profile ? false : item.disabled,
    })
  );

  const form = useForm({
    profileName: device.profile,
  });

  const submit = modal => {
    modal.resolve({
      profile: form.values.profileName,
    });
  };
  const close = () => modal.reject();

  const hasChanges = form.values.profileName !== device.profile;
  const isSubmitDisabled = !hasChanges;

  return (
    <ModalDialog
      submitHandler={() => submit(modal)}
      rejectHandler={close}
      disabled={isSubmitDisabled}
    >
      <CommonModalHeader>
        <FormattedMessage id="move_device" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ProfilesPicker
          label={<FormattedMessage id="profile" />}
          items={profileSelectItems}
          selected={form.values.profileName}
          onChange={value => form.set("profileName", value)}
        />
      </ModalDialog.Body>
      <CommonModalFooter />
    </ModalDialog>
  );
};
