import React from "react";
import { FormattedMessage } from "react-intl";

import { Input } from "../../../../../components";

export const DeviceDialogInput = ({
  value,
  onChange,
  error,
  label,
  placeholder,
  ...restProps
}) => {
  return (
    <FormattedMessage id={placeholder}>
      {placeholder => (
        <Input
          label={<FormattedMessage id={label} />}
          value={value}
          onChange={e => onChange(e.target.value)}
          onClear={() => onChange("")}
          placeholder={placeholder}
          error={error && <FormattedMessage id={error} />}
          {...restProps}
        />
      )}
    </FormattedMessage>
  );
};
