import React from "react";
import { FormattedMessage } from "react-intl";

import { ModalDialog } from "@sportal/cdk";
import { makeProfilePickerItems, ProfilesPicker } from "../deviceForm";
import { DeviceNameInput } from "../deviceForm/DeviceNameInput";
import {
  CommonModalFooter,
  CommonModalHeader,
  useForm,
} from "../../../../../components";
import { useIntlTranslate } from "../../../../../hooks";

export function MoveRoamingDeviceDialog({ modal, profiles, devices, device }) {
  const translate = useIntlTranslate();
  const profileSelectItems = makeProfilePickerItems(profiles, translate).map(
    item => ({
      ...item,
      disabled: item.value === device.profile ? false : item.disabled,
    })
  );

  const form = useForm({
    profile: device.profile,
  });

  const close = () => modal.reject();
  const submit = () => modal.resolve(form.getChanges());

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      disabled={!form.didFormChange() || form.hasErrors()}
    >
      <CommonModalHeader>
        <FormattedMessage id="move_device" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <DeviceNameInput
          device={device}
          devices={devices}
          value={device.name}
          disabled
        />
        <ProfilesPicker
          label={<FormattedMessage id="profile" />}
          items={profileSelectItems}
          selected={form.values.profile}
          onChange={value => form.set("profile", value)}
        />
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="move" />} />
    </ModalDialog>
  );
}
