import { Protection } from "@sportal/api";
import {
  CHANGE_PROTECTION_LEVEL,
  SET_CUSTOM_PROTECTION,
} from "./protectionEditor.actions";

export const protectionEditorReducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_PROTECTION_LEVEL:
      return {
        ...state,
        ...payload.protection,
      };
    case SET_CUSTOM_PROTECTION:
      return {
        ...state,
        name: Protection.Custom,
        blocked: payload.blocked,
      };
    default:
      return state;
  }
};
