import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { NotMobile, Mobile } from "../../../common/Responsive";
import { DetailedNotMobile } from "./detailedNotMobile/DetailedNotMobile";
import { DetailedMobile } from "./detailedMobile/DetailedMobile";

import "./DetailedServicePanel.scss";

const infectionMessages = {
  infected: "infection_found",
  clean: "no_infection_found",
  failed: "request_failed",
};

const statusIcons = {
  infected: "fas fa-exclamation-circle",
  clean: "fas fa-check-circle",
  failed: "fas fa-exclamation-triangle",
};

export const DetailedServicePanel = ({
  infectionStatus,
  title,
  serviceStatus,
  description,
  changeLink = true,
}) => {
  const passThroughProps = {
    serviceStatus,
    title,
    description,
    changeLink,
    statusIcon: statusIcons[infectionStatus],
    infectionMessage: infectionMessages[infectionStatus],
  };

  return (
    <div
      className={classnames(
        "detailed-service-panel",
        infectionStatus && `detailed-service-panel--${infectionStatus}`
      )}
    >
      <Mobile>
        <DetailedMobile {...passThroughProps} />
      </Mobile>
      <NotMobile>
        <DetailedNotMobile {...passThroughProps} />
      </NotMobile>
    </div>
  );
};

DetailedServicePanel.propTypes = {
  serviceStatus: PropTypes.bool.isRequired,
  infectionStatus: PropTypes.oneOf(["infected", "clean", "failed"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  changeLink: PropTypes.bool,
};
