import React from "react";
import { FormattedMessage } from "react-intl";

export const NonDefaultProtectionCardContent = ({
  name,
  ageGroup,
  protection,
  recommended,
}) => (
  <div className="wizard-protection-card__container">
    <div className={`profile-image ${ageGroup}`} />
    <div className="wizard-protection-card__content">
      <h3 className="wizard-protection-card__title">{name}</h3>
      <p className="wizard-protection-card__item">
        <FormattedMessage
          id="age_group_value"
          values={{
            ageGroup: (
              <b>
                <FormattedMessage id={ageGroup} />
              </b>
            ),
          }}
        />
      </p>
      <p className="wizard-protection-card__item wizard-protection-card__item--description">
        <FormattedMessage
          id={recommended ? "recommended_protection_for" : "protection_value"}
          values={{
            ageGroup: <FormattedMessage id={ageGroup} />,
            protection: (
              <b>
                <FormattedMessage id={protection} />
              </b>
            ),
          }}
        />
      </p>
    </div>
  </div>
);
