import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./ButtonLink.scss";

/**
 * A button that looks like a link
 */
export const ButtonLink = ({ children, className, ...attrs }) => (
  <button
    className={classnames("button-link link", className)}
    data-testid="button-link"
    {...attrs}
  >
    {children}
  </button>
);

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
