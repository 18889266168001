import _ from "lodash";
import { Profile } from "@sportal/api";

const isDefaultProfile = (profile: Profile) => profile.default;
const isNotDefaultProfile = _.negate(isDefaultProfile);

export function sortProfiles(profiles: Profile[]) {
  return [
    ..._.filter(profiles, isDefaultProfile),
    ..._.chain(profiles)
      .filter(isNotDefaultProfile)
      .sortBy(profile => profile.name.toUpperCase())
      .value(),
  ];
}
