import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./LoginPageContent.scss";

export const LoginPageContainer = ({
  centered,
  className,
  children,
  ...props
}) => (
  <div {...props} className={classnames("login-page", className)}>
    {children}
  </div>
);

const Header = ({ children }) => (
  <h1 className="login-page__content__header">{children}</h1>
);

const Content = ({ as: Component, className, children, ...props }) => (
  <Component
    {...props}
    className={classnames("login-page__content", className)}
  >
    {children}
  </Component>
);

LoginPageContainer.Header = Header;
LoginPageContainer.Content = Content;

LoginPageContainer.propTypes = {
  centered: PropTypes.bool,
};

Content.propTypes = {
  content: PropTypes.string,
};
Content.defaultProps = {
  as: "div",
};
