import React from "react";

import {
  isSuccess,
  useLoadableSlice,
} from "../../store/shared/withLoadingState";
import { getAccount } from "../../store/root.selectors";
import { loadAccount } from "../../store/account";
import { Logout } from "../Logout";
import { ErrorPage, shouldShowErrorPage } from "../../pages";
import { AccountServiceErrorCode } from "@sportal/api";

export const withAccountGuard = Component => () => {
  const account = useLoadableSlice(loadAccount(), getAccount);
  if (account === null) {
    return null;
  }

  if (isSuccess(account)) {
    return <Component />;
  }

  if (shouldShowErrorPage(account, AccountServiceErrorCode)) {
    return <ErrorPage />;
  }

  return <Logout reason={account.error.message} />;
};
