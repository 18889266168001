import _ from "lodash";

import { getAccount } from "../root.selectors";
import {
  BypassPrivateDns,
  BYPASS_PRIVATE_DNS,
  Service,
  ServiceData,
  UserMode,
} from "@sportal/api";
import { getUserMode } from "../info";
import { activationCommands } from "./activationCommands";

const getService = serviceName => state => getAccount(state)[serviceName];
const getPIService = state => getService(Service.PersonalInternet)(state);
const getSSService = state => getService(Service.SubscriberSafety)(state);

const isServiceActivated = service => service && service.activated;

export const isPersonalInternetActivated = _.flow(
  getPIService,
  isServiceActivated
);
export const isSubscriberSafetyActivated = _.flow(
  getSSService,
  isServiceActivated
);

const isServiceEnabled = service => service && service.enabled;

export const isPersonalInternetEnabled = _.flow(
  getPIService,
  isServiceEnabled
);
export const isSubscriberSafetyEnabled = _.flow(
  getSSService,
  isServiceEnabled
);

const isServiceBypassPinEnabled = service =>
  service && Boolean(service["bypass-pin-enabled"]);

export const isPersonalInternetBypassPinEnabled = _.flow(
  getPIService,
  isServiceBypassPinEnabled
);
export const isSubscriberSafetyBypassPinEnabled = _.flow(
  getSSService,
  isServiceBypassPinEnabled
);

const getServiceBypassPinValue = service =>
  service ? service["bypass-pin-value"] : "";

export const getPersonalInternetBypassPinValue = _.flow(
  getPIService,
  getServiceBypassPinValue
);
export const getSubscriberSafetyBypassPinValue = _.flow(
  getSSService,
  getServiceBypassPinValue
);

const hasProfiles = state => {
  const service = getService(Service.PersonalInternet)(state);
  return service && service["profile-count"] > 0;
};

const subscriberActivationSelectors = {
  [UserMode.SubscriberSafety]: isSubscriberSafetyActivated,
  // TODO: write a good explanation why SS is not checked for all the following user types
  [UserMode.Household]: _.overEvery(isPersonalInternetActivated, hasProfiles),
  [UserMode.PerDevice]: _.overEvery(isPersonalInternetActivated, hasProfiles),
  [UserMode.PISingle]: _.overEvery(isPersonalInternetActivated, hasProfiles),
  [UserMode.PIProfile]: _.overEvery(isPersonalInternetActivated, hasProfiles),
};

export const isSubscriberActivated = state => {
  const userMode = getUserMode(state);
  return subscriberActivationSelectors[userMode](state);
};

export const getActivationCommand = state =>
  activationCommands[getUserMode(state)].filter(({ service: serviceName }) => {
    const service = getService(serviceName)(state);
    return !isServiceActivated(service);
  });

export const getTimezone = _.flow(
  getAccount,
  ({ timezone }) => timezone
);

const isSPSONEnabled = (service: ServiceData): boolean =>
  service && service["spson-enabled"];
export const isSPSONAvailable = _.flow(
  getPIService,
  isSPSONEnabled,
  Boolean
);

const getBypassPrivateDNS = state => {
  const piService = getAccount(state)[Service.PersonalInternet];

  return piService && piService[BYPASS_PRIVATE_DNS];
};

export const getAPRStatus = state => {
  const bypassPrivateDNS = getBypassPrivateDNS(state);

  return (
    bypassPrivateDNS && bypassPrivateDNS[BypassPrivateDns.ApplePrivateRelay]
  );
};
