import _ from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns-tz";

import { Combobox } from "../../../../components";
import { loadTimezones } from "../../../../store/timezones";
import { useIntlTranslate } from "../../../../hooks";
import { useLoadableSlice } from "../../../../store/shared/withLoadingState";
import { getTimezones } from "../../../../store/root.selectors";

const useTimezones = () => {
  const timezones = useLoadableSlice(loadTimezones(), getTimezones);

  return timezones ? timezones.list : [];
};

export const TimezonePicker = ({ currentTimezone, onChange }) => {
  const timezones = useTimezones();
  const translate = useIntlTranslate();
  const timezoneItems = useMemo(() => {
    const now = Date.now();

    return _.map(timezones, timezone => ({
      value: timezone,
      content: `(GMT ${format(now, "xxx", {
        timeZone: timezone,
      })}) ${translate(timezone)}`,
    }));
  }, [timezones, translate]);

  return (
    <Combobox
      className="account-tab__row"
      label={<FormattedMessage id="timezone" />}
      searchInputPlaceholder={translate("search")}
      noResultsMessage={translate("no_results_found")}
      onChange={onChange}
      items={timezoneItems}
      selected={currentTimezone}
      comboboxSearchWrapperAriaLabel={translate("aria_combobox_label")}
      toggleButtonAriaLabel={translate("aria_combobox_toggle")}
    />
  );
};
