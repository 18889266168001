import {
  PersonalInternetServiceProfile,
  Service,
  SubscriberSafetyServiceProfile,
  UserMode,
} from "@sportal/api";

export const activationCommands = {
  [UserMode.SubscriberSafety]: [
    {
      service: Service.SubscriberSafety,
      serviceProfile: SubscriberSafetyServiceProfile.Standard,
    },
  ],
  [UserMode.Household]: [
    {
      service: Service.PersonalInternet,
      serviceProfile: PersonalInternetServiceProfile.Household,
    },
    {
      service: Service.SubscriberSafety,
      serviceProfile: SubscriberSafetyServiceProfile.Standard,
      enabled: false,
    },
  ],
  [UserMode.PerDevice]: [
    {
      service: Service.PersonalInternet,
      serviceProfile: PersonalInternetServiceProfile.PerDevice,
    },
    {
      service: Service.SubscriberSafety,
      serviceProfile: SubscriberSafetyServiceProfile.Standard,
      enabled: false,
    },
  ],
  [UserMode.PISingle]: [
    {
      service: Service.PersonalInternet,
      serviceProfile: PersonalInternetServiceProfile.Household,
    },
  ],
  [UserMode.PIProfile]: [
    {
      service: Service.PersonalInternet,
      serviceProfile: PersonalInternetServiceProfile.PerDevice,
    },
  ],
};
