import React, { VFC } from "react";

import {
  GetInputPropsOptions,
  GetPropsCommonOptions,
  GetToggleButtonPropsOptions,
  UseComboboxGetComboboxPropsOptions,
} from "downshift";

interface ComboboxSearchProps {
  getInputProps: <T>(options?: T) => T & GetInputPropsOptions;
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  getComboboxProps: (
    options?: UseComboboxGetComboboxPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => any;
  onInputClick: () => void;
  onInputChange: () => void;
  onInputBlur: () => void;
  disabled?: boolean;
  placeholder: string;
  comboboxSearchWrapperAriaLabel: string;
  toggleButtonAriaLabel: string;
}

export const ComboboxSearch: VFC<ComboboxSearchProps> = ({
  getInputProps,
  getToggleButtonProps,
  getComboboxProps,
  onInputClick,
  onInputChange,
  onInputBlur,
  disabled,
  placeholder,
  comboboxSearchWrapperAriaLabel,
  toggleButtonAriaLabel,
}) => {
  return (
    <div
      className="combobox__search"
      {...getComboboxProps({
        onBlur: onInputBlur,
      })}
      aria-label={comboboxSearchWrapperAriaLabel}
    >
      <input
        {...getInputProps({
          disabled,
          onChange: onInputChange,
        })}
        onClick={onInputClick}
        className={"combobox__search-input"}
        placeholder={placeholder}
      />

      <div
        {...getToggleButtonProps({
          disabled,
          onMouseDown: e => {
            e.preventDefault();
          },
        })}
        role="button"
        className="combobox__toggle"
        aria-label={toggleButtonAriaLabel}
      />
    </div>
  );
};
