import _ from "lodash";
import { getUnixTime } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { inRange } from "@sportal/lib";
import { SCHEDULE_TYPES } from "../../components/scheduler/scheduler.consts";
import { getSchedules } from "../root.selectors";
import { getTimezone } from "../account";

const scheduleTypes = Object.values(SCHEDULE_TYPES);

export const getProfileSchedules = (id, state) => getSchedules(state)[id];

export const getProfileSchedulerSchedules = (state, id) =>
  getProfileSchedules(id, state).filter(({ type }) =>
    scheduleTypes.includes(type)
  );

export const getProfileScheduleByName = (state, profileId, scheduleName) => {
  const schedules = getProfileSchedules(profileId, state);
  return _.find(schedules, ["name", scheduleName]);
};

export const isScheduleActive = (state, profileId, scheduleName) => {
  const schedule = getProfileScheduleByName(state, profileId, scheduleName);

  if (!schedule) {
    return false;
  }

  const timezone = getTimezone(state);
  const now = getUnixTime(zonedTimeToUtc(Date.now(), timezone));

  const start =
    schedule &&
    getUnixTime(zonedTimeToUtc(schedule.timeStart, schedule.timeZone));
  const end =
    schedule &&
    getUnixTime(zonedTimeToUtc(schedule.timeEnd, schedule.timeZone));

  return (
    schedule.enabled &&
    inRange(
      now,
      {
        start,
        end,
      },
      "()"
    )
  );
};
