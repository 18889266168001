import _ from "lodash";

import {
  LOAD_SHARED_PROTECTIONS,
  LOAD_SHARED_PROTECTIONS_SUCCESS,
  LOAD_SHARED_PROTECTIONS_FAILURE,
  SharedProtectionsActionTypes,
} from "./sharedProtections.types";
import { isFailure, Protection, SharedProtection } from "@sportal/api";
import { normalizeCollection } from "../../shared/normalizeCollection";
import { createTempNotification } from "../../../components/notifications";
import { Api } from "../../../api";

const isCustomProtection = protection => protection.name === Protection.Custom;

const reorderProtections = protections =>
  _.chain(protections)
    .filter(_.negate(isCustomProtection))
    .orderBy(protection => protection.blocked.length)
    .union(protections.filter(isCustomProtection))
    .value();

export const loadSharedProtections = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_SHARED_PROTECTIONS });

  const result = await api.protection.getSharedProtections();

  if (!isFailure(result)) {
    const protections = reorderProtections(result.data);
    dispatch(loadSharedProtectionsSuccess(protections));
  } else {
    dispatch(loadSharedProtectionsFailure(result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "failed_load_protection",
      })
    );
  }
};

export const loadSharedProtectionsSuccess = (
  protections: SharedProtection[]
): SharedProtectionsActionTypes => ({
  type: LOAD_SHARED_PROTECTIONS_SUCCESS,
  payload: normalizeCollection(protections, "name"),
});

export const loadSharedProtectionsFailure = (
  error: object
): SharedProtectionsActionTypes => ({
  type: LOAD_SHARED_PROTECTIONS_FAILURE,
  payload: error,
});
