import React from "react";

import { MobileModal, ModalService } from "../../../../../../components";
import { MobileModalListBody } from "../mobileModalListBody";
import { ToggleActionsButton } from "../toggleActionsButton";
import {
  DevicesTabContext,
  useDevicesTabContext,
} from "../../DevicesTabContext";

export const DeviceActionsToggle = ({ deviceName, menuItems }) => {
  // todo: modals don't have access to table context,
  //  selectors should be used instead, but this require DevicesTabContextProvider refactoring

  // this will be invoked for every row even if modal isn't shown
  const context = useDevicesTabContext();

  const openMobileModal = () => {
    ModalService.show(modal => ({
      dialog: (
        <DeviceActionsModal
          modal={modal}
          deviceName={deviceName}
          menuItems={menuItems}
          context={context}
        />
      ),
    }))
      .then(() => {})
      .catch(() => {});
  };

  return <ToggleActionsButton onClick={openMobileModal} />;
};

const DeviceActionsModal = ({ modal, deviceName, menuItems, context }) => {
  return (
    <DevicesTabContext.Provider value={context}>
      <MobileModal
        modal={modal}
        header={deviceName}
        content={<MobileModalListBody items={menuItems} />}
      />
    </DevicesTabContext.Provider>
  );
};
