import {
  LogicalDevicesService,
  LogicalDevicesProvider,
  HttpService,
  UserMode,
} from "@sportal/api";
import { makeManufacturerService } from "./manufacturerService";

export const makeLogicalDevicesService = (
  http: HttpService,
  baseUrl: string
) => (userMode: UserMode): LogicalDevicesService => {
  const stripOutProfile = userMode === UserMode.SubscriberSafety;

  return new LogicalDevicesService(
    new LogicalDevicesProvider(http, baseUrl),
    makeManufacturerService(http, baseUrl),
    stripOutProfile
  );
};
