import React from "react";
import { FormattedMessage } from "react-intl";

import { ProfileDetails } from "../ProfileDetails";

import "./ProfileDetailsMobile.scss";

export const ProfileDetailsMobile = props => {
  return (
    <div className="profile-details-mobile">
      <h2 className="profile-details-mobile__header">
        <FormattedMessage id="profile_details" />
      </h2>
      <ProfileDetails {...props} className="profile-details-mobile__content" />
    </div>
  );
};
