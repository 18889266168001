import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import _ from "lodash";
import PropTypes from "prop-types";

import { loadLogicalDevices } from "../../store/devices/logical";
import {
  GoHomeButton,
  InfoSection,
  NotHomeServicePanel,
  ProfileDetails,
  ProfileDetailsMobile,
  Scheduler,
  withLayout,
  withProfiles,
} from "../../components";
import { getLines, getLogicalDevices } from "../../store/root.selectors";
import { Mobile, NotMobile } from "../../common/Responsive";
import { getProfile } from "../../store/profiles";
import { ProfileDevices } from "../../components/profileDevices/ProfileDevices";
import { SCHEDULE_TYPES } from "../../components/scheduler/scheduler.consts";
import { useLoadableSlice } from "../../store/shared/withLoadingState";
import { useLists } from "../../components/urlLists/useLists";
import { ProfileUrlLists } from "../../components/profileUrlLists/ProfileUrlLists";
import { Report } from "../../components/report/Report";
import { loadLines } from "../../store/devices";
import { useProtections } from "./useProtections";
import { ProfileProtections } from "./ProfileProtections";
import { StickyProfilesSectionsProvider } from "./profileSection/StickyProvider";
import { isPerProfileReportVisible } from "../../store/settings";
import { isSingleMode } from "../../store/info";
import { hasDevicesSupport } from "../../store/shared/navigationSettings.selectors";
import { getDisplayName } from "../../common/getDisplayName";

import "./ProfilePage.scss";

const ProfilePageContent = ({ profile }) => {
  const showReport = useSelector(isPerProfileReportVisible);
  const showDevices = useSelector(hasDevicesSupport);
  const isSingle = useSelector(isSingleMode);

  const showProfileUrlLists = !isSingle;

  const { name, id } = profile;

  return (
    <div className="profile-page">
      <InfoSection>
        <NotMobile>
          <GoHomeButton />
        </NotMobile>
        <NotHomeServicePanel />
      </InfoSection>
      {showReport && <Report profileName={name} />}
      <div className="profile-page__content">
        <NotMobile>
          <div className="profile-page__sidebar">
            <div className="profile-page__sidebar-container">
              <ProfileDetails profile={profile} />
            </div>
          </div>
        </NotMobile>
        <Mobile>
          <ProfileDetailsMobile profile={profile} />
        </Mobile>
        <div className="profile-page__body-content">
          <div className="profile-page__body-content-container">
            <NotMobile>
              <h2 className="profile-page__body-content-header">
                <FormattedMessage id="profile_details" />
              </h2>
            </NotMobile>
            <StickyProfilesSectionsProvider>
              <ProfileProtections profile={profile} />
              <Scheduler profileId={id} type={SCHEDULE_TYPES.homework} />
              <Scheduler profileId={id} type={SCHEDULE_TYPES.internetOff} />
              {showProfileUrlLists && <ProfileUrlLists profileId={id} />}
              {showDevices && <ProfileDevices profileName={name} />}
            </StickyProfilesSectionsProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfilePageContent.propTypes = {
  profile: PropTypes.object.isRequired,
};

function withProfileData(Component) {
  function ProfileData({ profile }) {
    const lists = useLists(profile.id);
    const lines = useLoadableSlice(loadLines(), getLines);
    const devices = useLoadableSlice(loadLogicalDevices(), getLogicalDevices);
    const protections = useProtections();

    if (_.some([lists, lines, devices, protections], _.isNull)) {
      return null;
    }

    return <Component profile={profile} />;
  }

  ProfileData.displayName = `withProfileData(${getDisplayName(Component)})`;
  return ProfileData;
}

function withProfileExistsGuard(Component) {
  function ProfileExistsGuard() {
    const { id } = useParams();
    const profile = useSelector(state => getProfile(state, id));

    if (!profile) {
      return <Redirect to="/home" />;
    }

    return <Component profile={profile} />;
  }

  ProfileExistsGuard.displayName = `withProfileExistsGuard(${getDisplayName(
    Component
  )})`;
  return ProfileExistsGuard;
}

export const ProfilePage = _.flowRight([
  withLayout(),
  withProfiles,
  withProfileExistsGuard,
  withProfileData,
])(ProfilePageContent);
