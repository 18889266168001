import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./MultipleWizardDevicesPage.scss";

import { MultipleWizardPage } from "../MultipleWizardPage";
import { NextStepButton } from "../nextStepButton/NextStepButton";
import {
  TabHeader,
  TabTitle,
  DevicesControlBar,
  Checkbox,
  RefreshRequestsButton,
} from "../../../../components";
import { useToggle } from "@sportal/cdk";
import { DevicesTabContextProvider } from "../../../settings/tabs/devicesTab/DevicesTabContext";
import { useLoadableSlice } from "../../../../store/shared/withLoadingState";
import { getLines, getLogicalDevices } from "../../../../store/root.selectors";
import {
  loadLogicalDevices,
  loadLines,
  loadRequests,
} from "../../../../store/devices";
import { Mobile, NotMobile } from "../../../../common/Responsive";
import {
  RequestsSection,
  ProfilesDevices,
} from "../../../settings/tabs/devicesTab";
import { getProfilesList } from "../../store/profiles/profiles.selectors";
import { finishWizard } from "../../store/wizard.actions";

const MultipleWizardDevicesPageContent = () => {
  useLoadableSlice(loadLogicalDevices(), getLogicalDevices);
  useLoadableSlice(loadLines(), getLines);

  const [isAdvancedView, toggleAdvancedView] = useToggle(false);

  const dispatch = useDispatch();
  const refresh = useCallback(() => dispatch(loadRequests()), [dispatch]);

  return (
    <DevicesTabContextProvider profilesSelector={getProfilesList}>
      <DevicesControlBar className="multiple-wizard-devices-page__control-bar">
        <h3>
          <FormattedMessage id="manage_devices" />
        </h3>
        <Checkbox
          id={"multiple-wizard-devices-page-advanced-view"}
          onChange={toggleAdvancedView}
          checked={isAdvancedView}
          label={<FormattedMessage id="advanced_view" />}
        />
        <RefreshRequestsButton onClick={refresh} />
      </DevicesControlBar>
      <RequestsSection isAdvancedView={isAdvancedView} />
      <ProfilesDevices isAdvancedView={isAdvancedView} />
    </DevicesTabContextProvider>
  );
};

export const MultipleWizardDevicesPage = () => {
  const dispatch = useDispatch();
  const next = () => {
    dispatch(finishWizard());
  };

  return (
    <MultipleWizardPage>
      <div className="multiple-wizard-devices-page">
        <NotMobile>
          <TabHeader>
            <TabTitle>
              <FormattedMessage id="assign_devices" />
            </TabTitle>
          </TabHeader>
        </NotMobile>
        <Mobile>
          <TabTitle>
            <FormattedMessage id="assign_devices" />
          </TabTitle>
        </Mobile>
        <div className="multiple-wizard-devices-page__wizard-hint">
          <FormattedMessage id="assign_devices_summary" />
        </div>
        <div className="multiple-wizard-devices-page__content">
          <MultipleWizardDevicesPageContent />
        </div>
        <NextStepButton
          doNext={next}
          message={<FormattedMessage id="finish_setup" />}
        />
      </div>
    </MultipleWizardPage>
  );
};
