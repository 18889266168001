import { Column, HeaderGroup } from "react-table";

export const NO_VALUE_PLACEHOLDER = "—";
export const showValue = <T>(value: T): T | typeof NO_VALUE_PLACEHOLDER =>
  value ? value : NO_VALUE_PLACEHOLDER;

export const getColumnId = (column: Column): string =>
  column.id || (column.accessor as string);

export const defaultHiddenColumnConfig = {
  width: 0,
  disableSortBy: true,
};

export const getHeaderTranslationId = (
  headerGroups: HeaderGroup<any>[],
  columnId: string
): string =>
  headerGroups[0].headers.find(column => column.id === columnId)
    .headerTranslationId;
