import {
  SUBMIT_PROFILES,
  SUBMIT_PROFILES_SUCCESS,
  SUBMIT_PROFILES_FAILURE,
  SUBMIT_PROFILES_UPDATE,
} from "./profiles.actions";
import { withLoadingState } from "../../../../store/shared/withLoadingState";
import {
  addItem,
  removeItem,
  updateItem,
} from "../../../../store/shared/collection.operations";
import { denormalizeCollection } from "../../../../store/shared/normalizeCollection";
import { sortProfiles } from "../../../../store/profiles/profiles.helpers";

const initialState = { keys: [], list: {} };

const reducer = (state = initialState, action) => {
  if (action.type === SUBMIT_PROFILES_UPDATE) {
    const { add, update, remove } = action.payload;
    let newState = state;

    remove.forEach(({ id }) => (newState = removeItem(newState, id)));
    update.forEach(
      ({ profile, updates }) =>
        (newState = updateItem(newState, { ...profile, ...updates }))
    );
    add.forEach(profile => (newState = addItem(newState, profile)));

    const profiles = denormalizeCollection(newState);
    const sortedProfiles = sortProfiles(profiles);

    return {
      ...state,
      ...newState,
      keys: sortedProfiles.map(profile => profile.id),
    };
  } else {
    return state;
  }
};

export const wizardProfilesReducer = withLoadingState({
  loadActionType: SUBMIT_PROFILES,
  successActionType: SUBMIT_PROFILES_SUCCESS,
  failureActionType: SUBMIT_PROFILES_FAILURE,
})(reducer);
