import { InfoData } from "@sportal/api";

export const LOAD_INFO = "[AUTH] LOAD_INFO";
export const LOAD_INFO_SUCCESS = "[AUTH] LOAD_INFO_SUCCESS";
export const LOAD_INFO_FAILURE = "[AUTH] LOAD_INFO_FAILURE";

export interface LoadInfoAction {
  type: typeof LOAD_INFO;
}

export interface LoadInfoSuccessAction {
  type: typeof LOAD_INFO_SUCCESS;
  payload: InfoData;
}

export interface LoadInfoFailureAction {
  type: typeof LOAD_INFO_FAILURE;
  payload: {
    code?: string | number;
    message: string | undefined;
  };
}

export type InfoActionTypes =
  | LoadInfoAction
  | LoadInfoSuccessAction
  | LoadInfoFailureAction;
