import React from "react";
import { useSelector } from "react-redux";

import { getSubscriberName } from "../../../../store/info";
import { HeaderUserMenu } from "./HeaderUserMenu";
import { useLogoutMenuItem } from "./useLogoutMenuItem";

export function WizardHeaderUserMenu() {
  const subscriberName = useSelector(getSubscriberName);
  const [logoutMenuItem, sidebarLogoutMenuItem] = useLogoutMenuItem(
    subscriberName
  );

  return (
    <HeaderUserMenu
      dropdownItems={[logoutMenuItem]}
      sidebarItems={[sidebarLogoutMenuItem]}
      subscriberName={subscriberName}
    />
  );
}
