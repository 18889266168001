import React from "react";

import { Icon } from "@sportal/cdk";
import { isAssignedDeviceMerged } from "../../../store/devices";
import { Tooltip } from "../../tooltip/Tooltip";
import { useIntlTranslate } from "../../../hooks";
import { showValue } from "../../table";

import "./NetworkId.scss";

export const NetworkId = ({
  identifiers,
  phoneNumber,
  manufacturer,
  networkId,
}) => {
  const isMerged = isAssignedDeviceMerged({ identifiers });

  return isMerged ? (
    <MergedNetworkId phoneNumber={phoneNumber} networkId={networkId} />
  ) : (
    <SingleNetworkIdWithManufacturer
      manufacturer={manufacturer}
      phoneNumber={phoneNumber}
      networkId={networkId}
    />
  );
};

const SingleNetworkIdWithManufacturer = ({
  phoneNumber,
  manufacturer,
  networkId,
}) => {
  return (
    <div className="network-id">
      <span aria-hidden={true} className="network-id__manufacturer">
        {showValue(manufacturer)}
      </span>
      <span className="network-id__identifier">{networkId || phoneNumber}</span>
    </div>
  );
};

export const SingleNetworkId = ({ phoneNumber, networkId }) => {
  return (
    <div className={"network-id"}>
      <span className="network-id__identifier">{networkId || phoneNumber}</span>
    </div>
  );
};

export const MergedNetworkId = ({ phoneNumber, networkId }) => {
  const translate = useIntlTranslate();
  const label = translate("merged_devices");

  return (
    <div className="network-id--merged">
      <span className="sr-only">{label}</span>
      <div role="list" className="network-id">
        <div role="listitem" className="network-id__identifier">
          {phoneNumber}
        </div>
        <div role="listitem" className="network-id__identifier">
          {networkId}
        </div>
      </div>
      {/* @ts-ignore */}
      <Tooltip content={label} placement="top" aria-hidden={true}>
        <Icon icon="far fa-link network-id__icon" />
      </Tooltip>
    </div>
  );
};
