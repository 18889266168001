import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { CrossButton, Icon, RegularTooltip } from "@sportal/cdk";

import { useIntlTranslate } from "../../../hooks";
import { Button } from "../../button/Button";
import { hideSpsonPanel } from "../../../store/banners";
import { generateDeeplink } from "../../deeplinkDialog/deeplinkDialog.actions";

import "./SpsonPanel.scss";

export const SpsonPanel = () => {
  const dispatch = useDispatch();
  const translate = useIntlTranslate();

  function handleGenerateLinkClick() {
    dispatch(generateDeeplink());
  }

  return (
    <div className={"spson-panel"}>
      <div className={"spson-panel__main"}>
        <h3 className={"spson-panel__header"}>
          <Icon icon={"fas fa-shield-check"} />
          <span>
            <FormattedMessage id={"sps_client"} />
          </span>
        </h3>
        <p className={"spson-panel__info"}>
          <FormattedMessage id={"sps_panel_info"} />
        </p>
      </div>
      <div className={"spson-panel__actions"}>
        <Button
          className={"manage-devices spson-panel__action-button"}
          size={"medium"}
          color={"primary"}
          variant={"outlined"}
          as={"a"}
          href={"#settings/devices"}
        >
          <FormattedMessage id={"manage_devices"} />
        </Button>
        <Button
          size={"medium"}
          color={"primary"}
          className={"spson-panel__action-button"}
          aria-label={translate("aria_sps_button_generate_link")}
          onClick={handleGenerateLinkClick}
        >
          <FormattedMessage id={"generate_link"} />
        </Button>
      </div>
      <div className={"spson-panel__close"}>
        <RegularTooltip
          placement={"top-end"}
          content={<FormattedMessage id={"sps_close_tooltip"} />}
        >
          <CrossButton
            onClick={() => dispatch(hideSpsonPanel())}
            size={"small"}
            aria-label={translate("aria_sps_close_tooltip")}
          />
        </RegularTooltip>
      </div>
    </div>
  );
};
