import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
  CategoriesActionTypes,
} from "./categories.types";

const initialState = {
  keys: [],
  list: {},
};

const reducer = (state = initialState, action: CategoriesActionTypes) => {
  switch (action.type) {
    case LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const categoriesReducer = withLoadingState({
  loadActionType: LOAD_CATEGORIES,
  successActionType: LOAD_CATEGORIES_SUCCESS,
  failureActionType: LOAD_CATEGORIES_FAILURE,
})(reducer);
