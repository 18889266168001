import React from "react";

import { InfoSection, withLayout, WizardSteps } from "../../../components";
import { Desktop, NotMobile } from "../../../common/Responsive";
import { MultipleWizardSidebar } from "./wizardSidebar/MultipleWizardSidebar";

import { WizardHeader } from "../wizardHeader/WizardHeader";
import { useWizardNavigationContext } from "../WizardNavigationContext";
import { GoPrevStep } from "../goPrevStepButton/GoPrevStepButton";

import "../wizardLayout.scss";

const MultipleWizardPageComponent = ({ children }) => {
  const { stepIndex, goToPrevStep } = useWizardNavigationContext();

  return (
    <div className="multiple-wizard-page">
      <InfoSection>
        <NotMobile>
          <div>
            <GoPrevStep onClick={goToPrevStep} />
          </div>
        </NotMobile>
        <div className="wizard-steps-container">
          <WizardSteps currentStep={stepIndex} />
        </div>
      </InfoSection>
      <div className="wizard-page__content">
        <Desktop>
          <div className="wizard-page__sidebar">
            <div className="wizard-page__sidebar-container">
              <MultipleWizardSidebar />
            </div>
          </div>
        </Desktop>
        <div className="wizard-page__tabs-content">
          <div className="wizard-page__tabs-content-container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const MultipleWizardPage = withLayout({
  customHeader: () => <WizardHeader />,
})(MultipleWizardPageComponent);
