import _ from "lodash";
import { getSubscriberId } from "../../info";
import { getProfilesList } from "../../profiles";
import { normalizeCollection } from "../../shared/normalizeCollection";
import {
  LOAD_CUSTOM_PROTECTIONS,
  LOAD_CUSTOM_PROTECTIONS_SUCCESS,
  LOAD_CUSTOM_PROTECTIONS_FAILURE,
  SAVE_CUSTOM_PROTECTION,
  SAVE_CUSTOM_PROTECTION_SUCCESS,
  SAVE_CUSTOM_PROTECTION_FAILURE,
  CustomProtectionsActionTypes,
} from "./customProtections.types";
import { Api } from "../../../api";
import { isFailure } from "@sportal/api";
import { CustomProtection } from "@sportal/api";
import { createTempNotification } from "../../../components/notifications";

export const loadCustomProtections = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_CUSTOM_PROTECTIONS });

  const subscriberId = getSubscriberId(getState());
  const profiles = getProfilesList(getState());

  const result = await api.protection.getAllCustomProtections(
    subscriberId,
    profiles.map(profile => profile.name)
  );

  if (isFailure(result)) {
    dispatch(loadCustomProtectionsFailure(result.error));
  } else {
    const mapped = result.data.map(protection => ({
      ...protection,
      profile: _.find(profiles, { name: protection.profile }).id,
    }));

    dispatch(loadCustomProtectionsSuccess(mapped));
  }
};

export const loadCustomProtectionsSuccess = (
  data: CustomProtection[]
): CustomProtectionsActionTypes => ({
  type: LOAD_CUSTOM_PROTECTIONS_SUCCESS,
  payload: normalizeCollection(data, "profile"),
});

export const loadCustomProtectionsFailure = (
  error
): CustomProtectionsActionTypes => ({
  type: LOAD_CUSTOM_PROTECTIONS_FAILURE,
  payload: error,
});

export const saveCustomProtection = async ({
  subscriberId,
  profile,
  protection,
  api,
  dispatch,
}) => {
  dispatch({ type: SAVE_CUSTOM_PROTECTION });

  let result = await api.protection.saveCustomProtection(
    subscriberId,
    profile.name,
    protection.blocked
  );

  if (isFailure(result)) {
    dispatch(saveCustomProtectionFailure(result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "couldnt_save_changes",
      })
    );
  } else {
    dispatch(
      saveCustomProtectionSuccess({
        profile: profile.id,
        ...protection,
      })
    );
  }
};

export const saveCustomProtectionSuccess = (
  protection: CustomProtection
): CustomProtectionsActionTypes => ({
  type: SAVE_CUSTOM_PROTECTION_SUCCESS,
  payload: protection,
});

export const saveCustomProtectionFailure = (
  error
): CustomProtectionsActionTypes => ({
  type: SAVE_CUSTOM_PROTECTION_FAILURE,
  payload: error,
});
