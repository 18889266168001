import { UserMode } from "@sportal/api";
import { getUserMode } from "../../store/info";
import { ClearDataTypes } from "../../store/shared/clearData.constants";
import {
  isSSReportingLicensed,
  isPIReportingLicensed,
} from "../../store/settings";

const ClearDataOption = {
  [ClearDataTypes.Dns]: {
    label: "internet_activity",
    value: ClearDataTypes.Dns,
    isChecked: true,
  },
  [ClearDataTypes.Content]: {
    label: "blocked_activity",
    value: ClearDataTypes.Content,
    isChecked: true,
  },
  [ClearDataTypes.Safety]: {
    label: "malware_and_phishing",
    value: ClearDataTypes.Safety,
    isChecked: true,
  },
  [ClearDataTypes.Botnet]: {
    label: "infected_devices",
    value: ClearDataTypes.Botnet,
    isChecked: true,
  },
};

const SSOptions = [
  ClearDataOption.dns,
  ClearDataOption.safety,
  ClearDataOption.botnet,
];

const PIOptions = [ClearDataOption.dns, ClearDataOption.content];

const SSPIOptions = [
  ClearDataOption.dns,
  ClearDataOption.content,
  ClearDataOption.safety,
  ClearDataOption.botnet,
];

export const getClearDataOptions = state => {
  const userMode = getUserMode(state);
  const isSSReportAllowed = isSSReportingLicensed(state);
  const isPIReportAllowed = isPIReportingLicensed(state);

  if (!isSSReportAllowed && !isPIReportAllowed) {
    return [];
  }

  switch (userMode) {
    case UserMode.SubscriberSafety:
      return SSOptions;
    case UserMode.PISingle:
    case UserMode.PIProfile:
      return PIOptions;
    case UserMode.PerDevice:
    case UserMode.Household:
      return SSPIOptions;
    default:
      return [];
  }
};
