import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import "./TimePicker.scss";

import TimePickerInput from "./TimePickerInput";
import { Select } from "../select";
import {
  isLeftArrowPressed,
  isRightArrowPressed,
  isLeftOrRightArrowPressed,
} from "../helpers/keyboardEvents.helper";

const ampmOptions = [
  {
    content: "am",
    value: "am",
  },
  {
    content: "pm",
    value: "pm",
  },
];

function AmPmSelect({ selectedItem, onChange, ariaLabel }) {
  return (
    <Select
      className="time-picker-select"
      items={ampmOptions}
      selected={selectedItem}
      onChange={onChange}
      ariaLabel={ariaLabel}
    />
  );
}

export class TimePickerComponent extends Component {
  state = {
    isPickerFocused: false,
    lastEditedField: null,
    lastPressedIsNill: false,
  };

  timePickerRef = createRef();
  hoursRef = createRef();
  minutesRef = createRef();

  handleKeyDown = event => {
    if (!isLeftOrRightArrowPressed(event)) {
      return;
    }

    this.onNavigate(event);
  };

  handleFocus = () => {
    this.setState({
      isPickerFocused: true,
      lastEditedField: null,
      lastPressedIsNill: false,
    });
  };

  handleBlur = () => {
    if (!this.timePickerRef.current.contains(document.activeElement)) {
      this.setState({ isPickerFocused: false });
    }
  };

  handleSelectChange = value => {
    this.props.onInputChange("ampm", value);
  };

  handleInputChange = event => {
    const { lastEditedField, lastPressedIsNill } = this.state;
    const { name } = event.target;
    const prevValidValue = this.props[name],
      cursorPos = this._getCaretPosition(event.currentTarget),
      newSymbol = event.currentTarget.value[cursorPos - 1];

    let value;

    // if key is not \w set valid value
    if (!newSymbol || !newSymbol.match(/^[\w]?$/i)) {
      return;
    }

    if (name === "hours" || name === "minutes") {
      // if key is not \d set valid value
      if (!newSymbol.match(/^\d?$/)) {
        return;
      }

      // if key on this input pressed first time - take new value, if not - prev + new
      value =
        lastEditedField === null
          ? newSymbol
          : prevValidValue[prevValidValue.length - 1] + newSymbol;

      if (lastPressedIsNill) {
        value = "0" + newSymbol;
      }

      if (newSymbol === "0" && !lastEditedField) {
        this.setState({ lastPressedIsNill: true });
        value = "00";
      }
    } else {
      value = newSymbol + "M";
    }

    if (!this.props.preValidate(name, value)) {
      if (!this.props.preValidate(name, newSymbol)) {
        this._focusNextInput();
        return;
      }

      if (!lastEditedField || lastEditedField !== name) {
        value = newSymbol;
      } else {
        const isLastInput = _.isNull(this._focusNextInput());

        if (isLastInput) {
          value = newSymbol;
        } else return;
      }
    }

    this.props.onInputChange(name, value);
    lastEditedField === name && this._focusNextInput();
    this.setState({ lastEditedField: name });
  };

  onNavigate = event => {
    event.preventDefault();

    if (isRightArrowPressed(event)) {
      this._focusNextInput();
      return;
    }

    if (isLeftArrowPressed(event)) {
      this._focusPrevInput();
    }
  };

  _focusNextInput = () => {
    if (document.activeElement && document.activeElement.name === "hours") {
      return this.minutesRef.current.focus();
    }

    return null;
  };

  _focusPrevInput = () => {
    switch (document.activeElement && document.activeElement.name) {
      case "minutes":
        return this.hoursRef.current.focus();
      case "ampm":
        return this.minutesRef.current.focus();
      default:
        return;
    }
  };

  _getCaretPosition = field => {
    // Initialize
    let iCaretPos = 0;

    // IE Support
    if (document.selection) {
      // Set focus on the element
      field.focus();

      // To get cursor position, get empty selection range
      const sel = document.selection.createRange();

      // Move selection start to 0 position
      sel.moveStart("character", -field.value.length);

      // The caret position is selection length
      iCaretPos = sel.text.length;
    } else if (field.selectionStart || field.selectionStart === "0") {
      // Firefox support
      iCaretPos = field.selectionStart;
    }

    // Return results
    return iCaretPos;
  };

  render() {
    const {
      label,
      timeFormat24h,
      hours,
      minutes,
      ampm,
      ariaLabelledBy,
      ariaMinutes,
      ariaHours,
      ariaTimeFormat,
    } = this.props;
    const { isPickerFocused } = this.state;

    return (
      <div
        className="time-picker-wrapper"
        role="group"
        aria-labelledby={ariaLabelledBy}
      >
        {label && (
          <div className="time-picker__label" data-testid="time-picker-label">
            {label}
          </div>
        )}
        <div className="time-picker__content">
          <div
            ref={this.timePickerRef}
            className={classnames("time-picker", {
              "time-picker--focused": isPickerFocused,
            })}
          >
            <TimePickerInput
              ref={this.hoursRef}
              name="hours"
              value={hours}
              type="tel"
              pattern="[0-9]*"
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleInputChange}
              aria-label={ariaHours}
            />
            <div className="time-picker__divider">:</div>
            <TimePickerInput
              ref={this.minutesRef}
              name="minutes"
              value={minutes}
              type="tel"
              pattern="[0-9]*"
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleInputChange}
              aria-label={ariaMinutes}
            />
          </div>
          {!timeFormat24h && (
            <AmPmSelect
              selectedItem={ampm}
              onChange={this.handleSelectChange}
              ariaLabel={ariaTimeFormat}
            />
          )}
        </div>
      </div>
    );
  }
}

TimePickerComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  timeFormat24h: PropTypes.bool.isRequired,
  hours: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
  ampm: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  preValidate: PropTypes.func.isRequired,
  ariaLabelledBy: PropTypes.string,
  ariaMinutes: PropTypes.string.isRequired,
  ariaHours: PropTypes.string.isRequired,
  ariaTimeFormat: PropTypes.string.isRequired,
};
