import React from "react";
import { FormattedMessage } from "react-intl";

import { Mobile, NotMobile } from "../../../common/Responsive";
import { ButtonLink } from "../../buttonLink/ButtonLink";
import { openLearnMoreDialog } from "./learnMoreDialog/LearnMoreDialog";
import { Icon } from "../../icon";

import "./DeviceCodeHint.scss";

export const DeviceCodeHint = () => (
  <div className="device-code-hint">
    <NotMobile>
      <Icon icon="far fa-info-circle" />
    </NotMobile>
    <div className="device-code-hint__content">
      <Mobile>
        <Icon icon="far fa-info-circle" />
      </Mobile>
      <span className="device-code-hint__text">
        <FormattedMessage id="device_code_hint" />
      </span>
      <ButtonLink onClick={openLearnMoreDialog}>
        <FormattedMessage id="click_to_learn_more" />
      </ButtonLink>
    </div>
  </div>
);
