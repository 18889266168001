import _ from "lodash";

import { getInfo } from "../root.selectors";
import { UserMode, AuthMethod, AuthServiceErrorCode } from "@sportal/api";

const ssUserModes = [UserMode.SubscriberSafety];
const piUserModes = [UserMode.PISingle, UserMode.PIProfile];
const ssPiUserModes = [UserMode.Household, UserMode.PerDevice];

export const fromInfo = {
  getSubscriberId: info => info.id,
  getUserMode: info => info.mode,
  getDefaultTimezone: info => info.defaultTimezone,
  getSubscriberName: info => info.name,
};

export const getSubscriberId = _.flow(
  getInfo,
  fromInfo.getSubscriberId
);
export const getUserMode = _.flow(
  getInfo,
  fromInfo.getUserMode
);
export const getDefaultTimezone = _.flow(
  getInfo,
  fromInfo.getDefaultTimezone
);
export const getSubscriberName = _.flow(
  getInfo,
  fromInfo.getSubscriberName
);

export const isSSO = _.flow(
  getInfo,
  info => (info.error || info).authMethod === AuthMethod.SSO
);

export const isHousehold = state => getUserMode(state) === UserMode.Household;
export const isPISingle = state => getUserMode(state) === UserMode.PISingle;

export const isSS = state => ssUserModes.includes(getUserMode(state));
export const isPI = state => piUserModes.includes(getUserMode(state));
export const isSSPI = state => ssPiUserModes.includes(getUserMode(state));

const isUserModeOneOf = (...list: UserMode[]) => state =>
  _.includes(list, getUserMode(state));
export const hasMultipleSupport = isUserModeOneOf(
  UserMode.PerDevice,
  UserMode.PIProfile
);
export const isSingleMode = isUserModeOneOf(
  UserMode.Household,
  UserMode.PISingle
);

export const isWrongUserMode = info => {
  return info.error && info.error.code === AuthServiceErrorCode.WRONG_USER_MODE;
};
