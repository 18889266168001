import { UpdateResponseStatus } from "../shared";

export enum ListType {
  Allow = "whitelist",
  Block = "blacklist",
}

export interface ListServerData {
  content: string[];
  limit: number;
  total: number;
}

export interface ListData {
  content: string[];
  limit: number;
}

export interface ListsData {
  [ListType.Allow]: ListData;
  [ListType.Block]: ListData;
}

export enum UrlType {
  CORE_DOMAIN = "core-domain",
  FQDN = "fqdn",
  HOST_PATH = "host-path",
  NAKED_HOST_PATH = "naked-host-path",
  PUBLIC_SUFFIX = "public-suffix",
}

export enum UpdateFailureReason {
  PERSISTENCE_ERROR = "PERSISTENCE_ERROR",
  ALREADY_EXISTS = "ALREADY_EXISTS",
  PRESENTS_IN_BOTH_LISTS = "PRESENTS_IN_BOTH_LISTS",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  LIST_NODE_INVALID = "LIST_NODE_INVALID",
  ACCOUNT_LIST_LIMIT_REACHED = "ACCOUNT_LIST_LIMIT_REACHED",
}

export interface Url {
  type: UrlType;
  node: string;
}

interface UrlError extends Url {
  error: string;
  reason: UpdateFailureReason;
}

export interface UpdateResponse {
  adds?: Url[];
  removes?: Url[];
  failures: {
    adds: UrlError[];
    removes: UrlError[];
  };
  status: UpdateResponseStatus;
}
