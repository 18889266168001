import axios from "axios";
import qs from "qs";
import _ from "lodash";

import { logout } from "../store/shared/logout.actions";
import { ResponseCodeError } from "./ResponseCodeError";
import { getAttributes } from "../store/root.selectors";
import { isLoaded } from "../store/shared/withLoadingState";
import { HttpService } from "@sportal/api";

export const makeHttpService = (): HttpService =>
  axios.create({
    xsrfHeaderName: "X-CSRF-TOKEN",
    xsrfCookieName: "CSRF-TOKEN",
    paramsSerializer: params => qs.stringify(params),
  });

export function applyResponseCodeInterceptor(
  http,
  responseCodeHeader = "X-Response-Code"
) {
  const responseCodeHeaderName = responseCodeHeader.toLowerCase();

  function getStatusCodeFromHeader(response) {
    const status = Number(_.get(response.headers, responseCodeHeaderName));
    return _.isNumber(status) ? status : null;
  }

  function rejectAsError(status, error) {
    if (status) {
      error.response.status = status;
    }

    return Promise.reject(error);
  }

  http.interceptors.response.use(
    response => {
      const status = getStatusCodeFromHeader(response);

      if (!status || status < 400) {
        return response;
      }

      return rejectAsError(status, new ResponseCodeError(response));
    },
    error => {
      const status = getStatusCodeFromHeader(error.response);

      return rejectAsError(status, error);
    }
  );
}

const isInitialLoadingComplete = _.flow([getAttributes, isLoaded]);

export function applySessionExpirationInterceptor(http, store) {
  http.interceptors.response.use(
    response => response,
    error => {
      const state = store.getState();

      if (isInitialLoadingComplete(state) && sessionExpired(error)) {
        store.dispatch(logout("session_expired_please_log_in_again"));
        return new Promise(() => {});
      }

      return Promise.reject(error);
    }
  );
}

export const applyResponseInterceptors = (http, store, config) => {
  if (config.security.responseCodesFromHeader) {
    applyResponseCodeInterceptor(http, config.security.responseCodeHeader);
  }

  applySessionExpirationInterceptor(http, store);
};

const sessionExpired = error => {
  const codes = [401, 403];
  const statusCode = error.response && error.response.status;

  return codes.includes(statusCode);
};
