import React from "react";
import PropTypes from "prop-types";

import "./NotificationTitle.scss";

export const NotificationTitle = ({ title }) => (
  <h4 className="notification-title">{title}</h4>
);

NotificationTitle.propTypes = {
  title: PropTypes.node,
};
