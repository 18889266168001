import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { Durations } from "@sportal/api/reports";
import { Button } from "../../button/Button";
import { useReportContext } from "../../barchart/ReportProvider";

const durations = [
  {
    code: Durations.Day,
    content: <FormattedMessage id={"past_24_hours"} />,
  },
  {
    code: Durations.Week,
    content: <FormattedMessage id={"past_7_days"} />,
  },
  {
    code: Durations.Month,
    content: <FormattedMessage id={"past_30_days"} />,
  },
];

export const TimePeriodButtons = ({ className }) => {
  const { duration: selectedDuration, setDuration } = useReportContext();

  return (
    <div className={className}>
      {_.map(durations, ({ code, content }, key) => {
        const btnProps = {
          key,
          size: "medium",
          color: "primary",
          onClick: () => setDuration(code),
          ...(code !== selectedDuration && {
            variant: "inverted",
            color: "secondary",
          }),
        };

        return <Button {...btnProps}>{content}</Button>;
      })}
    </div>
  );
};
