import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { Mobile, NotMobile } from "../../../common/Responsive";
import { ScheduleDesktop, ScheduleMobile } from "../schedule/Schedule";
import { getProfileSchedulerSchedules } from "../../../store/schedules";
import { useSchedulerContext } from "../SchedulerContext";

const hours24 = _.range(0, 24);
const hours12 = [12, ..._.range(1, 13), ..._.range(1, 12)];

export const SchedulerGrid = () => {
  const { profileId, timeFormat24h, weekdays } = useSchedulerContext();

  const schedules = useSelector(
    state => getProfileSchedulerSchedules(state, profileId),
    _.isEqual
  );

  const gridData = useMemo(() => {
    const grid = weekdays.map(day => {
      return {
        row: day,
        cells: hours24.map(hour => {
          return {
            hour,
            scheduled: [],
          };
        }),
      };
    });

    const addScheduleInfo = (start, end, day, type) => {
      for (let i = start; i < end; i++) {
        day.cells[i].scheduled.push(type);
      }
    };

    schedules.forEach(
      ({
        day: scheduledDays,
        timeStart: scheduledStart,
        timeEnd: scheduledEnd,
        type: scheduledType,
      }) => {
        const startHr = scheduledStart === "24:00" ? "00:00" : scheduledStart;
        const endHr = scheduledEnd === "00:00" ? "24:00" : scheduledEnd;
        const start = parseFloat(startHr.replace(":", "."));
        const end = parseFloat(endHr.replace(":", "."));

        if (end <= start) {
          const startSelectedDay = Math.floor(start);
          const endSelectedDay = 24;
          const startNextDay = 0;
          const endNextDay = Math.ceil(end);

          scheduledDays.forEach(scheduledDay => {
            const dayInfoIndex = grid.findIndex(
              ({ row: day }) => day === scheduledDay
            );
            const dayInfo = grid[dayInfoIndex];
            const nextDayIndex = dayInfoIndex === 6 ? 0 : dayInfoIndex + 1;
            const nextDayInfo = grid[nextDayIndex];

            addScheduleInfo(
              startSelectedDay,
              endSelectedDay,
              dayInfo,
              scheduledType
            );
            addScheduleInfo(
              startNextDay,
              endNextDay,
              nextDayInfo,
              scheduledType
            );
          });
        } else {
          scheduledDays.forEach(scheduledDay => {
            const dayInfo = grid.find(({ row: day }) => day === scheduledDay);
            const startAt = Math.floor(start);
            const endAt = Math.ceil(end);

            addScheduleInfo(startAt, endAt, dayInfo, scheduledType);
          });
        }
      }
    );

    return grid;
  }, [schedules, weekdays]);

  const scheduleProps = {
    gridData,
    columnHours: timeFormat24h ? hours24 : hours12,
  };

  return (
    <Fragment>
      <Mobile>
        <ScheduleMobile {...scheduleProps} />
      </Mobile>
      <NotMobile>
        <ScheduleDesktop {...scheduleProps} />
      </NotMobile>
    </Fragment>
  );
};
