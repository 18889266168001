import { ThreatsData } from "@sportal/api";

export const LOAD_THREATS = "[THREATS] LOAD_THREATS";
export const LOAD_THREATS_SUCCESS = "[THREATS] LOAD_THREATS_SUCCESS";
export const LOAD_THREATS_FAILURE = "[THREATS] LOAD_THREATS_FAILURE";

interface loadThreatsAction {
  type: typeof LOAD_THREATS;
  payload: {
    language: string;
  };
}
interface loadThreatsSuccessAction {
  type: typeof LOAD_THREATS_SUCCESS;
  payload: {
    language: string;
    threats: ThreatsData;
  };
}
interface loadThreatsFailureAction {
  type: typeof LOAD_THREATS_FAILURE;
  payload: {
    language: string;
    error: any;
  };
}

export type ThreatsActionTypes =
  | loadThreatsAction
  | loadThreatsSuccessAction
  | loadThreatsFailureAction;
