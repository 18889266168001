import React from "react";
import { CompactServicePanel } from "./compactServicePanel/CompactServicePanel";
import { useServicePanel } from "./useServicePanel";

import "./NotHomeServicePanel.scss";

export const NotHomeServicePanel = () => {
  const services = useServicePanel();

  return (
    <div className="not-home-service-panel">
      {services.map(({ service, title, enabled }) => (
        <CompactServicePanel
          key={service}
          title={title}
          serviceStatus={enabled}
        />
      ))}
    </div>
  );
};
