import React, { FC, ReactElement } from "react";
import { HeaderGroup, SortingRule } from "react-table";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { MobileModal, ModalService } from "../../../../modal";
import {
  MobileModalListBody,
  MobileModalListItem,
} from "../../../../../pages/settings/tabs/devicesTab/tableComponents";
import { SortButton } from "../../../components/sortButton";
import { getHeaderTranslationId } from "../../../table.helpers";
import { useSortValues } from "../../../table.hooks";

interface SortToggleProps {
  headerGroups: HeaderGroup<any>[];
  setSortBy: (sortBy: SortingRule<any>[]) => void;
  isDataEmpty: boolean;
}

export const SortToggle: FC<SortToggleProps> = ({
  headerGroups,
  setSortBy,
  isDataEmpty,
}) => {
  const { sortableIds, sortedBy } = useSortValues(headerGroups);

  const openSortModal = () =>
    ModalService.show(modal => ({
      dialog: (
        <SortModal
          modal={modal}
          headerGroups={headerGroups}
          sortedBy={sortedBy}
          sortableIds={sortableIds}
        />
      ),
    }))
      .then((newSortBy: SortingRule<any>) => {
        // todo: remove this if statement when a11y issues with modal are solved
        //  -> currently selecting item with tab and hitting Enter does not perform action on the button, instead modal is closed

        if (newSortBy) {
          setSortBy([newSortBy]);
        }
      })
      .catch(_.noop);

  return (
    <SortButton
      isSorted={sortedBy.isSorted}
      isSortedDesc={sortedBy.isSortedDesc}
      onClick={openSortModal}
      disabled={isDataEmpty}
    >
      <FormattedMessage
        id={getHeaderTranslationId(headerGroups, sortedBy.id)}
      />
    </SortButton>
  );
};

interface SortModalProps {
  modal: any;
  headerGroups: HeaderGroup<any>[];
  sortedBy: HeaderGroup<any>;
  sortableIds: string[];
}

const SortModal: FC<SortModalProps> = ({
  modal,
  headerGroups,
  sortedBy,
  sortableIds,
}) => {
  const menuItems: ReactElement[] = sortableIds.map(id => {
    const isSelected = sortedBy.id === id;
    const action = () =>
      modal.resolve({
        id,
        ...(isSelected && { desc: !sortedBy.isSortedDesc }),
      });
    const icon =
      isSelected &&
      (sortedBy.isSortedDesc
        ? "far fa-long-arrow-alt-down"
        : "far fa-long-arrow-alt-up");

    return (
      <MobileModalListItem
        id={id}
        action={action}
        content={
          <FormattedMessage id={getHeaderTranslationId(headerGroups, id)} />
        }
        icon={icon}
        isSelected={isSelected}
      />
    );
  });

  return (
    <MobileModal
      modal={modal}
      header={<FormattedMessage id="sort_by" />}
      content={<MobileModalListBody items={menuItems} />}
    />
  );
};
