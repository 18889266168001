import React from "react";
import { LoginPageContainer } from "../loginPageContent/LoginPageContent";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getGoBackOnLoginErrorLink } from "../../../store/config";

import "./NoLogin.scss";

export const NoLogin = () => {
  const { link, linkText } = useSelector(getGoBackOnLoginErrorLink);

  return (
    <LoginPageContainer>
      <LoginPageContainer.Content className="no-login">
        <LoginPageContainer.Header>
          <FormattedMessage id={"access_denied"} />
        </LoginPageContainer.Header>
        <h5>
          <FormattedMessage id={"no_login_warning"} />
        </h5>
        <p>
          <FormattedMessage
            id={"no_login_go_back_message"}
            values={{
              link: (
                <a className="link" href={link}>
                  {linkText || link}
                </a>
              ),
            }}
          />
        </p>
      </LoginPageContainer.Content>
    </LoginPageContainer>
  );
};
