import { useDispatch } from "react-redux";
import { logout } from "../../../../store/shared/logout.actions";
import { FormattedMessage } from "react-intl";
import React, { Fragment } from "react";

export const useLogoutMenuItem = subscriberName => {
  const dispatch = useDispatch();

  const logoutItem = {
    id: "logout",
    action: () => dispatch(logout()),
    content: <FormattedMessage id="logout" />,
  };

  return [
    logoutItem,
    {
      ...logoutItem,
      content: (
        <Fragment>
          {logoutItem.content}
          <span className="user-menu-sidebar__menu__item__subscriber-name">
            ({subscriberName})
          </span>
        </Fragment>
      ),
    },
  ];
};
