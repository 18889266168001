import _ from "lodash";

import {
  APP_CODE,
  AppConfigProvider,
  AppConfigService,
  HttpService,
} from "@sportal/api";
import { Durations } from "@sportal/api/reports";

export const defaultLanguage = {
  key: "us_EG",
  name: "English",
  inversedDirection: false,
};

const defaults = {
  // TODO: Add default values as necessary.
  //  Old SC and SB shared defaults so it needs to be clarified which of them
  //  are really used in SC
  languages: {
    list: [defaultLanguage],
  },
  footerCompanyLink: "https://akamai.com",
  logoLink: "/home",
  termsLink: "",
  security: {
    enabled: true,
    noLoginPage: false,
    goBackOnLoginErrorLink: "",
    goBackOnLoginErrorLinkText: "",
    logoutUrl: "#login", // a path user redirected to after logout
  },
  defaultReportGranularity: Durations.Day,
  showTopForReports: true,
  showCountsForReports: false,
  showPerProfileReporting: false,
  appTitle: "Secure Internet Access Portal",
  urlFilters: {
    "url-types": ["core-domain"],
  },
  showToggles: [],
  firstDayOfWeek: "MO",
  hideDevicesTab: false,
};

export const mergeLink = (defaultValue, configValue) => {
  const validateLink = link => /^https?:\/\//i.test(link);
  const isLinkValid = validateLink(configValue);

  return isLinkValid ? configValue : defaultValue;
};

const mergeString = (defaultValue, configValue) => configValue || defaultValue;

const mergeReportGranularity = (defaultValue: Durations, configValue: any) =>
  _.includes(Durations, configValue) ? configValue : defaultValue;

const customizers = {
  footerCompanyLink: mergeLink,
  logoLink: mergeLink,
  appTitle: mergeString,
  termsLink: mergeLink,
  logoutUrl: mergeLink,
  defaultReportGranularity: mergeReportGranularity,
};

const isDefaultAppConfigValue = (path, value) =>
  _.get(defaults, path) === value;
export const isLogoutUrlDefault = value =>
  isDefaultAppConfigValue("security.logoutUrl", value);

export const SC_CODE = APP_CODE.PI;

export const makeAppConfigService = (
  http: HttpService
): AppConfigService<any> =>
  new AppConfigService(
    defaults as any, // todo: fix the type hack
    customizers,
    new AppConfigProvider(http)
  );
