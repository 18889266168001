import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { SwitchBase } from "../switchBase/SwitchBase";
import MaskSwitchIcon from "../maskSwitchIcon/MaskSwitchIcon";

import "./Checkbox.scss";

export function Checkbox({
  className,
  label,
  checked,
  error,
  warning,
  disabled,
  onChange,
  ...attrs
}) {
  return (
    <SwitchBase
      type="checkbox"
      className={classNames("checkbox", className)}
      label={label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      {...attrs}
    >
      <MaskSwitchIcon
        className={classNames("checkbox-switch", {
          "checkbox-switch--checked": checked,
          "checkbox-switch--error": error,
          "checkbox-switch--warning": warning,
          "checkbox-switch--disabled": disabled,
        })}
      />
    </SwitchBase>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
