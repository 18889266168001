import _ from "lodash";

import {
  Service,
  UserMode,
  ReportingService,
  ServiceType,
  BYPASS_PRIVATE_DNS,
  BypassPrivateDns,
} from "@sportal/api";
import { getUserMode } from "../info";
import { showPerProfileReport } from "../config";
import { RootState } from "../root.reducer";

export const getSettings = ({ settings }) => settings;
const getServices = state => getSettings(state).services;

export const getPIService = state =>
  _.find(getServices(state), { service: Service.PersonalInternet });
export const getSSService = state =>
  _.find(getServices(state), { service: Service.SubscriberSafety });

export const getProfilesLimit = state => getPIService(state)["max-profiles"];

const PIUserModes = [
  UserMode.PISingle,
  UserMode.PIProfile,
  UserMode.PerDevice,
  UserMode.Household,
];
export const isPersonalInternetAvailable = _.flow(
  getUserMode,
  userMode => _.includes(PIUserModes, userMode)
);

const SSUserModes = [
  UserMode.SubscriberSafety,
  UserMode.PerDevice,
  UserMode.Household,
];
export const isSubscriberSafetyAvailable = _.flow(
  getUserMode,
  userMode => _.includes(SSUserModes, userMode)
);

const isBypassPinEnabled = (isActivated, service) =>
  isActivated && service["bypass-pin-enabled"];

export const isPersonalInternetBypassPinAllowed = state =>
  isBypassPinEnabled(isPersonalInternetAvailable(state), getPIService(state));

export const isSubscriberSafetyBypassPinAllowed = state =>
  isBypassPinEnabled(isSubscriberSafetyAvailable(state), getSSService(state));

export const getAvailableReports = state => getSettings(state).reports;
export const isReportingLicensed = (state, reportingService) =>
  getSettings(state).reporting[reportingService];
export const isPIReportingLicensed = state =>
  isReportingLicensed(state, ReportingService.PersonalInternet);
export const isSSReportingLicensed = state =>
  isReportingLicensed(state, ReportingService.SubscriberSafety);

export const isReportVisible = state => {
  const isPIReportingLicensedValue = isPIReportingLicensed(state);
  const isSSReportingLicensedValue = isSSReportingLicensed(state);

  return isPIReportingLicensedValue || isSSReportingLicensedValue;
};

export const isPerProfileReportVisible = state => {
  return showPerProfileReport(state) && isReportVisible(state);
};

export const isBypassPrivateDNSFeatureEnabled = (state): boolean =>
  getPIService(state)[BYPASS_PRIVATE_DNS][BypassPrivateDns.ApplePrivateRelay];

export const isAllowListOverrideEnabled = (state: RootState): boolean => {
  const piService = getPIService(state);

  return piService["subscriber-allow-list-overrides"].includes(
    ServiceType.InternetSecurity
  );
};
