import React, { FC, useRef, useLayoutEffect, CSSProperties } from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import classNames from "classnames";

import { RenderRowDetailsInterface } from "./TBody.types";

import "./TBody.scss";

const getElementWidth = (element: Element): number =>
  Math.ceil(element.getBoundingClientRect().width);

interface TBodyProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  rows: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  isAllRowsExpanded: boolean;
  gridStyle: CSSProperties;
  rowDetailsStyle: CSSProperties;
  renderRowDetails: RenderRowDetailsInterface;
}

export const TBody: FC<TBodyProps> = ({
  getTableBodyProps,
  rows,
  prepareRow,
  isAllRowsExpanded,
  gridStyle,
  rowDetailsStyle,
  renderRowDetails,
}) => {
  const bodyRef = useRef<HTMLTableSectionElement>(null);

  useLayoutEffect(() => {
    const body = bodyRef.current;
    const bodyWidth = getElementWidth(body);
    const rowWidth = getElementWidth(body.querySelector('[role="row"]'));
    const isScrollVisible = body.scrollHeight > body.clientHeight;
    const isScrollOverlay = isScrollVisible && bodyWidth === rowWidth;

    body.classList.toggle("tbody--scroll-active", isScrollVisible);
    body.classList.toggle("tbody--scroll-overlay", isScrollOverlay);
  }, [rows, isAllRowsExpanded]);

  return (
    <div {...getTableBodyProps()} className="tbody" ref={bodyRef}>
      {rows.map(row => {
        prepareRow(row);

        return (
          <div {...row.getRowProps()} className="tr" style={gridStyle}>
            {row.cells.map(cell => {
              const { width, id } = cell.column;

              return (
                <div
                  {...cell.getCellProps()}
                  className={classNames(
                    `td td--${id}`,
                    width === 0 && "sr-only"
                  )}
                >
                  {cell.render("Cell")}
                </div>
              );
            })}

            {row.isExpanded && (
              <div
                className="tr__details"
                aria-hidden="true"
                style={rowDetailsStyle}
              >
                {renderRowDetails({ row })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
