import React from "react";
import PropTypes from "prop-types";

import "./InfoSection.scss";

// it would be nice to have better name for this component, any suggestions?
export const InfoSection = ({ children }) => (
  <div className="info-section">
    <div className="info-section__content">{children}</div>
  </div>
);

InfoSection.propTypes = {
  children: PropTypes.node,
};
