import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  Icon,
  UrlList as SharedUrlList,
  UrlListContent,
  UrlListHeader,
} from "@sportal/cdk";
import { punyToUnicode } from "@sportal/lib";
import { ButtonLink } from "../buttonLink/ButtonLink";
import { ConfirmationDialog } from "../confirmationDialog/ConfirmationDialog";
import { ModalService } from "../modal";

import "./UrlList.scss";

const RemoveAllModal = ({ modal, listType }) => {
  return (
    <ConfirmationDialog
      className="remove-all-urls-dialog"
      modal={modal}
      content={
        <FormattedMessage
          id="clean_up_message"
          values={{ list: <FormattedMessage id={listType} /> }}
        />
      }
      cancelMessage={<FormattedMessage id="cancel" />}
      submitMessage={<FormattedMessage id="clean_up" />}
    />
  );
};

// functions in component aren't memoized because memo of wrapper. It should avoid all unnecessary rerenders
export const UrlList = memo(
  ({ className, title, icon, onRemove, onRemoveAll, items }) => {
    function renderRow(item, index) {
      return (
        <div key={index} className="url-list__item">
          <div className="url-list__url">
            <Icon icon={icon} />
            {punyToUnicode(item)}
          </div>
          <ButtonLink
            onClick={
              onRemove instanceof Function
                ? onRemove.bind(null, item)
                : undefined
            }
          >
            <FormattedMessage id="remove" />
          </ButtonLink>
        </div>
      );
    }

    function renderRemoveAll(onRemoveAll) {
      function handleRemoveAllRequest(onRemoveAll) {
        ModalService.show(modal => ({
          dialog: <RemoveAllModal modal={modal} listType={title} />,
        }))
          .then(onRemoveAll)
          .catch(() => null);
      }

      return (
        <ButtonLink
          onClick={() => handleRemoveAllRequest(onRemoveAll)}
          disabled={!items.length}
        >
          <FormattedMessage id="remove_all" />
        </ButtonLink>
      );
    }

    return (
      <SharedUrlList className={className}>
        <UrlListHeader
          title={<FormattedMessage id={title} />}
          renderBulkActionComponent={() => renderRemoveAll(onRemoveAll)}
        />
        <UrlListContent
          items={items}
          renderRow={renderRow}
          emptyListMessage={<FormattedMessage id="no_urls" />}
        />
      </SharedUrlList>
    );
  }
);

UrlList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};
