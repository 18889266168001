import {
  LOAD_REPORTS,
  LOAD_REPORTS_FAILURE,
  LOAD_REPORTS_SUCCESS,
  ReportsActionTypes,
} from "./reports.types";
import { withLoadingState } from "../shared/withLoadingState";

export const initialState = {
  profile: "",
};

const reducer = (state = initialState, action: ReportsActionTypes) => {
  switch (action.type) {
    case LOAD_REPORTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const reportsReducer = withLoadingState({
  loadActionType: LOAD_REPORTS,
  successActionType: LOAD_REPORTS_SUCCESS,
  failureActionType: LOAD_REPORTS_FAILURE,
})(reducer);
