import {
  InfectionsService,
  InfectionsProvider,
  HttpService,
} from "@sportal/api";

export const makeInfectionsService = (
  http: HttpService,
  baseUrl: string
): InfectionsService =>
  new InfectionsService(new InfectionsProvider(http, baseUrl));
