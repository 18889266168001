import React from "react";

import { Desktop, TabletMobile } from "../../../common/Responsive";
import { PauseInternetProvider } from "../../pauseInternet/PauseInternetProvider";
import { ProfileCardDesktop } from "./desktopCard/ProfileCardDesktop";
import { ProfileCardMobile } from "./mobileCard/ProfileCardMobile";

export const ProfileCard = props => (
  <PauseInternetProvider
    profileId={props.profileId}
    showSeparateUnpauseButton={false}
  >
    <Desktop>
      <ProfileCardDesktop {...props} />
    </Desktop>
    <TabletMobile>
      <ProfileCardMobile {...props} />
    </TabletMobile>
  </PauseInternetProvider>
);
