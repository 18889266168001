import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addOrUpdatePermNotification,
  removePermNotification,
} from "../../../components/notifications";
import {
  isPersonalInternetAvailable,
  isSubscriberSafetyAvailable,
} from "../../settings";
import {
  isPersonalInternetEnabled,
  isSubscriberSafetyEnabled,
} from "../../account";

const DISABLED_PI_NOTIFICATION_ID = "disabled-personal-internet-warning";

function getNotificationMessage({ isSSAvailable, subscriberSafety }) {
  if (isSSAvailable) {
    return subscriberSafety
      ? "personal_internet_flash_notification_ss_enabled"
      : "personal_internet_flash_notification_ss_disabled";
  }

  return "personal_internet_flash_notification";
}

export function useDisabledPersonalInternetPermNotification() {
  const dispatch = useDispatch();

  const isPIAvailable = useSelector(isPersonalInternetAvailable);
  const isSSAvailable = useSelector(isSubscriberSafetyAvailable);

  const personalInternet = useSelector(isPersonalInternetEnabled);
  const subscriberSafety = useSelector(isSubscriberSafetyEnabled);

  useEffect(() => {
    if (!isPIAvailable) {
      return;
    }

    if (personalInternet) {
      dispatch(removePermNotification(DISABLED_PI_NOTIFICATION_ID));
    } else {
      dispatch(
        addOrUpdatePermNotification(
          {
            variant: "warning",
            description: getNotificationMessage({
              isSSAvailable,
              subscriberSafety,
            }),
          },
          DISABLED_PI_NOTIFICATION_ID
        )
      );
    }
  }, [
    dispatch,
    isPIAvailable,
    isSSAvailable,
    personalInternet,
    subscriberSafety,
  ]);
}
