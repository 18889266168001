import React from "react";
import { omit } from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Icon, usePopperAPI } from "@sportal/cdk";
import { useToggle } from "@sportal/cdk/hooks";
import { CategoryToggle } from "..";
import { CategoryItem, CategoryProtection } from "./CategoryItem";
import { CATEGORY_STATUS } from "../categoryToggle/CategoryToggle";

import "./CategoryItemWithChildren.scss";

export const CategoryItemWithChildren = ({
  protectionName,
  displayName,
  status,
  categories,
  onChange,
  className,
}) => {
  const [isOpen, setOpen] = useToggle(false);

  const {
    setAnchorElement,
    setPopperElement,
    styles,
    attributes,
  } = usePopperAPI();

  function handleToggleClick(e) {
    e.stopPropagation();
    onChange && onChange(categories.map(({ name }) => name));
  }

  return (
    <div className="categories-item-wrapper">
      <div
        ref={setAnchorElement}
        className={classnames(className, "categories-item", {
          "categories-item--open": isOpen,
        })}
      >
        <div
          onClick={() => setOpen(!isOpen)}
          className="categories-item__title"
        >
          <div className="categories-item__name">
            <Icon icon={"fas fa-sort-down"} />
            {displayName}
          </div>
          <CategoryToggle status={status} onClick={handleToggleClick} />
        </div>
        {protectionName && (
          <CategoryProtection protectionName={protectionName} />
        )}
      </div>

      {isOpen && (
        <div
          className="categories-item__list"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {categories.map(category => (
            <CategoryItem
              key={category.name}
              {...omit(category, "description")}
            />
          ))}
        </div>
      )}
    </div>
  );
};

CategoryItemWithChildren.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.node,
  status: PropTypes.oneOf(Object.values(CATEGORY_STATUS)).isRequired,
  onChange: PropTypes.func.isRequired,
};
