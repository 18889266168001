import React, { FC, ReactElement, useEffect, Fragment } from "react";
import { SortingRule, useExpanded, useSortBy, useTable } from "react-table";
import _ from "lodash";

import {
  RenderRowDetailsMobileInterface,
  SortToggle,
  TBodyMobile,
  THeadMobile,
} from "./components";
import { EmptyTBodyMobile } from "./components";
import { defaultTableInstanceConfig } from "../tableInstance.config";
import { TableCaption } from "../components";

import "./TableMobile.scss";

interface TableMobileProps {
  data: any[];
  columns: any[];
  isAdvancedView?: boolean;
  tableName: string;
  renderRowDetails: RenderRowDetailsMobileInterface;
  emptyTableContent?: ReactElement;
  initialSortBy: SortingRule<any>[];
}

export const TableMobile: FC<TableMobileProps> = ({
  data,
  columns,
  isAdvancedView,
  tableName,
  renderRowDetails,
  emptyTableContent,
  initialSortBy,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: initialSortBy },
      ...defaultTableInstanceConfig,
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    toggleAllRowsExpanded(isAdvancedView);
  }, [toggleAllRowsExpanded, isAdvancedView, data]);

  const isDataEmpty = _.isEmpty(data);
  const tableCaptionId = tableName.replace(/\s/g, "") + "Caption";

  return (
    <Fragment>
      <SortToggle
        headerGroups={headerGroups}
        setSortBy={setSortBy}
        isDataEmpty={isDataEmpty}
      />
      <table
        aria-labelledby={tableCaptionId}
        className="table-mobile"
        {...getTableProps()}
      >
        <TableCaption
          tableName={tableName}
          captionId={tableCaptionId}
          headerGroups={headerGroups}
        />
        <THeadMobile headerGroups={headerGroups} isDataEmpty={isDataEmpty} />
        {isDataEmpty ? (
          <EmptyTBodyMobile>{emptyTableContent}</EmptyTBodyMobile>
        ) : (
          <TBodyMobile
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            prepareRow={prepareRow}
            isAllRowsExpanded={isAllRowsExpanded}
            renderRowDetails={renderRowDetails}
          />
        )}
      </table>
    </Fragment>
  );
};
