import _ from "lodash";

import {
  DeviceDetails,
  DiscoveredDevice,
  Line,
  LogicalDevice,
  LogicalDeviceDetailsTypes,
  RequestWithCode,
  RequestWithName,
  RoamingDevicesLimits,
} from "@sportal/api";
import { getLogicalDevicesList } from "./logical";
import { getLinesList } from "./lines";
import {
  getLogicalDeviceDetails,
  getLogicalDevices,
  getRequests,
} from "../root.selectors";
import { normalizeMac } from "@sportal/lib";
import { getLogicalDeviceDetailsType } from "./logicalDeviceDetails";

export const getNewRequests = state => {
  const lines: Line[] = getLinesList(state);
  const requests = getRequests(state);
  const withName: RequestWithName[] = requests.withName;
  const withCode: RequestWithCode[] = requests.withCode;
  const discovered: DiscoveredDevice[] = requests.discovered;

  const existingIds = getExistingDevicesIds(state);

  const newWithNameMap = makeNewRequestsMap(withName, existingIds);
  const newWithCodeMap = makeNewRequestsMap(withCode, existingIds);

  const newWithName = _.mapValues(newWithNameMap, (request, id) => {
    const requestWithCode = newWithCodeMap[id];
    return !requestWithCode
      ? request
      : {
          ...request,
          code: requestWithCode.code,
          lastSeen: Math.max(request.lastSeen, requestWithCode.lastSeen),
        };
  });
  const newWithCode = _.pickBy<RequestWithCode>(
    newWithCodeMap,
    notInAnyOf(newWithName)
  );
  const newDiscovered = _.pickBy<DiscoveredDevice>(
    makeNewRequestsMap(discovered, existingIds),
    notInAnyOf(newWithName, newWithCode)
  );
  const linesMap = makeNewRequestsMap(lines, existingIds);
  const newLines = _.pickBy<Line>(
    linesMap,
    notInAnyOf(newWithName, newWithCode, newDiscovered)
  );

  const addLineAndTypeInformation = (request, normalizedId) =>
    _.has(linesMap, normalizedId)
      ? { ...request, name: request.id, type: LogicalDeviceDetailsTypes.Line }
      : { ...request, type: LogicalDeviceDetailsTypes.Mac };

  const addDeviceType = (request, normalizedId) =>
    _.has(linesMap, normalizedId)
      ? { ...request, type: LogicalDeviceDetailsTypes.Line }
      : { ...request, type: LogicalDeviceDetailsTypes.Mac };

  return {
    withName: _.mapValues(newWithName, addDeviceType),
    withCode: _.mapValues(newWithCode, addLineAndTypeInformation),
    discovered: {
      ..._.mapValues(newDiscovered, addLineAndTypeInformation),
      ..._.mapValues(newLines, addLineAndTypeInformation),
    },
  };
};

const getExistingDevicesIds = state => {
  const devices: LogicalDevice[] = getLogicalDevicesList(state);
  return new Set(
    _.flatMap(devices, ({ identifiers }) => identifiers).map(normalizeMac)
  );
};

function notInAnyOf(
  ...maps: { [id: string]: any }[]
): (request, id: string) => boolean {
  return (request, id: string) => _.every(maps, map => !_.has(map, id));
}

function normalizeRequests<T extends { id: string }>(
  requests: T[]
): { [normalizedId: string]: T } {
  return _.keyBy(requests, ({ id }) => normalizeMac(id));
}

function makeNewRequestsMap<T extends { id: string }>(
  requests: T[],
  existedIds: Set<string>
): { [normalizedId: string]: T } {
  return _.pickBy(
    normalizeRequests(requests),
    (request, id) => !existedIds.has(id)
  );
}

export const isAssignedDeviceMerged = (
  device: LogicalDevice | Pick<LogicalDevice, "identifiers">
): boolean => device.identifiers.length > 1;

export const isRoaming = ({
  identifiers: [firstIdentifier],
}: LogicalDevice) => state => {
  const firstDeviceType = getLogicalDeviceDetailsType(firstIdentifier)(state);

  return firstDeviceType === LogicalDeviceDetailsTypes.Roaming;
};

export const getRoamingLimit = _.flow([
  getLogicalDevices,
  state => state.roamingDevicesLimit,
]);

export const getRoamingDevices = (state): DeviceDetails[] => {
  const devicesDetails = getLogicalDeviceDetails(state);

  return _.filter(
    devicesDetails.list,
    id => id.type === LogicalDeviceDetailsTypes.Roaming
  );
};

export const getRoamingDevicesCount = (state): number => {
  const devicesDetails = getLogicalDeviceDetails(state);
  const roamingDevices = _.filter(
    devicesDetails.list,
    id => id.type === LogicalDeviceDetailsTypes.Roaming
  );

  return roamingDevices.length;
};

export const getRoamingDevicesLimits = (state): RoamingDevicesLimits => {
  const threshold = limit => Math.trunc(limit * 0.7);
  const limit = getRoamingLimit(state);
  const totalCount = getRoamingDevicesCount(state);
  const isRoamingLimitReached = totalCount >= limit;
  const isRoamingLimitApproaching =
    threshold(limit) <= totalCount && totalCount < limit;

  return {
    roamingDevicesLimit: limit,
    isRoamingLimitReached,
    isRoamingLimitApproaching,
    roamingDevicesCount: totalCount,
  };
};
