import React from "react";
import { FormattedMessage } from "react-intl";

import { BaseButton, Icon } from "@sportal/cdk";

import "./BackArrowButton.scss";

export const BackArrowButton = ({ messageId = "go_back", ...props }) => (
  <BaseButton className="go-back__button" {...props}>
    <Icon icon="far fa-arrow-left go-back__icon" />
    <span className="go-back__content">
      <FormattedMessage id={messageId} />
    </span>
  </BaseButton>
);
