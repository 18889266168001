import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import "./ProfileDevicesSection.scss";

import { DevicesCounter } from "../devicesCounter/DevicesCounter";
import { DeviceGroup, DeviceGroupHeader } from "../deviceGroup";
import { ProfileDevicesTable, ProfileDevicesTableMobile } from "./components";
import { useIntlTranslate } from "../../../../../hooks";
import { Mobile, NotMobile } from "../../../../../common/Responsive";

const ProfileDevicesCounter = ({ amount, limit }) => (
  <DevicesCounter
    className="profile-devices-counter"
    variant="outlined"
    label={<FormattedMessage id="devices" />}
    amount={amount}
    limit={limit}
  />
);

export const ProfileDevicesSection = ({
  isAdvancedView,
  profile,
  className,
  limit,
  devices,
}) => {
  const translate = useIntlTranslate();
  const sectionName = profile.default ? translate("default") : profile.name;
  const tableName = translate("aria_profile_table_name", { name: sectionName });

  return (
    <div
      className={classnames("profile-devices-section", className)}
      data-id={profile.name}
    >
      <DeviceGroup>
        <DeviceGroupHeader className="profile-devices-section__header">
          {sectionName}
          <ProfileDevicesCounter amount={_.size(devices)} limit={limit} />
        </DeviceGroupHeader>
        <NotMobile>
          <ProfileDevicesTable
            devices={devices}
            isAdvancedView={isAdvancedView}
            tableName={tableName}
          />
        </NotMobile>
        <Mobile>
          <ProfileDevicesTableMobile
            devices={devices}
            isAdvancedView={isAdvancedView}
            tableName={tableName}
          />
        </Mobile>
      </DeviceGroup>
    </div>
  );
};
