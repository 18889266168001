import React from "react";
import { Route, HashRouter, Switch } from "react-router-dom";

import { AuthRouter } from "./AuthRouter";
import { ScrollToTop } from "./ScrollToTop";
import { ModalHost } from "../components/modal";
import { sizes } from "../common/Responsive";

export const Router = () => {
  return (
    <HashRouter hashType="noslash">
      <ScrollToTop />
      <Switch>
        <Route children={<AuthRouter />} />
      </Switch>
      <ModalHost mobileBreakpoint={sizes.mobileMax} />
    </HashRouter>
  );
};
