import React, { Component, createRef } from "react";
import PropTypes from "prop-types";

import { ModalDialog } from "./ModalDialog";

const ModalWindow = ({ dialog, header, body, footer }) =>
  dialog || (
    <ModalDialog>
      <ModalDialog.Header>{header}</ModalDialog.Header>
      <ModalDialog.Body>{body}</ModalDialog.Body>
      <ModalDialog.Footer>{footer}</ModalDialog.Footer>
    </ModalDialog>
  );

export class Modal extends Component {
  modalDialogRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    return (
      <div
        ref={this.modalDialogRef}
        className="modal"
        tabIndex="-1"
        role="dialog"
        data-testid="modal"
        onClick={this.handleBackDropClick}
      >
        {this.state.isOpen ? <ModalWindow {...this.options} /> : null}
      </div>
    );
  }

  show = (options, resolve, reject) => {
    this.options = options;
    this.resolveHandler = resolve;
    this.rejectHandler = reject;
    this.setState(
      {
        isOpen: true,
      },
      () => {
        !this.checkIfModalFocused() && this.modalDialogRef.current.focus();
      }
    );

    document.body.classList.add("modal-open");
  };

  checkIfModalFocused = () => {
    return this.modalDialogRef.current.contains(document.activeElement);
  };

  resolve = data => {
    this.resolveHandler && this.resolveHandler(data);
    this.close();
  };

  reject = data => {
    this.rejectHandler && this.rejectHandler(data);
    this.close();
  };

  close = () => {
    this.setState({
      isOpen: false,
    });
    this.resolveHandler = undefined;
    this.rejectHandler = undefined;
    this.options = undefined;

    document.body.classList.remove("modal-open");
  };

  handleBackDropClick = () => {
    if (window.innerWidth <= this.props.mobileBreakpoint) {
      this.reject();
    }
  };
}

Modal.defaultProps = {
  mobileBreakpoint: 767,
};

Modal.propTypes = {
  mobileBreakpoint: PropTypes.number.isRequired,
};
