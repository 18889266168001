import React, { VFC } from "react";
import { FormattedMessage } from "react-intl";

import { RoamingDeviceDetails } from "@sportal/api";
import { AppStatus, ProtectionStatusInfo } from "../roamingComponents";
import { CellDetails } from "../../../../../../../components";

import "./RoamingDeviceGrid.scss";

interface RoamingDeviceGridProps {
  deviceDetails: RoamingDeviceDetails;
}

export const RoamingDeviceGrid: VFC<RoamingDeviceGridProps> = ({
  deviceDetails,
}) => {
  return (
    <div className="roaming-device-grid">
      <CellDetails
        id="roaming-uuid"
        header={<FormattedMessage id="roaming_uuid" />}
        value={deviceDetails.identifier}
      />
      <CellDetails
        id="roaming-full-name"
        header={<FormattedMessage id="roaming_full_name" />}
        value={deviceDetails.username}
      />
      <CellDetails
        id="roaming-protection-status"
        header={<FormattedMessage id="roaming_protection_status" />}
        value={<ProtectionStatusInfo status={deviceDetails.status} />}
      />
      <div className="roaming-device-grid__cell">
        <CellDetails
          id="roaming-app-status"
          header={<FormattedMessage id="roaming_app_status" />}
          value={<AppStatus status={deviceDetails.status} />}
        />
        <CellDetails
          id="roaming-app-version"
          header={<FormattedMessage id="roaming_app_version" />}
          value={deviceDetails["software-version"]}
        />
      </div>
      <CellDetails
        id="roaming-os"
        header={<FormattedMessage id="roaming_os" />}
        value={deviceDetails.os}
      />
    </div>
  );
};
