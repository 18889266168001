import React from "react";
import { FormattedMessage } from "react-intl";

import "./MultipleWizardSidebar.scss";

export const MultipleWizardSidebar = () => (
  <div className="multiple-wizard-sidebar">
    <h1 className="multiple-wizard-sidebar__title">
      <FormattedMessage id="multiple_profiles" />
    </h1>
    <div className="multiple-wizard-sidebar__info">
      <FormattedMessage id="multiple_profiles_info" />
    </div>
    <div className="multiple-wizard-sidebar__description">
      <FormattedMessage id="multiple_profiles_description" />
    </div>
  </div>
);
