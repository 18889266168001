import { getSubscriberId } from "../info";
import { isFailure, Profile, SafeSearchService } from "@sportal/api";
import { createTempNotification } from "../../components/notifications";
import {
  CHANGE_PROFILE_NAME,
  CHANGE_PROFILE_NAME_FAILURE,
  CHANGE_PROFILE_NAME_SUCCESS,
  CHANGE_PROFILE_PROTECTION_ENABLED,
  CHANGE_PROFILE_PROTECTION_ENABLED_FAILURE,
  CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS,
  CHANGE_PROFILE_SAFE_SEARCH,
  CHANGE_PROFILE_SAFE_SEARCH_FAILURE,
  CHANGE_PROFILE_SAFE_SEARCH_SUCCESS,
  ProfileActionTypes,
} from "./profile.types";
import { Api } from "../../api";

const createProfileChangeErrorNotification = () =>
  createTempNotification({
    variant: "error",
    description: "couldnt_save_changes",
  });

const createProfileChangeSuccessNotification = (
  description = "successfully_saved"
) =>
  createTempNotification({
    variant: "success",
    description: description,
  });

export const changeProfileName = (profile: Profile, newName: string) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: CHANGE_PROFILE_NAME });

  const subscriberId = getSubscriberId(getState());
  const updates = { name: newName };

  const result = await api.profiles.update(subscriberId, profile.name, updates);

  if (isFailure(result)) {
    dispatch(changeProfileNameFailure(result.error));
    dispatch(createProfileChangeErrorNotification());
  } else {
    dispatch(changeProfileNameSuccess(profile, updates));
    dispatch(createProfileChangeSuccessNotification());
  }
};

export const changeProfileNameSuccess = (
  profile: Profile,
  updates: { name: string }
): ProfileActionTypes => ({
  type: CHANGE_PROFILE_NAME_SUCCESS,
  payload: { profile, updates },
});

export const changeProfileNameFailure = (error): ProfileActionTypes => ({
  type: CHANGE_PROFILE_NAME_FAILURE,
  payload: error,
});

export const changeProfileSafeSearch = (
  profile: Profile,
  enabled: boolean
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: CHANGE_PROFILE_SAFE_SEARCH });

  const subscriberId = getSubscriberId(getState());
  const updates = {
    "safe-search-services": {
      [SafeSearchService.YouTube]: enabled,
      [SafeSearchService.Google]: enabled,
      [SafeSearchService.Bing]: enabled,
    },
  };
  const result = await api.profiles.update(subscriberId, profile.name, updates);
  if (isFailure(result)) {
    dispatch(changeProfileSafeSearchFailure(result.error));
    dispatch(createProfileChangeErrorNotification());
  } else {
    dispatch(changeProfileSafeSearchSuccess(profile, updates));
    dispatch(createProfileChangeSuccessNotification("safe_search_success"));
  }
};

// TODO: extract type for safe-search and apply it to argument here
export const changeProfileSafeSearchSuccess = (
  profile: Profile,
  updates
): ProfileActionTypes => ({
  type: CHANGE_PROFILE_SAFE_SEARCH_SUCCESS,
  payload: { profile, updates },
});

export const changeProfileSafeSearchFailure = (error): ProfileActionTypes => ({
  type: CHANGE_PROFILE_SAFE_SEARCH_FAILURE,
  payload: error,
});

export const changeProfileProtectionEnabled = (
  profile: Profile,
  enabled: boolean
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: CHANGE_PROFILE_PROTECTION_ENABLED });

  const subscriberId = getSubscriberId(getState());
  const updates = { "protection-enabled": enabled };
  const result = await api.profiles.update(subscriberId, profile.name, updates);

  if (isFailure(result)) {
    dispatch(changeProfileProtectionEnabledFailure(result.error));
    dispatch(createProfileChangeErrorNotification());
  } else {
    dispatch(changeProfileProtectionEnabledSuccess(profile, updates));
    dispatch(createProfileChangeSuccessNotification());
  }
};

export const changeProfileProtectionEnabledSuccess = (
  profile: Profile,
  updates: { "protection-enabled": boolean }
): ProfileActionTypes => ({
  type: CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS,
  payload: { profile, updates },
});

export const changeProfileProtectionEnabledFailure = (
  error
): ProfileActionTypes => ({
  type: CHANGE_PROFILE_PROTECTION_ENABLED_FAILURE,
  payload: error,
});
