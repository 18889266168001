import React, { VFC } from "react";
import { FormattedMessage } from "react-intl";

export const DeviceTypeHeader: VFC = () => {
  return (
    <span className="sr-only">
      <FormattedMessage id="device_type" />
    </span>
  );
};
