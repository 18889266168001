import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../../components";

import "./ProtectionChoiceContent.scss";

export function ProtectionChoiceContent({
  title,
  subtitle,
  description,
  image,
  onGetProtectionClick,
}) {
  return (
    <div className="protection-choice__content">
      <div className="protection-choice__content-container">
        <h1>
          <FormattedMessage id={title} />
        </h1>
        <p className="protection-choice__subtitle">
          <FormattedMessage id={subtitle} />
        </p>
        <p className="protection-choice__description">
          <FormattedMessage id={description} />
        </p>
      </div>
      <Button
        size="large"
        color={"secondary"}
        variant="inverted"
        className="protection-choice__button"
        onClick={onGetProtectionClick}
      >
        <FormattedMessage id="lets_go" />
      </Button>
      <img src={image} alt="" className="protection-choice__image" />
    </div>
  );
}

ProtectionChoiceContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onGetProtectionClick: PropTypes.func.isRequired,
};
