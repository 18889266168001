import { AccountData, CreateAccountData } from "@sportal/api";

export const LOAD_ACCOUNT = "[AUTH] LOAD_ACCOUNT";
export const LOAD_ACCOUNT_SUCCESS = "[AUTH] LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_FAILURE = "[AUTH] LOAD_ACCOUNT_FAILURE";

interface loadAccountSuccessAction {
  type: typeof LOAD_ACCOUNT_SUCCESS;
  payload: AccountData | CreateAccountData;
}
interface loadAccountFailureAction {
  type: typeof LOAD_ACCOUNT_FAILURE;
  payload: object;
}

export type AccountActionTypes =
  | loadAccountSuccessAction
  | loadAccountFailureAction;
