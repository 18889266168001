import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";

import { useReportContext } from "./ReportProvider";
import { useBarchartTooltip } from "./useBarchartTooltip";

export const Bars = ({ type }) => {
  const { data } = useReportContext();
  const { select, unselect } = useBarchartTooltip(state => ({
    select: state.select,
    unselect: state.unselect,
  }));

  const wrapper = useRef();

  useEffect(() => {
    const group = d3.select(wrapper.current);

    group.selectAll("rect").remove();

    group
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", (d, i) => `bar rect-stack${i}`)
      .attr("x", d => d.position.x)
      .attr("y", d => d.position.y[type])
      .attr("width", d => d.position.width)
      .attr("height", d => d.position.height[type])
      .on("mouseover", function() {
        const i = getBarIndex.call(this, group);
        select(i);
        d3.selectAll(`.rect-stack${i}`).classed("rect-stack--hover", true);
      })
      .on("mouseout", function() {
        const i = getBarIndex.call(this, group);
        unselect();
        d3.selectAll(`.rect-stack${i}`).classed("rect-stack--hover", false);
      });
  }, [data, type, select, unselect]);

  if (_.isEmpty(data)) {
    return null;
  }

  return <g className={`${type}-group`} ref={wrapper} />;
};

function getBarIndex(selection) {
  const { childNodes } = selection.nodes()[0];
  const barNode = this;

  return Array.from(childNodes).indexOf(barNode);
}
