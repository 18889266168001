import { CSSProperties } from "react";
import { HeaderGroup } from "react-table";
import _ from "lodash";

export const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 200,
};

const getColumnWidth = ({ minWidth, width, maxWidth }: HeaderGroup) => {
  const addPxUnit = (value: number | string) =>
    _.isString(value) ? value : value + "px";

  if (width === 0) return "";
  if (width !== defaultColumn.width) return addPxUnit(width);

  const minimumWidth = addPxUnit(minWidth || defaultColumn.minWidth);
  const maximumWidth = addPxUnit(maxWidth || defaultColumn.maxWidth);

  return `minmax(${minimumWidth}, ${maximumWidth})`;
};

const getVisibleElementsNumber = (headers: HeaderGroup[]) =>
  headers.filter(header => header.width !== 0).length + 1;

interface UseGridLayoutResult {
  gridStyle: CSSProperties;
  rowDetailsStyle: CSSProperties;
}

export const useGridLayout = (
  headerGroups: HeaderGroup<any>[]
): UseGridLayoutResult => {
  const { headers } = headerGroups[0];

  return {
    gridStyle: {
      display: "grid",
      gridTemplateColumns: headers
        .map(getColumnWidth)
        .filter(Boolean)
        .join(" "),
      columnGap: "16px",
    },
    rowDetailsStyle: {
      gridColumnStart: 1,
      gridColumnEnd: getVisibleElementsNumber(headers),
    },
  };
};

export const useSortValues = (
  headerGroups: HeaderGroup<any>[]
): { sortableIds: string[]; sortedBy: HeaderGroup<any> } => {
  const headers: HeaderGroup<any>[] = headerGroups[0]
    ? headerGroups[0].headers
    : [];
  const getCurrentSorted = (column: HeaderGroup<any>) => column.isSorted;
  const getSortableIDs = (column: HeaderGroup<any>): boolean => column.canSort;
  const extractId = (column: HeaderGroup<any>): string => column.id;

  const sortableIds = headers.filter(getSortableIDs).map(extractId);
  const sortedBy = headers.find(getCurrentSorted);

  return {
    sortableIds,
    sortedBy,
  };
};
