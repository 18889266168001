import { InfectionsServerData } from "@sportal/api";

import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_INFECTIONS,
  LOAD_INFECTIONS_FAILURE,
  LOAD_INFECTIONS_SUCCESS,
  InfectionsActionTypes,
} from "./infections.types";

export interface InfectionsState {
  list: InfectionsServerData;
}

const initialState = {
  list: [],
};

const reducer = (
  state: InfectionsState = initialState,
  action: InfectionsActionTypes
) => {
  switch (action.type) {
    case LOAD_INFECTIONS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export const infectionsReducer = withLoadingState({
  loadActionType: LOAD_INFECTIONS,
  successActionType: LOAD_INFECTIONS_SUCCESS,
  failureActionType: LOAD_INFECTIONS_FAILURE,
})(reducer);
