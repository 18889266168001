import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import "./RemoveDeviceDialog.scss";

import { ConfirmationDialog } from "../../../../../components";

export const RemoveRoamingDeviceDialog = ({ modal, device }) => (
  <ConfirmationDialog
    className="remove-roaming-device-dialog"
    modal={modal}
    title={<FormattedMessage id="revoke_access" />}
    content={
      <Fragment>
        <FormattedMessage id="revoke_access_info" />
        <p className="remove-roaming-device-dialog__note">
          <FormattedMessage id="revoke_access_note" />
        </p>
        <FormattedMessage
          id="confirm_revoke_access_device"
          values={{
            name: (
              <span className="remove-roaming-device-dialog__device-name">
                {device.name}
              </span>
            ),
          }}
        />
      </Fragment>
    }
    cancelMessage={<FormattedMessage id="cancel" />}
    submitMessage={<FormattedMessage id="revoke_access" />}
  />
);
