import React from "react";
import { FormattedMessage } from "react-intl";

export const ReportHeader = ({ profileName }) => {
  if (!profileName) {
    return <FormattedMessage id={"household_internet_usage"} />;
  }

  return <FormattedMessage id={"internet_usage"} values={{ profileName }} />;
};
