import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ServiceStatus } from "../../serviceStatus/ServiceStatus";
import "./CompactServicePanel.scss";

export const CompactServicePanel = ({ serviceStatus, title }) => (
  <div className="compact-service-panel">
    <div className="compact-service-panel__header">
      <FormattedMessage id={title} />
    </div>
    <div className="compact-service-panel__status">
      <ServiceStatus active={serviceStatus} />
    </div>
  </div>
);

CompactServicePanel.propTypes = {
  serviceStatus: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
