export const KEYBOARD_KEYS = {
  ARROW_LEFT: ["ArrowLeft", "Left"],
  ARROW_UP: ["ArrowUp", "Up"],
  ARROW_RIGHT: ["ArrowRight", "Right"],
  ARROW_DOWN: ["ArrowDown", "Down"],
  ESCAPE: ["Escape", "Esc"],
  ENTER: ["Enter"],
  SPACEBAR: ["Space", " "],
};

const isKeyPressed = (keyValues, { key }) =>
  keyValues.some(value => value === key);

export const isLeftArrowPressed = event =>
  isKeyPressed(KEYBOARD_KEYS.ARROW_LEFT, event);
export const isRightArrowPressed = event =>
  isKeyPressed(KEYBOARD_KEYS.ARROW_RIGHT, event);
export const isUpArrowPressed = event =>
  isKeyPressed(KEYBOARD_KEYS.ARROW_UP, event);
export const isDownArrowPressed = event =>
  isKeyPressed(KEYBOARD_KEYS.ARROW_DOWN, event);
export const isLeftOrRightArrowPressed = event =>
  isLeftArrowPressed(event) || isRightArrowPressed(event);
export const isEscapePressed = event =>
  isKeyPressed(KEYBOARD_KEYS.ESCAPE, event);
export const isEnterPressed = event => isKeyPressed(KEYBOARD_KEYS.ENTER, event);
export const isSpacebarPressed = event =>
  isKeyPressed(KEYBOARD_KEYS.SPACEBAR, event);
