import React, { VFC } from "react";
import classNames from "classnames";

enum AriaLive {
  Off = "off",
  Polite = "polite",
  Assertive = "assertive",
}

const ariaLive = (length: number, limit: number): AriaLive => {
  const limitExhaustion = Number((length / limit).toFixed(2));

  if (limitExhaustion < 0.9) {
    return AriaLive.Off;
  } else if (limitExhaustion < 0.99) {
    return AriaLive.Polite;
  } else {
    return AriaLive.Assertive;
  }
};

interface InputLimitProps {
  value: string;
  limit: number;
}

export const InputLimit: VFC<InputLimitProps> = ({ value, limit }) => {
  const length = value.trim().length;

  return (
    <span
      aria-relevant="text"
      aria-live={ariaLive(length, limit)}
      className={classNames("input__limit", {
        "input__limit--max-limit": length > limit,
      })}
    >{`${length}/${limit}`}</span>
  );
};
