import { useIntl } from "react-intl";
import { useCallback } from "react";

export const useIntlTranslate = () => {
  const intl = useIntl();

  return useCallback((id, values) => intl.formatMessage({ id }, values), [
    intl,
  ]);
};
