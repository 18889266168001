import { dateTimeFormatCache } from "./intlApi";

export const SECONDS_IN_DAY = 24 * 60 * 60;

export const formatIntlDateTime = ({
  time,
  hour12,
  timeZone,
  locale,
  ...rest
}) => {
  const options: any = {
    timeZone,
    ...rest,
  };

  /**
   * For some reason Chrome's implementation of Intl.DateTimeFormat ignores "hourCycle" in case "hour12" is specified.
   * I checked other browsers and they provide different behavior than Chrome does.
   * We need to skip setting "false" as a value of hour12 (by default format will still be in hour12) to make hourCycle work properly.
   * "hourCycle" with the value of "h23" is needed to avoid results like "24:46" which has to be "00:46"
   * BUG - https://support.google.com/chrome/thread/29828561?hl=en
   */
  if (hour12) {
    options.hour12 = hour12;
  } else {
    options.hourCycle = "h23";
  }

  return dateTimeFormatCache(locale, options).format(time);
};
