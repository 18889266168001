export const CLEAR_HISTORY = "[HISTORY] CLEAR_HISTORY";
export const CLEAR_HISTORY_SUCCESS = "[HISTORY] CLEAR_HISTORY_SUCCESS";
export const CLEAR_HISTORY_FAILURE = "[HISTORY] CLEAR_HISTORY_FAILURE";

interface ClearHistoryAction {
  type: typeof CLEAR_HISTORY;
}

interface ClearHistorySuccessAction {
  type: typeof CLEAR_HISTORY_SUCCESS;
  payload: string[];
}

interface ClearHistoryFailureAction {
  type: typeof CLEAR_HISTORY_FAILURE;
  payload: object;
}

export type ClearHistoryActionTypes =
  | ClearHistoryAction
  | ClearHistorySuccessAction
  | ClearHistoryFailureAction;
