import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Notification.scss";

import { CrossButton } from "@sportal/cdk";
import { StandardNotification } from "./notificationTypes/standard/StandardNotification";

export const Notification = ({
  className,
  variant,
  onClick,
  title,
  description,
  custom,
}) => (
  <div
    className={classNames(
      "notification",
      `notification--${variant}`,
      className
    )}
  >
    <div className="notification__body">
      <div className="notification__content">
        {custom || (
          <StandardNotification title={title} description={description} />
        )}
      </div>
      <CrossButton
        className="notification__close-button"
        size="large"
        onClick={onClick}
      />
    </div>
  </div>
);

Notification.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  custom: PropTypes.node,
};
