import React from "react";
import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "@sportal/cdk";
import { useOutsideClick, useToggle } from "@sportal/cdk/hooks";

import "./UserMenuSidebar.scss";

export const createTitle = item => ({
  ...item,
  className: "user-menu-sidebar__menu__title",
  clickable: false,
});
export const createLink = item => ({
  ...item,
  className: "user-menu-sidebar__menu__item--link",
  clickable: true,
});
export const createActionItem = item => ({
  ...item,
  className: classnames(
    "user-menu-sidebar__menu__item--action-item",
    item.className
  ),
  clickable: true,
});

export const UserMenuSidebar = ({ items }) => {
  const [opened, setOpened] = useToggle(false);
  const menuRef = useOutsideClick(() => setOpened(false), opened);

  const menuClasses = classnames("user-menu-sidebar", {
    "user-menu-sidebar--opened": opened,
  });

  const onItemClick = action => {
    setOpened(false);
    action && action();
  };

  return (
    <div className={menuClasses}>
      <div onClick={setOpened} className="user-menu-sidebar__toggle">
        <Icon icon="fas fa-cog" />
      </div>
      <div className="user-menu-sidebar__menu" ref={menuRef}>
        <ul>
          {_.map(items, (item, index) => (
            <li
              className={classnames(
                "user-menu-sidebar__menu__item",
                item.className
              )}
              key={index}
              onClick={
                item.clickable ? () => onItemClick(item.action) : undefined
              }
            >
              {item.content}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

UserMenuSidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      action: PropTypes.func,
      content: PropTypes.node,
      clickable: PropTypes.boolean,
    })
  ),
};
