import { HttpService, HttpServiceResponse } from "../shared";
import { SettingsServerData } from "./settings.types";

export class SettingsProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/settings`;
  }

  public get(): Promise<HttpServiceResponse<SettingsServerData>> {
    return this.http.get<SettingsServerData>(this.url);
  }
}
