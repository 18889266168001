import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Icon } from "@sportal/cdk";
import { ModalDialog, ModalService } from "../modal";

import "./PauseInternetModal.scss";

export function PauseInternetOptionsDialog({ modal, items }) {
  const { resolve, reject } = modal;

  return (
    <Fragment>
      <div className="pause-internet__modal__backdrop" onClick={reject} />
      <ModalDialog
        className="pause-internet__modal"
        submitHandler={resolve}
        rejectHandler={reject}
      >
        <ul>
          {items.map(item => (
            <li
              key={item.id}
              onClick={() => {
                item.action();
                resolve();
              }}
            >
              {item.content}
            </li>
          ))}
        </ul>
      </ModalDialog>
    </Fragment>
  );
}

export function PauseInternetModal({ items, label, renderToggle }) {
  return renderToggle({
    label,
    onClick: () => {
      ModalService.show(modal => ({
        dialog: <PauseInternetOptionsDialog modal={modal} items={items} />,
      }))
        .then(_.noop)
        .catch(_.noop);
    },
    icon: <Icon icon="far fa-angle-down" />,
  });
}

PauseInternetModal.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node,
      action: PropTypes.func,
    })
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderToggle: PropTypes.func.isRequired,
};
