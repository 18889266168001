import React, { Fragment, ReactElement, VFC } from "react";

import { LogicalDevice } from "@sportal/api";

export interface AdvancedColumn {
  header: ReactElement;
  renderValue: (device) => ReactElement | string;
}

interface ColumnTextProps {
  header: ReactElement;
  value: ReactElement | string;
}

const ColumnText: VFC<ColumnTextProps> = ({ header, value }) => {
  return (
    <Fragment>
      {header}: {value}
    </Fragment>
  );
};

interface AriaAdvancedColumnProps {
  columns: AdvancedColumn[];
  device: LogicalDevice;
}

export const AriaAdvancedColumn: VFC<AriaAdvancedColumnProps> = ({
  columns,
  device,
}) => {
  if (columns.length === 1) {
    return (
      <ColumnText
        header={columns[0].header}
        value={columns[0].renderValue(device)}
      />
    );
  }

  return (
    <div role="list" className="aria-advanced-column">
      {columns.map(({ header, renderValue }, index) => {
        return (
          <div role="listitem" key={index}>
            <ColumnText header={header} value={renderValue(device)} />
          </div>
        );
      })}
    </div>
  );
};
