import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { EndpointsTranslationsMap, ReportTypes } from "../../../store/reports";
import { Checkbox } from "../../checkbox/Checkbox";
import { useReportContext } from "../../barchart/ReportProvider";

export const ActivityCheckboxes = ({ className }) => {
  const {
    reports,
    enabledReports,
    displayedReports,
    setDisplayedReports,
  } = useReportContext();

  return (
    <div className={className}>
      {_.map(reports, report => {
        const enabled = enabledReports.includes(report);
        const displayed = displayedReports.includes(report);

        return (
          <Checkbox
            id={`report_${report}`}
            key={report}
            onChange={() => setDisplayedReports(report)}
            checked={enabled && displayed}
            label={<FormattedMessage id={EndpointsTranslationsMap[report]} />}
            error={report === ReportTypes.SS}
            warning={report === ReportTypes.Blocks}
            disabled={!enabled}
          />
        );
      })}
    </div>
  );
};
