import { SHOW_LOADER, HIDE_LOADER, LoaderActionTypes } from "./loader.types";

export const initialState = {
  loadersShown: 0,
};

export const loaderReducer = (
  state = initialState,
  action: LoaderActionTypes
) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loadersShown: state.loadersShown + 1,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loadersShown: state.loadersShown - 1,
      };
    default:
      return state;
  }
};
