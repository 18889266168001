import React from "react";
import classnames from "classnames";

import "./BaseHeader.scss";

export const BaseHeader = ({ className, children }) => {
  return (
    <header className="layout__header">
      <div className={classnames("layout__header__content", className)}>
        {children}
      </div>
    </header>
  );
};
