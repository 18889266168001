import { NormalizedCollection } from "../../shared/normalizeCollection";
import { CustomProtection } from "@sportal/api";

export const LOAD_CUSTOM_PROTECTIONS = "[PROTECTION] LOAD_CUSTOM_PROTECTIONS";
export const LOAD_CUSTOM_PROTECTIONS_SUCCESS =
  "[PROTECTION] LOAD_CUSTOM_PROTECTIONS_SUCCESS";
export const LOAD_CUSTOM_PROTECTIONS_FAILURE =
  "[PROTECTION] LOAD_CUSTOM_PROTECTIONS_FAILURE";
export const SAVE_CUSTOM_PROTECTION = "[PROTECTION] SAVE_CUSTOM_PROTECTION";
export const SAVE_CUSTOM_PROTECTION_SUCCESS =
  "[PROTECTION] SAVE_CUSTOM_PROTECTION_SUCCESS";
export const SAVE_CUSTOM_PROTECTION_FAILURE =
  "[PROTECTION] SAVE_CUSTOM_PROTECTIONS_FAILURE";

interface LoadCustomProtectionsAction {
  type: typeof LOAD_CUSTOM_PROTECTIONS;
}

interface LoadCustomProtectionsSuccessAction {
  type: typeof LOAD_CUSTOM_PROTECTIONS_SUCCESS;
  payload: NormalizedCollection<CustomProtection>;
}

interface LoadCustomProtectionsFailureAction {
  type: typeof LOAD_CUSTOM_PROTECTIONS_FAILURE;
  payload: object;
}

interface SaveCustomProtectionAction {
  type: typeof SAVE_CUSTOM_PROTECTION;
}

interface SaveCustomProtectionSuccessAction {
  type: typeof SAVE_CUSTOM_PROTECTION_SUCCESS;
  payload: CustomProtection;
}

interface SaveCustomProtectionFailureAction {
  type: typeof SAVE_CUSTOM_PROTECTION_FAILURE;
  payload: object;
}

export type CustomProtectionsActionTypes =
  | LoadCustomProtectionsAction
  | LoadCustomProtectionsSuccessAction
  | LoadCustomProtectionsFailureAction
  | SaveCustomProtectionAction
  | SaveCustomProtectionSuccessAction
  | SaveCustomProtectionFailureAction;
