import React, { FC } from "react";
import classNames from "classnames";

import "./EmptyTBody.scss";

interface EmptyTBodyProps {
  className?: string;
}

export const EmptyTBody: FC<EmptyTBodyProps> = ({ className, children }) => {
  return (
    <div role="rowgroup" className={classNames("empty-tbody", className)}>
      <div role="row">
        <div role="cell" className="empty-tbody__cell">
          {children}
        </div>
      </div>
    </div>
  );
};
