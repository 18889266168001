import _ from "lodash";

import {
  ADD_PERM_NOTIFICATION,
  UPDATE_PERM_NOTIFICATION,
  REMOVE_PERM_NOTIFICATION,
} from "./permanentNotifications.consts";

export const initialState = [];

const permanentNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PERM_NOTIFICATION:
      const newNotification = {
        ...action.payload.notification,
        id: action.payload.id,
      };

      return [...state, newNotification];
    case UPDATE_PERM_NOTIFICATION:
      const index = _.findIndex(state, ["id", action.payload.id]);

      if (index === -1) {
        return state;
      }

      const actualNotification = state[index];

      const updatedNotification = {
        ...actualNotification,
        ...action.payload.notification,
        id: actualNotification.id,
      };

      return [
        ...state.slice(0, index),
        updatedNotification,
        ...state.slice(index + 1),
      ];
    case REMOVE_PERM_NOTIFICATION:
      return state.filter(item => item.id !== action.payload.id);
    default:
      return state;
  }
};

export default permanentNotificationsReducer;
