export enum ReportTypes {
  Activity = "activity",
  Blocks = "blocks",
  SS = "ss",
  ActivityTop = "activity-top",
  BLocksTop = "blocks-top",
}

export const Endpoint = {
  [ReportTypes.Activity]: "dns/requests-by-group",
  [ReportTypes.Blocks]: "pi/blocks-by-group",
  [ReportTypes.SS]: "ss/blocks-by-group",
  [ReportTypes.ActivityTop]: "dns/top-requests-by-group",
  [ReportTypes.BLocksTop]: "pi/top-blocks-by-group",
};

export const EndpointsTranslationsMap = {
  [ReportTypes.Activity]: "internet_activity",
  [ReportTypes.Blocks]: "blocked_activity",
  [ReportTypes.SS]: "malware_and_phishing_blocked",
  [ReportTypes.BLocksTop]: "blocked_activity_top",
  [ReportTypes.ActivityTop]: "internet_activity_top",
};
