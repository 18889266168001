import React from "react";

import { isAssignedDeviceMerged } from "../../../../../../../store/devices";
import {
  MergedNetworkId,
  SingleNetworkId,
} from "../../../../../../../components/profileDevices/networkId";

export const DeviceIds = ({ identifiers, phoneNumber, networkId }) => {
  const isMerged = isAssignedDeviceMerged({ identifiers });

  return isMerged ? (
    <MergedNetworkId phoneNumber={phoneNumber} networkId={networkId} />
  ) : (
    <SingleNetworkId phoneNumber={phoneNumber} networkId={networkId} />
  );
};
