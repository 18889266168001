import { add, sub, getUnixTime } from "date-fns";

import { Durations } from "@sportal/api/reports";
import { formatIntlDateTime, SECONDS_IN_DAY } from "@sportal/lib";

export const getTimeBounds = duration => {
  const end = getDateEnd(duration);
  const start = getDateStart(end, duration);

  return { start, end };
};

const getDateStart = (end, duration) => {
  const shift = convertISOToDays(duration);

  return getUnixTime(sub(end * 1000, { days: shift }));
};

const getDateEnd = duration => {
  const now = new Date();
  const granularity = convertISODuration(duration);
  const rest = getUnixTime(now) % granularity;

  return getUnixTime(add(now, { seconds: granularity - rest }));
};

export const convertISODuration = duration => {
  //method returns length of the slice in seconds
  //900:15m, 7200:2h, 28800:8h

  switch (duration) {
    case Durations.Week:
      return 7200;
    case Durations.Month:
      return 28800;
    default:
      return 900;
  }
};

const convertISOToDays = duration => {
  switch (duration) {
    case Durations.Week:
      return 7;
    case Durations.Month:
      return 30;
    default:
      return 1;
  }
};

export const getAxisTicksStep = duration => {
  switch (duration) {
    case Durations.Week:
      return 2 * SECONDS_IN_DAY; // 2d
    case Durations.Month:
      return 7 * SECONDS_IN_DAY; // 7d
    default:
      return 6 * 60 * 60; // 6h
  }
};

export const formatTick = (duration, is24, tz, intl) => tick => {
  return formatIntlDateTime({
    time: tick * 1000,
    hour12: !is24,
    ...getIntlTimeFormat(duration, is24, tz),
    locale: intl.locale,
  });
};

const getIntlTimeFormat = (duration, is24, tz) => {
  switch (duration) {
    case Durations.Week:
      return {
        timeZone: tz,
        hour12: !is24,
        weekday: "short",
        day: "numeric",
      };
    case Durations.Month:
      return {
        timeZone: tz,
        day: "numeric",
        month: "short",
      };
    default:
      return {
        timeZone: tz,
        hour12: !is24,
        hour: "2-digit",
        minute: "2-digit",
      };
  }
};
