import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import { BaseInput } from "../baseInput/BaseInput";
import { Icon } from "../icon/Icon";
import { useToggle } from "../hooks";
import "./PINInput.scss";

// single character length + space between letters
// It's not good to keep those values in js and scss together but
// right now I don't see any other options
const CHARACTER_LENGTH = 1.5;

export function PINInput({ maxLength, onChange, error, ...attrs }) {
  const [isVisible, toggleVisibility] = useToggle(false);

  const handleChange = useCallback(
    event => {
      // this prevents value to exceed limited max length by change event
      if (_.isFunction(onChange) && event.target.value.length <= maxLength) {
        onChange(event);
      }
    },
    [onChange, maxLength]
  );

  const inputCharacterWidth = maxLength * CHARACTER_LENGTH;

  return (
    <div className="pin-group">
      <div
        className={classnames("pin-group__input", {
          "pin-group__input--has-error": error,
        })}
        style={{ width: `calc(${inputCharacterWidth}ch + 30px)` }}
      >
        <BaseInput
          {...attrs}
          type={isVisible ? "text" : "password"}
          onChange={handleChange}
          spellCheck={false}
        />
        <div
          className="pin-group__input-underline"
          style={{ width: `${inputCharacterWidth}ch` }}
        />
      </div>
      <button
        type="button"
        className="pin-group__append"
        onMouseDown={event => {
          event.preventDefault();
          toggleVisibility();
        }}
      >
        <Icon icon={classnames("far", isVisible ? "fa-eye-slash" : "fa-eye")} />
      </button>
    </div>
  );
}

PINInput.propTypes = {
  maxLength: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
