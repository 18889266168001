import { withLoadingState } from "../../shared/withLoadingState";
import {
  LOAD_REQUESTS,
  LOAD_REQUESTS_FAILURE,
  LOAD_REQUESTS_SUCCESS,
  RequestsActionTypes,
} from "./requests.types";

export interface RequestsState {
  withName: [];
  withCode: [];
  discovered: [];
}

const initialState: RequestsState = {
  withName: [],
  withCode: [],
  discovered: [],
};

const reducer = (
  state: RequestsState = initialState,
  action: RequestsActionTypes
) => {
  switch (action.type) {
    case LOAD_REQUESTS_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export const requestsReducer = withLoadingState({
  loadActionType: LOAD_REQUESTS,
  successActionType: LOAD_REQUESTS_SUCCESS,
  failureActionType: LOAD_REQUESTS_FAILURE,
})(reducer);
