import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { SegmentedButtonsGroup } from "../segmentedButtonsGroup/SegmentedButtonsGroup";
import { AgeGroup } from "@sportal/api";
import { useIntlTranslate } from "../../hooks";

import "./SegmentedAgeGroups.scss";

const ageGroups = [AgeGroup.Kids, AgeGroup.Teens, AgeGroup.Adults];
const buttons = ageGroups.map(value => ({
  value,
  node: <FormattedMessage id={value} />,
}));

export const SegmentedAgeGroups = ({ selectedValue, onChange }) => {
  const translate = useIntlTranslate();

  return (
    <SegmentedButtonsGroup
      label={<FormattedMessage id="age_group" />}
      className="segmented-age-groups"
      groupName="age-group"
      buttons={buttons}
      selectedValue={selectedValue}
      onChange={onChange}
      ariaGroupLabel={translate("age_group")}
    />
  );
};

SegmentedAgeGroups.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
