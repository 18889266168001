import React, { ReactElement, FC, useEffect, useCallback } from "react";
import classnames from "classnames";
import { UseSelectGetItemPropsOptions } from "downshift";

export interface DropdownItemInterface {
  id: string;
  content: string | ReactElement;
  action?: () => void;
  disabled?: boolean;
  className?: string;
}

interface DropdownItemProps {
  id?: string;
  disabled?: boolean;
  action?: () => void;
  className?: string;
  // below props aren't really optional, but to avoid unnecessary errors it's done like this
  item?: ReactElement;
  index?: number;
  highlighted?: boolean;
  getItemProps?: (options: UseSelectGetItemPropsOptions<ReactElement>) => any;
  onHighlightedChange?: (actionCallback: () => void) => void;
}

export const LazyLoadDropdownItem: FC<DropdownItemProps> = ({
  item,
  id,
  disabled,
  action,
  className,
  index,
  highlighted,
  getItemProps,
  onHighlightedChange,
  children,
}) => {
  const actionCallback = useCallback(() => {
    action && action();
  }, [action]);

  useEffect(() => {
    if (highlighted) {
      onHighlightedChange(actionCallback);
    }
  }, [highlighted, actionCallback, onHighlightedChange]);

  return (
    <div
      className={classnames("dropdown__item", className, {
        "dropdown__item--disabled": disabled,
        "dropdown__item--highlighted": highlighted,
      })}
      {...getItemProps({
        item,
        index,
        disabled: !action || disabled,
      })}
      id={id} // for qa
    >
      {children}
    </div>
  );
};
