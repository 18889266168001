import {
  UPDATE_VALUE,
  ADD_VALUES,
  REMOVE_VALUES,
  SCHEDULE_TOGGLED,
  SET_NEW_STATE,
} from "./schedule.actions";
import _ from "lodash";

function updateValue(data, key, value) {
  return {
    ...data,
    [key]: value,
  };
}

function addValues(oldValues, values) {
  return _.union(oldValues, values);
}

function removeValues(oldValues, values) {
  return _.difference(oldValues, values);
}

function toggleValues(oldValues, values, method) {
  const _values = _.isArray(values) ? values : [values];

  return method(oldValues, _values);
}

export const reducer = (state, { type, payload }) => {
  const { key } = payload;

  switch (type) {
    case UPDATE_VALUE:
      return updateValue(state, key, payload.value);
    case ADD_VALUES: {
      const values = toggleValues(state[key], payload.values, addValues);

      return updateValue(state, key, values);
    }
    case REMOVE_VALUES: {
      const values = toggleValues(state[key], payload.values, removeValues);

      return updateValue(state, key, values);
    }
    case SCHEDULE_TOGGLED:
      return {
        ...state,
        enabled: payload.enabled,
      };
    case SET_NEW_STATE:
      return payload;
    default:
      return state;
  }
};
