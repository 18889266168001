import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";

import { ProfileDeviceCard } from "./profileDeviceCard";
import { getProfileDevices } from "../../store/devices";
import { ProfileDevicesTable } from "./profileDevicesTable";
import { Desktop, TabletMobile } from "../../common/Responsive";

import "./ProfileDevices.scss";

const SettingsLink = ({ translationKey }) => (
  <Link className="link" to="/settings/devices">
    <FormattedMessage id={translationKey} />
  </Link>
);

const EmptyDevices = () => (
  <FormattedMessage
    id="empty_profile_devices"
    values={{
      settingsLink: ([key]) => {
        return <SettingsLink translationKey={key} />;
      },
    }}
  />
);

const ManageDevices = ({ profileName }) => (
  <FormattedMessage
    id="profile_devices"
    values={{
      profileName,
      settingsLink: ([key]) => <SettingsLink translationKey={key} />,
    }}
  />
);

export const ProfileDevices = ({ profileName }) => {
  const devices = _.orderBy(
    useSelector(getProfileDevices(profileName)),
    "name"
  );

  if (_.isEmpty(devices))
    return (
      <div className="profile-devices">
        <h3>
          <FormattedMessage id="devices" />
        </h3>
        <EmptyDevices />
      </div>
    );

  return (
    <div className="profile-devices">
      <div className="profile-devices__section-header">
        <h3>
          <FormattedMessage id="devices" />
        </h3>
        <p className="profile-devices__description">
          <ManageDevices profileName={profileName} />
        </p>
      </div>
      <div className="profile-devices__content">
        <TabletMobile>
          {devices.map(device => (
            <ProfileDeviceCard
              key={device.name}
              deviceName={device.name}
              networkId={device.networkId}
              phoneNumber={device.phoneNumber}
              manufacturer={device.manufacturer}
              identifiers={device.identifiers}
            />
          ))}
        </TabletMobile>
        <Desktop>
          <ProfileDevicesTable devices={devices} />
        </Desktop>
      </div>
    </div>
  );
};

ProfileDevices.propTypes = {
  profileName: PropTypes.string.isRequired,
};
