import _ from "lodash";
import { initialLoadableState } from "../shared/withLoadingState";

export const getListsSliceByProfileId = id => state =>
  state.lists.profiles[id] || initialLoadableState;

export const getListsByProfileId = id => state => {
  const lists = state.lists.profiles[id];

  return lists && { blacklist: lists.blacklist, whitelist: lists.whitelist };
};

export const getUrlOptionsUrl = state => _.get(state.lists.urlOptions, "url");
export const getUrlOptions = state => _.get(state.lists.urlOptions, "list");
export const getUrlOptionsError = state =>
  _.get(state.lists.urlOptions, "error");
