import { LogicalDeviceDetails, LogicalDeviceDetailsTypes } from "@sportal/api";

const isRoaming = (details: LogicalDeviceDetails): boolean =>
  details.type === LogicalDeviceDetailsTypes.Roaming;

const getDeviceDetails = (
  identifier: string,
  deviceDetailsList: LogicalDeviceDetails[]
): LogicalDeviceDetails =>
  deviceDetailsList.find(details => details.identifier === identifier);

export const isRoamingType = (
  identifier: string,
  deviceDetailsList: LogicalDeviceDetails[]
): boolean => isRoaming(getDeviceDetails(identifier, deviceDetailsList));

export const hasRoamingDevices = (devices: any[]): boolean => {
  return devices
    .map(device => device.deviceDetails)
    .flat()
    .some(isRoaming);
};
