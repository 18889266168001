import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { ModalService } from "@sportal/cdk";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { useDevicesTabContext } from "../DevicesTabContext";
import { MoveDeviceDialog } from "../moveDeviceDialog/MoveDeviceDialog";
import { MobileModalListItem } from "../tableComponents";
import { editLogicalDevice } from "../../../../../store/devices";

const useMoveDeviceInfo = device => {
  const dispatch = useDispatch();

  const { devicesProfiles, getProfileByName } = useDevicesTabContext();

  const move = () => {
    ModalService.show(modal => ({
      dialog: (
        <MoveDeviceDialog
          modal={modal}
          device={device}
          profiles={devicesProfiles}
        />
      ),
    }))
      .then(changes => dispatch(editLogicalDevice(device, changes)))
      .catch(_.noop);
  };

  const profile = getProfileByName(device.profile);
  const areOtherProfilesFull = _.every(_.without(devicesProfiles, profile), {
    isFull: true,
  });

  return {
    content: <FormattedMessage id={"move"} />,
    action: move,
    id: "move",
    disabled: areOtherProfilesFull,
  };
};

export const MoveDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, id, disabled } = useMoveDeviceInfo(device);

  return (
    <LazyLoadDropdownItem
      action={action}
      id={id}
      disabled={disabled}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const MoveDeviceModalItem = ({ device }) => {
  const { content, action, id, disabled } = useMoveDeviceInfo(device);

  return (
    <MobileModalListItem
      content={content}
      action={action}
      id={id}
      disabled={disabled}
    />
  );
};
