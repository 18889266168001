import { Timezones } from "@sportal/api";

export const LOAD_TIMEZONES = "[TIMEZONES] LOAD_TIMEZONES";
export const LOAD_TIMEZONES_SUCCESS = "[TIMEZONES] LOAD_TIMEZONES_SUCCESS";
export const LOAD_TIMEZONES_FAILURE = "[TIMEZONES] LOAD_TIMEZONES_FAILURE";

interface LoadTimezonesAction {
  type: typeof LOAD_TIMEZONES;
}

interface LoadTimezonesSuccessAction {
  type: typeof LOAD_TIMEZONES_SUCCESS;
  payload: Timezones;
}

interface LoadTimezonesFailureAction {
  type: typeof LOAD_TIMEZONES_FAILURE;
  payload: object;
}

export type TimezonesActionTypes =
  | LoadTimezonesAction
  | LoadTimezonesSuccessAction
  | LoadTimezonesFailureAction;
