import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getProfilesWithDevicesCount } from "../../../store/profiles";
import { Button, ProfileCards } from "../../../components";
import { getProfilesLimit } from "../../../store/settings";
import { loadLogicalDevices } from "../../../store/devices";
import { getLogicalDevices } from "../../../store/root.selectors";
import { useLoadableSlice } from "../../../store/shared/withLoadingState";
import { useMobileValue } from "../../../common/Responsive";

import "./MultipleContent.scss";

export const MultipleContent = () => {
  useLoadableSlice(loadLogicalDevices(), getLogicalDevices);

  const profiles = useSelector(getProfilesWithDevicesCount);
  const profilesLimit = useSelector(getProfilesLimit);
  const availableProfilesNumber = profilesLimit - profiles.length;
  const buttonSize = useMobileValue("medium", "small");

  return (
    <div className="multiple-content">
      <h3>
        <FormattedMessage id={"profiles"} />
      </h3>
      {profiles.length > 1 && (
        <div className="multiple-content__add-device">
          <FormattedMessage id="have_a_new_computer_or_mobile_device" />
          <Link to="/settings/devices">
            <Button
              className="multiple-content__add-device-button"
              size={buttonSize}
              color="primary"
              variant="outlined"
            >
              <FormattedMessage id="add_device" />
            </Button>
          </Link>
        </div>
      )}
      <ProfileCards
        profiles={profiles}
        availableProfilesNumber={availableProfilesNumber}
      />
    </div>
  );
};
