import { InfectedDeviceServerData } from "@sportal/api";

import { getSubscriberId } from "../info";
import { Api } from "../../api";
import {
  LOAD_INFECTED_DEVICES,
  LOAD_INFECTED_DEVICES_FAILURE,
  LOAD_INFECTED_DEVICES_SUCCESS,
  InfectedDevicesActionTypes,
} from "./infectedDevices.types";

export const loadInfectedDevices = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const subscriberId = getSubscriberId(getState());

  dispatch({ type: LOAD_INFECTED_DEVICES });

  const result = await api.infectedDevices.get(subscriberId);

  if (result.success === false) {
    dispatch(loadInfectedDevicesFailure(result.error));
    return;
  }

  dispatch(loadInfectedDevicesSuccess(result.data));
};

export const loadInfectedDevicesSuccess = (
  infectedDevices: InfectedDeviceServerData
): InfectedDevicesActionTypes => ({
  type: LOAD_INFECTED_DEVICES_SUCCESS,
  payload: infectedDevices,
});

export const loadInfectedDevicesFailure = (
  error
): InfectedDevicesActionTypes => ({
  type: LOAD_INFECTED_DEVICES_FAILURE,
  payload: error,
});
