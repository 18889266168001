import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ModalService } from "@sportal/cdk";
import { UnmergeDeviceDialog } from "../unmergeDeviceDialog/UnmergeDeviceDialog";
import {
  isAssignedDeviceMerged,
  unmergeLogicalDevice,
} from "../../../../../store/devices";
import { useDevicesTabContext } from "../DevicesTabContext";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { MobileModalListItem } from "../tableComponents";

const useUnmergeDeviceInfo = device => {
  const dispatch = useDispatch();
  const { getProfileByName } = useDevicesTabContext();

  const unmerge = () => {
    ModalService.show(modal => ({
      dialog: <UnmergeDeviceDialog modal={modal} device={device} />,
    }))
      .then(() => dispatch(unmergeLogicalDevice(device, device.phoneNumber)))
      .catch(_.noop);
  };

  const canUnmerge = !getProfileByName(device.profile).isFull;
  const isMerged = isAssignedDeviceMerged(device);

  return {
    content: <FormattedMessage id={"unmerge"} />,
    action: unmerge,
    hidden: !isMerged,
    disabled: !canUnmerge,
    id: "unmerge",
  };
};

export const UnmergeDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, hidden, disabled, id } = useUnmergeDeviceInfo(
    device
  );

  if (hidden) return null;

  return (
    <LazyLoadDropdownItem
      action={action}
      disabled={disabled}
      id={id}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const UnmergeDeviceModalItem = ({ device }) => {
  const { content, action, hidden, disabled, id } = useUnmergeDeviceInfo(
    device
  );

  if (hidden) return null;

  return (
    <MobileModalListItem
      content={content}
      action={action}
      id={id}
      disabled={disabled}
    />
  );
};
