import { isEnterPressed, isSpacebarPressed } from "../helpers";
import { MouseEvent } from "react";

interface ButtonToggleProps {
  "aria-controls": string;
  "aria-expanded": boolean;
  onClick: (e: MouseEvent) => void;
}

interface StaticToggleProps extends ButtonToggleProps {
  role: string;
  tabIndex: number;
  onKeydown: (e: KeyboardEvent) => void;
}

interface GetTogglePropsArgs {
  onToggle?: (e: KeyboardEvent | MouseEvent) => void;
  type?: string;
}

export interface GetToggleProps {
  ({ onToggle, type }: GetTogglePropsArgs):
    | ButtonToggleProps
    | StaticToggleProps;
}

interface MakeGetTogglePropsArgs {
  id: string;
  opened: boolean;
  toggleOpened: () => void;
}

interface MakeGetToggleProps {
  ({ id, opened, toggleOpened }: MakeGetTogglePropsArgs): GetToggleProps;
}

export const makeGetToggleProps: MakeGetToggleProps = ({
  id,
  opened,
  toggleOpened,
}) => ({ onToggle, type = "button" }) => {
  const buttonToggleProps = {
    "aria-controls": id,
    "aria-expanded": opened,
    onClick: onToggle || toggleOpened,
  };

  if (type === "button") return buttonToggleProps;

  return {
    ...buttonToggleProps,
    onKeyDown: e => {
      if (!isSpacebarPressed(e) && !isEnterPressed(e)) return;

      if (isSpacebarPressed(e)) {
        if (e.target.nodeName === "DIV") {
          e.preventDefault(); // prevent page scrolling
        } else return; // if target is not div we don't trigger collapse
      }

      if (onToggle) {
        onToggle(e);
        return;
      }
      toggleOpened();
    },
    role: "button",
    tabIndex: 0,
  };
};
