import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { CategoriesList } from "../../categoriesList/CategoriesList";
import { useSchedulerContext } from "../SchedulerContext";
import { CATEGORY_STATUS } from "../../categoryToggle/CategoryToggle";
import { format12h, format24h } from "../schedule/timeIndicator/TimeIndicator";
import { getCategoriesList } from "../../../store/categories";

import "./SchedulerCategories.scss";

const FIXED_DATE = "01/01/2021";
const extractTime = (hour, timeFormat24h) => {
  const time = hour === "24:00" ? "00:00" : hour;
  const timeFormat = timeFormat24h ? format24h : format12h;

  return format(new Date(`${FIXED_DATE} ${time}`), timeFormat);
};

export const SchedulerCategories = ({
  timeStart,
  timeEnd,
  blockedCategories,
  block,
  allow,
}) => {
  const { timeFormat24h } = useSchedulerContext();

  const startTime = extractTime(timeStart, timeFormat24h);
  const endTime = extractTime(timeEnd, timeFormat24h);

  const fetchedCategories = useSelector(getCategoriesList);
  const categories = useMemo(() => {
    return fetchedCategories.map(category => {
      const status =
        blockedCategories.findIndex(name => name === category.name) !== -1
          ? CATEGORY_STATUS.BLOCKED
          : CATEGORY_STATUS.ALLOWED;

      return {
        ...category,
        status,
      };
    });
  }, [fetchedCategories, blockedCategories]);

  return (
    <div className="scheduler-categories">
      <h4>
        <FormattedMessage
          id="from_to_on_block_categories"
          values={{
            startTime,
            endTime,
          }}
        />
      </h4>
      <CategoriesList
        allCategories={categories}
        blockedCategories={blockedCategories}
        allowAction={allow}
        blockAction={block}
      />
    </div>
  );
};
