import _ from "lodash";

import { getLines } from "../../root.selectors";
import { denormalizeCollection } from "../../shared/normalizeCollection";

export const getLinesList = _.flow([getLines, denormalizeCollection]);

export const isLine = (state, id) => {
  const lines = getLines(state);

  return _.has(lines.list, id);
};

export const isNotLine = _.negate(isLine);
