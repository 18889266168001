import { uuid } from "@sportal/lib";

import {
  ADD_PERM_NOTIFICATION,
  UPDATE_PERM_NOTIFICATION,
  REMOVE_PERM_NOTIFICATION,
} from "./permanentNotifications.consts";
import { getPermanentNotificationById } from "./permanentNotifications.selectors";

export const addPermNotificationAction = (id, notification) => ({
  type: ADD_PERM_NOTIFICATION,
  payload: { id, notification },
});

export const updatePermNotificationAction = (id, notification) => ({
  type: UPDATE_PERM_NOTIFICATION,
  payload: { id, notification },
});

export const addOrUpdatePermNotification = (notification, id = uuid()) => {
  return (dispatch, getState) => {
    const actualNotification = getPermanentNotificationById(getState(), id);

    const action = actualNotification
      ? updatePermNotificationAction(id, notification)
      : addPermNotificationAction(id, notification);

    dispatch(action);

    return id;
  };
};

export const removePermNotification = id => ({
  type: REMOVE_PERM_NOTIFICATION,
  payload: { id },
});
