import React from "react";
import { FormattedMessage } from "react-intl";

import { ConfirmationDialog } from "../../../../../components";

import "./UnmergeDeviceDialog.scss";

export const UnmergeDeviceDialog = ({ modal, device }) => (
  <ConfirmationDialog
    className={"unmerge-device-dialog"}
    modal={modal}
    content={
      <FormattedMessage
        id={"confirm_unmerge_device"}
        values={{
          device: (
            <span className={"unmerge-device-dialog__device-line"}>
              {device.phoneNumber}
            </span>
          ),
        }}
      />
    }
    cancelMessage={<FormattedMessage id={"cancel"} />}
    submitMessage={<FormattedMessage id={"unmerge"} />}
    submitButtonColor={"primary"}
  />
);
