import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getFooterCompanyLink } from "../../../store/config";
import { getTermsLink } from "../../../store/config";
import { ExternalLink } from "../../externalLink/ExternalLink";
import { OptionalFormattedMessage } from "../../../i18n/OptionalFormattedMessage";

import "./Footer.scss";

export const Footer = () => {
  const footerCompanyLink = useSelector(getFooterCompanyLink);
  const termsLink = useSelector(getTermsLink);

  return (
    <footer className="footer">
      <div>
        <FormattedMessage
          id="footer_link"
          values={{
            copyright: <FormattedMessage id="copyright_sign" />,
            year: <FormattedMessage id="year" />,
            companyLink: ([id]) => (
              <OptionalFormattedMessage id={id}>
                {companyName => (
                  <ExternalLink
                    className="footer__company-link"
                    url={footerCompanyLink}
                  >
                    {companyName}
                  </ExternalLink>
                )}
              </OptionalFormattedMessage>
            ),
            rights: <FormattedMessage id="rights" />,
          }}
        />
      </div>
      {termsLink && (
        <FormattedMessage
          id="footer_link_terms_and_conditions"
          values={{
            a: key => (
              <ExternalLink
                className="footer__terms-and-conditions"
                url={termsLink}
              >
                <FormattedMessage id={key} />
              </ExternalLink>
            ),
          }}
        />
      )}
    </footer>
  );
};
