import React, { Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getTimezone } from "../../store/account";
import { getIs24hourFormat } from "../../store/attributes";
import { convertISODuration } from "../../store/reports";
import { formatIntlDateTime } from "@sportal/lib";

import "./FormattedDateTime.scss";

export const dateTimeFormat24h = {
  month: "short",
  year: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const dateTimeFormat12h = {
  ...dateTimeFormat24h,
  hour: "numeric",
};

export function FormattedDateTime({ value }) {
  const { locale } = useIntl();
  const timeZone = useSelector(getTimezone);
  const is24h = useSelector(getIs24hourFormat);
  const dateConfig = is24h ? dateTimeFormat24h : dateTimeFormat12h;

  if (!value) return null;

  return (
    <Fragment>
      {formatIntlDateTime({
        ...dateConfig,
        time: value,
        hour12: !is24h,
        locale,
        timeZone,
      })}
    </Fragment>
  );
}

export const FormattedDateTimeRage = ({ time: unix, is24, duration, tz }) => {
  const intl = useIntl();

  const formattedTime = useMemo(() => {
    const baseDateConfig = is24 ? dateTimeFormat24h : dateTimeFormat12h;
    const formattedStart = formatIntlDateTime({
      ...baseDateConfig,
      time: unix * 1000,
      hour12: !is24,
      timeZone: tz,
      locale: intl.locale,
    });
    const unixEnd = unix + convertISODuration(duration);
    const formattedEnd = formatIntlDateTime({
      time: unixEnd * 1000,
      hour: baseDateConfig.hour,
      minute: baseDateConfig.minute,
      hour12: !is24,
      timeZone: tz,
      locale: intl.locale,
    });

    return `${formattedStart} – ${formattedEnd}`;
  }, [unix, is24, duration, tz, intl]);

  return <div className="date-time-range">{formattedTime}</div>;
};
