import React from "react";
import { FormattedMessage } from "react-intl";
import { Protection } from "@sportal/api";

export const ProtectionSubtitle = ({ selectedProtection }) => {
  return (
    <div className="protection-editor__subtitle">
      <FormattedMessage
        id={"protection_editor_subtitle"}
        values={{
          level: (
            <span className={"protection-editor__subtitle__level"}>
              <FormattedMessage id={selectedProtection.name} />
            </span>
          ),
          custom: (
            <span className={"protection-editor__subtitle__level"}>
              <FormattedMessage id={Protection.Custom} />
            </span>
          ),
        }}
      />
    </div>
  );
};
