import React, { FC } from "react";
import { Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import classNames from "classnames";

import { RenderRowDetailsMobileInterface } from "./TBodyMobile.types";

import "./TBodyMobile.scss";

interface TBodyMobileProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  rows: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  isAllRowsExpanded: boolean;
  renderRowDetails: RenderRowDetailsMobileInterface;
}

export const TBodyMobile: FC<TBodyMobileProps> = ({
  getTableBodyProps,
  rows,
  prepareRow,
  isAllRowsExpanded,
  renderRowDetails,
}) => {
  return (
    <tbody className="tbody-mobile" {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);

        return (
          <tr className="tr" {...row.getRowProps()}>
            {row.cells.map(cell => {
              const {
                value,
                column: { id },
              } = cell;

              return (
                <td
                  className={classNames(`td td--${id}`, !value && "td--empty")}
                  headers={id}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}

            {isAllRowsExpanded && renderRowDetails({ row })}
          </tr>
        );
      })}
    </tbody>
  );
};
