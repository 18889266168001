import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { CellDetailsMobile } from "../../../../../../../components";
import { AppStatus, ProtectionStatusInfo } from "../roamingComponents";

import "./MobileRoamingDetails.scss";

export const MobileRoamingDetails = ({ deviceDetails }) => {
  return (
    <Fragment>
      <CellDetailsMobile
        id="roaming-protection-status"
        header={<FormattedMessage id="roaming_protection_status" />}
        value={<ProtectionStatusInfo status={deviceDetails.status} />}
      />
      <CellDetailsMobile
        id="roaming-uuid"
        header={<FormattedMessage id="roaming_uuid" />}
        value={deviceDetails.identifier}
      />
      <CellDetailsMobile
        id="roaming-full-name"
        header={<FormattedMessage id="roaming_full_name" />}
        value={deviceDetails.username}
      />
      <CellDetailsMobile
        id="roaming-device-model"
        header={<FormattedMessage id="roaming_device_model" />}
        value={deviceDetails.model}
      />
      <CellDetailsMobile
        id="roaming-os"
        header={<FormattedMessage id="roaming_os" />}
        value={deviceDetails.os}
      />
      <CellDetailsMobile
        id="roaming-app-status"
        header={<FormattedMessage id="roaming_app_status" />}
        value={<AppStatus status={deviceDetails.status} />}
      />
      <CellDetailsMobile
        id="roaming-app-version"
        header={<FormattedMessage id="roaming_app_version" />}
        value={deviceDetails["software-version"]}
      />
    </Fragment>
  );
};
