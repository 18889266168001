import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import "./ProtectionChoicePage.scss";

import { ProtectionChoiceContent } from "./protectionChoiceContent/ProtectionChoiceContent";
import { chooseMultiple, chooseSingle } from "../store/wizard.actions";
import { useWizardNavigationContext } from "../WizardNavigationContext";

export function ProtectionChoicePage({ hasMultipleSupport }) {
  const { chooseNavigation } = useWizardNavigationContext();
  const dispatch = useDispatch();

  const profileTypes = {
    multiple: {
      title: "multiple_profiles",
      subtitle: "multiple_profiles_info",
      description: "multiple_profiles_description",
      image: "images/profiles.svg",
      onGetProtectionClick: () =>
        dispatch(chooseMultiple(() => chooseNavigation("multiple"))),
    },
    single: {
      title: "single_profile",
      subtitle: "single_profile_info",
      description: "single_profile_description",
      image: "images/household.svg",
      onGetProtectionClick: () =>
        dispatch(chooseSingle(() => chooseNavigation("single"))),
    },
  };
  const itemClasses = classnames("protection-choice__item", {
    "protection-choice__item--single-option": !hasMultipleSupport,
  });

  return (
    <div className="protection-choice__container">
      {hasMultipleSupport && (
        <div className={`${itemClasses} protection-choice__item--multiple`}>
          <ProtectionChoiceContent {...profileTypes.multiple} />
        </div>
      )}
      <div className={`${itemClasses} protection-choice__item--single`}>
        <ProtectionChoiceContent {...profileTypes.single} />
      </div>
    </div>
  );
}

ProtectionChoicePage.propTypes = {
  hasMultipleSupport: PropTypes.bool.isRequired,
};
