import React from "react";
import PropTypes from "prop-types";

import "./TabHeader.scss";

export function TabHeader(props) {
  return <div className="tab-header">{props.children}</div>;
}

TabHeader.propTypes = {
  children: PropTypes.node.isRequired,
};
