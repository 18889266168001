import { SchedulesProvider } from "./schedules.provider";
import { Schedule } from "./schedules.types";
import { success, failure, Result } from "../shared";

export enum ScheduleServiceErrorCode {
  GENERIC,
}

export class SchedulesService {
  constructor(private schedulesProvider: SchedulesProvider) {}

  public async create(
    subscriberId: string,
    profileName: string,
    schedule: Schedule
  ): Promise<Result<void>> {
    try {
      await this.schedulesProvider.create(subscriberId, profileName, schedule);

      return success();
    } catch (error) {
      return failure(ScheduleServiceErrorCode.GENERIC);
    }
  }

  public async update(
    subscriberId: string,
    profileName: string,
    scheduleName: string,
    schedule: Schedule
  ): Promise<Result<void>> {
    try {
      await this.schedulesProvider.update(
        subscriberId,
        profileName,
        scheduleName,
        schedule
      );

      return success();
    } catch (error) {
      return failure(ScheduleServiceErrorCode.GENERIC);
    }
  }

  public async delete(
    subscriberId: string,
    profileName: string,
    scheduleName: string
  ): Promise<Result<void>> {
    try {
      await this.schedulesProvider.delete(
        subscriberId,
        profileName,
        scheduleName
      );

      return success();
    } catch (error) {
      return failure(ScheduleServiceErrorCode.GENERIC);
    }
  }
}
