import { useReducer } from "react";

export const INITIAL_INDEX = -1;

export function reducer(currentIndex, nextIndex) {
  if (currentIndex === nextIndex) {
    return INITIAL_INDEX;
  }

  return nextIndex;
}

/**
 * this hook will return [activeIndex, toggleItem]
 */
export const useAccordion = () => useReducer(reducer, INITIAL_INDEX);
