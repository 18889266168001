import React, { ReactElement } from "react";
import { UseSelectGetItemPropsOptions } from "downshift";
import _ from "lodash";

import { DropdownItem, DropdownItemInterface } from "./DropdownItem";

interface RenderItemsOptions {
  items: DropdownItemInterface[] | ReactElement[];
  highlightedIndex: number;
  getItemProps: (
    options: UseSelectGetItemPropsOptions<DropdownItemInterface>
  ) => any;
}
export interface RenderItems {
  ({
    items,
    highlightedIndex,
    getItemProps,
  }: RenderItemsOptions): ReactElement[];
}

export const defaultRenderItems: RenderItems = ({
  items,
  highlightedIndex,
  getItemProps,
}) => {
  return items.map((item, index) => (
    <DropdownItem
      item={item}
      key={index}
      index={index}
      highlightedIndex={highlightedIndex}
      getItemProps={getItemProps}
    />
  ));
};

export interface OnSelectedItemChange {
  (selectedItem?: DropdownItemInterface | ReactElement): void;
}

export const defaultOnSelectedItemChange: OnSelectedItemChange = (
  selectedItem?
) => {
  if (selectedItem && "action" in selectedItem) {
    _.isFunction(selectedItem.action) && selectedItem.action();
  }
};
