import _ from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ModalDialog } from "@sportal/cdk";
import { DEVICE_NAME_MAX_LENGTH, DeviceDialogInput } from "../deviceForm";
import { DeviceNameInput } from "../deviceForm/DeviceNameInput";
import {
  CommonModalFooter,
  CommonModalHeader,
  useForm,
} from "../../../../../components";

import { getLogicalDeviceDetailsById } from "../../../../../store/devices/logicalDeviceDetails/logicalDeviceDetails.selectors";

export function EditInfoDeviceDialog({ modal, devices, deviceId }) {
  const device = useSelector(getLogicalDeviceDetailsById(deviceId));
  const [userName, setUserName] = useState(device.username);

  const form = useForm({
    name: device.name,
    username: device.username,
  });

  const close = () => modal.reject();
  const submit = () => modal.resolve(_.mapValues(form.getChanges(), _.trim));

  const inputChangeHandler = name => (value, error) => {
    form.set(name, value);
    form.setError(name, error);
  };

  const onDeviceUserNameChange = handler => userName => {
    const error = _.isEmpty(userName.trim()) ? "username_required" : null;

    setUserName(userName);
    handler(userName.trim(), error);
  };

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      disabled={!form.didFormChange() || form.hasErrors()}
    >
      <CommonModalHeader>
        <FormattedMessage id="edit_info" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <DeviceNameInput
          device={device}
          devices={devices}
          value={form.values.name}
          handleChange={inputChangeHandler("name")}
          error={form.errors.name}
        />
        <DeviceDialogInput
          label="full_name"
          placeholder="enter_full_name"
          value={userName}
          onChange={onDeviceUserNameChange(inputChangeHandler("username"))}
          error={form.errors.username}
          maxLength={DEVICE_NAME_MAX_LENGTH}
          autoCapitalize="none"
          autoCorrect="off"
          spellCheck="false"
        />
      </ModalDialog.Body>
      <CommonModalFooter />
    </ModalDialog>
  );
}
