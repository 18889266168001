import React from "react";
import { FormattedMessage } from "react-intl";
import { Column } from "react-table";

import { FormattedDateTime } from "../../../../../../../components";
import { RequestsMenu } from "../../RequestsMenu";
import { DeviceCode } from "../deviceCode";
import { DeviceTypeHeader } from "../../../tableComponents";
import { DeviceTypeTooltip } from "../../../../../../../components/devices/deviceTypeTooltip";
import { alphanumeric } from "../../../../../../../components/table/helpers/table-sorting.helpers";

export const COLUMNS_MOBILE: Column<any>[] = [
  {
    Header: <DeviceTypeHeader />,
    id: "deviceType",
    Cell: ({ row }) => {
      const device = row.original;

      return (
        <DeviceTypeTooltip
          isNewRequest={device.withName && device.name}
          type={device.type}
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="device_name" />,
    headerTranslationId: "device_name",
    id: "name",
    accessor: device => device.name || device.networkId || device.phoneNumber,
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="actions" />,
    id: "actions",
    Cell: ({ row }) => <RequestsMenu request={row.original} />,
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="last_seen" />,
    headerTranslationId: "last_seen",
    accessor: "lastSeen",
    Cell: ({ value }) => <FormattedDateTime value={value} />,
  },
  {
    Header: <FormattedMessage id="code" />,
    accessor: "code",
    Cell: ({ value }) => (value ? <DeviceCode code={value} /> : null),
    disableSortBy: true,
  },
];
