import React, { Fragment } from "react";
import { Button } from "../../../../components";
import { FormattedMessage } from "react-intl";
import { DefaultProtectionCardContent } from "./DefaultProtectionCardContent";
import { NonDefaultProtectionCardContent } from "./NonDefaultProtectionCardContent";
import { protectionByAgeGroup } from "../../../../store/profiles";

export const ProtectionCardContent = ({ profile }) => {
  return profile.default ? (
    <DefaultProtectionCardContent
      protection={profile.protection}
      recommended={protectionByAgeGroup.default === profile.protection}
    />
  ) : (
    <NonDefaultProtectionCardContent
      name={profile.name}
      ageGroup={profile["age-group"]}
      protection={profile.protection}
      recommended={
        protectionByAgeGroup[profile["age-group"]] === profile.protection
      }
    />
  );
};

export const ProtectionHeaderContent = ({ profile }) => {
  const { name, protection } = profile;
  const recommended = profile.default
    ? protectionByAgeGroup.default === protection
    : protectionByAgeGroup[profile["age-group"]] === protection;

  return (
    <div className="protection-header-content">
      <div className="profile-info">
        {profile.default ? (
          <Fragment>
            <i className="profile-image default" />
            <span className="protection-header-content__title">
              <FormattedMessage id="default" />
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <i className={`profile-image ${profile["age-group"]}`} />
            <span>{name}</span>
          </Fragment>
        )}
      </div>
      <p>
        <FormattedMessage
          id={recommended ? "recommended_protection_for" : "protection_value"}
          values={{
            ageGroup: (
              <FormattedMessage id={profile["age-group"] || "default"} />
            ),
            protection: (
              <b>
                <FormattedMessage id={protection} />
              </b>
            ),
          }}
        />
      </p>
    </div>
  );
};

export const WizardProfileProtectionCards = ({ profiles, onClick }) => {
  return (
    <div className="wizard-protection__content">
      {profiles.map(profile => (
        <div className="wizard-card wizard-protection-card" key={profile.id}>
          <ProtectionCardContent profile={profile} />
          <Button
            className="wizard-protection-card__button"
            size="medium"
            color="primary"
            onClick={() => onClick(profile)}
          >
            <FormattedMessage id="view_details_and_edit" />
          </Button>
        </div>
      ))}
    </div>
  );
};
