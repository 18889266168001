import _ from "lodash";

import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_THREATS,
  LOAD_THREATS_SUCCESS,
  LOAD_THREATS_FAILURE,
  ThreatsActionTypes,
} from "./threats.types";

const reducer = (state = {}, action: ThreatsActionTypes) => {
  switch (action.type) {
    case LOAD_THREATS_SUCCESS:
      return {
        ...state,
        list: action.payload.threats,
      };
    case LOAD_THREATS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const threatsByLanguageReducer = withLoadingState({
  loadActionType: LOAD_THREATS,
  successActionType: LOAD_THREATS_SUCCESS,
  failureActionType: LOAD_THREATS_FAILURE,
})(reducer);

export const threatsReducer = (state = {}, action) => {
  if (
    _.includes(
      [LOAD_THREATS, LOAD_THREATS_SUCCESS, LOAD_THREATS_FAILURE],
      action.type
    )
  ) {
    const { language } = action.payload;

    return {
      ...state,
      [language]: threatsByLanguageReducer(state[language] || {}, action),
    };
  }

  return state;
};
