import React, { VFC } from "react";
import { HeaderGroup } from "react-table";

import { useSortValues } from "../../table.hooks";
import { useIntlTranslate } from "../../../../hooks";
import { getHeaderTranslationId } from "../../table.helpers";

interface TableCaptionProps {
  as?: "span" | "caption";
  tableName: string;
  captionId: string;
  headerGroups: HeaderGroup<any>[];
}

export const TableCaption: VFC<TableCaptionProps> = ({
  as = "caption",
  tableName,
  captionId,
  headerGroups,
}) => {
  const { sortedBy } = useSortValues(headerGroups);
  const translate = useIntlTranslate();
  const Node = as;

  return (
    <Node id={captionId} className="sr-only" aria-live="polite">
      {sortedBy
        ? translate("aria_table_sorted_by", {
            tableName,
            sortedBy: translate(
              getHeaderTranslationId(headerGroups, sortedBy.id)
            ),
            sortedOrder: translate(
              sortedBy.isSortedDesc ? "sort_desc" : "sort_asc"
            ),
          })
        : translate("aria_table_not_sorted_by", { tableName })}
    </Node>
  );
};
