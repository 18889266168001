import * as _ from "lodash";

export interface NormalizedCollection<T> {
  list: { [key: string]: T };
  keys: string[];
}

/**
 * Transforms an array of objects to the normalized form
 */
export function normalizeCollection<T extends { [key: string]: any }>(
  array: T[],
  keyField: keyof T
): NormalizedCollection<T> {
  return _.transform(
    array,
    ({ list, keys }, item) => {
      const id = item[keyField];
      list[id] = item;
      keys.push(id);
    },
    {
      list: {},
      keys: [],
    } as NormalizedCollection<T>
  );
}

/**
 * Transforms a normalized collection to array
 */
export function denormalizeCollection<T>({
  list,
  keys,
}: NormalizedCollection<T>): T[] {
  return _.at<T>(list, keys);
}
