import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { BaseButton, Icon } from "@sportal/cdk";
import { useIntlTranslate } from "../../hooks";
import { formatDuration } from "./pauseInternet.helpers";

import "./PausedInternetButton.scss";

export function UnpauseButton({ onClick }) {
  return (
    <BaseButton className="paused-internet__unpause-button" onClick={onClick}>
      <Icon icon="far fa-times" />
    </BaseButton>
  );
}

UnpauseButton.propTypes = {
  onClick: PropTypes.func,
};

export function PausedInternetButton({
  timeLeft,
  onUnpause,
  showSeparateUnpauseButton,
  label,
}) {
  const translate = useIntlTranslate();

  const formattedTimeLeft = useMemo(() => {
    const shortHour = translate("short_hour");
    const shortMinute = translate("short_minute");
    return formatDuration(timeLeft, shortHour, shortMinute);
  }, [timeLeft, translate]);

  // to avoid button inside button `paused-internet` is a div
  return (
    <>
      {label && <span className="paused-internet__label">{label}</span>}
      <div
        className={classnames("paused-internet", {
          "paused-internet--interactive": !showSeparateUnpauseButton,
        })}
        onClick={showSeparateUnpauseButton ? _.noop : onUnpause}
      >
        <div className="paused-internet__time-left">
          <FormattedMessage
            id="internet_paused"
            values={{
              pausedTime: (
                <span className="paused-internet__time-left-value">
                  {formattedTimeLeft}
                </span>
              ),
            }}
          />
        </div>
        {showSeparateUnpauseButton && <UnpauseButton onClick={onUnpause} />}
      </div>
    </>
  );
}

PausedInternetButton.propTypes = {
  timeLeft: PropTypes.object.isRequired,
  onUnpause: PropTypes.func.isRequired,
  showSeparateUnpauseButton: PropTypes.bool.isRequired,
  label: PropTypes.node,
};
