import { Profile, SafeSearchService } from "@sportal/api";

export const CHANGE_PROFILE_NAME = "[PROFILE] CHANGE_NAME";
export const CHANGE_PROFILE_NAME_SUCCESS = "[PROFILE] CHANGE_NAME_SUCCESS";
export const CHANGE_PROFILE_NAME_FAILURE = "[PROFILE] CHANGE_NAME_FAILURE";
export const CHANGE_PROFILE_SAFE_SEARCH = "[PROFILE] CHANGE_SAFE_SEARCH";
export const CHANGE_PROFILE_SAFE_SEARCH_SUCCESS =
  "[PROFILE] CHANGE_SAFE_SEARCH_SUCCESS";
export const CHANGE_PROFILE_SAFE_SEARCH_FAILURE =
  "[PROFILE] CHANGE_SAFE_SEARCH_FAILURE";
export const CHANGE_PROFILE_PROTECTION_ENABLED =
  "[PROFILE] CHANGE_PROTECTION_ENABLED";
export const CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS =
  "[PROFILE] CHANGE_PROTECTION_ENABLED_SUCCESS";
export const CHANGE_PROFILE_PROTECTION_ENABLED_FAILURE =
  "[PROFILE] CHANGE_PROTECTION_ENABLED_FAILURE";

interface ChangeProfileNameAction {
  type: typeof CHANGE_PROFILE_NAME;
}

export interface ChangeProfileNameSuccessAction {
  type: typeof CHANGE_PROFILE_NAME_SUCCESS;
  payload: {
    profile: Profile;
    updates: {
      name: string;
    };
  };
}

interface ChangeProfileNameFailureAction {
  type: typeof CHANGE_PROFILE_NAME_FAILURE;
  payload: object;
}

interface ChangeProfileSafeSearchAction {
  type: typeof CHANGE_PROFILE_SAFE_SEARCH;
}

export interface ChangeProfileSafeSearchSuccessAction {
  type: typeof CHANGE_PROFILE_SAFE_SEARCH_SUCCESS;
  payload: {
    profile: Profile;
    updates: {
      "safe-search-services": {
        [SafeSearchService.YouTube]: boolean;
        [SafeSearchService.Google]: boolean;
        [SafeSearchService.Bing]: boolean;
      };
    };
  };
}

interface ChangeProfileSafeSearchFailureAction {
  type: typeof CHANGE_PROFILE_SAFE_SEARCH_FAILURE;
  payload: object;
}

interface ChangeProfileProtectionEnabledAction {
  type: typeof CHANGE_PROFILE_PROTECTION_ENABLED;
}

export interface ChangeProfileProtectionEnabledSuccessAction {
  type: typeof CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS;
  payload: {
    profile: Profile;
    updates: { "protection-enabled": boolean };
  };
}

interface ChangeProfileProtectionEnabledFailureAction {
  type: typeof CHANGE_PROFILE_PROTECTION_ENABLED_FAILURE;
  payload: object;
}

export type ProfileActionTypes =
  | ChangeProfileNameAction
  | ChangeProfileNameSuccessAction
  | ChangeProfileNameFailureAction
  | ChangeProfileSafeSearchAction
  | ChangeProfileSafeSearchSuccessAction
  | ChangeProfileSafeSearchFailureAction
  | ChangeProfileProtectionEnabledAction
  | ChangeProfileProtectionEnabledSuccessAction
  | ChangeProfileProtectionEnabledFailureAction;
