import React, { useLayoutEffect, useRef } from "react";
import _ from "lodash";
import classNames from "classnames";
import scrollIntoView from "scroll-into-view-if-needed";

import "./HorizontalMenu.scss";

export const HorizontalMenu = ({ className, menuItems }) => {
  const hmRef = useRef(null);

  useLayoutEffect(() => {
    const { current } = hmRef;
    const activeOption = current && current.querySelector(".hm__item--active");

    if (activeOption) {
      scrollIntoView(activeOption, { inline: "center" });
    }
  });

  return (
    <div className={classNames("horizontal-menu", className)} ref={hmRef}>
      <ul className="horizontal-menu__content">
        {_.map(menuItems, (menuItem, key) => (
          <li key={key} className="horizontal-menu__item">
            {menuItem}
          </li>
        ))}
      </ul>
    </div>
  );
};
