import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import "./SafetyContent.scss";

export const SafetyContent = () => {
  return (
    <div className="safety-content">
      <div className="safety-content__wrapper">
        <h2>
          <FormattedMessage id={"what_is_botnet"} />
        </h2>
        <p>
          <FormattedMessage id={"botnet_description_1"} />
        </p>
        <p>
          <FormattedMessage id={"botnet_description_2"} />
        </p>
      </div>
      <div className="safety-content__wrapper">
        <h2>
          <FormattedMessage id={"subscriber_safety_protection"} />
        </h2>
        <p>
          <FormattedMessage id={"subscriber_safety_protection_description"} />
        </p>
        <p>
          <FormattedMessage
            id={"subscriber_safety_protection_description_list_header"}
          />
        </p>
        <ul className="safety-content__list">
          {_.times(5, i => (
            <li key={i}>
              <FormattedMessage
                id={`subscriber_safety_protection_description_list_item_${++i}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
