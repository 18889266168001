import {
  SettingsServerData,
  SettingsServiceErrorCode,
  isFailure,
} from "@sportal/api";
import { getUserMode } from "../info";
import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAILURE,
  SettingsActionTypes,
} from "./settings.types";
import { Api } from "../../api";

const errorMessages = {
  [SettingsServiceErrorCode.MISCONFIGURED]: "app_improperly_configured",
};

export const loadSettings = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_SETTINGS });
  const userMode = getUserMode(getState());

  const result = await api.settings(userMode).get();

  if (isFailure(result)) {
    const message = errorMessages[result.error.code];

    dispatch(loadSettingsFailure({ ...result.error, message }));
  } else {
    dispatch(loadSettingsSuccess(result.data));
  }
};

export const loadSettingsSuccess = (
  settings: SettingsServerData
): SettingsActionTypes => ({
  type: LOAD_SETTINGS_SUCCESS,
  payload: settings,
});

export const loadSettingsFailure = (error): SettingsActionTypes => ({
  type: LOAD_SETTINGS_FAILURE,
  payload: error,
});
