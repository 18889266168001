import _ from "lodash";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { getSharedProtections } from "../../store/root.selectors";
import {
  findProtectionByCategories,
  getProfileProtection,
} from "../../store/protection";
import { protectionEditorReducer } from "./protectionEditor.reducer";
import {
  changeProtectionLevel,
  setCustomProtection,
} from "./protectionEditor.actions";

export const useProtectionState = profile => {
  const sharedProtections = useSelector(getSharedProtections);
  const savedProtection = useSelector(state =>
    getProfileProtection(state, profile)
  );
  const [currentProtection, dispatch] = useReducer(
    protectionEditorReducer,
    savedProtection
  );

  function setBlocked(blocked) {
    const protection = findProtectionByCategories(
      { sharedProtections },
      blocked
    );

    dispatch(
      protection
        ? changeProtectionLevel(protection)
        : setCustomProtection(blocked)
    );
  }

  function setLevel(name) {
    dispatch(changeProtectionLevel({ ...sharedProtections.list[name] }));
  }

  function actionFn(action, categories) {
    const payload = _.isArray(categories) ? categories : [categories];

    setBlocked(action(currentProtection.blocked, payload));
  }

  function block(categories) {
    actionFn(_.union, categories);
  }

  function allow(categories) {
    actionFn(_.difference, categories);
  }

  useEffect(() => {
    dispatch(changeProtectionLevel(savedProtection));
  }, [savedProtection]);

  return {
    savedProtection: _.pick(savedProtection, ["name", "blocked"]),
    currentProtection: _.pick(currentProtection, ["name", "blocked"]),
    setLevel,
    setBlocked,
    block,
    allow,
  };
};
