import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Notification } from "../notification/Notification";
import { removeTempNotification } from "./temporaryNotifications.actions";
import { getDisplayedTempNotifications } from "./temporaryNotifications.selectors";
import "./TemporaryNotifications.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export const TemporaryNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getDisplayedTempNotifications);

  const onRemove = id => {
    dispatch(removeTempNotification(id));
  };

  return (
    <div className="notifications-list--temporary">
      <TransitionGroup className="fade">
        {notifications.map(notification => (
          <CSSTransition key={notification.id} timeout={300} classNames="fade">
            <Notification
              {...notification}
              className="notification--temporary"
              onClick={() => onRemove(notification.id)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};
