import { Service } from "../settings";

export const BYPASS_PRIVATE_DNS = "bypass-private-dns";
export enum BypassPrivateDns {
  ApplePrivateRelay = "apple-private-relay",
}

export enum ServiceProfiles {
  SBSingle = "single-profile",
  SBMultiple = "multiple-profile",
}

export interface AccountData {
  id: string;
  timezone?: string;
  [Service.HttpsTermination]: {
    activated: boolean;
    enabled: boolean;
  };
  [Service.PersonalInternet]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string;
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
    "profile-count"?: number;
    "profile-limit"?: number;
    [BYPASS_PRIVATE_DNS]: {
      [BypassPrivateDns.ApplePrivateRelay]: boolean;
    };
  };
  [Service.SecureBusiness]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string;
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
    "profile-count"?: number;
    "profile-limit"?: number;
    "spson-enabled"?: boolean;
    status?: string;
    "bypass-private-dns"?: {
      "apple-private-relay": boolean;
    };
  };
  [Service.SubscriberSafety]: {
    activated: boolean;
    enabled: boolean;
    time?: string;
    type?: string; // not sure if this key can be received for this case
    "bypass-pin-enabled"?: boolean;
    "bypass-pin-value"?: string;
  };
}

export interface BypassPinUpdate {
  enabled?: boolean;
  value?: string;
}

export interface DeepLink {
  link: string;
  "deeplink-ttl": number;
}

export type BypassPinUpdates = {
  [Service.PersonalInternet]?: BypassPinUpdate;
  [Service.SubscriberSafety]?: BypassPinUpdate;
};

export interface BypassPrivateDNS {
  [BYPASS_PRIVATE_DNS]: {
    [BypassPrivateDns.ApplePrivateRelay]: boolean;
  };
}
