import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { ModalService } from "@sportal/cdk";
import { useDevicesTabContext } from "../DevicesTabContext";
import { MergeDeviceDialog } from "../mergeDeviceDialog/MergeDeviceDialog";
import {
  isAssignedDeviceMerged,
  mergeLogicalDevice,
} from "../../../../../store/devices";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { makeDeviceOutOfRequest } from "./deviceActions.helpers";
import { MobileModalListItem } from "../tableComponents";

const useMergeDeviceInfo = device => {
  const dispatch = useDispatch();
  const { getMergeableDevices } = useDevicesTabContext();

  const mergeableDevices = getMergeableDevices(device);

  const merge = () => {
    ModalService.show(modal => ({
      dialog: (
        <MergeDeviceDialog
          modal={modal}
          device={device}
          mergeableDevices={mergeableDevices}
        />
      ),
    }))
      .then(deviceToMerge =>
        dispatch(mergeLogicalDevice(device, deviceToMerge))
      )
      .catch(() => {});
  };

  return {
    content: <FormattedMessage id="merge_with_device" />,
    action: merge,
    hidden: _.isEmpty(mergeableDevices),
    id: "merge",
  };
};

export const MergeNonAssignedDeviceDropdownItem = ({ request, ...props }) => {
  const device = makeDeviceOutOfRequest(request);
  const { content, action, id, hidden } = useMergeDeviceInfo(device);

  if (hidden) return null;

  return (
    <LazyLoadDropdownItem action={action} id={id} {...props}>
      {content}
    </LazyLoadDropdownItem>
  );
};

export const MergeAssignedDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, id, hidden } = useMergeDeviceInfo(device);
  const isMerged = isAssignedDeviceMerged(device);

  if (hidden || isMerged) return null;

  return (
    <LazyLoadDropdownItem action={action} id={id} {...props}>
      {content}
    </LazyLoadDropdownItem>
  );
};

export const MergeNonAssignedDeviceModalItem = ({ request }) => {
  const device = makeDeviceOutOfRequest(request);
  const { content, action, id, hidden } = useMergeDeviceInfo(device);

  if (hidden) return null;

  return <MobileModalListItem content={content} action={action} id={id} />;
};

export const MergeAssignedDeviceModalItem = ({ device }) => {
  const { content, action, id, hidden } = useMergeDeviceInfo(device);
  const isMerged = isAssignedDeviceMerged(device);

  if (hidden || isMerged) return null;

  return <MobileModalListItem content={content} action={action} id={id} />;
};
