import React, { Fragment } from "react";
import { TimePeriodButtons } from "./auxiliaryComponents/TimePeriodButtons";
import { Desktop, Tablet } from "../../common/Responsive";
import { BarChart } from "../barchart/BarChart";
import { ActivityCheckboxes } from "./auxiliaryComponents/ActivityCheckboxes";
import { ReportHeader } from "./auxiliaryComponents/ReportHeader";

export const ReportNotMobile = ({ profileName }) => (
  <Fragment>
    <h3 className={"report__title"}>
      <ReportHeader profileName={profileName} />
    </h3>
    <div className={"report__controls"}>
      <TimePeriodButtons className={"report__time-selector"} />
      <Desktop>
        <ActivityCheckboxes className={"report__toggles"} />
      </Desktop>
    </div>
    <BarChart />
    <Tablet>
      <ActivityCheckboxes className={"report__toggles"} />
    </Tablet>
  </Fragment>
);
