import React, { ButtonHTMLAttributes, VFC } from "react";
import classnames from "classnames";

import { Icon } from "../icon/Icon";

import "./CrossButton.scss";

interface CrossButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: "small" | "medium" | "large";
  weight?: "regular" | "strong";
}

export const CrossButton: VFC<CrossButtonProps> = ({
  className,
  size,
  onClick,
  weight = "regular",
  ...attrs
}) => {
  return (
    <button
      className={classnames("cross-button", `cross-button--${size}`, className)}
      type="button"
      onClick={onClick}
      {...attrs}
    >
      <Icon
        icon={classnames("fa-times", weight === "strong" ? "fas" : "far")}
      />
    </button>
  );
};
