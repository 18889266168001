import { useState } from "react";
import { usePopper } from "react-popper";

import { PopperOptions } from "./popper.types";

const defaultOptions: PopperOptions = {};
export const usePopperAPI = (options: PopperOptions = defaultOptions) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);

  const popperInstance = usePopper(anchorElement, popperElement, {
    strategy: "absolute",
    placement: "bottom-end",
    ...options,
  });

  return { setAnchorElement, setPopperElement, ...popperInstance };
};
