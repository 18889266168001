import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const ModalHeader = ({ children, className }) => (
  <div className={classnames("modal__header", className)}>{children}</div>
);

ModalHeader.propTypes = {
  className: PropTypes.string,
};
