import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import { AccordionItem } from "../accordionItem/AccordionItem";
import { useAccordion } from "./useAccordion";

import "./Accordion.scss";

function AccordionColumn({ items, renderItem }) {
  return (
    <div className="accordion-column">
      {items.map((item, index) => renderItem(item, index))}
    </div>
  );
}

export const TwoColumnAccordion = ({ className, items }) => {
  const [activeIndex, toggleItem] = useAccordion();

  if (_.isEmpty(items)) return null;

  const middleIndex = Math.ceil(items.length / 2);

  const renderItem = ({ header, content, className, component }, index) =>
    component || (
      <AccordionItem
        className={className}
        key={index}
        active={activeIndex === index}
        header={header}
        content={content}
        onClick={() => toggleItem(index)}
      />
    );

  return (
    <div className={classnames("accordion", className)}>
      <AccordionColumn
        items={items.slice(0, middleIndex)}
        renderItem={renderItem}
      />
      <AccordionColumn
        items={items.slice(middleIndex)}
        renderItem={(item, index) => renderItem(item, index + middleIndex)}
      />
    </div>
  );
};

TwoColumnAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      content: PropTypes.node,
    })
  ).isRequired,
};
