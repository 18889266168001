import _ from "lodash";

import { denormalizeCollection } from "../../../../store/shared/normalizeCollection";
import { getProfiles } from "../wizard.selectors";

export const getProfilesList = _.flow([getProfiles, denormalizeCollection]);

export const getToAdd = (profiles, savedProfiles) =>
  profiles.filter(({ id }) => !_.has(savedProfiles, id));

const getProfilesDiff = (first, second) =>
  _.pickBy(
    _.pick(first, ["name", "age-group"]),
    (value, key) => value !== second[key]
  );

export const getToUpdate = (profiles, savedProfiles) =>
  _.transform(
    profiles,
    (result, profile) => {
      const savedProfile = savedProfiles[profile.id];
      const diff = savedProfile && getProfilesDiff(profile, savedProfile);
      if (!_.isEmpty(diff)) {
        result.push({ profile: savedProfile, updates: diff });
      }
    },
    []
  );

export const getToRemove = (profiles, savedProfiles) =>
  _.filter(savedProfiles, ({ id }) => !_.some(profiles, { id }));
