import React from "react";
import _ from "lodash";

import {
  isSuccess,
  useLoadableSlice,
} from "../../store/shared/withLoadingState";
import { loadProfiles } from "../../store/profiles";
import { getProfiles } from "../../store/root.selectors";

export const withProfiles = Component => props => {
  const profiles = useLoadableSlice(loadProfiles(), getProfiles);

  if (_.isNull(profiles)) {
    return null;
  }

  if (!isSuccess(profiles)) {
    throw Error("Profiles loading failed");
  }

  return <Component {...props} />;
};
