import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./PermanentNotifications.scss";

import { Notification } from "../notification/Notification";
import { removePermNotification } from "./pernamentNotifications.actions";
import { getPermanentNotifications } from "./permanentNotifications.selectors";
import { NewRequestsNotification } from "./NewRequestsNotification";

const NotificationsList = ({ notifications, onRemove }) => (
  <div className="notifications-list--permanent">
    <NewRequestsNotification />
    {notifications.map(({ id, ...notification }) => (
      <Notification
        key={id}
        id={id}
        {...notification}
        onClick={() => onRemove(id)}
      />
    ))}
  </div>
);

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export const PermanentNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getPermanentNotifications);

  return (
    <NotificationsList
      notifications={notifications}
      onRemove={id => dispatch(removePermNotification(id))}
    />
  );
};
