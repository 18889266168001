import {
  REMOVE_TEMP_NOTIFICATION,
  ADD_TEMP_NOTIFICATION_TO_PENDING,
  DISPLAY_TEMP_NOTIFICATION,
  DISPLAY_PENDING_TEMP_NOTIFICATION,
} from "./temporaryNotifications.consts";

export const initialState = {
  displayed: [],
  pending: [],
};

const temporaryNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_TEMP_NOTIFICATION:
      return {
        ...state,
        displayed: [...state.displayed, action.notification],
      };
    case ADD_TEMP_NOTIFICATION_TO_PENDING:
      return {
        ...state,
        pending: [...state.pending, action.notification],
      };
    case DISPLAY_PENDING_TEMP_NOTIFICATION:
      return {
        displayed: [...state.displayed, action.notification],
        pending: state.pending.filter(
          notification => notification.id !== action.notification.id
        ),
      };
    case REMOVE_TEMP_NOTIFICATION:
      return {
        ...state,
        displayed: state.displayed.filter(
          notification => notification.id !== action.id
        ),
      };
    default:
      return state;
  }
};

export default temporaryNotificationsReducer;
