import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isLoaded, shouldLoad } from "./withLoadingState.selectors";

export function useConditionalLoadableSlice(
  loadAction,
  selector,
  skipSelector = () => false
) {
  const dispatch = useDispatch();
  const state = useSelector(selector);
  const skip = useSelector(skipSelector);

  useEffect(() => {
    if (shouldLoad(state) && !skip) {
      dispatch(loadAction);
    }
  }, [state, loadAction, skip, dispatch]);

  return isLoaded(state) ? state : null;
}
