import {
  HttpService,
  ManufacturerProvider,
  ManufacturerService,
} from "@sportal/api";

let manufacturerService;

export const makeManufacturerService = (
  http: HttpService,
  baseUrl: string
): ManufacturerService => {
  manufacturerService =
    manufacturerService ||
    new ManufacturerService(new ManufacturerProvider(http, baseUrl));

  return manufacturerService;
};
