import React, { ReactElement, useRef, VFC } from "react";
import classNames from "classnames";
import { Collapse } from "react-collapse";

import { CollapseContext } from "./CollapseContext";
import { useToggle } from "../hooks";
import { makeGetToggleProps } from "./collapse.helpers";
import { randomId } from "../helpers";

import "./CollapsibleSection.scss";

interface Props {
  header: ReactElement | string;
  content: ReactElement | string;
  footer?: ReactElement | string;
  defaultOpened?: Boolean;
  className?: string;
  contentClassName?: string;
}

export const CollapsibleSection: VFC<Props> = ({
  defaultOpened = false,
  header,
  content,
  className,
  contentClassName,
  footer,
}) => {
  const [opened, toggleOpened] = useToggle(defaultOpened);
  const toggleRef = useRef<HTMLDivElement>(null);
  const { current: toggleId } = useRef(`collapsible-toggle${randomId()}`);

  const getToggleProps = makeGetToggleProps({
    id: toggleId,
    opened,
    toggleOpened,
  });

  return (
    <CollapseContext.Provider value={{ opened, toggleOpened, getToggleProps }}>
      <div
        data-testid="collapsible-section"
        className={classNames(
          "collapsible-section",
          className,
          opened && "collapsible-section--opened"
        )}
      >
        {header}
        <Collapse
          isOpened={opened}
          onRest={({ isOpened }) => {
            if (isOpened) return;
            toggleRef.current.classList.add("collapsible__wrapper--hidden");
          }}
          onWork={({ isOpened }) => {
            if (!isOpened) return;
            toggleRef.current.classList.remove("collapsible__wrapper--hidden");
          }}
        >
          <div ref={toggleRef} id={toggleId} className="collapsible__wrapper">
            <div
              className={classNames("collapsible__content", contentClassName)}
            >
              {content}
            </div>
            {footer && <div className="collapsible__footer">{footer}</div>}
          </div>
        </Collapse>
      </div>
    </CollapseContext.Provider>
  );
};

CollapsibleSection.displayName = "CollapsibleSection";
