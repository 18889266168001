import {
  BannersReducerState,
  BannersActionTypes,
  HIDE_ALLOW_LIST_OVERRIDE_WARNING,
  HIDE_SPSON_MAX_LIMIT_WARNING,
  HIDE_SPSON_PANEL,
} from "./banners.types";

export const initialState: BannersReducerState = {
  allowListOverrideWarningVisible: true,
  spsonPanelVisible: true,
  spsonMaxLimitWarningVisible: true,
};

export const bannersReducer = (
  state = initialState,
  action: BannersActionTypes
): BannersReducerState => {
  switch (action.type) {
    case HIDE_ALLOW_LIST_OVERRIDE_WARNING: {
      return {
        ...state,
        allowListOverrideWarningVisible: false,
      };
    }
    case HIDE_SPSON_PANEL: {
      return {
        ...state,
        spsonPanelVisible: false,
      };
    }
    case HIDE_SPSON_MAX_LIMIT_WARNING: {
      return {
        ...state,
        spsonMaxLimitWarningVisible: false,
      };
    }
    default:
      return state;
  }
};
