import React, { ReactElement, VFC, Fragment } from "react";
import classNames from "classnames";

import { Icon } from "@sportal/cdk";

import "./MobileModalListBody.scss";

const ICONS = {
  rename: "far fa-pencil",
  edit_info: "far fa-pencil",
  merge: "far fa-link",
  assign: "far fa-plus",
  remove: "far fa-trash",
  revoke_access: "far fa-ban",
  unmerge: "far fa-unlink",
  move: "far fa-chevron-double-right",
};

interface MobileModalListBodyProps {
  items: ReactElement[];
}

export const MobileModalListBody: VFC<MobileModalListBodyProps> = ({
  items,
}) => {
  return (
    <ul className="mobile-modal-list-body">
      {items.map((item, index) => (
        <Fragment key={index}>{item} </Fragment>
      ))}
    </ul>
  );
};

export interface MobileModalListItemProps {
  content: ReactElement;
  action: () => void;
  disabled?: boolean;
  id: string;
  icon?: string;
  isSelected?: boolean;
}

export const MobileModalListItem: VFC<MobileModalListItemProps> = ({
  disabled,
  id,
  action,
  icon,
  content,
  isSelected = false,
}) => {
  const iconClass = icon || ICONS[id];

  return (
    <li
      className={classNames(
        "mobile-modal-list-body__item",
        disabled && "mobile-modal-list-body__item--disabled"
      )}
      key={id}
    >
      <button
        className={classNames(
          "mobile-modal-list-body__item-button",
          isSelected && "mobile-modal-list-body__item-button--sorted"
        )}
        type="button"
        onClick={action}
        disabled={disabled}
      >
        <span aria-hidden="true" className="icon-wrapper">
          {iconClass && <Icon icon={iconClass} />}
        </span>
        {content}
      </button>
    </li>
  );
};
