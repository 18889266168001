import React from "react";
import { FormattedMessage } from "react-intl";
import { LazyLoadDropdown } from "@sportal/cdk/dropdown/LazyLoadDropdown";
import classnames from "classnames";

import { ToggleActionsButton } from "../toggleActionsButton";

import "./DeviceMenu.scss";

export const DeviceMenu = ({ items, className }) => (
  <LazyLoadDropdown
    className={classnames("device-menu", className)}
    items={items}
    toggleButton={<ToggleActionsButton />}
    hiddenLabel={<FormattedMessage id={"device_actions_menu_label"} />}
    collapseOnScroll
  />
);
