import _ from "lodash";
import {
  milliseconds,
  addMinutes,
  add,
  subHours,
  format,
  intervalToDuration,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { localizedTimeService } from "../../i18n/LocalizedTimeService";

export const UNITS = {
  days: "days",
  hours: "hours",
  minutes: "minutes",
};

export function getHoursFromDays(days = 0, hours = 0) {
  return days * 24 + hours;
}

export function getHoursFromMinutes(minutes) {
  return Math.trunc(minutes / 60);
}

export function formatDuration(duration, shortHourMessage, shortMinuteMessage) {
  const { days, hours, minutes } = duration;
  const utcTime = milliseconds(duration);

  if (days) {
    return (
      getHoursFromDays(days, hours).toString() +
      shortHourMessage +
      format(utcTime, ` m'${shortMinuteMessage}'`)
    );
  } else if (hours) {
    return format(
      subHours(utcTime, 1),
      `H'${shortHourMessage}' m'${shortMinuteMessage}'`
    );
  }

  if (minutes <= 1) return `1${shortMinuteMessage}`;
  if (minutes < 59) return format(utcTime, `m'${shortMinuteMessage}'`);

  return (
    getHoursFromMinutes(minutes).toString() +
    shortHourMessage +
    format(utcTime, ` m'${shortMinuteMessage}'`)
  );
}

export function humanizePrecisely(amount, units) {
  if (units === UNITS.minutes) {
    return localizedTimeService.formatDuration({ [units]: _.floor(amount) });
  }

  if (units === UNITS.hours) {
    const hours = _.floor(amount);
    const minutes = _.ceil((amount - hours) * 60);

    return localizedTimeService.formatDuration(
      { hours, minutes },
      { format: [UNITS.hours, UNITS.minutes] }
    );
  }

  return localizedTimeService.formatDuration({ [units]: _.round(amount) });
}

export function isValidPauseOption(item) {
  return item.amount > 0 && _.has(UNITS, item.units);
}

export function calculatePauseTimeEnd(now, pausePeriod) {
  return addMinutes(add(now, pausePeriod), 1);
}

export function getTimeLeft(schedule, now) {
  if (!schedule) {
    return null;
  }

  const end = zonedTimeToUtc(schedule.timeEnd, schedule.timeZone);

  return intervalToDuration({ start: now, end });
}
