import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import { IntlProvider } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { setLangCookie } from "./languageCookie";
import { getSelectedLanguage } from "./language.selectors";
import { normalizeLocale } from "./normalizeLocale";
import { useApi } from "../AppContext";
import { loaderHide, loaderShow } from "../store/loader";
import { localizedTimeService } from "./LocalizedTimeService";

function setTimeLocale(language) {
  const locale =
    language === "en-US" ? language : language.slice(0, language.indexOf("-"));

  localizedTimeService.setLocale(locale);
}

/*
  This hook is supposed to be the only source of updates for
  ConnectedIntlProvider component. It defers updates until needed
  translation file is loaded. During the loading of in case of error
  the last good state is returned.
*/
const useTranslationsState = () => {
  const selectedLanguage = useSelector(getSelectedLanguage, shallowEqual);
  const translations = useRef({});
  const api = useApi();
  const dispatch = useDispatch();

  const [state, setState] = useState({});

  useEffect(() => {
    const languageKey = normalizeLocale(selectedLanguage.key);
    const update = () => {
      setState({
        ...selectedLanguage,
        normalizedKey: languageKey,
        messages: translations.current[languageKey],
      });
    };

    setTimeLocale(languageKey);

    if (translations.current[languageKey]) {
      update();
      return;
    }

    let cancelled;
    dispatch(loaderShow());
    api.translations
      .get(languageKey)
      .then(({ success, data }) => {
        if (!success) return;
        translations.current[languageKey] = data;

        if (!cancelled) {
          update();
        }
      })
      .then(() => {
        dispatch(loaderHide());
      });
    return () => {
      cancelled = true;
    };
  }, [selectedLanguage, api.translations, dispatch, setState]);

  return state;
};

const LanguageContext = createContext(null);

export const useLanguageContext = () => useContext(LanguageContext);

export const ConnectedIntlProvider = ({ children }) => {
  const {
    messages,
    key,
    normalizedKey,
    inversedDirection: invertedDirection,
  } = useTranslationsState();

  useEffect(() => {
    if (key) {
      setLangCookie(key);
    }
  }, [key]);

  useLayoutEffect(() => {
    document.body.classList.remove(invertedDirection ? "ltr" : "rtl");
    document.body.classList.add(invertedDirection ? "rtl" : "ltr");
  }, [invertedDirection]);

  if (!messages) return null;

  return (
    <LanguageContext.Provider value={{ invertedDirection }}>
      <IntlProvider locale={normalizedKey} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
