import { Redirect, Route, Switch } from "react-router";
import { LoginPage } from "../pages";
import React from "react";

export const LoginRouter = () => (
  <Switch>
    <Route exact path="/login" component={LoginPage} />
    <Redirect to="/login" />
  </Switch>
);
