import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";
import { PermanentNotifications } from "../notifications/permanentNotifications/PermanentNotifications";
import { TemporaryNotifications } from "../notifications/temporaryNotifications/TemporaryNotifications";
import { isSSPI, isPI, isSS } from "../../store/info";

import "./Layout.scss";

export const withLayout = (layoutConfig = {}) => Component => props => {
  const isPIMode = useSelector(isPI);
  const isSSMode = useSelector(isSS);
  const isSSPIMode = useSelector(isSSPI);

  const { customHeader } = layoutConfig;

  const layoutClassNames = classNames("layout", {
    "layout--pi": isPIMode,
    "layout--ss": isSSMode,
    "layout--ss-pi": isSSPIMode,
  });

  return (
    <div className={layoutClassNames}>
      <TemporaryNotifications />
      {customHeader ? customHeader() : <Header />}
      <div className="layout__container">
        <PermanentNotifications />
        <Component {...props} />
      </div>
      <Footer />
    </div>
  );
};
