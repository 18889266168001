import { profileReducer } from "./profile.reducer";
import {
  CHANGE_PROFILE_NAME_SUCCESS,
  CHANGE_PROFILE_SAFE_SEARCH_SUCCESS,
  CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS,
  ProfileActionTypes,
} from "./profile.types";
import {
  LOAD_PROFILES,
  LOAD_PROFILES_FAILURE,
  LOAD_PROFILES_SUCCESS,
  ADD_PROFILE_SUCCESS,
  REMOVE_PROFILE_SUCCESS,
  ProfilesActionTypes,
} from "./profiles.types";
import { withLoadingState } from "../shared/withLoadingState";
import { SAVE_PROFILE_PROTECTION_SUCCESS } from "../protection";
import { addItem, removeItem } from "../shared/collection.operations";
import { FINISH_WIZARD } from "../../pages/wizard/store/wizard.actions";
import { Profile } from "@sportal/api";
import { denormalizeCollection } from "../shared/normalizeCollection";
import { sortProfiles } from "./profiles.helpers";

export const initialState = {
  keys: [],
  list: {},
};

// todo: make interfaces
type AllProfilesActionTypes =
  | ProfilesActionTypes
  | { type: typeof FINISH_WIZARD; payload: { profiles: Profile[] } }
  | {
      type: typeof SAVE_PROFILE_PROTECTION_SUCCESS;
      payload: {
        profile: Profile;
        protection: { name: string; blocked: string[] };
      };
    }
  | ProfileActionTypes;

const reducer = (state = initialState, action: AllProfilesActionTypes) => {
  switch (action.type) {
    case FINISH_WIZARD: {
      return {
        ...action.payload.profiles,
        success: true, // prevents loading the slice
        isLoading: false,
        error: null,
      };
    }
    case LOAD_PROFILES_SUCCESS: {
      return {
        ...state,
        ...action.payload.profiles,
      };
    }
    case ADD_PROFILE_SUCCESS: {
      const slice = addItem(state, action.payload);

      const profiles = denormalizeCollection<Profile>(slice);
      const sortedProfiles = sortProfiles(profiles);

      return {
        ...state,
        ...slice,
        keys: sortedProfiles.map(profile => profile.id),
      };
    }
    case REMOVE_PROFILE_SUCCESS: {
      return {
        ...state,
        ...removeItem(state, action.payload.id),
      };
    }
    case CHANGE_PROFILE_NAME_SUCCESS: {
      const { id } = action.payload.profile;
      const nextList = {
        ...state.list,
        [id]: profileReducer(state.list[id], action),
      };

      const profiles = denormalizeCollection<Profile>({
        keys: state.keys,
        list: nextList,
      });
      const sortedProfiles = sortProfiles(profiles);

      return {
        ...state,
        keys: sortedProfiles.map(profile => profile.id),
        list: nextList,
      };
    }
    case CHANGE_PROFILE_SAFE_SEARCH_SUCCESS:
    case CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS:
    case SAVE_PROFILE_PROTECTION_SUCCESS: {
      const { id } = action.payload.profile;
      return {
        ...state,
        list: {
          ...state.list,
          [id]: profileReducer(state.list[id], action),
        },
      };
    }
    default:
      return state;
  }
};

export const profilesReducer = withLoadingState({
  loadActionType: LOAD_PROFILES,
  successActionType: LOAD_PROFILES_SUCCESS,
  failureActionType: LOAD_PROFILES_FAILURE,
})(reducer);
