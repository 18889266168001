import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ModalService } from "@sportal/cdk";
import { RemoveRoamingDeviceDialog } from "../removeRoamingDeviceDialog/RemoveDeviceDialog";
import { revokeRoamingDevice } from "../../../../../store/devices";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { MobileModalListItem } from "../tableComponents";

const useRevokeRoamingDeviceInfo = device => {
  const dispatch = useDispatch();

  const revokeRoaming = () => {
    ModalService.show(modal => ({
      dialog: <RemoveRoamingDeviceDialog modal={modal} device={device} />,
    }))
      .then(() => dispatch(revokeRoamingDevice(device)))
      .catch(() => {});
  };

  return {
    content: <FormattedMessage id="revoke_access" />,
    className: "profile-device-menu__remove-item",
    action: revokeRoaming,
    id: "revoke_access",
  };
};

export const RevokeRoamingDeviceDropdownItem = ({ device, ...props }) => {
  const { content, className, action, id } = useRevokeRoamingDeviceInfo(device);

  return (
    <LazyLoadDropdownItem
      action={action}
      className={className}
      id={id}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const RevokeRoamingDeviceModalItem = ({ device }) => {
  const { content, action, id } = useRevokeRoamingDeviceInfo(device);

  return <MobileModalListItem content={content} action={action} id={id} />;
};
