export enum ServiceType {
  WebFilters = "web-filters",
  InternetSecurity = "internet-security",
  Spson = "spson",
}

export enum ReportingService {
  PersonalInternet = "personal-internet",
  SecureBusiness = "secure-business",
  SubscriberSafety = "subscriber-safety",
}

export enum SafeSearchService {
  Google = "google",
  Bing = "bing",
  YouTube = "youtube",
}

export enum Service {
  PersonalInternet = "personal-internet",
  SecureBusiness = "secure-business",
  SubscriberSafety = "subscriber-safety",
  HttpsTermination = "https-termination",
}

interface CommonServiceData {
  enabled: boolean;
}

//region HTTPSTerminationServiceData
interface HTTPSTerminationServiceData extends CommonServiceData {
  service: Service.HttpsTermination;
}
//endregion

//region PersonalInternetServiceData
interface CommonPersonalInternetServiceData extends CommonServiceData {
  service: Service.PersonalInternet;
  "perdevice-support": boolean;
  "household-support": boolean;
  "max-profiles": number;
  "bypass-pin-enabled": boolean;
  "safe-search": SafeSearchService[];
  "bypass-private-dns": {
    "apple-private-relay": boolean;
  };
}

interface SinglePersonalInternetServiceData
  extends CommonPersonalInternetServiceData {
  "household-support": true;
  "perdevice-support": false;
  "max-profiles": 1;
}

interface MultiplePersonalInternetServiceData
  extends CommonPersonalInternetServiceData {
  "perdevice-support": true;
  "max-profiles": number; // > 1
}

export type PersonalInternetServiceData =
  | SinglePersonalInternetServiceData
  | MultiplePersonalInternetServiceData;
//endregion

//region SecureBusinessServiceData
interface CommonSecureBusinessServiceData extends CommonServiceData {
  service: Service.SecureBusiness;
  "multiple-profiles-support": boolean;
  "single-profile-support": boolean;
  "max-profiles": number; // TODO: is it connected with "multiple-profiles-support"?
  "safe-search": SafeSearchService[];
  "subscriber-allow-list-overrides": [ServiceType.InternetSecurity?];
}

interface SingleSecureBusinessServiceData
  extends CommonSecureBusinessServiceData {
  "multiple-profiles-support": false;
  "max-profiles": 1;
}

interface MultipleSecureBusinessServiceData
  extends CommonSecureBusinessServiceData {
  "multiple-profiles-support": true;
  "max-profiles": number; // >1
}

type SecureBusinessServiceData =
  | SingleSecureBusinessServiceData
  | MultipleSecureBusinessServiceData;
//endregion

//region SubscriberSafetyServiceData
interface SubscriberSafetyServiceData extends CommonServiceData {
  service: Service.SubscriberSafety;
  "sb-multiple-support": boolean;
  "sb-single-support": boolean;
  "standard-support": boolean;
  "bypass-pin-enabled": boolean;
}
//endregion

export type ServiceData =
  | SecureBusinessServiceData
  | PersonalInternetServiceData
  | SubscriberSafetyServiceData
  | HTTPSTerminationServiceData;

export interface SettingsServerData {
  public: {
    "reporting-enabled": boolean;
  };
  reporting: {
    [service in ReportingService]: boolean;
  };
  reports: string[]; // used only for checking the presence of top 5 reports
  server: {
    leader: boolean;
    "time-zone": string;
  };
  services: ServiceData[];
}
