import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { BackArrowButton } from "../../../components";
import {
  useWizardNavigationContext,
  WizardPaths,
} from "../WizardNavigationContext";
import { getProfilesList } from "../store/profiles";

export const GoPrevStep = props => {
  const { step } = useWizardNavigationContext();
  const profiles = useSelector(getProfilesList);

  if (step === WizardPaths.Multiple) {
    return _.isEmpty(profiles) ? <BackArrowButton {...props} /> : null;
  }

  return <BackArrowButton {...props} />;
};
