export const HIDE_ALLOW_LIST_OVERRIDE_WARNING =
  "[BANNERS] HIDE_ALLOW_LIST_OVERRIDE_WARNING";
export const HIDE_SPSON_PANEL = "[BANNERS] HIDE_SPSON_PANEL";
export const HIDE_SPSON_MAX_LIMIT_WARNING =
  "[BANNERS] HIDE_SPSON_MAX_LIMIT_WARNING";

export interface BannersReducerState {
  allowListOverrideWarningVisible: boolean;
  spsonPanelVisible: boolean;
  spsonMaxLimitWarningVisible: boolean;
}

interface HideAllowListOverrideWarningAction {
  type: typeof HIDE_ALLOW_LIST_OVERRIDE_WARNING;
}

interface HideSpsonPanel {
  type: typeof HIDE_SPSON_PANEL;
}

interface HideSpsonMaxLimitWarning {
  type: typeof HIDE_SPSON_MAX_LIMIT_WARNING;
}

export type BannersActionTypes =
  | HideAllowListOverrideWarningAction
  | HideSpsonPanel
  | HideSpsonMaxLimitWarning;
