import { Profile, SharedProtection } from "@sportal/api";

export const SAVE_PROFILE_PROTECTION = "[PROTECTIONS] SAVE_PROFILE_PROTECTION";
export const SAVE_PROFILE_PROTECTION_SUCCESS =
  "[PROTECTIONS] SAVE_PROFILE_PROTECTION_SUCCESS";
export const SAVE_PROFILE_PROTECTION_FAILURE =
  "[PROTECTIONS] SAVE_PROFILE_PROTECTION_FAILURE";

interface SaveProfileProtectionAction {
  type: typeof SAVE_PROFILE_PROTECTION;
}

export interface SaveProfileProtectionSuccessAction {
  type: typeof SAVE_PROFILE_PROTECTION_SUCCESS;
  payload: {
    profile: Profile;
    protection: SharedProtection;
  };
}

interface SaveProfileProtectionFailureAction {
  type: typeof SAVE_PROFILE_PROTECTION_FAILURE;
  payload: object;
}

export type ProtectionsActionTypes =
  | SaveProfileProtectionAction
  | SaveProfileProtectionSuccessAction
  | SaveProfileProtectionFailureAction;
