import React from "react";
import PropTypes from "prop-types";
import { CategoriesList } from "../categoriesList/CategoriesList";
import { ProtectionPicker } from "./ProtectionPicker";
import { Protection } from "@sportal/api";
import { ProtectionSubtitle } from "./ProtectionSubtitle";

import "./ProtectionEditor.scss";

const isShared = protection =>
  protection.name && protection.name !== Protection.Custom;

export const ProtectionEditor = ({
  categories,
  protections,
  selectedProtection,
  changeProtection,
  block,
  allow,
}) => {
  return (
    <div className={"protection-editor"}>
      <div className="protection-editor__picker-wrapper">
        <ProtectionPicker
          protections={protections}
          selected={selectedProtection.name}
          onChange={selected => changeProtection(selected)}
        />
      </div>
      {isShared(selectedProtection) && (
        <ProtectionSubtitle selectedProtection={selectedProtection} />
      )}
      {/*TODO: refactor to avoid reapplying handlers on each change*/}
      <CategoriesList
        allCategories={categories}
        blockedCategories={selectedProtection.blocked}
        allowAction={allow}
        blockAction={block}
      />
    </div>
  );
};

ProtectionEditor.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.array,
      status: PropTypes.boolean,
    })
  ).isRequired,
  protections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      blocked: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  selectedProtection: PropTypes.shape({
    name: PropTypes.string,
    blocked: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  changeProtection: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired,
  allow: PropTypes.func.isRequired,
};
