import { RequestsCollection } from "@sportal/api";

export const LOAD_REQUESTS = "[DEVICES] LOAD_REQUESTS";
export const LOAD_REQUESTS_SUCCESS = "[DEVICES] LOAD_REQUESTS_SUCCESS";
export const LOAD_REQUESTS_FAILURE = "[DEVICES] LOAD_REQUESTS_FAILURE";

interface LoadRequestsAction {
  type: typeof LOAD_REQUESTS;
}

interface LoadRequestsSuccessAction {
  type: typeof LOAD_REQUESTS_SUCCESS;
  payload: RequestsCollection;
}

interface LoadRequestsFailureAction {
  type: typeof LOAD_REQUESTS_FAILURE;
  payload: object;
}

export type RequestsActionTypes =
  | LoadRequestsAction
  | LoadRequestsSuccessAction
  | LoadRequestsFailureAction;
