import React, { VFC } from "react";
import { FormattedMessage } from "react-intl";

import { RoamingDeviceStatus } from "@sportal/api";

import "./appStatus.scss";

const classNameMap = {
  [RoamingDeviceStatus.Enabled]: "app-status--enabled",
  [RoamingDeviceStatus.Disabled]: "app-status--disabled",
};

const translateMap = {
  [RoamingDeviceStatus.Enabled]: "roaming_app_status_enabled",
  [RoamingDeviceStatus.Disabled]: "roaming_app_status_disabled",
};

interface AppStatusProps {
  status: RoamingDeviceStatus;
}

export const AppStatus: VFC<AppStatusProps> = ({ status }) => {
  return (
    <span className={`app-status ${classNameMap[status]}`}>
      <FormattedMessage id={translateMap[status]} />
    </span>
  );
};
