import React from "react";
import { AuthServiceErrorCode } from "@sportal/api";
import { useDispatch } from "react-redux";

import { useLoadableSlice } from "../../store/shared/withLoadingState";
import { fromInfo, isWrongUserMode, loadInfo } from "../../store/info";
import { getInfo } from "../../store/root.selectors";
import { ErrorPage, shouldShowErrorPage } from "../../pages";
import { LoginRouter } from "../LoginRouter";
import { logout } from "../../store/shared/logout.actions";

export const withAuthGuard = Component => () => {
  const dispatch = useDispatch();
  const info = useLoadableSlice(loadInfo(), getInfo);
  if (info === null) {
    return null;
  }

  if (shouldShowErrorPage(info, AuthServiceErrorCode)) {
    return <ErrorPage />;
  }

  if (isWrongUserMode(info)) {
    dispatch(logout(info.error.message, false));
  }

  const isUserAuthenticated = !!fromInfo.getSubscriberId(info);
  return isUserAuthenticated ? <Component /> : <LoginRouter />;
};
