import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { BaseInput } from "../baseInput/BaseInput";

import "./SwitchBase.scss";

/*
 * If you want a boolean button with custom icon - you're in the right place!
 * BaseInput is hidden by default and replaced with icon
 */
export function SwitchBase({
  id,
  className,
  label,
  children,
  ariaOptionLabel,
  ...attrs
}) {
  const classes = classnames(
    "switch-base",
    {
      "switch-base--disabled": attrs.disabled,
    },
    className
  );

  return (
    <label
      className={classes}
      data-testid="switch-base-label"
      {...(id && { htmlFor: id })}
      {...(ariaOptionLabel && { "aria-label": ariaOptionLabel })}
    >
      {children}
      <BaseInput hidden {...(id && { id })} {...attrs} />
      {label}
    </label>
  );
}

SwitchBase.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.object,
};
