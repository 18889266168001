import React, { Fragment } from "react";

import { Mobile, NotMobile } from "../../../../../common/Responsive";
import { DeviceActionsToggle, DeviceMenu } from "../tableComponents";
import {
  AssignDeviceDropdownItem,
  AssignDeviceModalItem,
  MergeNonAssignedDeviceDropdownItem,
  MergeNonAssignedDeviceModalItem,
} from "../deviceActionItems";

export const RequestsMenu = ({ request }) => {
  const dropdownItems = [
    <AssignDeviceDropdownItem request={request} />,
    <MergeNonAssignedDeviceDropdownItem request={request} />,
  ];

  const modalItems = [
    <AssignDeviceModalItem request={request} />,
    <MergeNonAssignedDeviceModalItem request={request} />,
  ];

  return (
    <Fragment>
      <Mobile>
        <DeviceActionsToggle
          deviceName={request.name || request.networkId}
          menuItems={modalItems}
        />
      </Mobile>

      <NotMobile>
        <DeviceMenu className="requests-menu" items={dropdownItems} />
      </NotMobile>
    </Fragment>
  );
};
