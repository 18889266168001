import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { BaseInput } from "../baseInput/BaseInput";
import { Icon } from "../icon/Icon";

import "./Switcher.scss";

export const Switcher = ({ checked, disabled, onChange, id, ...attrs }) => (
  <div
    data-testid={"switch"}
    className={classNames("switcher", {
      "switcher--checked": checked,
      "switcher--disabled": disabled,
    })}
  >
    <label htmlFor={id} className="switch">
      <span className={"switch__toggle"}></span>
      <span className={"switch__status"}>
        <span className={"switch__status--on"}>
          <Icon icon={"far fa-check"} />
        </span>
        <span className={"switch__status--off"}>
          <Icon icon={"far fa-times"} />
        </span>
      </span>
    </label>
    <BaseInput
      id={id}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      hidden
      {...attrs}
      role={"switch"}
      aria-checked={checked}
    />
  </div>
);

Switcher.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
