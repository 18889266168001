import _ from "lodash";
import classnames from "classnames";

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

import { Form } from "@sportal/cdk";
import { useMount, usePrevious } from "@sportal/cdk/hooks";
import { useIntlTranslate } from "../../../../../hooks";
import {
  Button,
  Input,
  SubmitButton,
  useForm,
} from "../../../../../components";
import {
  checkUrl,
  resetUrlOptions,
} from "../../../../../store/lists/lists.actions";
import {
  getUrlOptions,
  getUrlOptionsError,
  getUrlOptionsUrl,
} from "../../../../../store/lists/lists.selectors";
import { UrlOptions } from "../urlIOptions/UrlOptions";

import { useUrlFromRoute } from "../useUrlFromRoute";

import "./UrlCheck.scss";

export const UrlCheck = ({ className, profileId }) => {
  const translate = useIntlTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const urlOptions = useSelector(getUrlOptions);
  const checkedUrl = useSelector(getUrlOptionsUrl);
  const checkError = useSelector(getUrlOptionsError);
  const form = useForm({ url: "" });

  const prevUrlOptions = usePrevious(urlOptions);
  const prevUrl = usePrevious(form.values.url);
  const prevUrlChanged = form.values.url !== prevUrl;

  const [urlFromRoute, urlFromRouteInvalid] = useUrlFromRoute();

  const handleSubmit = useCallback(() => {
    const url = form.getChanges().url.trim();
    form.set("url", url);
    dispatch(checkUrl(url));
  }, [form, dispatch]);

  const updateUrl = useCallback(
    value => {
      form.set("url", value);
      form.setError("url", null);
    },
    [form]
  );

  const resetForm = useCallback(() => {
    dispatch(resetUrlOptions());
  }, [dispatch]);

  useMount(() => () => dispatch(resetUrlOptions()));

  useMount(() => {
    if (urlFromRoute) {
      form.set("url", urlFromRoute);
      dispatch(checkUrl(urlFromRoute));
      history.replace("./");
    }

    if (urlFromRouteInvalid) {
      history.replace("./");
    }
  });

  useEffect(() => {
    if (
      !_.isEmpty(prevUrlOptions) &&
      _.isEmpty(urlOptions) &&
      checkedUrl === undefined
    ) {
      updateUrl(""); // reset form value after adding url to list
    }
  }, [prevUrlOptions, urlOptions, updateUrl, checkedUrl]);

  return (
    <div className={classnames("url-check", className)}>
      <Form
        className="url-check__form"
        onSubmit={handleSubmit}
        formProps={form}
        disabled={
          !form.values.url ||
          form.values.url.trim() === "" ||
          !form.didFormChange()
        }
      >
        <div className="url-check__form__input">
          <Input
            label={<FormattedMessage id={"enter_url"} />}
            placeholder={translate("enter_url_placeholder")}
            value={form.values.url}
            onChange={e => updateUrl(e.target.value)}
            onClear={() => _.isEmpty(urlOptions) && updateUrl("")}
            error={
              checkError &&
              !prevUrlChanged && <FormattedMessage id={checkError} />
            }
            disabled={!_.isEmpty(urlOptions)}
          />
        </div>
        <div className="url-check__form__button">
          {_.isEmpty(urlOptions) ? (
            <SubmitButton color="primary" size="large">
              <FormattedMessage id="check" />
            </SubmitButton>
          ) : (
            <Button color="secondary" size="large" onClick={resetForm}>
              <FormattedMessage id="cancel" />
            </Button>
          )}
        </div>
      </Form>
      {!_.isEmpty(urlOptions) && !checkError && (
        <UrlOptions items={urlOptions} profileId={profileId} />
      )}
    </div>
  );
};
