import React, { CSSProperties, FC } from "react";
import { HeaderGroup } from "react-table";
import classNames from "classnames";

import { SortButton } from "../sortButton";

import "./THead.scss";

interface THeadProps {
  headerGroups: HeaderGroup<any>[];
  gridStyle: CSSProperties;
  isDataEmpty: boolean;
}

export const THead: FC<THeadProps> = ({
  headerGroups,
  gridStyle,
  isDataEmpty,
}) => {
  return (
    <div role="rowgroup" className="thead">
      {headerGroups.map(headerGroup => (
        <div
          {...headerGroup.getHeaderGroupProps()}
          className="tr"
          style={gridStyle}
          aria-hidden={isDataEmpty}
        >
          {headerGroup.headers.map(column => {
            const { canSort, isSorted, isSortedDesc } = column;

            return (
              <div
                {...column.getHeaderProps()}
                {...(isSorted && {
                  "aria-sort": isSortedDesc ? "descending" : "ascending",
                })}
                className={classNames(
                  `th th--${column.id}`,
                  {
                    "sr-only": column.width === 0,
                  },
                  { "th--disabled": isDataEmpty }
                )}
              >
                {canSort ? (
                  <SortButton
                    isSorted={isSorted}
                    isSortedDesc={isSortedDesc}
                    onClick={(column.getSortByToggleProps() as any).onClick}
                    disabled={isDataEmpty}
                  >
                    {column.render("Header")}
                  </SortButton>
                ) : (
                  column.render("Header")
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
