import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { showValue } from "../../../../../../../components";

import "./DeviceCode.scss";

interface DeviceCodeProps {
  code?: string;
}

export const DeviceCode: FC<DeviceCodeProps> = ({ code }) => {
  return (
    <span className="device-code">
      <FormattedMessage id="code" />: {showValue(code)}
    </span>
  );
};
