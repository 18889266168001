import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { ModalService } from "@sportal/cdk";
import { RemoveDeviceDialog } from "../removeDeviceDialog/RemoveDeviceDialog";
import { removeLogicalDevice } from "../../../../../store/devices";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { MobileModalListItem } from "../tableComponents";

const useRemoveDeviceInfo = device => {
  const dispatch = useDispatch();

  const remove = () => {
    ModalService.show(modal => ({
      dialog: <RemoveDeviceDialog modal={modal} device={device} />,
    }))
      .then(() => dispatch(removeLogicalDevice(device)))
      .catch(_.noop);
  };

  return {
    content: <FormattedMessage id="remove" />,
    className: "profile-device-menu__remove-item",
    action: remove,
    id: "remove",
  };
};

export const RemoveDeviceDropdownItem = ({ device, ...props }) => {
  const { content, className, action, id } = useRemoveDeviceInfo(device);

  return (
    <LazyLoadDropdownItem
      action={action}
      className={className}
      id={id}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const RemoveDeviceModalItem = ({ device }) => {
  const { content, action, id } = useRemoveDeviceInfo(device);

  return <MobileModalListItem content={content} action={action} id={id} />;
};
