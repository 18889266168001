import React, { createContext, useContext, useState, useMemo } from "react";

import { uuid } from "@sportal/lib";

export const useUuid = () => useMemo(() => uuid(), []);

const StickyProfileContext = createContext({
  stickySections: {},
  setStickySections: () => {},
});

export const useStickyContext = () => useContext(StickyProfileContext);

export const StickyProfilesSectionsProvider = ({ children }) => {
  const [sections, setSections] = useState({});

  const contextValue = {
    stickySections: sections,
    setStickySections: (id, isOpened) => {
      setSections(prevSections => ({
        ...prevSections,
        [id]: isOpened,
      }));
    },
  };

  return (
    <StickyProfileContext.Provider value={contextValue}>
      {children}
    </StickyProfileContext.Provider>
  );
};
