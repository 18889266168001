import { Durations } from "@sportal/api/reports";
import { ReportTypes } from "./reports.constants";

export const LOAD_REPORTS = "[REPORTS] LOAD_REPORTS";
export const LOAD_REPORTS_SUCCESS = "[REPORTS] LOAD_REPORTS_SUCCESS";
export const LOAD_REPORTS_FAILURE = "[REPORTS] LOAD_REPORTS_FAILURE";

interface ReportsDataFormatted {
  [ReportTypes.Activity]?: number;
  [ReportTypes.Blocks]?: number;
  [ReportTypes.SS]?: number;
  [ReportTypes.ActivityTop]?: [string, number];
  [ReportTypes.BLocksTop]?: [string, number];
}

export interface LoadReportsPayload {
  data: ReportsDataFormatted[];
  profile?: string;
  duration: Durations;
}

interface LoadReportsAction {
  type: typeof LOAD_REPORTS;
}

interface LoadReportsSuccessAction {
  type: typeof LOAD_REPORTS_SUCCESS;
  payload: LoadReportsPayload;
}

interface LoadReportsFailureAction {
  type: typeof LOAD_REPORTS_FAILURE;
  payload: object;
}

export type ReportsActionTypes =
  | LoadReportsAction
  | LoadReportsSuccessAction
  | LoadReportsFailureAction;
