import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { NotificationTitle } from "../../NotificationTitle";

export const StandardNotification = ({ title, description }) => (
  <Fragment>
    {title && <NotificationTitle title={<FormattedMessage id={title} />} />}
    {description && (
      <div className="notification__description">
        <p>
          {typeof description === "string" ? (
            <FormattedMessage id={description} />
          ) : (
            <FormattedMessage {...description} />
          )}
        </p>
      </div>
    )}
  </Fragment>
);

StandardNotification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
