import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ServiceStatus } from "../../../serviceStatus/ServiceStatus";
import { Icon } from "@sportal/cdk";

import "./DetailedNotMobile.scss";

export const DetailedNotMobile = ({
  serviceStatus,
  title,
  description,
  changeLink,
  statusIcon,
  infectionMessage,
}) => (
  <div className="detailed-service-panel--not-mobile">
    <div className="detailed-service-panel__content">
      <div className="detailed-service-panel__header">
        <span className="detailed-service-panel__title">
          <FormattedMessage id={title} />
        </span>
        {infectionMessage && (
          <span className="infection-status">
            <Icon icon={statusIcon} />
            <FormattedMessage id={infectionMessage} />
          </span>
        )}
      </div>
      <div className="detailed-service-panel__description">
        <FormattedMessage id={description} />
      </div>
    </div>
    <div className="detailed-service-panel__status">
      <ServiceStatus active={serviceStatus} />
      {changeLink && (
        <Link className="link" to="/settings/block-page">
          <FormattedMessage id="change" />
        </Link>
      )}
    </div>
  </div>
);

DetailedNotMobile.propTypes = {
  serviceStatus: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  changeLink: PropTypes.bool,
  statusIcon: PropTypes.string,
  infectionMessage: PropTypes.string,
};
