import _ from "lodash";
import { getTimezoneOffset } from "date-fns-tz";

import {
  LOAD_TIMEZONES,
  LOAD_TIMEZONES_SUCCESS,
  LOAD_TIMEZONES_FAILURE,
  TimezonesActionTypes,
} from "./timezones.types";
import { createTempNotification } from "../../components/notifications";
import { Api } from "../../api";
import { Timezones } from "@sportal/api";

export const loadTimezones = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_TIMEZONES } as TimezonesActionTypes);

  const result = await api.timezones.get();

  if (result.success === false) {
    dispatch(loadTimezonesFailure(result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "cannot_load_timezones",
      })
    );
    return;
  }

  const timezones = _.orderBy(
    result.data,
    timezone => getTimezoneOffset(timezone),
    "asc"
  );

  dispatch(loadTimezonesSuccess(timezones));
};

export const loadTimezonesSuccess = (
  timezones: Timezones
): TimezonesActionTypes => ({
  type: LOAD_TIMEZONES_SUCCESS,
  payload: timezones,
});

export const loadTimezonesFailure = (error): TimezonesActionTypes => ({
  type: LOAD_TIMEZONES_FAILURE,
  payload: error,
});
