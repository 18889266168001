import React from "react";
import { FormattedMessage } from "react-intl";

import "./RemoveDeviceDialog.scss";

import { ConfirmationDialog } from "../../../../../components";

export const RemoveDeviceDialog = ({ modal, device }) => (
  <ConfirmationDialog
    className="remove-device-dialog"
    modal={modal}
    content={
      <FormattedMessage
        id="confirm_remove_device"
        values={{
          name: (
            <span className="remove-device-dialog__device-name">
              {device.name}
            </span>
          ),
        }}
      />
    }
    cancelMessage={<FormattedMessage id="cancel" />}
    submitMessage={<FormattedMessage id="remove" />}
  />
);
