import _ from "lodash";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { useForm } from "@sportal/cdk";
import {
  CommonModalFooter,
  CommonModalHeader,
  ModalDialog,
  ModalHint,
} from "../../../../../components";
import {
  DeviceDialogInput,
  makeProfilePickerItems,
  ProfilesPicker,
  useDeviceIdValidator,
  useDeviceNameValidator,
} from "../deviceForm";
import { useIntlTranslate } from "../../../../../hooks";

const prepareDeviceName = _.trim;

export function AddDeviceDialog({ modal, profiles, devices }) {
  const translate = useIntlTranslate();
  const profileSelectItems = makeProfilePickerItems(profiles, translate);

  const firstAvailableProfileItem = _.find(profileSelectItems, {
    disabled: false,
  });
  const form = useForm({
    name: "",
    mac: "",
    profileName: firstAvailableProfileItem.value,
  });

  const changeName = name => {
    form.set("name", name);
    form.setError("name", null);
  };

  const changeMac = mac => {
    form.set("mac", mac);
    form.setError("mac", null);
  };

  const validateName = useDeviceNameValidator(devices);
  const validateMac = useDeviceIdValidator(devices);
  const submit = useCallback(
    modal => {
      const name = prepareDeviceName(form.values.name);
      const mac = form.values.mac;

      const errors = _.pickBy(
        {
          name: validateName(name),
          mac: validateMac(mac),
        },
        error => !_.isEmpty(error)
      );

      if (!_.isEmpty(errors)) {
        _.forEach(errors, (error, key) => {
          form.setError(key, error);
        });
      } else {
        modal.resolve({
          name,
          mac,
          profileName: form.values.profileName,
        });
      }
    },
    [form, validateName, validateMac]
  );

  const close = () => modal.reject();
  const isSubmitDisabled =
    _.isEmpty(form.values.mac) ||
    _.isEmpty(prepareDeviceName(form.values.name));

  return (
    <ModalDialog
      className="add-device-modal"
      disabled={isSubmitDisabled}
      submitHandler={() => submit(modal)}
      rejectHandler={close}
    >
      <CommonModalHeader>
        <FormattedMessage id="add_device_manually" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ModalHint>
          <FormattedMessage id="add_device_message" />
        </ModalHint>
        <DeviceDialogInput
          label="device_name"
          placeholder="enter_device_name"
          value={form.values.name}
          onChange={changeName}
          error={form.errors.name}
          autoFocus
          maxLength="30"
          autoCapitalize="none"
          autoCorrect="off"
          spellCheck="false"
        />
        <DeviceDialogInput
          label="mac_address"
          placeholder="mac_address_placeholder"
          value={form.values.mac}
          onChange={changeMac}
          error={form.errors.mac}
          maxLength="17"
          autoCapitalize="none"
          autoCorrect="off"
          spellCheck="false"
          inputMode="verbatim"
        />
        <ProfilesPicker
          label={<FormattedMessage id="profile" />}
          items={profileSelectItems}
          selected={form.values.profileName}
          onChange={value => form.set("profileName", value)}
        />
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="add_device" />} />
    </ModalDialog>
  );
}
