import _ from "lodash";
import React from "react";

import { ProfileDevicesSection } from "../profileDevicesSection";
import { useDevicesTabContext } from "../DevicesTabContext";

export const ProfilesDevices = ({ isAdvancedView }) => {
  const { devicesProfiles } = useDevicesTabContext();

  return _.map(devicesProfiles, ({ profile, limit, devices }) => (
    <ProfileDevicesSection
      key={profile.id}
      profile={profile}
      limit={limit}
      devices={devices}
      isAdvancedView={isAdvancedView}
    />
  ));
};
