import { createContext, useContext } from "react";
import _ from "lodash";
import { GetToggleProps } from "./collapse.helpers";

interface CollapseContextInterface {
  opened: Boolean;
  toggleOpened: () => void;
  getToggleProps: GetToggleProps | (() => void);
}

export const CollapseContext = createContext<CollapseContextInterface>({
  opened: false,
  toggleOpened: _.noop,
  getToggleProps: _.noop,
});

export const useCollapseContext = () => useContext(CollapseContext);
