import React, { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Mobile, NotMobile } from "../../../../common/Responsive";
import { UserMenuDropdown } from "./UserMenuDropdown";
import {
  createActionItem,
  createLink,
  createTitle,
  UserMenuSidebar,
} from "./UserMenuSidebar";

export const HeaderUserMenu = ({
  sidebarItems,
  dropdownItems,
  subscriberName,
}) => {
  const mobileItems = useMemo(
    () =>
      _.map(sidebarItems, item => {
        if (item.content && item.content.type === Link) {
          return createLink(item);
        }
        if (!!item.action) {
          return createActionItem(item);
        }

        return createTitle(item);
      }),
    [sidebarItems]
  );

  return (
    <Fragment>
      <Mobile>
        <UserMenuSidebar items={mobileItems} />
      </Mobile>
      <NotMobile>
        <UserMenuDropdown
          items={dropdownItems}
          subscriberName={subscriberName}
        />
      </NotMobile>
    </Fragment>
  );
};
