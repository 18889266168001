import React, { ReactElement, VFC } from "react";
import _ from "lodash";

interface InputErrorProps {
  error: string[] | ReactElement[] | string | ReactElement;
  ariaDescribedById: string;
}

export const InputError: VFC<InputErrorProps> = ({
  error,
  ariaDescribedById,
}) => {
  return (
    <div className="input__error" id={ariaDescribedById} aria-live="polite">
      {_.castArray(error).map((error, index) => (
        <div key={index}>{error}</div>
      ))}
    </div>
  );
};
