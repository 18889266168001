import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../../../components";
import { WizardPageTitle } from "../wizardPageTitle/WizardPageTitle";

export function WizardProtectionTitle({ title, goBack }) {
  return (
    <WizardPageTitle>
      <Button size="medium" color="secondary" onClick={goBack}>
        <FormattedMessage id={"to_all_profiles"} />
      </Button>
      <FormattedMessage id={title} />
    </WizardPageTitle>
  );
}

WizardProtectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
