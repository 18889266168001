import _ from "lodash";
import React, { useState } from "react";

import {
  DEVICE_NAME_MAX_LENGTH,
  useDeviceNameValidator,
} from "./useDeviceNameValidator";

import { DeviceDialogInput } from "./DeviceDialogInput";

export const DeviceNameInput = ({
  devices,
  device,
  handleChange,
  value,
  error,
  ...restProps
}) => {
  const [name, setName] = useState(value);
  const otherDevices = _.reject(devices, { name: device.name });
  const validateName = useDeviceNameValidator(otherDevices);

  const onDeviceNameChange = name => {
    const error = validateName(name.trim());

    setName(name);
    handleChange(name.trim(), error);
  };

  return (
    <DeviceDialogInput
      label="device_name"
      placeholder="enter_device_name"
      value={name}
      onChange={onDeviceNameChange}
      error={error}
      autoFocus
      maxLength={DEVICE_NAME_MAX_LENGTH}
      autoCapitalize="none"
      autoCorrect="off"
      spellCheck="false"
      {...restProps}
    />
  );
};
