import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Button } from "../../button/Button";
import { useIntlTranslate } from "../../../hooks";
import { generateDeeplink } from "../../deeplinkDialog/deeplinkDialog.actions";
import { getRoamingLimit } from "../../../store/devices";

import "./SpsonCompactPanel.scss";

export const SpsonCompactPanel = () => {
  const translate = useIntlTranslate();
  const dispatch = useDispatch();
  const roamingLimit = useSelector(getRoamingLimit);

  function handleGenerateLinkClick() {
    dispatch(generateDeeplink());
  }

  return (
    <div className={"spson-compact-panel"}>
      <div className={"spson-compact-panel__main"}>
        <h3 className={"spson-compact-panel__header"}>
          <FormattedMessage id={"sps_client_installation_link"} />
        </h3>
        <div className={"spson-compact-panel__roaming-limit"}>
          {translate("roaming_limit_message", {
            roamingLimit,
          })}
        </div>
      </div>
      <div className={"spson-compact-panel__actions"}>
        <Button
          className={"generate-link"}
          size={"medium"}
          color={"primary"}
          aria-label={translate("aria_sps_button_generate_link")}
          onClick={handleGenerateLinkClick}
        >
          <FormattedMessage id={"generate_link"} />
        </Button>
      </div>
    </div>
  );
};
