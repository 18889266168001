import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getLines } from "../../../../store/root.selectors";
import {
  getDevicesLimit,
  getLogicalDevicesList,
  getLogicalDeviceDetailsList,
} from "../../../../store/devices";
import { isRoamingType } from "./profileDevicesSection/components/profileDeviceTable.helpers";

function useGetMergeableDevices(devices, isLine) {
  const deviceDetailsList = useSelector(getLogicalDeviceDetailsList);

  const isMergedOrARoamingDevice = useCallback(
    device => {
      const isMerged = _.size(device.identifiers) > 1;
      const isRoaming = isRoamingType(device.identifiers[0], deviceDetailsList);

      return isMerged || isRoaming;
    },
    [deviceDetailsList]
  );

  const mergeableDevices = useMemo(
    () =>
      _.reject(devices, isMergedOrARoamingDevice).map(device => ({
        device: device,
        isLine: isLine(device),
      })),
    [devices, isLine, isMergedOrARoamingDevice]
  );

  return useCallback(
    device => {
      // lines can be merged only with fixed and vise versa
      return _.filter(mergeableDevices, { isLine: !isLine(device) }).map(
        ({ device }) => device
      );
    },
    [mergeableDevices, isLine]
  );
}

export const DevicesTabContext = createContext({});

// TODO: the pretty similar processing is done for devices on Profile Details
//  it may make sense to do it earlier than in component.
//  As a possible way – couple loading logical-devices with lines
//  and distinguish macs and phone numbers right after loading,
//  this way it will be needed to keep correct types for ids during merge/unmerge
const distinguishIds = isLineId => device => ({
  ...device,
  networkId: _.find(device.identifiers, _.negate(isLineId)),
  phoneNumber: _.find(device.identifiers, isLineId),
});

export const DevicesTabContextProvider = ({ children, profilesSelector }) => {
  const profiles = useSelector(profilesSelector, _.isEqual);
  const devices = useSelector(getLogicalDevicesList, _.isEqual);
  const deviceDetailsList = useSelector(getLogicalDeviceDetailsList, _.isEqual);
  const limit = useSelector(getDevicesLimit);
  const { list: lines } = useSelector(getLines, _.isEqual);

  const isLineId = useCallback(id => _.has(lines, id), [lines]);

  const devicesWithDetails = useMemo(() => {
    const getDeviceDetails = identifier =>
      deviceDetailsList.find(details => identifier === details.identifier);

    return devices.map(device => ({
      ...device,
      deviceDetails: device.identifiers.map(getDeviceDetails),
    }));
  }, [deviceDetailsList, devices]);

  const devicesProfiles = useMemo(() => {
    const devicesByProfile = _.groupBy(
      devicesWithDetails,
      ({ profile }) => profile
    );
    return _.map(profiles, profile => ({
      profile: profile,
      limit: limit,
      devices: _.map(devicesByProfile[profile.name], distinguishIds(isLineId)),
      isFull: limit !== 0 && _.size(devicesByProfile[profile.name]) >= limit,
    }));
  }, [profiles, devicesWithDetails, limit, isLineId]);

  const isLine = useCallback(device => isLineId(device.identifiers[0]), [
    isLineId,
  ]);
  const getMergeableDevices = useGetMergeableDevices(devices, isLine);
  const getProfileByName = useCallback(
    profileName => {
      return _.find(
        devicesProfiles,
        fullInfo => fullInfo.profile.name === profileName
      );
    },
    [devicesProfiles]
  );

  const contextValue = useMemo(
    () => ({
      getMergeableDevices,
      devicesProfiles,
      devices,
      allProfilesFull: _.every(devicesProfiles, { isFull: true }),
      getProfileByName,
    }),
    [getMergeableDevices, devicesProfiles, devices, getProfileByName]
  );

  return (
    <DevicesTabContext.Provider value={contextValue}>
      {children}
    </DevicesTabContext.Provider>
  );
};

export const useDevicesTabContext = () => useContext(DevicesTabContext);
