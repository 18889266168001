import React from "react";

import { Icon } from "../icon/Icon";

import "./RoamingIcon.scss";

export function RoamingIcon() {
  return (
    <span className="roaming-icon" aria-hidden="true">
      <Icon icon="far fa-signal roaming-icon__bars" />
    </span>
  );
}
