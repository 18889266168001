import React, { cloneElement, forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { BaseButton } from "@sportal/cdk";

import "./Button.scss";

export const Button = forwardRef(function Button(
  {
    size,
    color,
    variant,
    className,
    ellipsis = true,
    iconLeft,
    iconRight,
    children,
    as,
    ...buttonProps
  },
  ref
) {
  const buttonClass = classnames(
    "button",
    `button--${size}`,
    color && {
      [`button--${color}`]: !variant,
      [`button--${color}--${variant}`]: variant,
    },
    className
  );
  const Component = as || BaseButton;

  const cloneIcon = (icon, side) =>
    cloneElement(icon, {
      className: classnames(
        `button__icon button__icon--${side}`,
        icon.props.icon || icon.props.className
      ),
    });

  return (
    <Component className={buttonClass} {...buttonProps} ref={ref}>
      <span
        className={classnames("button__content", {
          "button__content--ellipsis": ellipsis,
          "button__content--icon-left": iconLeft,
          "button__content--icon-right": iconRight,
        })}
      >
        {iconLeft && cloneIcon(iconLeft, "left")}
        {children}
        {iconRight && cloneIcon(iconRight, "right")}
      </span>
    </Component>
  );
});

Button.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
  ]),
  variant: PropTypes.oneOf(["outlined", "inverted"]),
  ellipsis: PropTypes.bool,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  className: PropTypes.string,
  children: PropTypes.any,
  as: PropTypes.oneOf(["button", "a"]),
  href: PropTypes.string,
};
