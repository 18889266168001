import React, { VFC, LabelHTMLAttributes } from "react";
import { GetLabelPropsOptions } from "downshift";
import classNames from "classnames";

import "./ComboboxLabel.scss";

interface ComboboxLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  getLabelProps: (options?: GetLabelPropsOptions) => any;
  ariaLabel?: string;
  value?: string;
}

export const ComboboxLabel: VFC<ComboboxLabelProps> = ({
  getLabelProps,
  ariaLabel,
  value,
}) => {
  return (
    <label
      {...getLabelProps()}
      className={classNames("combobox__label", {
        "combobox__label--hidden": !value,
      })}
      aria-label={ariaLabel}
    >
      {value}
    </label>
  );
};
