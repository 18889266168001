import React from "react";

import { Icon } from "../../icon";
import { RoamingIcon } from "../../roamingIcon/RoamingIcon";
import { LogicalDeviceDetailsTypes } from "@sportal/api";

enum DeviceType {
  Merged = "merged",
  Roaming = "roaming",
  Mobile = "mobile",
  Wifi = "wifi",
  NewRequest = "new_request",
}

export const DeviceIcon: { [key in DeviceType]: JSX.Element } = {
  [DeviceType.Merged]: <Icon icon="far fa-phone-laptop" />,
  [DeviceType.Roaming]: <RoamingIcon />,
  [DeviceType.Mobile]: <Icon icon="fas fa-mobile-alt" />,
  [DeviceType.Wifi]: <Icon icon="fas fa-wifi" />,
  [DeviceType.NewRequest]: <Icon icon="fas fa-user-plus new-request-icon" />,
};

export const DeviceIconDescription = {
  [DeviceType.Merged]: "icon_merged_devices",
  [DeviceType.Roaming]: "icon_roaming",
  [DeviceType.Mobile]: "icon_mobile",
  [DeviceType.Wifi]: "icon_wifi",
  [DeviceType.NewRequest]: "icon_new_request",
};

export const DeviceTypeMap = {
  Merged: DeviceType.Merged,
  NewRequest: DeviceType.NewRequest,
  [LogicalDeviceDetailsTypes.Roaming]: DeviceType.Roaming,
  [LogicalDeviceDetailsTypes.Mac]: DeviceType.Wifi,
  [LogicalDeviceDetailsTypes.Line]: DeviceType.Mobile,
};
