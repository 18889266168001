import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ExternalLink } from "../externalLink/ExternalLink";

export const isExternalLink = to => /^https?:\/\//i.test(to);

export const ConfigLink = ({ to, ...props }) => {
  return isExternalLink(to) ? (
    <ExternalLink url={to} {...props} />
  ) : (
    <Link to={to} {...props} />
  );
};

ConfigLink.propTypes = {
  to: PropTypes.string.isRequired,
};
