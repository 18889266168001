export const UPDATE_VALUE = "[SCHEDULE] UPDATE_VALUE";
export const updateValue = (key, value) => ({
  type: UPDATE_VALUE,
  payload: { key, value },
});

export const REMOVE_VALUES = "[SCHEDULE] REMOVE_VALUES";
export const removeValues = (key, values) => ({
  type: REMOVE_VALUES,
  payload: { key, values },
});

export const ADD_VALUES = "[SCHEDULE] ADD_VALUES";
export const addValues = (key, values) => ({
  type: ADD_VALUES,
  payload: { key, values },
});

export const SCHEDULE_TOGGLED = "[SCHEDULE] SCHEDULE_TOGGLED";
export const scheduleToggled = updatedSchedule => ({
  type: SCHEDULE_TOGGLED,
  payload: updatedSchedule,
});

export const SET_NEW_STATE = "[SCHEDULE] SET_NEW_STATE";
export const setNewState = state => ({
  type: SET_NEW_STATE,
  payload: state,
});
