import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  getColumnId,
  RenderRowDetailsInterface,
  Table,
} from "../../../../../../../components";
import { ADVANCED_DETAILS_COLUMN, COLUMNS } from "./ProfileDevicesTable.consts";
import { isRoamingType } from "../profileDeviceTable.helpers";
import { RoamingDeviceGrid } from "../roamingDeviceGrid";
import { NonRoamingDeviceGrid } from "../nonRoamingDeviceGrid";

const INITIAL_SORT_BY = [{ id: "lastSeen", desc: true }];

export const renderDeviceDetails: RenderRowDetailsInterface = ({ row }) => {
  const device = row.original;
  const isRoaming = isRoamingType(device.identifiers[0], device.deviceDetails);

  return isRoaming ? (
    <RoamingDeviceGrid deviceDetails={device.deviceDetails[0]} />
  ) : (
    <NonRoamingDeviceGrid device={device} />
  );
};

interface ProfileDevicesTableProps {
  devices: any[];
  isAdvancedView: boolean;
  tableName: string;
}

export const ProfileDevicesTable: FC<ProfileDevicesTableProps> = ({
  devices,
  isAdvancedView,
  tableName,
}) => {
  const columns = [...COLUMNS, ADVANCED_DETAILS_COLUMN];
  const hiddenColumns = [ADVANCED_DETAILS_COLUMN].map(getColumnId);

  return (
    <Table
      data={devices}
      columns={columns}
      hiddenColumns={hiddenColumns}
      tableName={tableName}
      isAdvancedView={isAdvancedView}
      renderRowDetails={renderDeviceDetails}
      emptyTableContent={<FormattedMessage id="no_devices_assigned" />}
      initialSortBy={_.isEmpty(devices) ? [] : INITIAL_SORT_BY}
    />
  );
};
