import { AttributesProvider } from "./attributes.provider";
import { failure, Result, success } from "../shared";
import { Attribute, AttributesData } from "./attributes.types";

export enum AttributesServiceErrorCode {
  GENERIC,
  CANNOT_UPDATE,
}

export class AttributesService {
  constructor(private provider: AttributesProvider) {}

  public async get(subscriberId: string): Promise<Result<AttributesData>> {
    try {
      const { data } = await this.provider.get(subscriberId);
      return success(data);
    } catch (error) {
      return failure(AttributesServiceErrorCode.GENERIC);
    }
  }

  public async updateTimeFormat(
    subscriberId: string,
    timeFormat24h: boolean
  ): Promise<Result<boolean>> {
    try {
      await this.provider.update(
        subscriberId,
        Attribute.TIME_FORMAT,
        timeFormat24h
      );
      return success(timeFormat24h);
    } catch (error) {
      return failure(AttributesServiceErrorCode.CANNOT_UPDATE);
    }
  }

  public async updateLanguage(
    subscriberId: string,
    language: string
  ): Promise<Result<string>> {
    try {
      await this.provider.update(subscriberId, Attribute.LANGUAGE, language);
      return success(language);
    } catch (error) {
      return failure(AttributesServiceErrorCode.CANNOT_UPDATE);
    }
  }
}
