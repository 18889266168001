import { format, formatDuration } from "date-fns";
import { format as formatWithTimezone } from "date-fns-tz";

export class LocalizedTimeService {
  private locales: { [key: string]: Locale } = {};
  private currentLocaleKey: string;

  addLocale(key, value) {
    this.locales[key] = value;
  }

  async setLocale(localeKey) {
    try {
      const localeModule = await import(`date-fns/locale/${localeKey}`);

      this.addLocale(localeKey, localeModule);
      this.setCurrentLocale(localeKey);
    } catch (e) {
      const englishKey = "en-US";
      const englishLocale = await import(`date-fns/locale/en-US`);

      this.addLocale(englishKey, englishLocale);
      this.setCurrentLocale(englishKey);
    }
  }

  private setCurrentLocale(key) {
    this.currentLocaleKey = key;
  }

  private get currentLocale() {
    return this.locales[this.currentLocaleKey];
  }

  format(date, pattern = "PP") {
    return format(date, pattern, {
      locale: this.currentLocale,
    });
  }

  formatWithTimezone(date, pattern = "PP", timeZone) {
    return formatWithTimezone(date, pattern, {
      timeZone,
      locale: this.currentLocale,
    });
  }

  formatDuration(duration) {
    return formatDuration(duration, {
      locale: this.currentLocale,
    });
  }
}

export const localizedTimeService = new LocalizedTimeService();
