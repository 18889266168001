import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { useIntlTranslate } from "../../hooks";
import { Input } from "../input/Input";
import { MAX_LENGTH } from "../../pages/profile/profileNameValidation";

export const ProfileNameInput = ({ errorMessage, name, handleUpdate }) => {
  const translate = useIntlTranslate();

  return (
    <Input
      label={<FormattedMessage id="profile_name" />}
      placeholder={translate("enter_profile_name")}
      value={name}
      error={errorMessage && <FormattedMessage id={errorMessage} />}
      autoFocus
      maxLength={MAX_LENGTH}
      onChange={e => handleUpdate("name", e.target.value)}
      onClear={() => handleUpdate("name", "")}
      onBlur={e => handleUpdate("name", e.target.value.trim())}
    />
  );
};

ProfileNameInput.propTypes = {
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};
