import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

export const OptionalFormattedMessage = ({ id, ...props }) => {
  const intl = useIntl();

  return intl.messages[id] ? <FormattedMessage id={id} {...props} /> : null;
};

OptionalFormattedMessage.propTypes = {
  id: PropTypes.string.isRequired,
};
