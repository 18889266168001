import { failure, Result, success } from "../shared";
import { ProtectionProvider } from "./protection.provider";
import {
  Category,
  CustomProtection,
  SharedProtection,
} from "./protection.types";
import { Protection } from "./constants";

export enum ProtectionServiceErrorCode {
  GENERIC,
}

export class ProtectionService {
  constructor(private provider: ProtectionProvider) {}

  public async getCategories(): Promise<Result<Category[]>> {
    try {
      const {
        data: { content },
      } = await this.provider.getCategories();
      return success(content);
    } catch (error) {
      return failure(ProtectionServiceErrorCode.GENERIC);
    }
  }

  public async getSharedProtections(): Promise<Result<SharedProtection[]>> {
    try {
      const {
        data: { content },
      } = await this.provider.getSharedProtections();
      return success(
        content.map<SharedProtection>(({ name, categories }) => ({
          name,
          blocked: categories.map(cat => cat.name),
        }))
      );
    } catch (error) {
      return failure(ProtectionServiceErrorCode.GENERIC);
    }
  }

  public async getCustomProtection(
    subscriberId: string,
    profileName: string
  ): Promise<Result<CustomProtection>> {
    try {
      const {
        data: { content },
      } = await this.provider.getCustomProtection(subscriberId, profileName);
      return success({
        name: Protection.Custom,
        profile: profileName,
        blocked: content.map(({ name }) => name),
      });
    } catch (error) {
      return failure(ProtectionServiceErrorCode.GENERIC);
    }
  }

  public async getAllCustomProtections(
    subscriberId: string,
    profileNames: string[]
  ): Promise<Result<CustomProtection[]>> {
    try {
      const result = await Promise.all(
        profileNames.map(async profileName => {
          const {
            data: { content },
          } = await this.provider.getCustomProtection(
            subscriberId,
            profileName
          );

          return {
            name: Protection.Custom,
            profile: profileName,
            blocked: content.map(({ name }) => name),
          };
        })
      );

      return success(result);
    } catch (error) {
      return failure(ProtectionServiceErrorCode.GENERIC);
    }
  }

  public async saveCustomProtection(
    subscriberId: string,
    profileName: string,
    payload: string[]
  ): Promise<Result<void>> {
    try {
      await this.provider.saveCustomProtection(
        subscriberId,
        profileName,
        payload
      );
      return success();
    } catch (error) {
      return failure(ProtectionServiceErrorCode.GENERIC);
    }
  }
}
