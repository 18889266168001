import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Icon } from "@sportal/cdk";
import { CategoryToggle } from "..";
import { CATEGORY_STATUS } from "../categoryToggle/CategoryToggle";

import "./CategoryItem.scss";

export const CategoryDescription = ({ children }) => (
  <div className={"categories-item__description"}>{children}</div>
);

export const CategoryProtection = ({ protectionName }) => (
  <div className="categories-item__protection">
    <FormattedMessage
      id="blocked_by"
      values={{
        protection: <CategoryProtectionName translationKey={protectionName} />,
      }}
    />
  </div>
);

export const CategoryProtectionName = ({ translationKey }) => (
  <span className={"categories-item__protection-name"}>
    <FormattedMessage id={translationKey} />
  </span>
);

export class CategoryItem extends Component {
  handleClick = e => {
    const { name, onChange } = this.props;

    e.stopPropagation();
    onChange && onChange(name);
  };

  render() {
    const { displayName, description, status, protectionName } = this.props;

    return (
      <div className="categories-item">
        <div className="categories-item__title">
          <div className="categories-item__name">
            {description && <Icon icon={"fas fa-sort-down"} />}
            {displayName}
          </div>
          <CategoryToggle status={status} onClick={this.handleClick} />
        </div>
        {protectionName && (
          <CategoryProtection protectionName={protectionName} />
        )}
      </div>
    );
  }
}

CategoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.node,
  status: PropTypes.oneOf(Object.values(CATEGORY_STATUS)).isRequired,
  onChange: PropTypes.func.isRequired,
};
