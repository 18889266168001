import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import { showValue } from "../../table.helpers";

import "./CellDetails.scss";

interface CellDetailsProps {
  id: string;
  header: ReactElement | string;
  value?: ReactElement | string;
}

export const CellDetails: FC<CellDetailsProps> = ({ id, header, value }) => {
  return (
    <div
      className={classNames("cell-details", { [`cell-details--${id}`]: id })}
    >
      <div className="cell-details__header">{header}</div>
      <div className="cell-details__value">
        {showValue<ReactElement | string>(value)}
      </div>
    </div>
  );
};
