import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { AccordionItem } from "../accordionItem/AccordionItem";
import { useAccordion } from "./useAccordion";

import "./Accordion.scss";

export const Accordion = ({ items }) => {
  const [activeIndex, toggleItem] = useAccordion();

  if (_.isEmpty(items)) return null;

  return (
    <div className="accordion">
      {items.map(({ header, content }, index) => (
        <AccordionItem
          key={index}
          active={activeIndex === index}
          header={header}
          content={content}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      content: PropTypes.node,
    })
  ).isRequired,
};
