export const SHOW_LOADER = "[LOADER] SHOW_LOADER";
export const HIDE_LOADER = "[LOADER] HIDE_LOADER";

interface ShowLoaderAction {
  type: typeof SHOW_LOADER;
}

interface HideLoaderAction {
  type: typeof HIDE_LOADER;
}

export type LoaderActionTypes = ShowLoaderAction | HideLoaderAction;
