const LANGUAGE_COOKIE_KEY = "ng-nom_pi_lang";

export const getLangFromCookies = () => {
  const languageCookie = document.cookie
    .split("; ")
    .find(cookie => cookie.startsWith(LANGUAGE_COOKIE_KEY));

  return languageCookie ? languageCookie.split("=")[1] : null;
};

export const setLangCookie = language => {
  document.cookie = `${LANGUAGE_COOKIE_KEY}=${language}`;
};
