import _ from "lodash";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

interface MaxWidthMQ {
  maxWidth: number;
}

interface MinWidthMQ {
  minWidth: number;
}

interface ScreenSizeSpec {
  [sizeName: string]: MaxWidthMQ | MinWidthMQ;
}

export const makeUseScreenSizes = <T>(spec: ScreenSizeSpec) => {
  // Fix the order of items to make iterating stable and predictable
  const _spec = _.toPairs(spec);

  return function useScreenSizes(): T {
    const state = useRef(null);

    const matches = _spec
      .map<[string, boolean]>(([size, mq]) => [
        size,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useMediaQuery(mq) as boolean,
      ])
      .filter(([, match]) => match === true);

    if (matches.length === 1) {
      state.current = matches[0][0];
    }
    return state.current;
  };
};
