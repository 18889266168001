import React from "react";

import {
  isSuccess,
  useLoadableSlice,
} from "../../store/shared/withLoadingState";
import { getSettings, loadSettings } from "../../store/settings";
import { Logout } from "../Logout";
import { ErrorPage, shouldShowErrorPage } from "../../pages";
import { SettingsServiceErrorCode } from "@sportal/api";

export const withSettingsGuard = Component => () => {
  const settings = useLoadableSlice(loadSettings(), getSettings);
  if (settings === null) {
    return null;
  }

  if (isSuccess(settings)) {
    return <Component />;
  }

  if (shouldShowErrorPage(settings, SettingsServiceErrorCode)) {
    return <ErrorPage />;
  }

  return <Logout reason={settings.error.message} />;
};
