import React from "react";
import _ from "lodash";

import { getSubscriberId } from "../../../../store/info";
import {
  createTempNotification,
  ListNotification,
} from "../../../../components/notifications";

const SAVE_ACCOUNT_SETTINGS = "[ACCOUNT_TAB] SAVE_ACCOUNT_SETTINGS";
export const saveAccountSettings = changes => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: SAVE_ACCOUNT_SETTINGS });

  const subscriberId = getSubscriberId(getState());
  const results = await doSaveAccountSettings(subscriberId, api, changes);

  const failedFields = _.keys(_.pickBy(results, { success: false }));
  dispatch(createTempNotification(buildSaveNotificationSpec(failedFields)));

  const savedFields = _.keys(_.pickBy(results, { success: true }));
  if (!_.isEmpty(savedFields)) {
    dispatch(saveAccountSettingsSuccess(_.pick(changes, savedFields)));
  }
};

async function doSaveAccountSettings(subscriberId, api, changes) {
  const saveField = (field, value) =>
    ({
      language: value => api.attributes.updateLanguage(subscriberId, value),
      is24hourFormat: value =>
        api.attributes.updateTimeFormat(subscriberId, value),
      timezone: value => api.account.updateTimezone(subscriberId, value),
      aprOn: value => api.account.updateAPRSetting(subscriberId, value),
    }[field](value));

  const results = {};

  for (const [field, value] of _.toPairs(changes)) {
    results[field] = await saveField(field, value);
  }

  return results;
}

const buildSaveNotificationSpec = failedFields => {
  if (_.isEmpty(failedFields)) {
    return {
      variant: "success",
      title: "success",
      description: "successfully_saved",
    };
  }

  const fieldsTranslations = {
    language: "language",
    is24hourFormat: "time_format",
    timezone: "timezone",
    aprOn: "apr_checkbox_account",
  };

  return {
    variant: "error",
    custom: (
      <ListNotification
        title="error"
        description="couldnt_save_changes_because"
        items={_.map(failedFields, field => fieldsTranslations[field])}
      />
    ),
  };
};

export const SAVE_ACCOUNT_SETTINGS_SUCCESS =
  "[ACCOUNT_TAB] SAVE_ACCOUNT_SETTINGS_SUCCESS";
export const saveAccountSettingsSuccess = savedChanges => ({
  type: SAVE_ACCOUNT_SETTINGS_SUCCESS,
  payload: savedChanges,
});

export const SAVE_ACCOUNT_SETTINGS_FAILURE =
  "[ACCOUNT_TAB] SAVE_ACCOUNT_SETTINGS_FAILURE";
export const saveAccountSettingsFailure = error => ({
  type: SAVE_ACCOUNT_SETTINGS_FAILURE,
  payload: error,
});
