import React, { FC } from "react";

import "./EmptyTBodyMobile.scss";

export const EmptyTBodyMobile: FC = ({ children }) => {
  return (
    <tbody className="empty-tbody-mobile">
      <tr role="row">
        <td role="cell" className="empty-tbody-mobile__cell">
          {children}
        </td>
      </tr>
    </tbody>
  );
};
