import _ from "lodash";

import { showTopDataForReports } from "../config";
import {
  getAvailableReports,
  isPIReportingLicensed,
  isSSReportingLicensed,
} from "../settings";
import { Endpoint, ReportTypes } from "./reports.constants";
import {
  isPersonalInternetActivated,
  isSubscriberSafetyActivated,
  isSubscriberSafetyEnabled,
} from "../account";

export const getReportTypes = state => {
  const availableReports = getAvailableReports(state);
  const isEndpointAvailable = endpoint =>
    _.includes(availableReports, endpoint);

  const isPIActivated = isPersonalInternetActivated(state);
  const isSSActivated = isSubscriberSafetyActivated(state);

  const isPIReportingLicensedValue = isPIReportingLicensed(state);
  const isSSReportingLicensedValue = isSSReportingLicensed(state);

  const isTopRequestsEnabled =
    isPIReportingLicensedValue && showTopDataForReports(state);

  const chartReports = {
    [ReportTypes.Activity]: {
      available:
        (isPIActivated || isSSActivated) &&
        (isPIReportingLicensedValue || isSSReportingLicensedValue) &&
        isEndpointAvailable(Endpoint[ReportTypes.Activity]),
      enabled: true,
    },
    [ReportTypes.Blocks]: {
      available:
        isPIActivated &&
        isPIReportingLicensedValue &&
        isEndpointAvailable(Endpoint[ReportTypes.Blocks]),
      enabled: true,
    },
    [ReportTypes.SS]: {
      available:
        isSSActivated &&
        isSSReportingLicensedValue &&
        isEndpointAvailable(Endpoint[ReportTypes.SS]),
      enabled: isSubscriberSafetyEnabled(state),
    },
  };

  const tooltipReports = {
    [ReportTypes.ActivityTop]: {
      available:
        (isPIActivated || isSSActivated) &&
        isTopRequestsEnabled &&
        isEndpointAvailable(Endpoint[ReportTypes.ActivityTop]),
    },
    [ReportTypes.BLocksTop]: {
      available:
        isPIActivated &&
        isTopRequestsEnabled &&
        isEndpointAvailable(Endpoint[ReportTypes.BLocksTop]),
    },
  };

  return {
    reports: pickAvailable(chartReports),
    enabledReports: pickEnabled(chartReports),
    tooltipReports: pickAvailable(tooltipReports),
  };
};

const pickKeysByTruthyProp = (collection, prop) =>
  _.keys(_.pickBy(collection, item => item[prop]));
const pickAvailable = collection =>
  pickKeysByTruthyProp(collection, "available");
const pickEnabled = collection => pickKeysByTruthyProp(collection, "enabled");
