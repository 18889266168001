import { Schedule } from "@sportal/api";
import {
  ADD_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_SUCCESS,
  SchedulesActionTypes,
} from "./schedules.types";
import {
  fromProfiles,
  LOAD_PROFILES_SUCCESS,
  ADD_PROFILE_SUCCESS,
} from "../profiles";
import { FINISH_WIZARD } from "../../pages/wizard/store/wizard.actions";

const scheduleReducer = (
  state: Schedule[] = [],
  action: SchedulesActionTypes
) => {
  switch (action.type) {
    case ADD_SCHEDULE_SUCCESS:
      return [...state, action.payload.schedule];
    case UPDATE_SCHEDULE_SUCCESS:
      return state.map(schedule => {
        if (schedule.name !== action.payload.schedule.name) return schedule;
        return action.payload.schedule;
      });
    case DELETE_SCHEDULE_SUCCESS:
      return state.filter(({ name }) => name !== action.payload.scheduleName);
    default:
      return state;
  }
};

// todo: add types, for now tests webstorm is throwing errors in tests files if below declaration is used
// type AllSchedulesActionTypes =
//   | SchedulesActionTypes
//   | {
//       type: typeof LOAD_PROFILES_SUCCESS;
//       payload: { profiles: any; schedules: object };
//     }
//   | { type: typeof ADD_PROFILE_SUCCESS; payload: { id: string } }
//   | { type: typeof FINISH_WIZARD; payload: any }; // normalizedCollection Profile[]

export const schedulesReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PROFILES_SUCCESS:
      return {
        ...action.payload.schedules,
      };
    case ADD_PROFILE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: [],
      };
    case FINISH_WIZARD:
      return {
        ...state,
        ...fromProfiles
          .getProfilesIds(action.payload.profiles)
          .reduce((result, id) => {
            result[id] = [];
            return result;
          }, {}),
      };
    case ADD_SCHEDULE_SUCCESS:
    case UPDATE_SCHEDULE_SUCCESS:
    case DELETE_SCHEDULE_SUCCESS: {
      const { profileId } = action.payload;
      return {
        ...state,
        [profileId]: scheduleReducer(state[profileId], action),
      };
    }
    default:
      return state;
  }
};
