import { Service } from "../settings";

export enum PersonalInternetServiceProfile {
  Household = "household",
  PerDevice = "perdevice",
}

export enum SubscriberSafetyServiceProfile {
  Standard = "standard",
  SBSingle = "sb-single",
  SBMultiple = "sb-multiple",
}

interface PersonalInternetActivationCommand {
  service: Service.PersonalInternet;
  serviceProfile: PersonalInternetServiceProfile;
}

interface SubscriberSafetyActivationCommand {
  service: Service.SubscriberSafety;
  serviceProfile?: SubscriberSafetyServiceProfile;
  enabled?: boolean;
}

export type ServicesActivationCommand = (
  | PersonalInternetActivationCommand
  | SubscriberSafetyActivationCommand)[];
