import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { login } from "../login.actions";
import { LoginPageContainer } from "../loginPageContent/LoginPageContent";
import { Button, Input } from "../../../components";

import "./CommonLogin.scss";

export const CommonLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const submit = useCallback(
    e => {
      e.preventDefault();

      if (username) {
        dispatch(login(username, password));
      }
    },
    [username, password, dispatch]
  );

  return (
    <LoginPageContainer>
      <LoginPageContainer.Content
        as="form"
        className="login-form"
        method="post"
        onSubmit={submit}
      >
        <LoginPageContainer.Header>
          <FormattedMessage id="account_login" />
        </LoginPageContainer.Header>
        <Input
          label={<FormattedMessage id="username" />}
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />

        <Input
          label={<FormattedMessage id="password" />}
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          className="login-form__button"
          size="large"
          color="primary"
          type="submit"
          disabled={username === ""}
        >
          <FormattedMessage id="login" />
        </Button>
      </LoginPageContainer.Content>
    </LoginPageContainer>
  );
};
