import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { TRANSLATIONS } from "../scheduler.consts";
import { useSchedule } from "../useScheduler/useSchedule";
import { preparePayload } from "../scheduler.helpers";
import { submitSchedule } from "../../../store/schedules";
import { useSchedulerContext } from "../SchedulerContext";
import {
  ScheduleFormDescription,
  SchedulerFormContent,
} from "./SchedulerFormParts";
import {
  ProfileSection,
  ProfileSectionHeaderForm,
  ProfileSectionCollapseToggle,
  ProfileSectionFooterFormControls,
} from "../../../pages/profile/profileSection";

import "./SchedulerForm.scss";

export const SchedulerForm = ({ initSchedule, isNewSchedule = false }) => {
  const dispatch = useDispatch();
  const {
    schedule,
    updateValue,
    addValues,
    removeValues,
    discardChanges,
    setScheduleToggleClicked,
    scheduleChanged,
  } = useSchedule(initSchedule);

  const { profileId, type } = useSchedulerContext();

  const formDisabled =
    (isNewSchedule && _.isEmpty(schedule.day)) || !scheduleChanged;

  const toggleSchedule = e => {
    setScheduleToggleClicked(true);
    saveSchedule({
      ...initSchedule,
      enabled: e.target.checked,
    });
  };

  const handleSubmit = () => {
    const newSchedule = preparePayload(schedule);

    setScheduleToggleClicked(false);
    saveSchedule(newSchedule);
  };

  const saveSchedule = newSchedule => {
    dispatch(submitSchedule(profileId, newSchedule));
  };

  return (
    <ProfileSection
      className="schedule-form"
      header={
        <ProfileSectionHeaderForm
          type={type}
          title={<FormattedMessage id={TRANSLATIONS[type].title} />}
          disabled={isNewSchedule}
          checked={schedule.enabled}
          hasChanges={!formDisabled}
          onChange={toggleSchedule}
        />
      }
      toggle={
        <ProfileSectionCollapseToggle hasChanges={!formDisabled}>
          <FormattedMessage id={TRANSLATIONS[type].toggle} />
        </ProfileSectionCollapseToggle>
      }
      description={<ScheduleFormDescription />}
      content={
        <SchedulerFormContent
          schedule={schedule}
          updateValue={updateValue}
          addValues={addValues}
          removeValues={removeValues}
        />
      }
      footer={
        <ProfileSectionFooterFormControls
          hasChanges={!formDisabled}
          discardDisabled={!scheduleChanged}
          submitDisabled={formDisabled}
          discardChanges={discardChanges}
          submitChanges={handleSubmit}
        />
      }
    />
  );
};

SchedulerForm.propTypes = {
  initSchedule: PropTypes.object.isRequired,
  isNewSchedule: PropTypes.bool,
};
