import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse } from "react-collapse";

export const AccordionItem = ({
  active,
  header,
  content,
  className,
  onClick,
}) => (
  <div className={classNames("accordion-item", className)}>
    <div className="accordion-item__header" onClick={onClick}>
      {header}
    </div>
    <Collapse isOpened={active}>{content}</Collapse>
  </div>
);

AccordionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
};
