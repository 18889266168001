import React from "react";
import _ from "lodash";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import {
  GoHomeButton,
  InfoSection,
  NotHomeServicePanel,
  withLayout,
} from "../../components";
import { useSelector } from "react-redux";
import { getNavigationSettings } from "../../store/shared/navigationSettings.selectors";
import { SettingsNavBar } from "./settingsNavBar/SettingsNavBar";
import { MobileSettingsNavBar } from "./settingsNavBar/MobileSettingsNavBar";
import { Desktop, NotMobile, TabletMobile } from "../../common/Responsive";
import { AccountTab, BlockPageTab, DevicesTab, UrlFiltersTab } from "./tabs";

import "./SettingsPage.scss";

const TABS_CONTENT = {
  account: AccountTab,
  lists: UrlFiltersTab,
  block: BlockPageTab,
  devices: DevicesTab,
};

const SettingsPageComponent = () => {
  let { path } = useRouteMatch();
  const tabs = useSelector(getNavigationSettings);

  return (
    <div className="settings-page">
      <InfoSection>
        <NotMobile>
          <GoHomeButton />
        </NotMobile>
        <NotHomeServicePanel />
      </InfoSection>
      <div className="settings-page__content">
        <Desktop>
          <div className="settings-page__sidebar">
            <div className="settings-page__sidebar-container">
              <SettingsNavBar tabs={tabs} />
            </div>
          </div>
        </Desktop>
        <TabletMobile>
          <MobileSettingsNavBar tabs={tabs} />
        </TabletMobile>
        <div className="settings-page__tabs-content">
          <div className="settings-page__tabs-content-container">
            <Switch>
              {_.map(tabs, ({ id, route }) => (
                <Route
                  key={id}
                  exact
                  path={`${path}${route}`}
                  component={TABS_CONTENT[id]}
                />
              ))}
              <Redirect from={path} to={`${path}${_.first(tabs).url}`} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SettingsPage = withLayout()(SettingsPageComponent);
