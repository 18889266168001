import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { getProfilesList } from "../../../store/profiles";
import { useProfileErrors } from "../../../pages/profile/profileNameValidation";
import { ProfileNameInput } from "../ProfileModalParts";
import { ModalDialog } from "@sportal/cdk";
import { errorTranslation } from "../profileModals.helpers";
import { CommonModalHeader, CommonModalFooter } from "../../modal";

export const EditProfileModal = ({ existingProfile, modal }) => {
  const profiles = useSelector(getProfilesList);
  const [profileName, setProfileName] = useState(existingProfile.name);
  const [errors, hasErrors] = useProfileErrors(profiles, [
    { ...existingProfile, name: profileName },
  ]);

  const updateProfile = (key, value) => {
    setProfileName(value);
  };

  const submitProfile = () => {
    if (formDisabled) return;

    const name = profileName.trim(); // onBlur effect may won't work when user hits enter
    modal.resolve(name);
  };

  const formDisabled = useMemo(
    () =>
      !profileName.trim() ||
      profileName.trim() === existingProfile.name ||
      hasErrors,
    [profileName, hasErrors] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const close = () => modal.reject();

  return (
    <ModalDialog
      submitHandler={submitProfile}
      rejectHandler={close}
      disabled={formDisabled}
    >
      <CommonModalHeader>
        <FormattedMessage id="edit_profile" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ProfileNameInput
          name={profileName}
          errorMessage={errorTranslation(errors[existingProfile.id])}
          handleUpdate={updateProfile}
        />
      </ModalDialog.Body>
      <CommonModalFooter />
    </ModalDialog>
  );
};
