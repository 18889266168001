import _ from "lodash";

import { ListsProvider } from "./lists.provider";
import {
  failure,
  Result,
  statusCodeMatches,
  success,
  UpdateResponseStatus,
  HttpServiceResponse,
} from "../shared";
import {
  Url,
  ListsData,
  ListType,
  UpdateResponse,
  UrlType,
  ListServerData,
  ListData,
} from "./lists.types";
import { APP_CODE } from "../appConfig";

export enum ListsServiceErrorCode {
  GENERIC,
  BAD_REQUEST,
  TLD_NOT_SUPPORTED,
}

const PRIORITY_OF_URL_TYPES = {
  [UrlType.CORE_DOMAIN]: 1,
  [UrlType.FQDN]: 2,
  [UrlType.HOST_PATH]: 3,
  [UrlType.NAKED_HOST_PATH]: 4,
};

export class ListsService {
  constructor(
    private provider: ListsProvider,
    private urlTypes: UrlType[],
    private appCode: APP_CODE
  ) {}

  public async get(
    subscriberId: string,
    profileId?: string
  ): Promise<Result<ListsData>> {
    try {
      const [allowList, blockList] = await Promise.all(
        [ListType.Allow, ListType.Block].map(listType =>
          this.provider.get(subscriberId, listType, profileId)
        )
      );

      const mapListData = ({
        data: { content, limit },
      }: HttpServiceResponse<ListServerData>): ListData => ({
        content,
        limit,
      });

      return success({
        [ListType.Allow]: mapListData(allowList),
        [ListType.Block]: mapListData(blockList),
      });
    } catch (error) {
      return failure(ListsServiceErrorCode.GENERIC);
    }
  }

  public async add(
    subscriberId: string,
    listType: ListType,
    items: string[],
    profileId?: string
  ): Promise<Result<UpdateResponse>> {
    try {
      const { data } = await this.provider.add(
        subscriberId,
        listType,
        items,
        profileId
      );

      if (data.status === UpdateResponseStatus.PARTIAL_SUCCESS) {
        // add better error handling when service will be used for bulk adding urls (not supported currently in SC)
        return failure(data.failures.adds[0].reason);
      }

      return success(data);
    } catch (error) {
      if (statusCodeMatches(error, 400)) {
        return failure(error.response.data.error.reason);
      }

      return failure(ListsServiceErrorCode.GENERIC);
    }
  }

  public async remove(
    subscriberId: string,
    listType: ListType,
    items: string[],
    profileId?: string
  ): Promise<Result<UpdateResponse>> {
    try {
      const { data } = await this.provider.remove(
        subscriberId,
        listType,
        items,
        profileId
      );
      return success(data);
    } catch (error) {
      return failure(ListsServiceErrorCode.GENERIC);
    }
  }

  public async check(url: string): Promise<Result<Url[]>> {
    try {
      const {
        data: { result },
      } = await this.provider.check(url);

      const processedUrls = this.processUrls(result);

      if (
        processedUrls.length === 1 &&
        this.appCode === APP_CODE.PI &&
        processedUrls[0].type === UrlType.PUBLIC_SUFFIX
      ) {
        return failure(ListsServiceErrorCode.TLD_NOT_SUPPORTED);
      }

      return success(processedUrls);
    } catch (error) {
      if (error.status === 400) {
        return failure(ListsServiceErrorCode.BAD_REQUEST);
      }

      return failure(ListsServiceErrorCode.GENERIC);
    }
  }

  private processUrls(data) {
    if (data.length === 1) return data;

    const isPublicSuffix = type => type === UrlType.PUBLIC_SUFFIX;
    const includedInTypes = type => _.includes(this.urlTypes, type);
    const comparePriorities = (a, b) =>
      PRIORITY_OF_URL_TYPES[a.type] - PRIORITY_OF_URL_TYPES[b.type];

    return _.chain(data)
      .filter(({ type }) => !isPublicSuffix(type) && includedInTypes(type))
      .uniqBy("node")
      .sort(comparePriorities)
      .value();
  }
}
