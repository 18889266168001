import _ from "lodash";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { CommonModalHeader, useForm } from "../../../../../components";
import { ModalDialog } from "@sportal/cdk";
import {
  DeviceDialogInput,
  makeProfilePickerItems,
  ProfilesPicker,
  useDeviceNameValidator,
  DEVICE_NAME_MAX_LENGTH,
} from "../deviceForm";
import { useIntlTranslate } from "../../../../../hooks";
import { CommonModalFooter } from "../../../../../components/modal";

const prepareDeviceName = _.trim;

export function AssignDeviceDialog({ modal, profiles, devices, device }) {
  const translate = useIntlTranslate();
  const profileSelectItems = makeProfilePickerItems(profiles, translate);

  const form = useForm({
    name: device.name || "",
    profileName: _.find(profileSelectItems, { disabled: false }).value,
  });

  const changeName = name => {
    form.set("name", name);
    form.setError("name", null);
  };

  const validateName = useDeviceNameValidator(devices);
  const submit = useCallback(
    modal => {
      const name = prepareDeviceName(form.values.name);
      const nameError = validateName(name);

      if (!_.isEmpty(nameError)) {
        form.setError("name", nameError);
      } else {
        modal.resolve({
          name,
          profileName: form.values.profileName,
        });
      }
    },
    [form, validateName]
  );

  const close = () => modal.reject();

  const isSubmitDisabled = _.isEmpty(prepareDeviceName(form.values.name));

  return (
    <ModalDialog
      className="assign-device-modal"
      disabled={isSubmitDisabled}
      submitHandler={() => submit(modal)}
      rejectHandler={close}
    >
      <CommonModalHeader>
        <FormattedMessage id="assign_to_profile" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <DeviceDialogInput
          label="device_name"
          placeholder="enter_device_name"
          value={form.values.name}
          onChange={changeName}
          error={form.errors.name}
          autoFocus
          maxLength={DEVICE_NAME_MAX_LENGTH}
          autoCapitalize="none"
          autoCorrect="off"
          spellCheck="false"
        />
        <ProfilesPicker
          label={<FormattedMessage id="assign_to" />}
          items={profileSelectItems}
          selected={form.values.profileName}
          onChange={value => form.set("profileName", value)}
        />
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="assign" />} />
    </ModalDialog>
  );
}
