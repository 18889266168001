import { ClearHistoryProvider } from "./clearHistory.provider";
import { failure, Result, success } from "../shared";

export enum ClearHistoryServiceErrorCode {
  GENERIC,
}

export class ClearHistoryService {
  constructor(private clearHistoryProvider: ClearHistoryProvider) {}

  public async clear(
    subscriberId: string,
    metrics: string[]
  ): Promise<Result<string[]>> {
    try {
      await this.clearHistoryProvider.clear(subscriberId, metrics);
      return success(metrics);
    } catch (error) {
      return failure(ClearHistoryServiceErrorCode.GENERIC);
    }
  }
}
