import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getDefaultProfile } from "../../../../store/profiles";

export const LinkToDefaultProfile = props => {
  const defaultProfile = useSelector(getDefaultProfile);

  return <Link {...props} to={`/profile/${defaultProfile.id}`} />;
};
