import _ from "lodash";

import { withLoadingState } from "../shared/withLoadingState";
import { SAVE_ACCOUNT_SETTINGS_SUCCESS } from "../../pages/settings/tabs/accountTab/accountTab.actions";
import {
  LOAD_ATTRIBUTES,
  LOAD_ATTRIBUTES_SUCCESS,
  LOAD_ATTRIBUTES_FAILURE,
  AttributesActionTypes,
} from "./attributes.types";
import { Attribute } from "@sportal/api";

type AllAttributesActionTypes =
  | AttributesActionTypes
  | { type: typeof SAVE_ACCOUNT_SETTINGS_SUCCESS; payload: any }; // todo: create type

const attributesReducer = (state = {}, action: AllAttributesActionTypes) => {
  switch (action.type) {
    case LOAD_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_ACCOUNT_SETTINGS_SUCCESS:
      const update = _.pick(action.payload, [
        Attribute.LANGUAGE,
        Attribute.TIME_FORMAT,
      ]);

      return _.isEmpty(update) ? state : { ...state, ...update };
    default:
      return state;
  }
};

export default withLoadingState({
  loadActionType: LOAD_ATTRIBUTES,
  successActionType: LOAD_ATTRIBUTES_SUCCESS,
  failureActionType: LOAD_ATTRIBUTES_FAILURE,
})(attributesReducer);
