import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { ServiceStatus } from "../../../serviceStatus/ServiceStatus";
import { CollapsibleSection, useCollapseContext } from "@sportal/cdk";
import { Icon } from "@sportal/cdk";

import "./DetailedMobile.scss";

export const DetailedMobile = ({
  serviceStatus,
  title,
  description,
  changeLink,
  statusIcon,
  infectionMessage,
}) => (
  <CollapsibleSection
    className="detailed-service-panel--mobile"
    header={
      <DetailedMobileHeader
        title={title}
        infectionMessage={infectionMessage}
        statusIcon={statusIcon}
        serviceStatus={serviceStatus}
        changeLink={changeLink}
      />
    }
    contentClassName="detailed-service-panel__description"
    content={
      <Fragment>
        {infectionMessage && (
          <span className="infection-status">
            <Icon icon={statusIcon} />
            <FormattedMessage id={infectionMessage} />
          </span>
        )}
        <FormattedMessage id={description} />
      </Fragment>
    }
  />
);

DetailedMobile.propTypes = {
  serviceStatus: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  changeLink: PropTypes.bool,
  statusIcon: PropTypes.string,
  infectionMessage: PropTypes.string,
};

const DetailedMobileHeader = ({
  title,
  infectionMessage,
  statusIcon,
  serviceStatus,
  changeLink,
}) => {
  const { getToggleProps } = useCollapseContext();

  return (
    <div className="detailed-mobile-header">
      <div
        className="detailed-service-panel__header"
        {...getToggleProps({ type: "div" })}
      >
        <div className="detailed-service-panel__title-wrapper">
          <span className="detailed-service-panel__title">
            <FormattedMessage id={title} />
          </span>
          {infectionMessage && (
            <span className="infection-status">
              <Icon icon={statusIcon} />
            </span>
          )}
        </div>
      </div>
      <div
        className="detailed-service-panel__status"
        onClick={e => e.stopPropagation()}
      >
        <ServiceStatus active={serviceStatus} />
        {changeLink && (
          <Link className="link" to="/settings/block-page">
            <FormattedMessage id="change" />
          </Link>
        )}
      </div>
    </div>
  );
};

DetailedMobileHeader.propTypes = {
  title: PropTypes.string,
  infectionMessage: PropTypes.string,
  statusIcon: PropTypes.string,
  serviceStatus: PropTypes.bool,
  changeLink: PropTypes.bool,
};
