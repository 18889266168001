export const SCHEDULE_TYPES = {
  homework: "homework",
  internetOff: "internet-off",
};

export const NEW_SCHEDULES = [
  {
    name: "homework0",
    type: "homework",
    enabled: false,
    categories: [],
    timeStart: "00:00",
    timeEnd: "24:00",
    day: [],
  },
  {
    name: "internet-off0",
    type: "internet-off",
    enabled: false,
    categories: [],
    timeStart: "00:00",
    timeEnd: "24:00",
    day: [],
  },
];

export const WEEK_DAYS = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

export const TRANSLATIONS = {
  homework: {
    title: "homework_time",
    description: "restrict_content_not_for_homework_time",
    toggle: "set_homework_time",
  },
  "internet-off": {
    title: "internet_off",
    description: "turn_off_internet_completely",
    toggle: "set_internet_off_schedule",
  },
};
