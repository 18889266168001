import { getSubscriberId } from "../info";
import { addOrUpdatePermNotification } from "../../components/notifications";
import {
  LOAD_ATTRIBUTES,
  LOAD_ATTRIBUTES_SUCCESS,
  LOAD_ATTRIBUTES_FAILURE,
  AttributesActionTypes,
} from "./attributes.types";
import { AttributesData, isFailure } from "@sportal/api";
import { Api } from "../../api";

export const loadAttributes = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_ATTRIBUTES });

  const subscriberId = getSubscriberId(getState());
  const result = await api.attributes.get(subscriberId);

  const action = isFailure(result)
    ? loadAttributesFailure(result.error)
    : loadAttributesSuccess(result.data);
  dispatch(action);
};

export const loadAttributesSuccess = (
  attributes: AttributesData
): AttributesActionTypes => ({
  type: LOAD_ATTRIBUTES_SUCCESS,
  payload: attributes,
});

const loadAttributesFailure = error => dispatch => {
  dispatch({
    type: LOAD_ATTRIBUTES_FAILURE,
    payload: error,
  });
  dispatch(
    addOrUpdatePermNotification({
      variant: "warning",
      description: "failed_to_load_attributes",
    })
  );
};
