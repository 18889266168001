import { HttpService, HttpServiceResponse } from "../shared";
import { Service } from "../settings";

export class ControlProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/control`;
  }

  public createAccount(
    subscriberId: string,
    timezone: string
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(this.url, {
      id: subscriberId,
      "time-zone": timezone,
    });
  }

  public activateService(
    subscriberId: string,
    service: Service,
    serviceProfile?: string
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(`${this.url}/${subscriberId}/service`, {
      service,
      ...(serviceProfile && { "service-profile": serviceProfile }),
    });
  }
}
