import { SettingsServerData } from "@sportal/api";

export const LOAD_SETTINGS = "[AUTH] LOAD_SETTINGS";
export const LOAD_SETTINGS_SUCCESS = "[AUTH] LOAD_SETTINGS_SUCCESS";
export const LOAD_SETTINGS_FAILURE = "[AUTH] LOAD_SETTINGS_FAILURE";

interface loadSettingsAction {
  type: typeof LOAD_SETTINGS;
}

interface loadSettingsSuccessAction {
  type: typeof LOAD_SETTINGS_SUCCESS;
  payload: SettingsServerData;
}

interface loadSettingsFailureAction {
  type: typeof LOAD_SETTINGS_FAILURE;
  payload: object;
}

export type SettingsActionTypes =
  | loadSettingsAction
  | loadSettingsSuccessAction
  | loadSettingsFailureAction;
