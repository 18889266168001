import { getConfig } from "../root.selectors";

const getSecurity = state => getConfig(state).security;
const getLanguages = state => getConfig(state).languages;
export const getDefault24hFormat = state => getConfig(state).time24;
export const getFirstDayOfWeek = state => getConfig(state).firstDayOfWeek;
export const getShowToggles = state => getConfig(state).showToggles;
export const getLanguageList = state => getLanguages(state).list;
export const getDefaultLanguage = state => getLanguages(state).default;
export const getLogoutUrl = state => getSecurity(state).logoutUrl;
export const getBackendUrl = state => getConfig(state).backendUrl;
export const isNoLoginPage = state => getSecurity(state).noLoginPage;
export const getGoBackOnLoginErrorLink = state => {
  const { goBackOnLoginErrorLink, goBackOnLoginErrorLinkText } = getSecurity(
    state
  );

  return { link: goBackOnLoginErrorLink, linkText: goBackOnLoginErrorLinkText };
};

export const getLogoLink = state => getConfig(state).logoLink;
export const getFooterCompanyLink = state => getConfig(state).footerCompanyLink;
export const getTermsLink = state => getConfig(state).termsLink;
export const isHideDevicesTab = state => getConfig(state).hideDevicesTab;
export const getMenuButtons = state => getConfig(state).menuButtons;
export const getInspectStreamAgeGroups = state =>
  getConfig(state).inspectStreamAgeGroups;
export const getInternetPauseOptions = state =>
  getConfig(state).internetPauseOptions;
export const showTopDataForReports = state =>
  getConfig(state).showTopForReports;
export const getDefaultReportDuration = state =>
  getConfig(state).defaultReportGranularity;
export const showCountsInTooltip = state =>
  getConfig(state).showCountsForReports;
export const getMandatoryPinService = state =>
  getConfig(state).mandatoryPinService;
export const showPerProfileReport = state =>
  getConfig(state).showPerProfileReporting;
