import React, { useEffect, useRef, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { Durations } from "@sportal/api/reports";
import { Mobile, NotMobile } from "../../common/Responsive";
import { ReportProvider, useReportContext } from "../barchart/ReportProvider";
import { loadReports } from "../../store/reports";
import { ReportMobile } from "./ReportMobile";
import { ReportNotMobile } from "./ReportNotMobile";

import "./Report.scss";

const ReportComponent = ({ profileName }) => {
  const dispatch = useDispatch();
  const { duration, reports, tooltipReports } = useReportContext();

  useEffect(() => {
    const reportsToLoad = [
      ...reports,
      ...(duration === Durations.Day ? tooltipReports : []),
    ];

    dispatch(loadReports(reportsToLoad, duration, profileName));
  }, [duration]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Mobile>
        <ReportMobile profileName={profileName} />
      </Mobile>
      <NotMobile>
        <ReportNotMobile profileName={profileName} />
      </NotMobile>
    </Fragment>
  );
};

export const Report = props => {
  const [width, setWidth] = useState(0);
  const container = useRef(null);

  useEffect(() => {
    const handleResize = _.debounce(() => {
      container.current && setWidth(container.current.clientWidth);
    }, 500);

    setWidth(container.current.clientWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={"report"}>
      <div className={"report__container"}>
        <div className={"report__container--inner"} ref={container}>
          <ReportProvider containerWidth={width}>
            <ReportComponent {...props} />
          </ReportProvider>
        </div>
      </div>
    </div>
  );
};
