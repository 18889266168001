import _ from "lodash";
import { uuid } from "@sportal/lib";

import { Protection, ProfilesServiceErrorCode } from "@sportal/api";
import { getSubscriberId } from "../../../../store/info";
import { getToRemove, getToUpdate, getToAdd } from "./profiles.selectors";
import { createTempNotification } from "../../../../components/notifications";
import { getProfilesFactory } from "../../../../store/profiles";
import { saveCustomProtection } from "../../../../store/protection";
import { getProfiles } from "../wizard.selectors";
import { finishWizard } from "../wizard.actions";

const errorMessages = {
  [ProfilesServiceErrorCode.GENERIC]: "something_went_wrong",
};

const notificationsTemplates = {
  [ProfilesServiceErrorCode.GENERIC]: {
    variant: "error",
    description: errorMessages[ProfilesServiceErrorCode.GENERIC],
  },
};

export const SUBMIT_SINGLE_PROFILE = "[WIZARD_PROFILES] SUBMIT_SINGLE_PROFILE";
export const submitSingleProfile = (partialProfile, protection) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: SUBMIT_SINGLE_PROFILE });

  const state = getState();
  const subscriberId = getSubscriberId(state);
  const profile = { ...partialProfile, protection: protection.name };

  const profileSaveResult = await api.profiles.create(subscriberId, [profile]);

  if (profileSaveResult.success === false) {
    dispatch(submitProfilesFailure(profileSaveResult.error));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description: "couldnt_save_changes",
      })
    );
    return;
  }

  const [createdProfile] = profileSaveResult.data;

  // replace client generated id with the real one
  const newProfile = {
    ...profile,
    id: createdProfile.id,
  };

  if (protection.name === Protection.Custom) {
    await saveCustomProtection({
      subscriberId,
      profile: newProfile,
      protection,
      api,
      dispatch,
    });
  }

  dispatch(
    submitProfilesUpdate({
      add: [newProfile],
      update: [],
      remove: [],
    })
  );
  dispatch(submitProfilesSuccess());
  dispatch(finishWizard());
};

export const SAVE_PROFILE_PROTECTION =
  "[WIZARD_PROFILES] SAVE_PROFILE_PROTECTION";
export const saveProfileProtection = (profile, protection) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: SAVE_PROFILE_PROTECTION });

  const state = getState();
  const subscriberId = getSubscriberId(state);
  const updates = { protection: protection.name };

  const profileSaveResult = await api.profiles.update(
    subscriberId,
    profile.name,
    updates
  );

  if (profileSaveResult.success === false) {
    dispatch(submitProfilesFailure(profileSaveResult.error));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description: "couldnt_save_changes",
      })
    );
    return;
  }

  if (protection.name === Protection.Custom) {
    await saveCustomProtection({
      subscriberId,
      profile,
      protection,
      api,
      dispatch,
    });
  }

  dispatch(
    submitProfilesUpdate({
      add: [],
      update: [{ profile, updates }],
      remove: [],
    })
  );
  dispatch(submitProfilesSuccess());
};

export const SUBMIT_PROFILES = "[WIZARD_PROFILES] SUBMIT_PROFILES";
export const submitProfiles = partialProfiles => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: SUBMIT_PROFILES });

  const state = getState();
  const subscriberId = getSubscriberId(state);
  const savedProfiles = getProfiles(state).list;

  const factory = getProfilesFactory(state);
  const profiles = partialProfiles.map(profile =>
    profile.default
      ? profile
      : factory(profile.id, profile.name, profile["age-group"])
  );

  const succeed = {
    add: [],
    update: [],
    remove: [],
  };

  const failure = error => {
    const notification = notificationsTemplates[error.code];
    const message = errorMessages[error.code];

    dispatch(submitProfilesUpdate(succeed));
    dispatch(submitProfilesFailure({ ...error, message }));

    notification && dispatch(createTempNotification(notification));
    throw Error();
  };

  const toRemove = getToRemove(profiles, savedProfiles);
  if (!_.isEmpty(toRemove)) {
    for (const profile of toRemove) {
      const removeResult = await api.profiles.delete(
        subscriberId,
        profile.name
      );
      if (removeResult.success === false) {
        return failure(removeResult.error);
      }
      succeed.remove.push(profile);
    }
  }

  const toUpdate = getToUpdate(profiles, savedProfiles);
  if (!_.isEmpty(toUpdate)) {
    for (const { profile, updates } of toUpdate) {
      const removeResult = await api.profiles.update(
        subscriberId,
        profile.name,
        updates
      );
      if (removeResult.success === false) {
        return failure(removeResult.error);
      }
      succeed.update.push({ profile, updates });
    }
  }

  const toAdd = getToAdd(profiles, savedProfiles);
  if (!_.isEmpty(toAdd)) {
    const addResult = await api.profiles.create(subscriberId, toAdd);
    if (addResult.success === false) {
      return failure(addResult.error);
    }

    const createdProfileIdsByName = _.chain(addResult.data)
      .keyBy("name")
      .mapValues("id")
      .value();

    // replace client generated ids with the real one
    succeed.add = _.map(toAdd, profile => ({
      ...profile,
      id: createdProfileIdsByName[profile.name],
    }));
  }

  dispatch(submitProfilesUpdate(succeed));
  dispatch(submitProfilesSuccess());
};

export const SUBMIT_PROFILES_SUCCESS =
  "[WIZARD_PROFILES] SUBMIT_PROFILES_SUCCESS";
const submitProfilesSuccess = () => ({
  type: SUBMIT_PROFILES_SUCCESS,
});

export const SUBMIT_PROFILES_UPDATE =
  "[WIZARD_PROFILES] SUBMIT_PROFILES_UPDATE";
const submitProfilesUpdate = succeed => ({
  type: SUBMIT_PROFILES_UPDATE,
  payload: succeed,
});

export const SUBMIT_PROFILES_FAILURE =
  "[WIZARD_PROFILES] SUBMIT_PROFILES_FAILURE";
const submitProfilesFailure = error => ({
  type: SUBMIT_PROFILES_FAILURE,
  payload: error,
});

export const ADD_PROFILE = "[WIZARD_PROFILES] ADD_PROFILE";
export const addProfile = (name, ageGroup) => (dispatch, getState) => {
  const profile = getProfilesFactory(getState())(name, ageGroup);
  dispatch({ type: ADD_PROFILE, payload: { id: uuid(), ...profile } });
};
