import { AuthServiceErrorCode, InfoData, isFailure } from "@sportal/api";
import {
  LOAD_INFO,
  LOAD_INFO_FAILURE,
  LOAD_INFO_SUCCESS,
  LoadInfoFailureAction,
  LoadInfoSuccessAction,
} from "./info.types";
import { Api } from "../../api";

const errorMessages = {
  [AuthServiceErrorCode.WRONG_USER_MODE]: "login_error",
};

export const loadInfo = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_INFO });

  const result = await api.auth.getInfo();

  const action = isFailure(result)
    ? loadInfoFailure(result.error)
    : loadInfoSuccess(result.data);

  dispatch(action);
};

export const loadInfoSuccess = (info: InfoData): LoadInfoSuccessAction => ({
  type: LOAD_INFO_SUCCESS,
  payload: info,
});

export const loadInfoFailure = (error: {
  code?: string | number;
}): LoadInfoFailureAction => ({
  type: LOAD_INFO_FAILURE,
  payload: {
    ...error,
    message: errorMessages[error.code],
  },
});
