import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Button } from "../../../../components";

import "../../wizardLayout.scss";

// todo:
//  fix wizard router

export const NextStepButton = ({ doNext, disabled, nextTab, message }) => (
  <div className="wizard-page__content-footer">
    <Button
      className="wizard-page__button-next"
      color="primary"
      size="large"
      disabled={disabled}
      onClick={doNext}
    >
      {message && _.isEmpty(nextTab) ? (
        message
      ) : (
        <FormattedMessage
          id="next_with_colon"
          values={{ nextStep: <FormattedMessage id={nextTab} /> }}
        />
      )}
    </Button>
  </div>
);

NextStepButton.propTypes = {
  doNext: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nextTab: PropTypes.oneOf(["protection_setup", "assign_devices"]),
  message: PropTypes.node,
};
