import _ from "lodash";
import { useDispatch } from "react-redux";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Radio, RadioGroup } from "@sportal/cdk";
import { Button } from "../../../../../components";
import { addUrl } from "../../../../../store/lists/lists.actions";
import { ListType, UrlType } from "@sportal/api/lists";
import { punyToUnicode } from "@sportal/lib";

import "./UrlOptions.scss";
import { useIntlTranslate } from "../../../../../hooks";

export const UrlOptions = ({ items, profileId }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(_.last(items).node);
  const isHostPathSelected = useMemo(() => {
    const selected = items.find(({ node }) => node === selectedOption);

    return selected.type === UrlType.HOST_PATH;
  }, [items, selectedOption]);

  return (
    <div className="url-options">
      <div className="url-options__info">
        <FormattedMessage id="whitelist_priority_warning" />
      </div>
      <fieldset>
        <legend className="url-options__title">
          <FormattedMessage id="select_domain_tip" />
        </legend>

        <RadioGroup
          selectedValue={selectedOption}
          onChange={type => setSelectedOption(type)}
          name="url-options"
        >
          {items.map(item => (
            <UrlOption
              type={item.type}
              url={item.node}
              id={item.type}
              key={item.type}
            />
          ))}
        </RadioGroup>
      </fieldset>

      <div className="url-options__actions">
        <Button
          size={"large"}
          color={"danger"}
          onClick={() =>
            dispatch(addUrl(ListType.Block, selectedOption, profileId))
          }
        >
          <FormattedMessage id={"block"} />
        </Button>
        <Button
          size={"large"}
          color={"success"}
          onClick={() =>
            dispatch(addUrl(ListType.Allow, selectedOption, profileId))
          }
          disabled={isHostPathSelected}
        >
          <FormattedMessage id={"allow"} />
        </Button>
      </div>
    </div>
  );
};

const UrlOption = ({ url, type, id }) => {
  const translate = useIntlTranslate();

  return (
    <Radio
      className={"url-option"}
      value={url}
      key={type}
      ariaOptionLabel={`${translate(type)} ${url}`}
      id={id}
      {...(type === UrlType.HOST_PATH && {
        "aria-describedby": "host-path_description",
      })}
      label={
        <div className="url-option__label">
          <div className="url-option__label__type">
            <FormattedMessage id={type} />
          </div>
          <div className="url-option__label__url">{punyToUnicode(url)}</div>
          {type === UrlType.HOST_PATH && (
            <div className="url-option__label__tip" id="host-path_description">
              <FormattedMessage id="cannot_allow_path" />
            </div>
          )}
        </div>
      }
    />
  );
};
