import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import { AgeGroup } from "@sportal/api";
import { ModalDialog } from "@sportal/cdk";
import { uuid } from "@sportal/lib";
import { getProfilesList } from "../../../store/profiles";
import { useProfileErrors } from "../../../pages/profile/profileNameValidation";
import { errorTranslation } from "../profileModals.helpers";
import { CommonModalHeader, CommonModalFooter } from "../../modal";
import { ProfileNameInput } from "../ProfileModalParts";
import { SegmentedAgeGroups } from "../../segmentedAgeGroups/SegmentedAgeGroups";

const ageGroups = [AgeGroup.Kids, AgeGroup.Teens, AgeGroup.Adults];
const defaultAgeGroup = ageGroups[0]; // this can be replaced by selector in future to get default value from appConfig

const excludePristine = profiles =>
  profiles.filter(profile => !profile.pristine);

export const AddProfileModal = ({ modal }) => {
  const reject = () => modal.reject();

  const profiles = useSelector(getProfilesList);

  const [profile, setProfile] = useState(() => ({
    id: uuid(),
    name: "",
    ageGroup: defaultAgeGroup,
    pristine: true,
  }));

  const [errorMessage, hasError] = useProfileErrors(
    profiles,
    excludePristine([profile])
  );

  const updateProfile = (key, value) => {
    setProfile(oldProfile => {
      const newProfile = _.omit(oldProfile, "pristine");
      return { ...newProfile, [key]: value };
    });
  };

  const submitProfile = () => {
    if (formDisabled) return;

    const name = profile.name.trim(); // onBlur effect may won't work when user hits enter
    modal.resolve({ ...profile, name });
  };

  const formDisabled = useMemo(() => !profile.name.trim() || hasError, [
    profile.name,
    hasError,
  ]);

  return (
    <ModalDialog
      submitHandler={submitProfile}
      rejectHandler={reject}
      disabled={formDisabled}
    >
      <CommonModalHeader onCloseClick={reject}>
        <FormattedMessage id="create_new_profile" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <ProfileNameInput
          name={profile.name}
          errorMessage={errorTranslation(errorMessage[profile.id])}
          handleUpdate={updateProfile}
        />
        <SegmentedAgeGroups
          selectedValue={profile.ageGroup}
          onChange={e => updateProfile("ageGroup", e.target.value)}
        />
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="create" />} />
    </ModalDialog>
  );
};

AddProfileModal.propTypes = {
  modal: PropTypes.object.isRequired,
};
