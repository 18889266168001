import React, { Fragment, memo, VFC } from "react";

import { Mobile, NotMobile } from "../../../../../common/Responsive";
import { DeviceActionsToggle, DeviceMenu } from "../tableComponents";
import { ProfileDeviceMenuProps } from "./ProfileDeviceMenu";
import {
  EditRoamingDeviceDropdownItem,
  EditRoamingDeviceModalItem,
  MoveRoamingDeviceDropdownItem,
  MoveRoamingDeviceModalItem,
  RevokeRoamingDeviceDropdownItem,
  RevokeRoamingDeviceModalItem,
} from "../deviceActionItems";

export const ProfileRoamingDeviceMenu: VFC<ProfileDeviceMenuProps> = memo(
  function ProfileRoamingDeviceMenu({ device }) {
    const dropdownItems = [
      <EditRoamingDeviceDropdownItem device={device} />,
      <MoveRoamingDeviceDropdownItem device={device} />,
      <RevokeRoamingDeviceDropdownItem device={device} />,
    ];

    const modalItems = [
      <EditRoamingDeviceModalItem device={device} />,
      <MoveRoamingDeviceModalItem device={device} />,
      <RevokeRoamingDeviceModalItem device={device} />,
    ];

    return (
      <Fragment>
        <Mobile>
          <DeviceActionsToggle
            deviceName={device.name || device.networkId}
            menuItems={modalItems}
          />
        </Mobile>

        <NotMobile>
          <DeviceMenu className="profile-device-menu" items={dropdownItems} />
        </NotMobile>
      </Fragment>
    );
  }
);
