import { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";

const getChanges = (current, prev) =>
  _.pickBy(current, (value, key) => value !== prev[key]);

function useErrors() {
  const [errors, setErrors] = useState({});

  const set = useCallback(
    (key, message) =>
      setErrors(prevErrors => ({ ...prevErrors, [key]: message })),
    [setErrors]
  );

  const reset = useCallback(() => {
    if (!_.isEmpty(errors)) {
      setErrors({});
    }
  }, [errors, setErrors]);

  return [errors, set, reset];
}

export function useForm(initialState = {}, options) {
  const [state, setState] = useState(initialState);
  const [errors, setError, resetErrors] = useErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(() => options, []);

  useEffect(() => {
    // todo: for now this is not used anywhere
    if (config && config.enableReinitialize) {
      setState(initialState);
      resetErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, initialState]);

  const set = useCallback(
    (name, value) => {
      setState(values => ({
        ...values,
        [name]: value,
      }));
    },
    [setState]
  );

  return {
    set,
    values: state,
    errors,
    didFormChange: () => !_.isEqual(state, initialState),
    didValueChange: key => !_.isEqual(state[key], initialState[key]),
    getChanges: () => getChanges(state, initialState),
    discardChanges: () => setState(initialState),
    setError,
    resetErrors,
    hasErrors: () => _.some(errors, Boolean),
  };
}

// props info
// useForm {
//   initialState: object,
//   options?: {
//     enableReinitialize?: boolean
//   }
// };
