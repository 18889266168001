import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import {
  RenderRowDetailsInterface,
  TableMobile,
} from "../../../../../../../components";
import { COLUMNS_MOBILE } from "./ProfileDevicesTableMobile.consts";
import { isRoamingType } from "../profileDeviceTable.helpers";
import { MobileNonRoamingDetails } from "../mobileNonRoamingDetails";
import { MobileRoamingDetails } from "../mobileRoamingDetails";

const INITIAL_SORT_BY = [{ id: "lastSeen", desc: true }];

const renderDeviceDetails: RenderRowDetailsInterface = ({ row }) => {
  const device = row.original;
  const isRoaming = isRoamingType(device.identifiers[0], device.deviceDetails);

  return isRoaming ? (
    <MobileRoamingDetails deviceDetails={device.deviceDetails[0]} />
  ) : (
    <MobileNonRoamingDetails device={device} />
  );
};

interface ProfileDevicesTableMobileProps {
  devices: any[];
  isAdvancedView: boolean;
  tableName: string;
}

export const ProfileDevicesTableMobile: FC<ProfileDevicesTableMobileProps> = ({
  devices,
  isAdvancedView,
  tableName,
}) => {
  return (
    <TableMobile
      data={devices}
      columns={COLUMNS_MOBILE}
      isAdvancedView={isAdvancedView}
      tableName={tableName}
      renderRowDetails={renderDeviceDetails}
      emptyTableContent={<FormattedMessage id="no_devices_assigned" />}
      initialSortBy={INITIAL_SORT_BY}
    />
  );
};
