import React from "react";

import { Router } from "./router/Router";
import { ConnectedIntlProvider } from "./i18n/ConnectedIntlProvider";
import { LoaderContainer } from "./components/loader";
import { ResponsiveContextProvider } from "./common/Responsive";
import { ErrorBoundary } from "./common/ErrorBoundary";
import { ErrorPage } from "./pages";

const App = () => (
  <ResponsiveContextProvider>
    <ConnectedIntlProvider>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Router />
      </ErrorBoundary>
    </ConnectedIntlProvider>
    <LoaderContainer />
  </ResponsiveContextProvider>
);

export default App;
