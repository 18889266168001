import _ from "lodash";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../store/categories";
import { submitSingleProfile } from "../store/profiles";
import { getSharedProtections } from "../../../store/root.selectors";
import { ProtectionEditor } from "../../../components/protectionEditor/ProtectionEditor";
import { useProtectionState } from "../../../components/protectionEditor/useProtectionState";
import { NextStepButton } from "../multiple/nextStepButton/NextStepButton";

export const SingleWizardProtections = ({ profile }) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);
  const sharedProtections = useSelector(getSharedProtections);

  const { currentProtection, setLevel, block, allow } = useProtectionState(
    profile
  );

  const finish = () =>
    dispatch(submitSingleProfile(profile, currentProtection));

  return (
    <Fragment>
      <ProtectionEditor
        categories={_.map(categories.list)}
        protections={_.map(sharedProtections.list)}
        selectedProtection={currentProtection}
        changeProtection={setLevel}
        block={block}
        allow={allow}
      />
      <NextStepButton
        doNext={finish}
        message={<FormattedMessage id={"finish_setup"} />}
      />
    </Fragment>
  );
};
