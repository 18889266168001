import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SingleContent } from "../singleContent/SingleContent";
import { getProfilesList } from "../../../store/profiles";
import { MultipleContent } from "../multipleContent/MultipleContent";
import { withProfiles } from "../../../components";

export const PerDeviceContentComponent = () => {
  const profiles = useSelector(getProfilesList);
  const [showUpgrade, toggleShowUpgrade] = useState(profiles.length === 1);

  return showUpgrade ? (
    <SingleContent onUpgradeClick={() => toggleShowUpgrade(!showUpgrade)} />
  ) : (
    <MultipleContent />
  );
};

export const PerDeviceContent = withProfiles(PerDeviceContentComponent);
