import React, { useRef } from "react";
import { useIntl } from "react-intl";
import * as d3 from "d3";
import _ from "lodash";
import { add, getUnixTime, sub, getHours, getMinutes } from "date-fns";

import { Durations } from "@sportal/api/reports";
import { formatTick, getAxisTicksStep } from "../../store/reports";
import { useReportContext } from "./ReportProvider";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

const tickPadding = 40;

export const XAxis = ({ tz, is24, topOffset }) => {
  const { duration, xScale: scale, ticks } = useReportContext();
  const intl = useIntl();
  const wrapper = useRef();

  const axis = d3.select(wrapper.current);

  const tickValues = rangeInclusive(
    getFirstTick(duration, _.first(ticks), tz),
    _.last(ticks),
    getAxisTicksStep(duration)
  );

  axis.call(
    d3
      .axisBottom(scale)
      .tickSize(0)
      .tickValues(tickValues)
      .tickFormat(formatTick(duration, is24, tz, intl))
      .tickPadding(tickPadding)
  );

  return (
    <g
      className={"xAxis"}
      transform={`translate(0,${topOffset})`}
      ref={wrapper}
    />
  );
};

export const getFirstTick = (duration, start, tz) => {
  if (duration !== Durations.Day) {
    return start;
  }

  let tick = utcToZonedTime(start * 1000, tz);

  const hours = getHours(tick);
  const minutes = getMinutes(tick);
  const hoursInSlice = 6;
  const isExactHour = hours % hoursInSlice === 0 && minutes === 0;

  if (isExactHour) return start;

  const closestHour = hours + hoursInSlice - (hours % hoursInSlice);

  tick = add(tick, { hours: closestHour - hours });
  tick = sub(tick, { minutes });

  return getUnixTime(zonedTimeToUtc(tick, tz));
};

const rangeInclusive = (start, end, step) => {
  //_.range and d3.range have default and non-changeable inclusivity: `[)`, but we need to have `[]`
  if (start === end) return [start];

  const result = [];
  let tick = start;

  while (tick <= end) {
    result.push(tick);
    tick += step;
  }

  return result;
};
