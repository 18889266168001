import _ from "lodash";
import { format, utcToZonedTime } from "date-fns-tz";

import { addOrUpdateSchedule, deleteSchedule } from "../../store/schedules";
import { getTimezone } from "../../store/account";
import { getProfile, getInternetPauseSchedule } from "../../store/profiles";
import { calculatePauseTimeEnd } from "./pauseInternet.helpers";

const INTERNET_PAUSE_SCHEDULE_NAME = "internet-pause";
export const INTERNET_PAUSE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm";

export const pauseInternet = (profileId, pausePeriod) => (
  dispatch,
  getState
) => {
  const profile = getProfile(getState(), profileId);

  const currentSchedule = getInternetPauseSchedule(getState(), profileId) || {};

  const timezone = getTimezone(getState());
  const now = utcToZonedTime(Date.now(), timezone);

  const schedule = _.assign(currentSchedule, {
    name: INTERNET_PAUSE_SCHEDULE_NAME,
    type: INTERNET_PAUSE_SCHEDULE_NAME,
    timeZone: timezone,
    day: [],
    categories: [],
    enabled: true,
    timeStart: format(now, INTERNET_PAUSE_TIME_FORMAT),
    timeEnd: format(
      calculatePauseTimeEnd(now, pausePeriod),
      INTERNET_PAUSE_TIME_FORMAT
    ),
    // We have to add 1 min for handling difference if timeStart is between 11:00:01 and 11:00:58
    // because we can't save seconds (or timestamp) to the backend
  });

  dispatch(addOrUpdateSchedule(profile.name, profileId, schedule, false));
};

export const unpauseInternet = profileId => (dispatch, getState) => {
  const profile = getProfile(getState(), profileId);

  dispatch(
    deleteSchedule(profile.name, profileId, INTERNET_PAUSE_SCHEDULE_NAME, false)
  );
};
