import React, { memo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import { Icon } from "../icon/Icon";
import "./Carousel.scss";

// memoizing indicator item will result only 2 items rerender after selectedIndex change
const CarouselIndicatorItem = memo(
  ({ isSelected, index, setSelectedIndex }) => {
    function handleClick() {
      setSelectedIndex(index);
    }

    return (
      <button
        type="button"
        className={classnames("carousel-indicators__item", {
          "carousel-indicators__item--active": isSelected,
        })}
        onClick={handleClick}
        data-testid="carousel-indicator"
      />
    );
  }
);

function CarouselIndicators({ selectedIndex, chunksCount, setSelectedIndex }) {
  // chunk count may change after resize
  // This effect will keep selectedIndex in actual range
  useEffect(() => {
    const lastChunkIndex = chunksCount - 1;

    if (selectedIndex > lastChunkIndex) {
      setSelectedIndex(lastChunkIndex);
    }
  }, [selectedIndex, chunksCount, setSelectedIndex]);

  return (
    <div className="carousel-indicators">
      {_.times(chunksCount).map(index => (
        <CarouselIndicatorItem
          key={index}
          isSelected={index === selectedIndex}
          index={index}
          setSelectedIndex={setSelectedIndex}
        />
      ))}
    </div>
  );
}

const CarouselArrowItem = memo(({ className, icon, onClick, dataTestId }) => (
  <button
    type="button"
    className={classnames("carousel-slider__arrow", className)}
    onClick={onClick}
    data-testid={dataTestId}
  >
    <Icon icon={icon} />
  </button>
));

function CarouselSlides({ selectedIndex, items, itemWidth, renderItem }) {
  const offsetPercentage = selectedIndex * -100;

  return (
    <div className="carousel-slides">
      <div
        className="carousel-slides__container"
        style={{
          marginLeft: `${offsetPercentage}%`,
          marginRight: `${offsetPercentage}%`,
        }}
        data-testid="carousel-items-container"
      >
        {_.map(items, (item, index) => (
          <div
            key={index}
            className="carousel-slides__item"
            style={{
              flex: `0 0 ${itemWidth}%`,
            }}
            data-testid="carousel-item"
          >
            {renderItem(item)}
          </div>
        ))}
      </div>
    </div>
  );
}

export function Carousel({ items, className, renderItem, chunkSize }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const setPrev = useCallback(() => setSelectedIndex(index => index - 1), [
    setSelectedIndex,
  ]);

  const setNext = useCallback(() => setSelectedIndex(index => index + 1), [
    setSelectedIndex,
  ]);

  const chunksCount = Math.ceil(items.length / chunkSize);

  if (chunksCount === 0) {
    return null;
  }

  return (
    <div className={classnames("carousel", className)}>
      <div className="carousel-slider">
        {selectedIndex !== 0 && (
          <CarouselArrowItem
            className="carousel-slider__arrow--prev"
            icon="far fa-chevron-left"
            onClick={setPrev}
            dataTestId="carousel-arrow-prev"
          />
        )}
        <CarouselSlides
          selectedIndex={selectedIndex}
          items={items}
          itemWidth={100 / chunkSize}
          renderItem={renderItem}
        />
        {selectedIndex !== chunksCount - 1 && (
          <CarouselArrowItem
            className="carousel-slider__arrow--next"
            icon="far fa-chevron-right"
            onClick={setNext}
            dataTestId="carousel-arrow-next"
          />
        )}
      </div>
      <CarouselIndicators
        selectedIndex={selectedIndex}
        chunksCount={chunksCount}
        setSelectedIndex={setSelectedIndex}
      />
    </div>
  );
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  chunkSize: PropTypes.number,
};

Carousel.defaultProps = {
  chunkSize: 3,
};
