import React from "react";
import { Column } from "react-table";
import { FormattedMessage } from "react-intl";

import {
  AdvancedColumn,
  AriaAdvancedColumn,
  defaultHiddenColumnConfig,
  FormattedDateTime,
  getColumnId,
  NO_VALUE_PLACEHOLDER,
  showValue,
} from "../../../../../../../components";
import { RequestsMenu } from "../../RequestsMenu";
import { alphanumeric } from "../../../../../../../components/table/helpers/table-sorting.helpers";
import { DeviceTypeHeader } from "../../../tableComponents";
import { DeviceTypeTooltip } from "../../../../../../../components/devices/deviceTypeTooltip";
import { SingleNetworkId } from "../../../../../../../components/profileDevices/networkId";

const ADVANCED_COLUMNS: AdvancedColumn[] = [
  {
    header: <FormattedMessage id="network_id" />,
    renderValue: device => {
      return (
        <SingleNetworkId
          phoneNumber={device.phoneNumber}
          networkId={device.networkId}
        />
      );
    },
  },
];

export const ADVANCED_DETAILS_COLUMN: Column<any> = {
  ...defaultHiddenColumnConfig,
  id: "details",
  Header: <FormattedMessage id="details" />,
  Cell: ({ row }) => {
    const device = row && row.original;

    return <AriaAdvancedColumn columns={ADVANCED_COLUMNS} device={device} />;
  },
};

export const HIDDEN_COLUMNS = [ADVANCED_DETAILS_COLUMN].map(getColumnId);

export const COLUMNS: Column<any>[] = [
  {
    Header: <DeviceTypeHeader />,
    id: "deviceType",
    Cell: ({ row }) => {
      const device = row.original;

      return (
        <DeviceTypeTooltip
          isNewRequest={device.withName && device.name}
          type={device.type}
        />
      );
    },
    width: 12,
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="device_name" />,
    headerTranslationId: "device_name",
    id: "name",
    accessor: device => device.name || device.networkId || device.phoneNumber,
    width: "minmax(200px, 1fr)",
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="code" />,
    accessor: "code",
    Cell: ({ value }) => <span>{showValue<string>(value)}</span>,
    width: 52,
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="manufacturer" />,
    headerTranslationId: "manufacturer",
    accessor: "manufacturer",
    Cell: ({ value }) => <span>{showValue<string>(value)}</span>,
    maxWidth: 200,
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="last_seen" />,
    headerTranslationId: "last_seen",
    accessor: "lastSeen",
    Cell: ({ value }) =>
      value ? <FormattedDateTime value={value} /> : NO_VALUE_PLACEHOLDER,
    maxWidth: 170,
  },
  {
    Header: <FormattedMessage id="actions" />,
    id: "actions",
    Cell: ({ row }) => <RequestsMenu request={row.original} />,
    width: 70,
    disableSortBy: true,
  },
  ADVANCED_DETAILS_COLUMN,
];
