import React from "react";

import { useLoadableSlice } from "../../store/shared/withLoadingState";
import { loadAttributes } from "../../store/attributes";
import { getAttributes } from "../../store/root.selectors";

export const withAttributesGuard = Component => () => {
  const attributes = useLoadableSlice(loadAttributes(), getAttributes);
  if (attributes === null) {
    return null;
  }

  return <Component />;
};
