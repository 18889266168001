import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Button } from "../button/Button";
import { Icon } from "@sportal/cdk";

import "./CategoryToggle.scss";

export const CATEGORY_STATUS = {
  BLOCKED: "blocked",
  ALLOWED: "allowed",
  CUSTOM: "custom",
};

const CONTENTS = {
  [CATEGORY_STATUS.ALLOWED]: {
    icon: <Icon icon="far fa-check" />,
    content: <FormattedMessage id={"allowed_status"} />,
  },
  [CATEGORY_STATUS.BLOCKED]: {
    icon: <Icon icon="far fa-ban" />,
    content: <FormattedMessage id={"blocked_status"} />,
  },
  [CATEGORY_STATUS.CUSTOM]: {
    icon: (
      <i className="icon rainbow-hamburger">
        <span />
        <span />
        <span />
      </i>
    ),
    content: <FormattedMessage id={"custom_status"} />,
  },
};

export const CategoryToggle = ({ status, ...attrs }) => {
  const { content, icon } = CONTENTS[status];

  return (
    <div className="category-toggle">
      <Button
        size="medium"
        className="button--custom"
        iconLeft={icon}
        {...attrs}
      >
        {content}
      </Button>
    </div>
  );
};

CategoryToggle.propTypes = {
  status: PropTypes.oneOf(Object.values(CATEGORY_STATUS)),
};
