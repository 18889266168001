import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./SegmentedButton.scss";

export const SegmentedButton = ({
  id,
  children,
  className,
  groupName,
  value,
  checked,
  onChange,
}) => (
  <div
    className={classNames("segmented-button", className)}
    data-testid="segmented-button"
  >
    <input
      id={id}
      type="radio"
      data-testid="segmented-button-radio"
      className="segmented-button__radio-button sr-only"
      name={groupName}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id} className="segmented-button__label">
      <span>{children}</span>
    </label>
  </div>
);

SegmentedButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
