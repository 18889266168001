import { useSelector } from "react-redux";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@sportal/cdk";
import { ProfileSection } from "../../pages/profile/profileSection";
import { UrlLists } from "../urlLists/UrlLists";
import { UrlCheck } from "../../pages/settings/tabs/urlFiltersTab/urlCheck/UrlCheck";
import { isAllowListOverrideEnabled } from "../../store/settings";
import { AllowListOverrideWarning } from "../allowListOverrideWarning";

import "./ProfileUrlLists.scss";

export const ProfileUrlLists = ({ profileId }) => {
  const showALOWarning = useSelector(isAllowListOverrideEnabled);

  return (
    <ProfileSection
      className="profile-url-lists"
      header={
        <h3>
          <FormattedMessage id="black_white_list" />
        </h3>
      }
      toggle={
        <Fragment>
          <FormattedMessage id="black_white_list_edit" />
          <Icon icon="far fa-angle-down" />
        </Fragment>
      }
      description={<FormattedMessage id="black_white_list_description" />}
      content={
        <Fragment>
          {showALOWarning && <AllowListOverrideWarning />}
          <UrlCheck
            profileId={profileId}
            className="profile-url-lists__url-check"
          />
          <UrlLists
            profileId={profileId}
            className="profile-url-lists__lists"
          />
        </Fragment>
      }
    />
  );
};
