import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import * as d3 from "d3";
import _ from "lodash";

import { inRange } from "@sportal/lib";
import { getReports } from "../../store/root.selectors";
import {
  convertISODuration,
  getReportTypes,
  getTimeBounds,
  ReportTypes,
} from "../../store/reports";
import { getDefaultReportDuration } from "../../store/config";

const reportHeight = 110;

const xScale = d3.scaleBand().paddingInner(0.1);
const yScaleTopPart = d3
  .scalePow()
  .exponent(0.4)
  .range([reportHeight, 0]);
const yScaleBottomPart = d3.scaleLinear().range([25, 0]);

const domainFunctions = {
  yScaleTopPart: d => d.activity || d.blocks,
  yScaleBottomPart: d => d.ss,
};

//calculate Y Offset for each data type
//Y Offset is for the position where bar should begin
const calculateYOffsets = ({ activity, blocks, ss }) => {
  if (!activity && !blocks && !ss) return {};
  const getOffsetFor = value => Math.abs(yScaleTopPart(value)) || 0;

  return {
    ...(activity ? { activity: getOffsetFor(activity) } : {}),
    ...(blocks ? { blocks: getOffsetFor(blocks) } : {}),
    ...(ss ? { ss: reportHeight + 1 } : {}),
  };
};
//calculate height for each data type
//height is for the height of the bar
const calculateHeights = ({ activity, blocks, ss }) => {
  if (!activity && !blocks && !ss) return {};
  const getHeightFor = (value, scale) => Math.abs(scale(value) - scale(0)) || 0;

  return {
    ...(activity ? { activity: getHeightFor(activity, yScaleTopPart) } : {}),
    ...(blocks ? { blocks: getHeightFor(blocks, yScaleTopPart) } : {}),
    ...(ss ? { ss: getHeightFor(ss, yScaleBottomPart) } : {}),
  };
};

//xScale should be updated once container is rendered
//it's needed to define ticks allocation properly
const updateXScale = (ticks, width) => {
  if (!width || _.isEmpty(ticks)) return;

  xScale.domain(ticks).range([0, width]);
};
//yScale should be updated when data is available
const updateYScales = rawData => {
  if (!rawData) return;
  yScaleTopPart.domain([0, d3.max(rawData, domainFunctions.yScaleTopPart)]);
  yScaleBottomPart.domain([
    0,
    d3.max(rawData, domainFunctions.yScaleBottomPart),
  ]);
};
//calculate all ticks for x axis in defined time range
const calculateTicks = (rawData, duration) => {
  const getFirstInRange = (array, start, end) => {
    if (!array) return;

    const slice = _.find(array, ({ time }) =>
      inRange(time, { start, end }, "[]")
    );
    return slice ? slice.time : null;
  };
  //there are some issues caused by the fact that we have time ticks from backend and on UI side
  //from time to time values for ticks are different
  //so I decided to take one tick from the backend and fill in array with ticks by adding/subtracting granularity
  //to ensure that ticks are in sync
  const { start, end } = getTimeBounds(duration);
  const granularity = convertISODuration(duration);
  const firstInRange = getFirstInRange(rawData, start, end);

  if (_.isEmpty(rawData) || !firstInRange) {
    return d3.range(start, end, granularity);
  }

  let tick = firstInRange;
  const ticks = [];

  do {
    ticks.push(tick);
    tick -= granularity;
  } while (tick > start);

  tick = firstInRange + granularity;
  while (tick < end) {
    ticks.push(tick);
    tick += granularity;
  }

  return _.orderBy(ticks);
};

//extend data from the store with chart-specific properties
const prepareReportData = (reportWidth, rawData) => {
  if (!rawData || !reportWidth) return [];

  return _.map(rawData, rawSlice => {
    const { time, ...data } = rawSlice;
    const width = xScale.bandwidth();

    return {
      time,
      data,
      position: {
        x: xScale(time),
        y: calculateYOffsets(data),
        height: calculateHeights(data),
        width,
      },
    };
  });
};
//these classes are responsible for hiding/showing bars for specific type of data
//hiding/showing is handled in ActivityCheckboxes component
const prepareCSSClasses = displayedReports => {
  return classnames("report__chart", {
    "report__chart--activity-hidden": !displayedReports.includes(
      ReportTypes.Activity
    ),
    "report__chart--blocks-hidden": !displayedReports.includes(
      ReportTypes.Blocks
    ),
    "report__chart--ss-hidden": !displayedReports.includes(ReportTypes.SS),
  });
};

const useDisplayedReports = initial => {
  const [reports, setState] = useState(initial);

  function setDisplayedReports(type) {
    if (_.includes(reports, type)) {
      setState(_.without(reports, type));
    } else {
      setState(_.union(reports, [type]));
    }
  }

  return [reports, setDisplayedReports];
};

export const ReportContext = createContext({});
export const ReportProvider = ({ containerWidth, children }) => {
  const defaultDuration = useSelector(getDefaultReportDuration);
  const { data: rawData } = useSelector(getReports);
  const { reports, enabledReports, tooltipReports } = useSelector(
    getReportTypes
  );

  const [duration, setDuration] = useState(defaultDuration);
  const [displayedReports, setDisplayedReports] = useDisplayedReports(
    enabledReports
  );

  const ticks = calculateTicks(rawData, duration);

  updateXScale(ticks, containerWidth);
  updateYScales(rawData);

  const data = prepareReportData(containerWidth, rawData);

  const barchartClasses = prepareCSSClasses(displayedReports);

  const contextValue = {
    data,
    reports,
    enabledReports,
    tooltipReports,
    barchartClasses,
    xScale,
    ticks,
    duration,
    setDuration,
    displayedReports,
    setDisplayedReports,
  };

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => useContext(ReportContext);
