import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import { Icon } from "@sportal/cdk";
import {
  removeProfile,
  changeProfileName,
  changeProfileSafeSearch,
} from "../../store/profiles";
import { EditProfileModal } from "../profileModals/editProfileModal/EditProfileModal";
import { DeleteProfileModal } from "../profileModals/deleteProfileModal/DeleteProfileModal";
import { ModalService } from "../modal";
import { Button } from "../button/Button";
import { Checkbox } from "../checkbox/Checkbox";
import { PauseInternet } from "../pauseInternet/PauseInternet";
import { PauseInternetProvider } from "../pauseInternet/PauseInternetProvider";
import { Tooltip } from "../tooltip/Tooltip";

import "./ProfileDetails.scss";

const ActionButtons = ({ profile, history }) => {
  const dispatch = useDispatch();

  const openEditProfileDialog = () =>
    ModalService.show(modal => ({
      dialog: <EditProfileModal existingProfile={profile} modal={modal} />,
    }))
      .then(name => dispatch(changeProfileName(profile, name)))
      .catch(() => {});

  const openDeleteProfileDialog = () =>
    ModalService.show(modal => ({
      dialog: <DeleteProfileModal modal={modal} />,
    }))
      .then(() => dispatch(removeProfile(profile, history)))
      .catch(() => {});

  return (
    <div className="profile-details__action-buttons">
      <Button
        color="secondary"
        size="medium"
        variant="inverted"
        onClick={openEditProfileDialog}
        className="edit-button"
        iconLeft={<Icon icon="far fa-pencil-alt" />}
      >
        <FormattedMessage id="edit" />
      </Button>
      <Button
        color="secondary"
        size="medium"
        variant="inverted"
        onClick={openDeleteProfileDialog}
        className="delete-button"
        iconLeft={<Icon icon="far fa-trash-alt" />}
      >
        <FormattedMessage id="delete" />
      </Button>
    </div>
  );
};

const ProfileDetailsContent = ({ profile, className, history }) => {
  const dispatch = useDispatch();

  const ageGroup = profile["age-group"];
  const safeSearchServices = profile["safe-search-services"];

  const isSafeSearchEnabled = _.values(safeSearchServices).every(Boolean);

  const profileKey = profile.default ? "default" : ageGroup;

  const profileDetailsClasses = classnames(
    "profile-details",
    `profile-details--${profileKey}`,
    className
  );

  return (
    <PauseInternetProvider profileId={profile.id} showSeparateUnpauseButton>
      <div className={profileDetailsClasses}>
        <div className="profile-details__age-group-container">
          <span className="profile-details__image" />
          {!profile.default && (
            <span className="profile-details__age-group">
              {ageGroup && <FormattedMessage id={ageGroup} />}
            </span>
          )}
        </div>
        <div className="profile-details__name">{profile.name}</div>
        {!profile.default && (
          <ActionButtons profile={profile} history={history} />
        )}
        <PauseInternet
          profileName={profile.name}
          variant="inverted"
          label={<FormattedMessage id="internet_pause_settings" />}
          size="medium"
        />
        <div className="profile-details__safe-search">
          <Checkbox
            id={"profile-details-safe-search"}
            className="profile-details__checkbox"
            label={
              <div>
                <span className="safe-search__description">
                  <FormattedMessage id="enable_safesearch" />
                </span>
                <Tooltip
                  placement="bottom"
                  content={<FormattedMessage id="safe_search_tip" />}
                >
                  <Icon icon="far fa-question-circle safe-search-icon" />
                </Tooltip>
              </div>
            }
            checked={isSafeSearchEnabled}
            onChange={() =>
              dispatch(changeProfileSafeSearch(profile, !isSafeSearchEnabled))
            }
          />
        </div>
      </div>
    </PauseInternetProvider>
  );
};

ProfileDetailsContent.propTypes = {
  profile: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export const ProfileDetails = withRouter(ProfileDetailsContent);
