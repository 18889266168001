import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { ModalDialog, useModalDialogContext } from "../Modal";
import { Button } from "../../button/Button";

export const CommonModalFooter = ({
  submitButtonColor,
  submitMessage,
  cancelMessage,
}) => {
  const { disabled, submit, reject } = useModalDialogContext();

  return (
    <ModalDialog.Footer>
      <ModalDialog.Control>
        <Button color="secondary" size="large" onClick={reject}>
          {cancelMessage}
        </Button>
      </ModalDialog.Control>
      <ModalDialog.Control>
        <Button
          data-testid={"submit-btn"}
          color={submitButtonColor}
          size="large"
          disabled={disabled}
          onClick={submit}
        >
          {submitMessage}
        </Button>
      </ModalDialog.Control>
    </ModalDialog.Footer>
  );
};

CommonModalFooter.defaultProps = {
  submitButtonColor: "primary",
  submitMessage: <FormattedMessage id="save" />,
  cancelMessage: <FormattedMessage id="cancel" />,
};

CommonModalFooter.propTypes = {
  submitButtonColor: PropTypes.string,
  submitMessage: PropTypes.node.isRequired,
  cancelMessage: PropTypes.node.isRequired,
};
