import React, { FC, ReactElement } from "react";
import classNames from "classnames";

import "./TabSection.scss";

interface TabSectionProps {
  className?: string;
  header: ReactElement | string;
  description?: ReactElement | string;
}

export const TabSection: FC<TabSectionProps> = ({
  className,
  header,
  description,
  children,
}) => {
  return (
    <div className={classNames("tab-section", className)}>
      <div className="tab-section__header">
        <h3>{header}</h3>
        {description && <p>{description}</p>}
      </div>

      <div className="tab-section__content">{children}</div>
    </div>
  );
};
