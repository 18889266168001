import { FormattedMessage } from "react-intl";
import React from "react";

export const DefaultProtectionCardContent = ({ protection, recommended }) => (
  <div className="wizard-protection-card__container">
    <div className="profile-image default" />
    <div className="wizard-protection-card__content">
      <h3 className="wizard-protection-card__title">
        <FormattedMessage id="default" />
      </h3>
      <p className="wizard-protection-card__item">
        <FormattedMessage id="default_group_protection_description" />
      </p>
      <p className="wizard-protection-card__item wizard-protection-card__item--description">
        <FormattedMessage
          id={recommended ? "recommended_protection_for" : "protection_value"}
          values={{
            ageGroup: <FormattedMessage id="default" />,
            protection: (
              <b>
                <FormattedMessage id={protection} />
              </b>
            ),
          }}
        />
      </p>
    </div>
  </div>
);
