import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { ModalDialog, CommonModalHeader, CommonModalFooter } from "../modal";

export const ConfirmationDialog = ({
  modal,
  className,
  title,
  cancelMessage,
  submitMessage,
  content,
  submitButtonColor = "danger",
}) => {
  const cancel = () => modal.reject();
  const submit = () => modal.resolve();

  return (
    <ModalDialog
      className={className}
      submitHandler={submit}
      rejectHandler={cancel}
    >
      <CommonModalHeader>{title}</CommonModalHeader>
      <ModalDialog.Body>{content}</ModalDialog.Body>
      <CommonModalFooter
        submitButtonColor={submitButtonColor}
        submitMessage={submitMessage}
        cancelMessage={cancelMessage}
      />
    </ModalDialog>
  );
};

ConfirmationDialog.defaultProps = {
  title: <FormattedMessage id="confirm_action" />,
  // TODO: adjust default messages to cover most of the cases
  cancelMessage: <FormattedMessage id="no" />,
  submitMessage: <FormattedMessage id="yes" />,
};

ConfirmationDialog.propTypes = {
  modal: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.node,
  content: PropTypes.node,
  cancelMessage: PropTypes.node,
  submitMessage: PropTypes.node,
};
