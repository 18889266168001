import _ from "lodash";

import { getLangFromCookies } from "../i18n/languageCookie";
import { getLanguageList, getDefaultLanguage } from "../store/config";
import { getAttributeLanguage } from "../store/attributes";
import { getAttributes } from "../store/root.selectors";
import { isLoaded } from "../store/shared/withLoadingState";
import { defaultLanguage } from "../api/appConfigService";

const isInitialLoadingComplete = _.flow([getAttributes, isLoaded]);

export const getSelectedLanguage = state => {
  /** cookie is set for every language change
   * if user is not authenticated language from cookie is taken OR if user never entered website before,
   * the appConfig language is taken.
   * if there is attribute it means user was once logged in and changed language.
   * if user is authenticated and there is no attribute it means user never changed language
   * so appConfig language will be used. (different user probably logged in so cookie session is not reliable)
   * if no appConfig default is specified or value can't be found in the list - defaultLanguage will be used
   */

  const attrLang = getAttributeLanguage(state);
  const cookLang = getLangFromCookies();
  const configLang = getDefaultLanguage(state);
  const langList = getLanguageList(state);
  const langKey = isInitialLoadingComplete(state) ? attrLang : cookLang;

  const selectedLanguage = _.find(langList, {
    key: langKey || configLang,
  });

  return selectedLanguage || defaultLanguage;
};
