import _ from "lodash";

import { getThreats } from "../root.selectors";
import { getSelectedLanguage } from "../../i18n/language.selectors";
import { initialLoadableState } from "../shared/withLoadingState";

export const getThreatsBySelectedLanguage = state => {
  const threatsByLanguage = getThreats(state);
  const { key: language } = getSelectedLanguage(state);

  return threatsByLanguage[language] || initialLoadableState;
};

export const getThreatsById = _.flow(
  getThreatsBySelectedLanguage,
  threatsSlice => _.keyBy(threatsSlice.list, "id")
);
