import { createContext, useContext } from "react";

import { WEEK_DAYS } from "./scheduler.consts";

export const SchedulerContext = createContext({
  profileId: "",
  type: "",
  weekdays: WEEK_DAYS,
  timeFormat24h: true,
});

export const useSchedulerContext = () => useContext(SchedulerContext);
