import { getSubscriberId } from "../../../store/info";
import { getActivationCommand } from "../../../store/account";
import { getProfiles } from "./wizard.selectors";
import { createTempNotification } from "../../../components/notifications";

const Protection = {
  Single: "single",
  Multiple: "multiple",
};

export const CHOOSE_PROTECTION = "[WIZARD] CHOOSE_PROTECTION";
export const chooseProtection = (protection, goToNextStep = () => {}) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: CHOOSE_PROTECTION, payload: { protection } });
  const command = getActivationCommand(getState());
  if (command.length === 0) {
    goToNextStep();
    return;
  }

  const subscriberId = getSubscriberId(getState());
  const result = await api.account.activate(subscriberId, command);
  if (result.success === false) {
    // TODO: if activation fails the error page is shown
    dispatch(chooseProtectionFailure(result.error));
  } else {
    dispatch(chooseProtectionSuccess(result.data));
    goToNextStep();
  }
};

export const chooseSingle = goToNextStep =>
  chooseProtection(Protection.Single, goToNextStep);
export const chooseMultiple = goToNextStep =>
  chooseProtection(Protection.Multiple, goToNextStep);

export const CHOOSE_PROTECTION_SUCCESS = "[WIZARD] CHOOSE_PROTECTION_SUCCESS";
export const chooseProtectionSuccess = data => ({
  type: CHOOSE_PROTECTION_SUCCESS,
  payload: data,
});

export const CHOOSE_PROTECTION_FAILURE = "[WIZARD] CHOOSE_PROTECTION_FAILURE";
export const chooseProtectionFailure = error => ({
  type: CHOOSE_PROTECTION_FAILURE,
  payload: error,
});

export const FINISH_WIZARD = "[WIZARD] FINISH_WIZARD";
export const finishWizard = () => (dispatch, getState) => {
  const profiles = getProfiles(getState());
  dispatch({
    type: FINISH_WIZARD,
    payload: { profiles },
  });
  dispatch(
    createTempNotification({
      variant: "success",
      description: "register_successful",
    })
  );
};
