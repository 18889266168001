import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { utcToZonedTime } from "date-fns-tz";
import { getHours, getMinutes, format } from "date-fns";

import { useLanguageContext } from "../../../../i18n/ConnectedIntlProvider";
import { useSchedulerContext } from "../../SchedulerContext";
import { getTimezone } from "../../../../store/account";
import { NotMobile } from "../../../../common/Responsive";

import "./TimeIndicator.scss";

export const format12h = "hh:mm aaa";
export const format24h = "HH:mm";

const getCurrentTimeInformation = (timeFormat24h, currentTimezone) => {
  const now = utcToZonedTime(Date.now(), currentTimezone);
  const [hours, minutes] = [getHours(now), getMinutes(now)];
  const time = format(now, timeFormat24h ? format24h : format12h);

  return { time, hours, minutes };
};

export const TimeIndicator = ({ currentHoursRange }) => {
  const { timeFormat24h } = useSchedulerContext();

  const { invertedDirection } = useLanguageContext();

  const [currentTime, setCurrentTime] = useState(null);

  const currentTimezone = useSelector(getTimezone);

  const timeCoordinates = useMemo(() => {
    if (!currentTime) return;
    const { hours, minutes } = currentTime;
    const sliceStart = currentHoursRange[0];
    const hoursInGrid = currentHoursRange[1] - currentHoursRange[0];

    const differenceInHours = hours - sliceStart;
    const timeElement = differenceInHours + minutes / 60;
    return ((timeElement / hoursInGrid) * 100).toFixed(1);
  }, [currentTime, currentHoursRange]);

  const updateTime = useCallback(() => {
    const newTime = getCurrentTimeInformation(timeFormat24h, currentTimezone);

    setCurrentTime(newTime);
  }, [timeFormat24h, currentTimezone]);

  const showIndicator = useMemo(() => {
    if (!currentTime) return;

    return (
      currentHoursRange[0] <= currentTime.hours &&
      currentTime.hours < currentHoursRange[1]
    );
  }, [currentTime, currentHoursRange]);

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 10000);

    return () => clearInterval(interval);
  }, [updateTime]);

  const firstHalf = useMemo(() => timeCoordinates < 50, [timeCoordinates]);

  return !showIndicator ? null : (
    <div
      style={{
        [invertedDirection
          ? "right"
          : "left"]: `calc(${timeCoordinates}% - 1px)`,
      }}
      className="time-indicator"
    >
      <div className="time-indicator__info">
        <span
          style={
            !firstHalf
              ? {
                  transform: `translateX(${
                    invertedDirection ? "100%" : "-100%"
                  })`,
                }
              : {}
          }
        >
          <NotMobile>
            <FormattedMessage id="current_time" />
          </NotMobile>{" "}
          {currentTime.time}
        </span>
      </div>
    </div>
  );
};

TimeIndicator.propTypes = {
  currentHoursRange: PropTypes.arrayOf(PropTypes.number).isRequired,
};
