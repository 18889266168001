import React from "react";
import { FormattedMessage } from "react-intl";

import "./WizardSidebar.scss";

export const WizardSidebar = () => (
  <div className="wizard-sidebar">
    <h1 className="wizard-sidebar__title">
      <FormattedMessage id="single_profile" />
    </h1>
    <p className="wizard-sidebar__description">
      <FormattedMessage id="same_level_of_protection" />
    </p>
  </div>
);
