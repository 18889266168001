import React from "react";
import { FormattedMessage } from "react-intl";

import { CommonAdvancedViewGrid } from "../../../tableComponents";
import { CellDetails } from "../../../../../../../components";
import { DeviceIds } from "../deviceIds";

export const NonRoamingDeviceGrid = ({ device }) => {
  return (
    <CommonAdvancedViewGrid>
      <CellDetails
        id="network-id"
        header={<FormattedMessage id="network_id" />}
        value={
          <DeviceIds
            identifiers={device.identifiers}
            phoneNumber={device.phoneNumber}
            networkId={device.networkId}
          />
        }
      />
    </CommonAdvancedViewGrid>
  );
};
