import {
  BannersActionTypes,
  HIDE_ALLOW_LIST_OVERRIDE_WARNING,
  HIDE_SPSON_MAX_LIMIT_WARNING,
  HIDE_SPSON_PANEL,
} from "./banners.types";

export const hideAllowListOverrideWarning = (): BannersActionTypes => ({
  type: HIDE_ALLOW_LIST_OVERRIDE_WARNING,
});

export const hideSpsonPanel = (): BannersActionTypes => ({
  type: HIDE_SPSON_PANEL,
});

export const hideSpsonMaxLimitWarning = (): BannersActionTypes => ({
  type: HIDE_SPSON_MAX_LIMIT_WARNING,
});
