import { LogicalDeviceDetails, LogicalDeviceDetailsTypes } from "@sportal/api";
import { getLogicalDeviceDetails } from "../../root.selectors";
import { RootState } from "../../root.reducer";
import { denormalizeCollection } from "../../shared/normalizeCollection";

export const getLogicalDeviceDetailsById = (id: string) => (
  state: RootState
): LogicalDeviceDetails => getLogicalDeviceDetails(state).list[id];

export const getLogicalDeviceDetailsType = (id: string) => (
  state: RootState
): LogicalDeviceDetailsTypes => getLogicalDeviceDetailsById(id)(state).type;

export const getLogicalDeviceDetailsList = (
  state: RootState
): LogicalDeviceDetails[] =>
  denormalizeCollection(getLogicalDeviceDetails(state));
