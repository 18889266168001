import _ from "lodash";

import { ListsProvider, ListsService } from "@sportal/api/lists";
import { SC_CODE } from "./appConfigService";
import { HttpService } from "@sportal/api";

export const makeListsService = (http: HttpService, config): ListsService =>
  new ListsService(
    new ListsProvider(http, config.backendUrl, SC_CODE),
    _.get(config.urlFilters, "url-types"),
    SC_CODE
  );
