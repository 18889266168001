import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { BannerInline } from "../../bannerInline/BannerInline";
import { getRoamingDevicesLimits } from "../../../store/devices";

import "./RoamingLimitReachedWarningReadonly.scss";

export const RoamingLimitReachedWarningReadonly = () => {
  const {
    isRoamingLimitReached,
    roamingDevicesCount,
    roamingDevicesLimit,
  } = useSelector(getRoamingDevicesLimits);

  if (!isRoamingLimitReached) return null;

  const message = (
    <FormattedMessage
      id="roaming_reached_limit_info"
      values={{
        b: chunks => <span className="strong">{chunks}</span>,
        roamingDevicesCount,
        roamingDevicesLimit,
      }}
    />
  );

  return (
    <BannerInline
      className={"roaming-limit-reached-warning"}
      message={message}
    />
  );
};
