import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ssoLogin } from "../login.actions";
import { LoginPageContainer } from "../loginPageContent/LoginPageContent";
import { Button } from "../../../components";

import "./SsoLogin.scss";

export const SsoLogin = () => {
  const dispatch = useDispatch();

  const login = useCallback(() => {
    dispatch(ssoLogin());
  }, [dispatch]);

  return (
    <LoginPageContainer>
      <LoginPageContainer.Content className="sso-login">
        <LoginPageContainer.Header>
          <FormattedMessage id={"account_login"} />
        </LoginPageContainer.Header>
        <h2>
          <FormattedMessage id={"part_of_akamai"} />
        </h2>
        <p>
          <FormattedMessage id={"login_with_sso_description"} />
        </p>
        <Button onClick={login} size={"large"} color={"primary"}>
          <FormattedMessage id={"continue"} />
        </Button>
      </LoginPageContainer.Content>
    </LoginPageContainer>
  );
};
