import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const ModalFooter = ({ children, className }) => (
  <div className={classnames("modal__footer", className)}>{children}</div>
);

ModalFooter.propTypes = {
  className: PropTypes.string,
};
