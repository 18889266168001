import { getSubscriberId, getDefaultTimezone } from "../info";
import {
  AccountData,
  AccountServiceErrorCode,
  CreateAccountData,
  isFailure,
} from "@sportal/api";
import {
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_FAILURE,
  AccountActionTypes,
} from "./account.types";
import { Api } from "../../api";

export const errorMessages = {
  [AccountServiceErrorCode.CANNOT_CREATE_ACCOUNT]: "login_error",
};

export const loadAccount = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_ACCOUNT });
  const subscriberId = getSubscriberId(getState());
  const timezone = getDefaultTimezone(getState());

  const result = await api.account.get(subscriberId, timezone);

  if (isFailure(result)) {
    const message = errorMessages[result.error.code];

    dispatch(loadAccountFailure({ ...result.error, message }));
  } else {
    dispatch(loadAccountSuccess(result.data));
  }
};

export const loadAccountSuccess = (
  account: AccountData | CreateAccountData
): AccountActionTypes => ({
  type: LOAD_ACCOUNT_SUCCESS,
  payload: account,
});

export const loadAccountFailure = (error): AccountActionTypes => ({
  type: LOAD_ACCOUNT_FAILURE,
  payload: error,
});
