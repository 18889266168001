import { HttpServiceError, HttpServiceResponse } from "@sportal/api";

export class ResponseCodeError extends Error implements HttpServiceError {
  public name = "ResponseCodeError";
  public response?: HttpServiceResponse;

  constructor(response: HttpServiceResponse) {
    super("Custom status code was found among response headers");
    this.response = response;
  }
}
