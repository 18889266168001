export const initialLoadableState = {
  isLoading: false,
  success: false,
  error: null,
};

const onLoad = state => ({
  ...state,
  isLoading: true,
  success: false,
  error: null,
});

const onSuccess = state => ({
  ...state,
  isLoading: false,
  success: true,
  error: null,
});

const onFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  success: false,
  error: payload,
});

export const withLoadingState = ({
  loadActionType,
  successActionType,
  failureActionType,
  dynamicSlice = false,
}) => {
  const actionsMap = {
    [loadActionType]: onLoad,
    [successActionType]: onSuccess,
    [failureActionType]: onFailure,
  };

  return baseReducer => (state, action) => {
    if (state === undefined) {
      return {
        ...baseReducer(state, action),
        ...(dynamicSlice ? {} : initialLoadableState),
      };
    }

    const handler = actionsMap[action.type];
    const newState = handler ? handler(state, action) : state;
    return baseReducer(newState, action);
  };
};
