import React from "react";
import { FormattedMessage } from "react-intl";

import { ConfirmationDialog } from "../../confirmationDialog/ConfirmationDialog";

export const DeleteProfileModal = ({ modal }) => (
  <ConfirmationDialog
    className="delete-profile-dialog"
    modal={modal}
    content={<FormattedMessage id="delete_profile_message" />}
    cancelMessage={<FormattedMessage id="cancel" />}
    submitMessage={<FormattedMessage id="delete" />}
  />
);
