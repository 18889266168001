import React, { VFC } from "react";
import classnames from "classnames";
import { GetItemPropsOptions } from "downshift";

import "./ComboboxItem.scss";

export interface IComboboxItem {
  value: string;
  content: string;
  disabled?: boolean;
}

interface ComboboxItemProps {
  item: IComboboxItem;
  index: number;
  getItemProps: (options: GetItemPropsOptions<IComboboxItem>) => any;
  selected: boolean;
  highlighted: boolean;
}

export const ComboboxItem: VFC<ComboboxItemProps> = ({
  item,
  index,
  getItemProps,
  selected,
  highlighted,
}) => {
  return (
    <li
      {...getItemProps({
        item,
        index,
        disabled: item.disabled,
      })}
      className={classnames("combobox-menu__item", {
        "combobox-menu__item--disabled": item.disabled,
        "combobox-menu__item--highlighted": highlighted,
        "combobox-menu__item--selected": selected,
      })}
      data-value={item.value}
    >
      {item.content}
    </li>
  );
};
