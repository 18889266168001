import {
  ProtectionProvider,
  ProtectionService,
} from "@sportal/api/protections";
import { SC_CODE } from "./appConfigService";
import { HttpService } from "@sportal/api";

export const makeProtectionService = (
  http: HttpService,
  baseUrl: string
): ProtectionService =>
  new ProtectionService(new ProtectionProvider(http, baseUrl, SC_CODE));
