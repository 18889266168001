import React, { memo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Icon, useFormContext } from "@sportal/cdk";
import { useDidUpdate, useCallbackInstance } from "@sportal/cdk/hooks";
import { MandatoryPinService } from "@sportal/api";

import {
  Checkbox,
  TabHeader,
  Form,
  SubmitButton,
  useForm,
  PINInput,
  withProfiles,
} from "../../../../components";
import { SettingsTabTitle } from "../../settingsTabTitle/SettingsTabTitle";
import {
  isPersonalInternetAvailable,
  isSubscriberSafetyAvailable,
  isPersonalInternetBypassPinAllowed,
  isSubscriberSafetyBypassPinAllowed,
} from "../../../../store/settings";
import {
  isPersonalInternetEnabled,
  isSubscriberSafetyEnabled,
  isSubscriberSafetyActivated,
  isPersonalInternetBypassPinEnabled,
  isSubscriberSafetyBypassPinEnabled,
  getPersonalInternetBypassPinValue,
  getSubscriberSafetyBypassPinValue,
} from "../../../../store/account";
import { getMandatoryPinService } from "../../../../store/config";
import { saveBlockPageSettings } from "./blockPageTab.actions";
import { useMobileValue } from "../../../../common/Responsive";
import { TabSection } from "../tabSection";

import "./BlockPageTab.scss";

const BlockPageCheckbox = memo(
  ({ id, name, labelMessage, descriptionMessage, ...rest }) => {
    const { form } = useFormContext();

    return (
      <div className="block-page-tab__checkbox">
        <Checkbox
          {...rest}
          id={id}
          name={name}
          checked={form.values[name]}
          onChange={event => form.set(name, event.target.checked)}
          className="block-page-tab__checkbox-input"
          label={<FormattedMessage id={labelMessage} />}
          aria-describedby={`${id}-description`}
        />
        {descriptionMessage && (
          <div id={`${id}-description`}>
            <FormattedMessage id={descriptionMessage} />
          </div>
        )}
      </div>
    );
  }
);

function BlockPagePin() {
  const { form } = useFormContext();

  const [value, setValue] = useState(form.values.pin);

  const validateAndSetPin = useCallback(
    pin => {
      const pinValidationError = validatePin(pin);

      // error state will be reseted when validation is successful
      form.setError("pin", pinValidationError);
      form.set("pin", pin);
    },
    [form]
  );

  const handlePinChange = useCallback(
    event => {
      const { value } = event.target;

      const isAllowed = /^[a-zA-Z0-9]*$/.test(value);

      if (!isAllowed) {
        return;
      }

      if (value.length === 5) {
        validateAndSetPin(value);
      }

      setValue(value);
    },
    [validateAndSetPin, setValue]
  );

  const handlePinBlur = useCallback(
    event => {
      validateAndSetPin(event.target.value);
    },
    [validateAndSetPin]
  );

  const errorMessageId = form.errors.pin;

  return (
    <div className="block-page-tab__pin">
      <div className="block-page-tab__pin-required">
        *
        <FormattedMessage id="required" />
      </div>
      <PINInput
        name="pin"
        maxLength={5}
        value={value}
        onChange={handlePinChange}
        onBlur={handlePinBlur}
        error={Boolean(errorMessageId)}
      />
      <div className="block-page-tab__pin-hint">
        <Icon icon="fas fa-info-circle" />
        {"a-z, A-Z, 0-9 "}
        <FormattedMessage id="only" />
      </div>
      {errorMessageId && (
        <div className="block-page-tab__pin-error">
          <FormattedMessage id={errorMessageId} />
        </div>
      )}
    </div>
  );
}

function validatePin(pin = "") {
  if (!pin.length) {
    return "bypass_pin_is_required";
  }

  if (pin.length < 5) {
    return "bypass_pin_must_contain";
  }

  return "";
}

function useBlockPage() {
  const isPIAvailable = useSelector(isPersonalInternetAvailable);
  const isSSAvailable = useSelector(isSubscriberSafetyAvailable);

  const personalInternet = useSelector(isPersonalInternetEnabled);
  const subscriberSafety = useSelector(isSubscriberSafetyEnabled);

  const isSSActivated = useSelector(isSubscriberSafetyActivated);
  const isPIPinVisible = useSelector(isPersonalInternetBypassPinAllowed);
  const isSSPinAllowed = useSelector(isSubscriberSafetyBypassPinAllowed);
  const isSSPinVisible = isSSPinAllowed && isSSActivated;

  const isPIPinOn = useSelector(isPersonalInternetBypassPinEnabled);
  const isSSPinOn = useSelector(isSubscriberSafetyBypassPinEnabled);

  const pinPI = useSelector(getPersonalInternetBypassPinValue);
  const pinSS = useSelector(getSubscriberSafetyBypassPinValue);

  return {
    isPIAvailable,
    isSSAvailable,
    personalInternet: isPIAvailable && personalInternet,
    subscriberSafety: isSSAvailable && subscriberSafety,
    pin: pinPI || pinSS || "",
    isPIPinVisible,
    isSSPinVisible,
    isPIPinOn,
    isSSPinOn,
  };
}

function useBypassPinCheckboxesSync({ checked, watch, onSync }) {
  const callbackRef = useCallbackInstance(onSync);

  useDidUpdate(() => {
    if (watch) {
      callbackRef.current(checked);
    }
  }, [checked]);
}

/**
 * Bypass pin checkboxes should show actual state nevetheless what is prefered one according to mandatoryPinService.
 * If mandatoryPinService is not specified user is free to turn on and off bypass pin checkboxes.
 * If mandatoryPinService is set to PI we should display initial form state as it is, but if personal internet is on checked ones should be disabled
 * and if it's off unchecked ones should be disabled. This way we will not allow user to unsync with prefered values.
 * If user checks or uchecks personal internet bypass pin checkboxes should be in sync. This means next:
 * personal internet is on - checkboxes are on and disabled
 * personal internet is off - checkboxes are unchecked and disabled
 * Same logic goes with subscriber safety
 *
 * for more info please see https://github.nominum.com/nominum/subscriber-portal/pull/538
 */
function BypassPinCheckboxes({ isPIPinVisible, isSSPinVisible }) {
  const { form } = useFormContext();

  const {
    personalInternet,
    subscriberSafety,
    isPIPinOn,
    isSSPinOn,
  } = form.values;

  const isPIAvailable = useSelector(isPersonalInternetAvailable);
  const isSSAvailable = useSelector(isSubscriberSafetyAvailable);

  const mandatoryPinService = useSelector(getMandatoryPinService);

  const isValidMandatoryPinService =
    (mandatoryPinService === MandatoryPinService.PI && isPIAvailable) ||
    (mandatoryPinService === MandatoryPinService.SS && isSSAvailable);

  const mandatoryPinServiceValue = isValidMandatoryPinService
    ? mandatoryPinService
    : "";

  /**
   * This will determine prefered state for bypass-pin checkboxes.
   * Once form is loaded this will keep unsynced checkboxes enabled, but after checking/unchecking
   * unsynced pin checkbox(es), it will become disabled (that means synced).
   * If the user changes value for personal internet (when mandatoryPinService === 'PI')
   * or subscriber safety (when mandatoryPinService === 'SS') bypass pin checkboxes also change their values
   * and they keep disabled
   *
   * When there is no valid mandatoryPinService the user is allowed to check/uncheck bypass pin checkboxes,
   * they will be enabled all the time
   */
  const mandatoryServiceStatus = mandatoryPinServiceValue
    ? mandatoryPinServiceValue === MandatoryPinService.PI
      ? personalInternet
      : subscriberSafety
    : -1;

  const isPIPinDisabled = isPIPinOn === mandatoryServiceStatus;
  const isSSPinDisabled = isSSPinOn === mandatoryServiceStatus;

  function handleCheckboxSync(value) {
    if (isPIPinVisible) {
      form.set("isPIPinOn", value);
    }

    if (isSSPinVisible) {
      form.set("isSSPinOn", value);
    }
  }

  useBypassPinCheckboxesSync({
    checked: personalInternet,
    watch: mandatoryPinServiceValue === MandatoryPinService.PI,
    onSync: handleCheckboxSync,
  });

  useBypassPinCheckboxesSync({
    checked: subscriberSafety,
    watch: mandatoryPinServiceValue === MandatoryPinService.SS,
    onSync: handleCheckboxSync,
  });

  return (
    <fieldset>
      <legend className={"sr-only"}>
        <FormattedMessage id={"bypass_pin_options_title"} />
      </legend>
      {isPIPinVisible && (
        <BlockPageCheckbox
          id={"block-page-tab-personal-internet-pin"}
          name="isPIPinOn"
          labelMessage="personal_internet"
          disabled={isPIPinDisabled}
        />
      )}
      {isSSPinVisible && (
        <BlockPageCheckbox
          id={"block-page-tab-subscriber-safety-pin"}
          name="isSSPinOn"
          labelMessage="malware_and_phishing_protection"
          disabled={isSSPinDisabled}
        />
      )}
    </fieldset>
  );
}

export const BlockPageTabContent = () => {
  const dispatch = useDispatch();

  const {
    isPIAvailable,
    isSSAvailable,
    personalInternet,
    subscriberSafety,
    pin,
    isPIPinVisible,
    isSSPinVisible,
    isPIPinOn,
    isSSPinOn,
  } = useBlockPage();

  const isPinSectionVisible = isPIPinVisible || isSSPinVisible;

  const form = useForm({
    personalInternet,
    subscriberSafety,
    pin,
    isPIPinOn,
    isSSPinOn,
  });

  const handleSubmit = useCallback(() => {
    if (isPinSectionVisible) {
      const pinValidationError = validatePin(form.values.pin);

      if (pinValidationError) {
        form.setError("pin", pinValidationError);
        return;
      }
    }

    dispatch(saveBlockPageSettings(form.getChanges()));
  }, [form, dispatch, isPinSectionVisible]);

  const buttonSize = useMobileValue("large", "medium");

  return (
    <div className="block-page-tab">
      <TabHeader>
        <SettingsTabTitle tabName="block_page" />
      </TabHeader>
      <Form
        onSubmit={handleSubmit}
        formProps={form}
        disabled={!form.didFormChange() || form.hasErrors()}
      >
        <TabSection header={<FormattedMessage id="protection_settings" />}>
          <fieldset>
            <legend className={"sr-only"}>
              <FormattedMessage id={"protection_settings"} />
            </legend>
            {isPIAvailable && (
              <BlockPageCheckbox
                id={"block-page-tab-personal-internet"}
                name="personalInternet"
                labelMessage="personal_internet"
                descriptionMessage="personal_internet_description"
              />
            )}
            {isSSAvailable && (
              <BlockPageCheckbox
                id={"block-page-tab-subscriber-safety"}
                name="subscriberSafety"
                labelMessage="malware_and_phishing_protection"
                descriptionMessage="malware_and_phishing_protection_description"
              />
            )}
          </fieldset>
        </TabSection>

        {isPinSectionVisible && (
          <TabSection
            header={<FormattedMessage id="bypass_pin_title" />}
            description={<FormattedMessage id="bypass_pin_description" />}
          >
            <BlockPagePin />

            <div className="bypass-pin-options-title">
              <FormattedMessage id="bypass_pin_options_title" />
            </div>
            <BypassPinCheckboxes
              isPIPinVisible={isPIPinVisible}
              isSSPinVisible={isSSPinVisible}
            />
          </TabSection>
        )}

        <div className="settings-page__footer">
          <SubmitButton color="primary" size={buttonSize}>
            <FormattedMessage id="save" />
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
};

export const BlockPageTab = withProfiles(BlockPageTabContent);
