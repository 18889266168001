import React from "react";
import { useSelector } from "react-redux";

import { loginComponentSelector } from "./loginComponentSelector";
import { withLayout } from "../../components/layout/Layout";
import { LoginHeader } from "./loginHeader/LoginHeader";

const LoginPage = () => {
  const Component = useSelector(loginComponentSelector);
  return <Component />;
};

export default withLayout({
  customHeader: () => <LoginHeader />,
})(LoginPage);
