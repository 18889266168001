import React from "react";
import PropTypes from "prop-types";

import { Icon } from "@sportal/cdk";
import { NotMobile } from "../../../common/Responsive";
import { UnsavedChanges } from "./UnsavedChanges";

export const ProfileSectionCollapseToggle = ({ children, hasChanges }) => (
  <div className="profile-section__collapse__toggle">
    {children}
    <Icon icon="far fa-angle-down" />
    <NotMobile>{hasChanges && <UnsavedChanges />}</NotMobile>
  </div>
);

ProfileSectionCollapseToggle.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
};
