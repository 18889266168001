import React from "react";
import { FormattedMessage } from "react-intl";
import { Column } from "react-table";

import { ProfileDeviceMenu } from "../../ProfileDeviceMenu";
import { FormattedDateTime } from "../../../../../../../components";
import { ProfileDeviceTypeTooltip } from "../../../../../../../components/profileDevices/profileDeviceTypeTooltip";
import { alphanumeric } from "../../../../../../../components/table/helpers/table-sorting.helpers";

export const COLUMNS_MOBILE: Column<any>[] = [
  {
    Header: <FormattedMessage id="device_type" />,
    id: "deviceType",
    Cell: ({ row }) => (
      <ProfileDeviceTypeTooltip identifiers={row.original.identifiers} />
    ),
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="device_name" />,
    headerTranslationId: "device_name",
    accessor: "name",
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="actions" />,
    id: "actions",
    // @ts-ignore
    Cell: ({ row }) => <ProfileDeviceMenu device={row.original} />,
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="last_seen" />,
    headerTranslationId: "last_seen",
    accessor: "lastSeen",
    Cell: ({ value }) => <FormattedDateTime value={value} />,
  },
];
