import _ from "lodash";

import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_FAILURE,
  LOAD_ACCOUNT_SUCCESS,
} from "./account.types";
import {
  CHOOSE_PROTECTION_SUCCESS,
  FINISH_WIZARD,
} from "../../pages/wizard/store/wizard.actions";
import { SAVE_ACCOUNT_SETTINGS_SUCCESS } from "../../pages/settings/tabs/accountTab/accountTab.actions";
import { SAVE_BLOCK_PAGE_SETTINGS_SUCCESS } from "../../pages/settings/tabs/blockPageTab/blockPageTab.actions";
import { BypassPrivateDns, BYPASS_PRIVATE_DNS, Service } from "@sportal/api";
import { fromProfiles } from "../profiles";

const accountReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_ACCOUNT_SUCCESS:
    case CHOOSE_PROTECTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_ACCOUNT_SETTINGS_SUCCESS:
      const { timezone, aprOn } = action.payload;

      const getNewSCService = () => {
        const bypassPrivateDNS = {
          ...state[Service.PersonalInternet][BYPASS_PRIVATE_DNS],
          [BypassPrivateDns.ApplePrivateRelay]: aprOn,
        };

        return {
          ...state[Service.PersonalInternet],
          [BYPASS_PRIVATE_DNS]: bypassPrivateDNS,
        };
      };

      return {
        ...state,
        ...(timezone && { timezone }),
        ...(!_.isUndefined(aprOn) && {
          [Service.PersonalInternet]: getNewSCService(),
        }),
      };
    case SAVE_BLOCK_PAGE_SETTINGS_SUCCESS:
      const { payload } = action;

      let personalInternet = _.clone(state[Service.PersonalInternet]);
      let subscriberSafety = _.clone(state[Service.SubscriberSafety]);

      if (_.has(payload, "personalInternet")) {
        personalInternet.enabled = payload.personalInternet.data;
      }

      if (_.has(payload, "subscriberSafety")) {
        subscriberSafety.enabled = payload.subscriberSafety.data;

        if (payload.subscriberSafety.activateService) {
          subscriberSafety.activated = true;
        }
      }

      if (_.has(payload, "pinUpdate")) {
        const assignPinUpdates = (destination, source) => {
          _.assignIn(
            destination,
            _.mapKeys(source, (_value, key) => `bypass-pin-${key}`)
          );
        };

        if (_.has(payload.pinUpdate.data, Service.PersonalInternet)) {
          assignPinUpdates(
            personalInternet,
            payload.pinUpdate.data[Service.PersonalInternet]
          );
        }

        if (_.has(payload.pinUpdate.data, Service.SubscriberSafety)) {
          assignPinUpdates(
            subscriberSafety,
            payload.pinUpdate.data[Service.SubscriberSafety]
          );
        }
      }

      return {
        ...state,
        [Service.PersonalInternet]: personalInternet,
        [Service.SubscriberSafety]: subscriberSafety,
      };
    case FINISH_WIZARD: {
      return {
        ...state,
        [Service.PersonalInternet]: {
          ...state[Service.PersonalInternet],
          "profile-count": fromProfiles.getProfilesCount(
            action.payload.profiles
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default withLoadingState({
  loadActionType: LOAD_ACCOUNT,
  successActionType: LOAD_ACCOUNT_SUCCESS,
  failureActionType: LOAD_ACCOUNT_FAILURE,
})(accountReducer);
