import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Button, ProfileCards } from "../../../components";
import { getProfilesWithDevicesCount } from "../../../store/profiles";

import "./SingleContent.scss";

export const SingleContent = ({ onUpgradeClick }) => {
  const profiles = useSelector(getProfilesWithDevicesCount);

  return (
    <div className="single-content">
      <h3>
        <FormattedMessage id="using_single_profile" />
      </h3>
      <p>
        <FormattedMessage id="same_level_of_protection" />
      </p>
      <div className="single-content__actions">
        <Button size="medium" color="primary" onClick={onUpgradeClick}>
          <FormattedMessage id="upgrade_to_multiple_profiles" />
        </Button>
        <ProfileCards profiles={[profiles[0]]} availableProfilesNumber={0} />
      </div>
    </div>
  );
};
