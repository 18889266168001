import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./DevicesCounter.scss";

import { NotMobile } from "../../../../../common/Responsive";

export const DevicesCounter = ({
  variant,
  label,
  amount,
  limit,
  className,
}) => (
  <div
    className={classnames(
      "devices-counter",
      `devices-counter--${variant}`,
      className
    )}
  >
    <div className="devices-counter__amount">
      {amount}
      {limit ? `/${limit}` : null}
    </div>
    <NotMobile>
      <div className="devices-counter__label">{label}</div>
    </NotMobile>
  </div>
);

DevicesCounter.propTypes = {
  label: PropTypes.node.isRequired,
  amount: PropTypes.number.isRequired,
  limit: PropTypes.number,
  variant: PropTypes.oneOf(["primary", "secondary", "outlined"]),
  className: PropTypes.string,
};
