import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.scss";

import App from "./App";
import { initialize } from "./init";
import { AppProvider } from "./AppContext";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

initialize().then(result => {
  const root = document.getElementById("root");
  const { store, api } = result;

  if (!result) {
    root.innerText = "Something went wrong";
    return;
  }

  ReactDOM.render(
    <AppProvider value={{ api }}>
      <Provider store={store}>
        <App />
      </Provider>
    </AppProvider>,
    root
  );
});
