import { withLoadingState } from "../shared/withLoadingState";
import { LOGIN_FAILURE } from "../../pages/login/login.actions";
import {
  InfoActionTypes,
  LOAD_INFO,
  LOAD_INFO_FAILURE,
  LOAD_INFO_SUCCESS,
} from "./info.types";
import { InfoData } from "@sportal/api";
import { LoadingState } from "../shared/withLoadingState/withLoadableSlice.types";

type InfoState = InfoData & LoadingState;
// TODO: make a type for LOGIN_FAILURE and use it here
type InfoReducerActionTypes =
  | InfoActionTypes
  | { type: typeof LOGIN_FAILURE; payload: any };

const initialState = {} as InfoState;

const infoReducer = (
  state: InfoState = initialState,
  action: InfoReducerActionTypes
): InfoState => {
  switch (action.type) {
    case LOAD_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default withLoadingState({
  loadActionType: LOAD_INFO,
  successActionType: LOAD_INFO_SUCCESS,
  failureActionType: LOAD_INFO_FAILURE,
})(infoReducer);
