import React, { FC, ReactElement, useEffect } from "react";
import {
  Column,
  SortingRule,
  useExpanded,
  useSortBy,
  useTable,
} from "react-table";
import classNames from "classnames";
import _ from "lodash";

import {
  EmptyTBody,
  RenderRowDetailsInterface,
  TableCaption,
  TBody,
  TFoot,
  THead,
} from "./components";
import { defaultColumn, useGridLayout } from "./table.hooks";
import { defaultTableInstanceConfig } from "./tableInstance.config";

import "./Table.scss";

const hiddenColumnsDefault = [];
const getHiddenColumns = (isAdvancedView: boolean, hiddenColumns: string[]) =>
  isAdvancedView ? hiddenColumnsDefault : hiddenColumns;

interface TableProps {
  data: any[];
  columns: Column<any>[];
  hiddenColumns?: string[];
  isAdvancedView?: boolean;
  tableName: string;
  renderRowDetails?: RenderRowDetailsInterface;
  footerContent?: ReactElement;
  emptyTableContent?: ReactElement;
  initialSortBy: SortingRule<any>[];
}

export const Table: FC<TableProps> = ({
  data,
  columns,
  hiddenColumns = hiddenColumnsDefault,
  isAdvancedView,
  tableName,
  renderRowDetails,
  footerContent,
  emptyTableContent,
  initialSortBy,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
    setHiddenColumns,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: getHiddenColumns(isAdvancedView, hiddenColumns),
        sortBy: initialSortBy,
      },
      ...defaultTableInstanceConfig,
    },
    useSortBy,
    useExpanded
  );

  const { gridStyle, rowDetailsStyle } = useGridLayout(headerGroups);

  useEffect(() => {
    toggleAllRowsExpanded(isAdvancedView);
    setHiddenColumns(getHiddenColumns(isAdvancedView, hiddenColumns));
  }, [
    toggleAllRowsExpanded,
    isAdvancedView,
    setHiddenColumns,
    hiddenColumns,
    data,
  ]);

  useEffect(() => {
    setSortBy(initialSortBy);
  }, [initialSortBy, setSortBy]);

  const isDataEmpty = _.isEmpty(data);
  const tableCaptionId = tableName.replace(/\s/g, "") + "Caption";

  return (
    <div
      className={classNames("table", footerContent && "table--with-footer")}
      aria-labelledby={tableCaptionId}
      {...getTableProps()}
    >
      <TableCaption
        as="span"
        tableName={tableName}
        captionId={tableCaptionId}
        headerGroups={headerGroups}
      />
      <THead
        headerGroups={headerGroups}
        gridStyle={gridStyle}
        isDataEmpty={isDataEmpty}
      />
      {isDataEmpty ? (
        <EmptyTBody>{emptyTableContent}</EmptyTBody>
      ) : (
        <TBody
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          isAllRowsExpanded={isAllRowsExpanded}
          gridStyle={gridStyle}
          rowDetailsStyle={rowDetailsStyle}
          renderRowDetails={renderRowDetails}
        />
      )}
      {footerContent && <TFoot content={footerContent} />}
    </div>
  );
};
