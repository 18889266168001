import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_FAILURE,
  LOAD_SETTINGS_SUCCESS,
  SettingsActionTypes,
} from "./settings.types";

const settingsReducer = (state = {}, action: SettingsActionTypes) => {
  switch (action.type) {
    case LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default withLoadingState({
  loadActionType: LOAD_SETTINGS,
  successActionType: LOAD_SETTINGS_SUCCESS,
  failureActionType: LOAD_SETTINGS_FAILURE,
})(settingsReducer);
