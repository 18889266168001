import React from "react";

import { Input as SharedInput } from "@sportal/cdk";
import { useIntlTranslate } from "../../hooks";

import "./Input.scss";

export const Input = props => {
  const translate = useIntlTranslate();

  return (
    <SharedInput
      clearButtonAriaLabel={translate("aria_clear_input")}
      {...props}
    />
  );
};
