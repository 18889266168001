import { HttpService } from "../shared";

export class AppConfigProvider {
  constructor(private http: HttpService) {}

  public get() {
    return this.http.get("./appConfig.json").then(response => {
      // axios shallows invalid json error so we need a workaround
      if (typeof response.data === "string") {
        JSON.parse(response.data); // It should always throw
      }
      return response;
    });
  }
}
