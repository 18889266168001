import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { ModalService } from "@sportal/cdk";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { RenameDeviceDialog } from "../renameDeviceDialog/RenameDeviceDialog";
import { editLogicalDevice } from "../../../../../store/devices";
import { useDevicesTabContext } from "../DevicesTabContext";
import { MobileModalListItem } from "../tableComponents";

const useRenameDeviceInfo = device => {
  const dispatch = useDispatch();

  const { devices } = useDevicesTabContext();

  const edit = () => {
    ModalService.show(modal => ({
      dialog: (
        <RenameDeviceDialog modal={modal} device={device} devices={devices} />
      ),
    }))
      .then(changes => dispatch(editLogicalDevice(device, changes)))
      .catch(_.noop);
  };

  return {
    content: <FormattedMessage id="rename" />,
    action: edit,
    id: "rename",
  };
};

export const RenameDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, id } = useRenameDeviceInfo(device);

  return (
    <LazyLoadDropdownItem action={action} id={id} {...props}>
      {content}
    </LazyLoadDropdownItem>
  );
};

export const RenameDeviceModalItem = ({ device }) => {
  const { content, action, id } = useRenameDeviceInfo(device);

  return <MobileModalListItem content={content} action={action} id={id} />;
};
