import { getSubscriberId } from "../../info";
import { Api } from "../../../api";
import { isFailure, RequestsCollection } from "@sportal/api";
import {
  LOAD_REQUESTS,
  LOAD_REQUESTS_FAILURE,
  LOAD_REQUESTS_SUCCESS,
  RequestsActionTypes,
} from "./requests.types";
import { getRequests } from "../../root.selectors";
import { isLoading } from "../../shared/withLoadingState";
import { createTempNotification } from "../../../components/notifications";

export const loadRequests = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  if (isLoading(getRequests(getState()))) {
    return;
  }

  dispatch({ type: LOAD_REQUESTS });
  const subscriberId = getSubscriberId(getState());
  const result = await api.deviceRequests.getRequests(subscriberId);

  if (isFailure(result)) {
    dispatch(loadRequestsFailure(result.error));
    return;
  }

  dispatch(loadRequestsSuccess(result.data));
};

const loadRequestsSuccess = (
  requests: RequestsCollection
): RequestsActionTypes => ({
  type: LOAD_REQUESTS_SUCCESS,
  payload: requests,
});

const loadRequestsFailure = error => dispatch => {
  dispatch({
    type: LOAD_REQUESTS_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "failed_fetch_devices_data",
    })
  );
};
