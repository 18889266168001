import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Icon } from "@sportal/cdk";
import { useToggle } from "@sportal/cdk/hooks";
import { Dropdown } from "../dropdown/Dropdown";
import { sameWidthAsParent } from "@sportal/cdk/popper/popper.modifiers";

import "./PauseInternetDropdown.scss";

export function PauseInternetDropdown({
  items,
  label,
  hiddenLabel,
  renderToggle,
}) {
  const [open, toggleOpenState] = useToggle(false);

  return (
    <Dropdown
      label={label}
      hiddenLabel={hiddenLabel}
      onDropdownToggle={toggleOpenState}
      toggleButton={renderToggle({
        icon: (
          <Icon
            icon={classnames("far fa-angle-down", {
              "icon--reverse": open,
            })}
          />
        ),
      })}
      items={items}
      popperOptions={{
        modifiers: [sameWidthAsParent],
      }}
    />
  );
}

PauseInternetDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node,
      action: PropTypes.func,
    })
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderToggle: PropTypes.func.isRequired,
};
