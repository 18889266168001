import React from "react";

import { BaseHeader } from "../../../components/layout/headerComponents/baseHeader/BaseHeader";
import { HeaderLogo } from "../../../components/layout/headerComponents/headerLogo/HeaderLogo";
import { Mobile, NotMobile } from "../../../common/Responsive";
import { HeaderTitle } from "../../../components/layout/headerComponents/headerTitle/HeaderTitle";

import "./LoginHeader.scss";

export const LoginHeader = () => (
  <BaseHeader className="login-header">
    <NotMobile>
      <div className="login-header__content-left">
        <HeaderLogo />
        <HeaderTitle />
      </div>
    </NotMobile>
    <Mobile>
      <div className="login-header__content-left">
        <HeaderTitle />
      </div>
    </Mobile>
  </BaseHeader>
);
