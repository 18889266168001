import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getToggles } from "../../../components/scheduler/scheduler.helpers";
import { Switcher } from "../../../components";
import { Mobile } from "../../../common/Responsive";
import { UnsavedChanges } from "./UnsavedChanges";

import "./ProfileSectionHeaderForm.scss";

export const ProfileSectionHeaderForm = ({
  type,
  title,
  disabled,
  hasChanges,
  checked,
  onChange,
}) => {
  const showToggles = useSelector(getToggles, shallowEqual);
  const headerId = `${type}-id`;
  const switchId = `${type}-switch-id`;

  return (
    <div className="profile-section__header-form">
      <div className="profile-section__header-form-title">
        <h3 id={headerId}>{title}</h3>
        <Mobile>{hasChanges && <UnsavedChanges />}</Mobile>
      </div>
      {showToggles.includes(type) && (
        <Switcher
          id={switchId}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          aria-labelledby={headerId}
        />
      )}
    </div>
  );
};

ProfileSectionHeaderForm.propTypes = {
  type: PropTypes.oneOf(["webfilters", "internet-off", "homework"]).isRequired, // homework-time => homework
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
