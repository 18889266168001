import * as _ from "lodash";
import { isValidMac, macToOui } from "@sportal/lib";

import { success, SuccessResult } from "../../shared";
import {
  IManufacturerProvider,
  IManufacturerService,
  Manufacturer,
  ManufacturersMap,
} from "./manufacturer.types";

function makeCache() {
  let cache: { [oui: string]: string | undefined } = {};

  return {
    get: (mac: string) => cache[macToOui(mac)],
    set: (mac: string, name: string) => {
      cache[macToOui(mac)] = name;
    },
    has: (mac: string) => cache.hasOwnProperty(macToOui(mac)),
  };
}

export class ManufacturerService implements IManufacturerService {
  private readonly cache = makeCache();

  constructor(private provider: IManufacturerProvider) {}

  public async get(
    identifiers: string[]
  ): Promise<SuccessResult<ManufacturersMap>> {
    const macAddresses = identifiers.filter(isValidMac);

    const macAddressesToLoad = macAddresses.filter(mac => !this.cache.has(mac));

    if (!_.isEmpty(macAddressesToLoad)) {
      await this.doRequest(macAddressesToLoad);
    }

    return success(this.getFromCache(macAddresses));
  }

  private async doRequest(macAddresses: string[]): Promise<void> {
    try {
      const { data: manufacturers } = await this.provider.get(
        _.uniqBy(macAddresses, macToOui)
      );
      this.updateCache(manufacturers);
    } catch {}
  }

  private updateCache(manufacturers: Manufacturer[]): void {
    manufacturers.forEach(({ id, name }) => this.cache.set(id, name));
  }

  private getFromCache(macAddresses: string[]): ManufacturersMap {
    return _.transform(
      macAddresses,
      (result, mac) => {
        result[mac] = this.cache.get(mac);
      },
      {}
    );
  }
}
