import { combineReducers } from "redux";

import { wizardProfilesReducer } from "./profiles/profiles.reducer";
import { FINISH_WIZARD } from "./wizard.actions";

const featureReducers = combineReducers({
  profiles: wizardProfilesReducer,
});

export const wizardReducer = (state, action) => {
  return featureReducers(
    action.type === FINISH_WIZARD ? undefined : state,
    action
  );
};
