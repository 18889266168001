import React, { FC, ReactElement } from "react";

import "./TFoot.scss";

interface TFootProps {
  content: ReactElement;
}

export const TFoot: FC<TFootProps> = ({ content }) => {
  return (
    <div role="rowgroup" className="tfoot">
      <div role="row" className="tr">
        <div role="cell" className="td">
          {content}
        </div>
      </div>
    </div>
  );
};
