import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { punyToUnicode } from "@sportal/lib";

import {
  showCountsInTooltip,
  showTopDataForReports,
} from "../../../store/config";
import { ReportTypes } from "../../../store/reports";
import { useReportContext } from "../ReportProvider";
import { useTooltipContent } from "./useTooltipContent";
import { FormattedDateTimeRage } from "../../formattedDateTime/FormattedDateTime";

import "./BarChartTooltip.scss";

export const BarChartTooltip = ({ container, is24, duration, tz }) => {
  const { displayedReports } = useReportContext();
  const showCounts = useSelector(showCountsInTooltip);
  const showTop = useSelector(showTopDataForReports);
  const tooltip = useTooltipContent(container);

  if (!tooltip) return null;

  const {
    activeSlice: time,
    activePayload: data,
    activeCoordinate: { x },
  } = tooltip;

  const isReportDisplayed = key => displayedReports.includes(key);
  const isActivityDisplayed = isReportDisplayed("activity");
  const isBlocksDisplayed = isReportDisplayed("blocks");
  const isSSDisplayed = isReportDisplayed("ss");

  const areCountsDisplayed = showCounts && (isBlocksDisplayed || isSSDisplayed);
  const isTopDisplayed = showTop && (isActivityDisplayed || isBlocksDisplayed);

  return (
    <div
      className={"report__tooltip report-tooltip"}
      style={{ left: Math.ceil(x) }}
    >
      <FormattedDateTimeRage
        time={time}
        is24={is24}
        duration={duration}
        tz={tz}
      />
      {areCountsDisplayed && (
        <div className={"report-tooltip__counts"}>
          {isBlocksDisplayed && renderCountBlocks(data)}
          {isSSDisplayed && renderCountSSBlocks(data)}
        </div>
      )}
      {isTopDisplayed && (
        <div className={"report-tooltip__top"}>
          {isActivityDisplayed && renderTopActivity(data)}
          {isBlocksDisplayed && renderTopBlocks(data)}
        </div>
      )}
    </div>
  );
};

const renderTopActivity = data =>
  renderTop(data[ReportTypes.ActivityTop], "top_visited");
const renderTopBlocks = data =>
  renderTop(data[ReportTypes.BLocksTop], "top_blocked");

const renderCountBlocks = data =>
  renderCount(data[ReportTypes.Blocks], "blocked_activity");
const renderCountSSBlocks = data =>
  renderCount(data[ReportTypes.SS], "malware_and_phishing_blocked");

const renderTop = (data, translationKey) => {
  const top = _.fromPairs(data);

  if (_.isEmpty(top)) return null;

  return (
    <div className={"list"}>
      <div className={"list__header"}>
        <FormattedMessage id={translationKey} />
      </div>
      <div className={"list__content"}>
        {_.map(top, (count, node) => (
          <div key={node} className={"list__item"}>
            {punyToUnicode(node)}
          </div>
        ))}
      </div>
    </div>
  );
};
const renderCount = (count, translationKey) => {
  if (!count) return null;
  return (
    <div className={"count"}>
      <div className={"count__name"}>
        <FormattedMessage id={translationKey} />
        {":"}
      </div>
      <div className={"count__value"}>{count}</div>
    </div>
  );
};
