import { HttpService, HttpServiceResponse } from "../shared";
import { Durations, ReportData } from "./reports.types";

export class ReportsProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/series`;
  }

  public get(
    endpoint: string,
    subscriberId: string,
    duration: Durations,
    profileName?: string
  ): Promise<HttpServiceResponse<ReportData>> {
    const profile = profileName ? `&group_name=${profileName}` : "";

    return this.http.get(
      `${this.url}/${endpoint}?view=${subscriberId}&duration=${duration}${profile}&adjust_granularity=true`
    );
  }
}
