import {
  AccountProvider,
  AccountService,
  ControlProvider,
  HttpService,
} from "@sportal/api";

export const makeAccountService = (
  http: HttpService,
  baseUrl: string
): AccountService =>
  new AccountService(
    new AccountProvider(http, baseUrl),
    new ControlProvider(http, baseUrl)
  );
