import React, { ReactElement, VFC } from "react";

import { InputLimit } from "./InputLimit";

interface InputLabelProps {
  label?: string | ReactElement;
  maxLength?: number | string;
  showLimit?: boolean;
  id?: string;
  value?: string;
}

export const InputLabel: VFC<InputLabelProps> = ({
  label,
  maxLength,
  showLimit,
  id,
  value,
}) => {
  return (
    <div className={"input__label-wrapper"}>
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      )}

      {showLimit && <InputLimit value={value} limit={Number(maxLength)} />}
    </div>
  );
};
