import React from "react";
import { useSelector } from "react-redux";
import { ConfigLink } from "../../../configLink/ConfigLink";
import { getLogoLink } from "../../../../store/config";

import "./HeaderLogo.scss";

export const HeaderLogo = () => {
  const logoLink = useSelector(getLogoLink);

  return (
    <ConfigLink to={logoLink} className="header-logo">
      <img
        className="header-logo__image"
        src="images/logo_header.svg"
        alt="Logo"
      />
    </ConfigLink>
  );
};
