export const CHANGE_PROTECTION_LEVEL = "[PROTECTIONS] CHANGE_PROTECTION_LEVEL";
export const changeProtectionLevel = protection => ({
  type: CHANGE_PROTECTION_LEVEL,
  payload: { protection },
});

export const SET_CUSTOM_PROTECTION = "[PROTECTIONS] SET_CUSTOM_PROTECTION";
export const setCustomProtection = blocked => ({
  type: SET_CUSTOM_PROTECTION,
  payload: { blocked },
});
