import React, { useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";

import {
  ModalDialog,
  CommonModalHeader,
  CommonModalFooter,
  ModalHint,
} from "../modal";
import { Checkbox } from "../checkbox/Checkbox";
import { getClearDataOptions } from "./clearDataModal.selectors";

import "./ClearDataModal.scss";

const reducer = (state, key) => {
  return {
    ...state,
    [key]: !state[key],
  };
};

export const ClearDataModal = ({ modal }) => {
  const options = useSelector(getClearDataOptions);

  const [checkboxes, toggleOption] = useReducer(reducer, options, options =>
    _.chain(options)
      .keyBy("value")
      .mapValues(option => option.isChecked)
      .value()
  );

  const isSubmitDisabled = _.every(checkboxes, item => !item);
  const metrics = _.keys(_.pickBy(checkboxes));

  const close = () => modal.reject();
  const submit = () => modal.resolve(metrics);

  return (
    <ModalDialog
      submitHandler={submit}
      rejectHandler={close}
      disabled={isSubmitDisabled}
      className="clear-data-modal"
    >
      <CommonModalHeader>
        <FormattedMessage id="clear_usage_data" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <fieldset>
          <ModalHint>
            <FormattedMessage id="select_data_to_remove" />
          </ModalHint>

          {options.map(({ label, value }) => (
            <Checkbox
              id={`clear-data-${value}`}
              key={value}
              data-value={value}
              label={<FormattedMessage id={label} />}
              checked={checkboxes[value]}
              onChange={() => toggleOption(value)}
              className="clear-data-modal__data-history"
            />
          ))}
        </fieldset>
      </ModalDialog.Body>
      <CommonModalFooter
        submitButtonColor="danger"
        submitMessage={<FormattedMessage id="clear" />}
      />
    </ModalDialog>
  );
};
