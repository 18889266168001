import _ from "lodash";
import { HttpService, HttpServiceResponse } from "../shared";

export class AuthProvider {
  private get url(): string {
    return `${this.baseUrl}`;
  }

  constructor(private http: HttpService, private baseUrl: string) {}

  public login(
    username: string,
    password: string
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(
      `${this.url}/login`,
      this.stringifyQueryParams({ username, password }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }

  public logout(): Promise<HttpServiceResponse<void>> {
    return this.http.post(`${this.url}/logout`);
  }

  private stringifyQueryParams(params: { [key: string]: string }): string {
    return _.toPairs(params)
      .map(pair => pair.join("="))
      .join("&");
  }
}
