import React from "react";

import { Mobile, NotMobile } from "../../../common/Responsive";
import { BaseHeader } from "../../../components/layout/headerComponents/baseHeader/BaseHeader";
import { HeaderLogo } from "../../../components/layout/headerComponents/headerLogo/HeaderLogo";
import { HeaderTitle } from "../../../components/layout/headerComponents/headerTitle/HeaderTitle";
import { WizardHeaderUserMenu } from "../../../components/layout/headerComponents/headerUserMenu/WizardHeaderUserMenu";
import { useWizardNavigationContext } from "../WizardNavigationContext";
import { GoPrevStep } from "../goPrevStepButton/GoPrevStepButton";

import "./WizardHeader.scss";

export const WizardHeader = () => {
  const { goToPrevStep } = useWizardNavigationContext();

  return (
    <BaseHeader className="wizard-header">
      <NotMobile>
        <div className="wizard-header__content-left">
          <HeaderLogo />
          <HeaderTitle />
        </div>
      </NotMobile>
      <Mobile>
        <div className="wizard-header__content-left">
          <GoPrevStep onClick={goToPrevStep} />
        </div>
      </Mobile>
      <div className="wizard-header__content-right">
        <WizardHeaderUserMenu />
      </div>
    </BaseHeader>
  );
};
