import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { useExternalLink } from "../../../externalLink/ExternalLink";
import { HeaderUserMenu } from "./HeaderUserMenu";
import { getSubscriberName } from "../../../../store/info";
import { getNavigationSettings } from "../../../../store/shared/navigationSettings.selectors";
import { getMenuButtons } from "../../../../store/config";
import { useLogoutMenuItem } from "./useLogoutMenuItem";
import { ModalService } from "../../../modal";
import { ClearDataModal } from "../../../clearDataModal/ClearDataModal";
import { clearHistory } from "../../../../store/clearHistory";
import { getClearDataOptions } from "../../../clearDataModal/clearDataModal.selectors";

export const CommonHeaderUserMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openExternalLink] = useExternalLink();

  const settingLinks = useSelector(getNavigationSettings);
  const configLinks = useSelector(state => getMenuButtons(state) || []);
  const subscriberName = useSelector(getSubscriberName);
  const [logoutMenuItem, sidebarLogoutMenuItem] = useLogoutMenuItem(
    subscriberName
  );
  const clearDataOption = useSelector(getClearDataOptions);

  const pushHistoryItem = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const clearData = useCallback(() => {
    ModalService.show(modal => ({
      dialog: <ClearDataModal modal={modal} />,
    }))
      .then(metrics => dispatch(clearHistory(metrics)))
      .catch(() => null);
  }, [dispatch]);

  const commonMenuItems = useMemo(() => {
    const clearDataItem = {
      className: "clear-data",
      content: <FormattedMessage id="clear_data" />,
      action: clearData,
      id: "clear-data",
    };

    if (_.isEmpty(clearDataOption)) {
      return [];
    }

    return [clearDataItem];
  }, [clearDataOption, clearData]);

  const menuItems = useMemo(() => {
    const settingsItems = settingLinks.map(({ id, url, translationKey }) => ({
      id,
      content: <FormattedMessage id={translationKey} />,
      action: () => pushHistoryItem(`/settings${url}`),
    }));

    const configItems = configLinks
      .filter(({ href, text }) => href && text)
      .map(({ href, text }) => ({
        id: text,
        content: <FormattedMessage id={text} />,
        action: () => openExternalLink(href),
      }));

    return [...configItems, ...settingsItems, ...commonMenuItems];
  }, [
    settingLinks,
    configLinks,
    openExternalLink,
    pushHistoryItem,
    commonMenuItems,
  ]);

  return (
    <HeaderUserMenu
      dropdownItems={[...menuItems, logoutMenuItem]}
      sidebarItems={[sidebarLabelMenuItem, ...menuItems, sidebarLogoutMenuItem]}
      subscriberName={subscriberName}
    />
  );
};

const sidebarLabelMenuItem = {
  content: <FormattedMessage id="settings" />,
  id: "settings",
};
