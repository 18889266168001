import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { useMount } from "@sportal/cdk/hooks";
import { getNewRequests, loadRequests } from "../../../../../store/devices";
import { DevicesCounter } from "../devicesCounter/DevicesCounter";
import { DeviceGroup, DeviceGroupHeader } from "../deviceGroup";
import { RequestsTable, RequestsTableMobile } from "./components";
import { Mobile, NotMobile } from "../../../../../common/Responsive";
import { useIntlTranslate } from "../../../../../hooks";
import { DeviceCodeHint } from "../../../../../components";

import "./RequestsSection.scss";

const NewRequestsCounter = ({ amount }) => (
  <DevicesCounter
    className="new-requests-counter"
    variant="primary"
    label={<FormattedMessage id="requests" />}
    amount={amount}
  />
);

const DiscoveredDevicesCounter = ({ amount }) => (
  <DevicesCounter
    className="new-requests-counter"
    variant="outlined"
    label={<FormattedMessage id="discovered" />}
    amount={amount}
  />
);

// Missed values should go down
const sortRequests = requests =>
  _.orderBy(requests, ({ lastSeen = 0 }) => lastSeen, "desc");

const mapDevice = device => ({
  name: device.name,
  code: device.code,
  manufacturer: device.manufacturer,
  networkId: device.id,
  lastSeen: device.lastSeen,
  withName: device.withName,
  type: device.type,
});

const mapWithName = device => ({ ...device, withName: true });

export const RequestsSection = ({ isAdvancedView }) => {
  const translate = useIntlTranslate();
  const tableName = translate("new_devices");

  const dispatch = useDispatch();
  useMount(() => {
    dispatch(loadRequests());
  });

  const { withName, withCode, discovered } = useSelector(
    getNewRequests,
    _.isEqual
  );

  const devices = [
    ...sortRequests(withName).map(mapWithName),
    ...sortRequests(withCode),
    ...sortRequests(discovered),
  ].map(mapDevice);

  return (
    <div className="requests-section" data-id="requests">
      <DeviceGroup>
        <DeviceGroupHeader className="requests-section__header">
          <FormattedMessage id="new_devices" />
          <NewRequestsCounter amount={_.size(withName)} />
          <DiscoveredDevicesCounter
            amount={_.size(withCode) + _.size(discovered)}
          />
        </DeviceGroupHeader>
        <NotMobile>
          <RequestsTable
            devices={devices}
            isAdvancedView={isAdvancedView}
            tableName={tableName}
          />
        </NotMobile>
        <Mobile>
          <DeviceCodeHint />
          <RequestsTableMobile
            devices={devices}
            isAdvancedView={isAdvancedView}
            tableName={tableName}
          />
        </Mobile>
      </DeviceGroup>
    </div>
  );
};
