import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { UrlLists } from "../../../../components/urlLists/UrlLists";
import { GLOBAL_URL_FILTERS } from "../../../../store/lists/lists.actions";
import { useLists } from "../../../../components/urlLists/useLists";
import { AllowListOverrideWarning, TabHeader } from "../../../../components";
import { SettingsTabTitle } from "../../settingsTabTitle/SettingsTabTitle";
import { UrlCheck } from "./urlCheck/UrlCheck";
import { isAllowListOverrideEnabled } from "../../../../store/settings";

export const UrlFiltersTab = () => {
  useLists(GLOBAL_URL_FILTERS);

  const showALOWarning = useSelector(isAllowListOverrideEnabled);

  return (
    <Fragment>
      <TabHeader>
        <SettingsTabTitle tabName="black_white_list" />
      </TabHeader>
      {showALOWarning && <AllowListOverrideWarning />}
      <UrlCheck profileId={GLOBAL_URL_FILTERS} />
      <UrlLists profileId={GLOBAL_URL_FILTERS} />
    </Fragment>
  );
};
