import React, { ReactElement, VFC } from "react";
import { FormattedMessage } from "react-intl";
import { createPortal } from "react-dom";
import classnames from "classnames";

import {
  CrossButton,
  PopperPlacement,
  useOutsideClick,
  useToggle,
} from "@sportal/cdk";
import { useTooltipPosition } from "@sportal/cdk/tooltip";
import { useCollapseOnScroll } from "@sportal/cdk/hooks/useCollapseListener";

import "./Popover.scss";

interface PopoverProps {
  visible?: boolean;
  placement: PopperPlacement;
  popoverToggle: ReactElement;
  popoverTitle: string;
  popoverBody: ReactElement;
  hasCloseIcon?: boolean;
  isAttachedToRoot?: boolean;
  popoverClassName?: string;
}

export const Popover: VFC<PopoverProps> = ({
  hasCloseIcon = true,
  visible = false,
  placement,
  popoverToggle,
  popoverTitle,
  popoverBody,
  popoverClassName,
  ...attrs
}) => {
  const rootElement = document.querySelector("#root") || document.body;

  const {
    setReferenceElement,
    setPopperElement,
    setArrowElement,
    styles,
    attributes,
  } = useTooltipPosition(placement);
  const [opened, toggle] = useToggle(visible);
  const wrapperRef = useOutsideClick(toggle, opened);
  const overlayClasses = classnames("popover__content", popoverClassName);

  useCollapseOnScroll(true, () => toggle(false), opened);

  const overlay = (
    <div
      className={overlayClasses}
      ref={setPopperElement}
      style={styles.popper}
      aria-hidden={!opened}
      aria-live={"polite"}
      {...attributes.popper}
    >
      <div className={"popover__header"}>
        <h5>
          <FormattedMessage id={popoverTitle} />
        </h5>
      </div>
      <div className={"popover__body"}>{popoverBody}</div>
      <div
        className={"popover__arrow"}
        ref={setArrowElement}
        style={styles.arrow}
      />
      {hasCloseIcon && (
        <CrossButton
          className="popover__close"
          size="small"
          weight="regular"
          onClick={toggle}
          aria-hidden={true}
        />
      )}
    </div>
  );

  return (
    <div className={"popover"} ref={wrapperRef} {...attrs} role={"tooltip"}>
      <div
        className={"popover__toggle"}
        onClick={toggle}
        ref={setReferenceElement}
        aria-expanded={opened}
        role={"button"}
      >
        {popoverToggle}
      </div>
      {opened && createPortal(overlay, rootElement)}
    </div>
  );
};
