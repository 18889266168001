import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ProfileCards, withProfiles } from "../../../components";
import { getProfilesWithDevicesCount } from "../../../store/profiles";
import { useLoadableSlice } from "../../../store/shared/withLoadingState";
import { loadLogicalDevices } from "../../../store/devices";
import { getLogicalDevices } from "../../../store/root.selectors";

export const HouseholdContentComponent = () => {
  useLoadableSlice(loadLogicalDevices(), getLogicalDevices);

  const profiles = useSelector(getProfilesWithDevicesCount);

  return (
    <div className="household-content">
      <h3 className={"household-content__title"}>
        <FormattedMessage id={"using_single_profile"} />
      </h3>
      <p className={"household-content__description"}>
        <FormattedMessage id={"same_level_of_protection"} />
      </p>
      <ProfileCards profiles={[profiles[0]]} availableProfilesNumber={0} />
    </div>
  );
};

export const HouseholdContent = withProfiles(HouseholdContentComponent);
