import React from "react";
import classNames from "classnames";
import "./MaskSwitchIcon.scss";

/**
 * This components serves as mask layout for radio / checkbox / switch, etc.
 *
 * Styles are defined by class, because each input type will have it's own look
 */

function MaskSwitchIcon({ className }) {
  return <span className={classNames("mask-icon", className)} />;
}

export default MaskSwitchIcon;
