import React, { InputHTMLAttributes, VFC } from "react";
import classNames from "classnames";

import "./BaseInput.scss";

interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
}

export const BaseInput: VFC<BaseInputProps> = ({
  className,
  type = "text",
  hidden,
  onChange,
  id,
  ...attrs
}) => {
  return (
    <input
      className={classNames(
        "base-input",
        { "base-input--hidden": hidden },
        className
      )}
      type={type}
      onChange={onChange}
      {...attrs}
      data-testid="base-input"
      id={id}
    />
  );
};
