import _ from "lodash";
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useHistory } from "react-router";

export const WizardPaths = {
  ProtectionChoice: "protection-choice",
  Single: "single",
  Multiple: "multiple",
  Protection: "multiple/protection",
  Devices: "multiple/devices",
};

export const WizardNavigationContext = createContext({});

const SingleWizardNavigationChain = [
  WizardPaths.ProtectionChoice,
  WizardPaths.Single,
];
const MultipleWizardNavigationChain = [
  WizardPaths.ProtectionChoice,
  WizardPaths.Multiple,
  WizardPaths.Protection,
  WizardPaths.Devices,
];
const getStepFromLocation = location =>
  location.pathname.replace("/wizard/", "");

export const WizardNavigationContextProvider = ({ children }) => {
  const history = useHistory();
  const [step, setStep] = useState(WizardPaths.ProtectionChoice);
  const [navigationChain, updateNavigationChain] = useState(
    MultipleWizardNavigationChain
  );

  useEffect(() => {
    const stepFromLocation = getStepFromLocation(history.location);

    if (step !== stepFromLocation) {
      history.push("/wizard/" + step);
    }
  }, [step, history]);

  const isStepInChain = step => navigationChain.includes(step);
  const getStepIndex = step => _.indexOf(navigationChain, step);
  const shouldPreventNavigation = (step, stepFromLocation) =>
    !isStepInChain(stepFromLocation) ||
    getStepIndex(stepFromLocation) > getStepIndex(step);

  useEffect(() => {
    return history.listen(location => {
      const stepFromLocation = getStepFromLocation(location);

      if (step === stepFromLocation) return;

      if (shouldPreventNavigation(step, stepFromLocation)) {
        return history.goBack();
      }

      setStep(stepFromLocation);
    });
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  const chooseNavigation = wizardType => {
    wizardType === "single"
      ? updateNavigationChain(SingleWizardNavigationChain)
      : updateNavigationChain(MultipleWizardNavigationChain);

    setStep(wizardType);
  };
  const safeSetStep = delta => step => {
    const safeIndex = _.clamp(
      getStepIndex(step) + delta,
      0,
      navigationChain.length - 1
    );

    return navigationChain[safeIndex];
  };

  const contextValue = useMemo(
    () => ({
      step: step,
      stepIndex: getStepIndex(step),
      goToPrevStep: () => setStep(safeSetStep(-1)),
      goToNextStep: () => setStep(safeSetStep(1)),
      chooseNavigation,
    }),
    [step] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <WizardNavigationContext.Provider value={contextValue}>
      {children}
    </WizardNavigationContext.Provider>
  );
};

export const useWizardNavigationContext = () =>
  useContext(WizardNavigationContext);
