import { getSubscriberId } from "../../info";
import { isFailure, Line } from "@sportal/api";
import { normalizeCollection } from "../../shared/normalizeCollection";
import {
  LOAD_LINES,
  LOAD_LINES_FAILURE,
  LOAD_LINES_SUCCESS,
} from "./lines.types";
import { Api } from "../../../api";
import { createTempNotification } from "../../../components/notifications";

export const loadLines = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_LINES });
  const subscriberId = getSubscriberId(getState());
  const result = await api.lines.get(subscriberId);

  if (isFailure(result)) {
    dispatch(loadLinesFailure(result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description: "failed_fetch_lines_data",
      })
    );
    return;
  }

  dispatch(loadLinesSuccess(result.data));
};

export const loadLinesSuccess = (lines: Line[]) => ({
  type: LOAD_LINES_SUCCESS,
  payload: normalizeCollection(lines, "id"),
});

export const loadLinesFailure = error => ({
  type: LOAD_LINES_FAILURE,
  payload: error,
});
