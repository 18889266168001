import { makeAuthService } from "./authService";
import { makeSettingsService } from "./settingsService";
import { makeAccountService } from "./accountService";
import { makeAttributesService } from "./attributesService";
import { makeLogicalDevicesService } from "./logicalDevicesService";
import { makeLinesService } from "./linesService";
import { makeTimezoneService } from "./timezoneService";
import { makeTranslationService } from "./translationService";
import { makeProfilesService } from "./profilesService";
import { makeProtectionService } from "./protectionService";
import { makeDeviceRequestsService } from "./deviceRequestsService";
import { makeReportsService } from "./reportsService";
import { makeThreatsService } from "./threatsService";
import { makeListsService } from "./listsService";
import { makeScheduleService } from "./schedulesService";
import { makeInfectionsService } from "./infectionsService";
import { makeInfectedDevicesService } from "./infectedDevicesService";
import { makeClearHistoryService } from "./clearHistoryService";
import { Api } from "./api.types";
import { HttpService } from "@sportal/api";

export const makeApi = (http: HttpService, config: any): Api => ({
  auth: makeAuthService(http, config.backendUrl),
  settings: makeSettingsService(http, config.backendUrl),
  account: makeAccountService(http, config.backendUrl),
  attributes: makeAttributesService(http, config.backendUrl),
  translations: makeTranslationService(http),
  logicalDevices: makeLogicalDevicesService(http, config.backendUrl),
  lines: makeLinesService(http, config.backendUrl),
  deviceRequests: makeDeviceRequestsService(http, config.backendUrl),
  timezones: makeTimezoneService(http),
  profiles: makeProfilesService(http, config.backendUrl),
  protection: makeProtectionService(http, config.backendUrl),
  schedules: makeScheduleService(http, config.backendUrl),
  reports: makeReportsService(http, config.backendUrl),
  lists: makeListsService(http, config),
  threats: makeThreatsService(http, config.backendUrl),
  infections: makeInfectionsService(http, config.backendUrl),
  infectedDevices: makeInfectedDevicesService(http, config.backendUrl),
  clearHistory: makeClearHistoryService(http, config.backendUrl),
});

export { makeHttpService } from "./httpService";
export { makeAppConfigService } from "./appConfigService";
export { makeTranslationService } from "./translationService";
export { Api } from "./api.types";
