import { useMemo } from "react";
import _ from "lodash";

const profileNamePattern = /^[^:/?#[\]@!$&'%()*+,;=.\\`^|"]*$/;
export const MAX_LENGTH = 30;

export const profileNameErrors = {
  MISSING: "missing",
  INVALID: "invalid",
  EXISTS: "exists",
  TOO_LONG: "tooLong",
};

const trimAndLowerCase = name => name.trim().toLowerCase();

const testProfileName = ({ name, id }, profiles) => {
  if (!name) return profileNameErrors.MISSING;

  const toLong = name.length > MAX_LENGTH;
  if (toLong) return profileNameErrors.TOO_LONG;

  const invalid = !profileNamePattern.test(name);
  if (invalid) return profileNameErrors.INVALID;

  const equalName = profiles.some(
    existingProfile =>
      existingProfile.id !== id && // omit the same profile
      trimAndLowerCase(existingProfile.name) === trimAndLowerCase(name)
  );
  if (equalName) return profileNameErrors.EXISTS;
};

export const useProfileErrors = (profiles, proneToErrorsProfiles) => {
  const errors = useMemo(() => {
    let errorsMessages = {};

    proneToErrorsProfiles.forEach(profile => {
      const error = testProfileName(profile, profiles);

      if (error) {
        errorsMessages[profile.id] = error;
      }
    });

    return errorsMessages;
  }, [profiles, proneToErrorsProfiles]);

  return [errors, !_.isEmpty(errors)];
};
