import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Icon } from "@sportal/cdk";

import "./WizardStep.scss";

export function WizardStep({ stepNumber, title, isActive, isCompleted }) {
  const wizardStepClasses = classnames("wizard-step", {
    "wizard-step--active": isActive,
    "wizard-step--disabled": !isActive && !isCompleted,
    "wizard-step--completed": isCompleted,
  });

  return (
    <li className={wizardStepClasses}>
      <span className="round-tab">
        {isCompleted ? <Icon icon="fas fa-check completed-icon" /> : stepNumber}
      </span>
      <span className="wizard-step__title">
        <FormattedMessage id={title} />
      </span>
    </li>
  );
}

WizardStep.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};
