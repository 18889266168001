import React from "react";
import { FormattedMessage } from "react-intl";

import "./HeaderTitle.scss";

export const HeaderTitle = () => (
  <span className="title">
    <FormattedMessage id="subscriber_portal" />
  </span>
);
