import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { BaseButton, Icon } from "@sportal/cdk";
import { PauseInternet } from "../../../pauseInternet/PauseInternet";
import { usePauseInternet } from "../../../pauseInternet/PauseInternetProvider";

import "./ProfileCardMobile.scss";

export function ProfileCardMobile({
  profileId,
  profileName,
  ageGroup,
  protectionLevel,
  devicesCount,
  className,
  isDefault,
}) {
  const { isInternetPaused } = usePauseInternet(usePauseInternet);

  const themeKey = isDefault ? "default" : ageGroup;
  const cardTheme = classnames("mobile-card", `${themeKey}__theme`, className, {
    "mobile-card__internet-paused": isInternetPaused,
  });

  return (
    <div className={cardTheme}>
      <header className="mobile-card__header">
        <span className="mobile-card__image" />
        <div className="mobile-card__info">
          <h3 className="mobile-card__title">{profileName}</h3>
          <p>{ageGroup && <FormattedMessage id={ageGroup} />}</p>
        </div>
        <Link
          to={`/profile/${profileId}`}
          className="mobile-card__arrow-button"
        >
          <BaseButton>
            <Icon icon="far fa-arrow-alt-circle-right" />
          </BaseButton>
        </Link>
      </header>
      <div className="mobile-card__content">
        <p className="mobile-card__protection-level">
          <FormattedMessage
            id="protection_level_message"
            values={{
              message: (
                <span className="strong">
                  <FormattedMessage id={protectionLevel} />
                </span>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="devices_count"
            values={{
              message: <span className="strong">{devicesCount}</span>,
            }}
          />
        </p>
        <div className="mobile-card__button">
          <PauseInternet profileName={profileName} />
        </div>
      </div>
    </div>
  );
}

ProfileCardMobile.defaultProps = {
  isInternetPaused: false,
};

ProfileCardMobile.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  protectionLevel: PropTypes.string.isRequired,
  devicesCount: PropTypes.number.isRequired,
  ageGroup: PropTypes.oneOf(["default", "adults", "teens", "kids"]),
  className: PropTypes.string,
  isDefault: PropTypes.bool,
};
