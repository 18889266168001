import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";

import { Dropdown, Icon } from "@sportal/cdk";

import "./UserMenuDropdown.scss";

const UserMenuHeader = forwardRef(({ subscriberName, ...rest }, ref) => (
  <button ref={ref} {...rest} className="user-menu-dropdown__toggle">
    <span className="user-menu-dropdown__toggle__name">{subscriberName}</span>
    <Icon icon="fas fa-cog" />
  </button>
));

export const UserMenuDropdown = ({ items, subscriberName }) => (
  <Dropdown
    items={items}
    toggleButton={<UserMenuHeader subscriberName={subscriberName} />}
    className="user-menu-dropdown"
    hiddenLabel={<FormattedMessage id={"user_menu_label"} />}
  />
);
