import _ from "lodash";

import { getBackendUrl, getLogoutUrl } from "../config";
import { isSSO } from "../info";
import { isLogoutUrlDefault } from "../../api/appConfigService";
import { createTempNotification } from "../../components/notifications";

const LOGOUT = "[AUTH] LOGOUT";
export const logout = (reason, doReload = true) => (dispatch, getState) => {
  dispatch({ type: LOGOUT });

  const action = isSSO(getState())
    ? ssoLogout()
    : noSsoLogout(reason, doReload);
  dispatch(action);
};

const NO_SSO_LOGOUT = "[AUTH] NO_SSO_LOGOUT";
const noSsoLogout = (reason, doReload) => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: NO_SSO_LOGOUT });

  // /logout request is obligatory for any case
  const result = await api.auth.logout();
  if (result.success === false) {
    // TODO: We can get success === false here in 2 cases
    //  1. Logout explicitly failed. It actually means something
    //  terrific happened on BE and probably user's session is terminated anyway
    //  so we can safely redirect the user to login page and get /info
    //  2. Request failed by timeout and we do not know if it reached BE.
    //  We can pretend that everything is fine (and we actually do atm)
    //  but since session id is in cookie user can refresh the page and
    //  continue to work with the portal.
    //  ! For the current implementation it means that AuthGuard passes
    //  so it should work as before
    //  ! We could use storage item and try to perform a logout
    //  at the very beginning of loading
  }

  const strategy = doReload
    ? reloadStrategies.withReload
    : reloadStrategies.withoutReload;

  dispatch(strategy(reason));
};

const reloadStrategies = {
  // Reload should be performed in order to wipe state and get fresh data from BE
  withReload: reason => (dispatch, getState, { storage, location }) => {
    const logoutUrl = getLogoutUrl(getState());
    // default value for logout url is internal path, in that case we don't want to assign
    if (!isLogoutUrlDefault(logoutUrl)) {
      location.assign(logoutUrl);
      return;
    }

    if (!_.isEmpty(reason)) {
      storage.setItem(storage.Keys.logoutReason, reason);
    }
    location.reload(true);
  },
  // Reload is not needed when the user stays on login page or will see a error page
  withoutReload: reason => dispatch => {
    if (!_.isEmpty(reason)) {
      dispatch(
        createTempNotification({
          variant: "error",
          description: reason,
        })
      );
    }
  },
};

const SSO_LOGOUT = "[AUTH] SSO_LOGOUT";
const ssoLogout = () => (dispatch, getState, { location }) => {
  dispatch({ type: SSO_LOGOUT });

  const backendUrl = getBackendUrl(getState());
  location.assign(
    (backendUrl || "") +
      "/saml/logout?action=" +
      encodeURIComponent(location.origin + location.pathname + "#login")
  );
};
