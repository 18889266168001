import _ from "lodash";

const defaultKeyField = "id";

export const addItem = (collection, item, keyField = defaultKeyField) => ({
  keys: [...collection.keys, item[keyField]],
  list: { ...collection.list, [item[keyField]]: item },
});

export const updateItem = (collection, item, keyField = defaultKeyField) => {
  const { [keyField]: key, ...props } = item;

  return {
    keys: collection.keys,
    list: {
      ...collection.list,
      [key]: { ...collection.list[key], ...props },
    },
  };
};

export const addOrUpdateItem = (collection, item, keyField = defaultKeyField) =>
  collection.keys.includes(item[keyField])
    ? updateItem(collection, item, keyField)
    : addItem(collection, item, keyField);

export const removeItem = (collection, key) => ({
  keys: _.without(collection.keys, key),
  list: _.omit(collection.list, key),
});
