import { HouseholdContent } from "./householdContent/HouseholdContent";
import { PerDeviceContent } from "./perDeviceContent/PerDeviceContent";
import { SafetyContent } from "./safetyContent/SafetyContent";
import { isSS, isHousehold, isPISingle } from "../../store/info";

export const homePageContentSelector = state => {
  if (isSS(state)) return SafetyContent;
  if (isHousehold(state) || isPISingle(state)) return HouseholdContent;
  return PerDeviceContent;
};
