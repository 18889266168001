import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Icon, useCollapseContext } from "@sportal/cdk";
import { Mobile, NotMobile } from "../../../common/Responsive";

export const ProfileSectionFooter = ({
  footerContent,
  footer,
  footerRef,
  updateSection,
  toggleContent,
}) => {
  const { opened, toggleOpened, getToggleProps } = useCollapseContext();

  const stickyClass = classNames("profile-section__footer", {
    "profile-section__footer--fixed": opened && footer && footer.sticky,
  });

  return (
    <div
      className="profile-section__footer-wrapper"
      style={{ height: footer && footer.height }}
    >
      <div className={stickyClass} ref={footerRef}>
        <span
          {...getToggleProps({
            onToggle: () => updateSection(toggleOpened, opened),
            type: "span",
          })}
          className="profile-section__footer__toggle"
        >
          <div className="sr-only">{toggleContent}</div>
          <div aria-hidden="true">
            <Mobile>
              <Icon icon="far fa-caret-circle-up" />
            </Mobile>
            <NotMobile>
              <FormattedMessage id="collapse" /> <Icon icon="far fa-angle-up" />
            </NotMobile>
          </div>
        </span>
        {footerContent}
      </div>
    </div>
  );
};

ProfileSectionFooter.propTypes = {
  footerContent: PropTypes.node,
  footer: PropTypes.object,
  footerRef: PropTypes.object.isRequired,
  updateSection: PropTypes.func.isRequired,
  toggleContent: PropTypes.node,
};
