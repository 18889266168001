import { useEffect } from "react";

import { useIsMounted } from "../useIsMounted";

export const useCollapseOnScroll = (
  collapseOnScroll: boolean,
  onScroll: () => void,
  isOpened: boolean
): void => {
  const isMounted = useIsMounted();

  useEffect(() => {
    const collapse = () => {
      if (isMounted()) {
        onScroll();
      }
    };

    if (collapseOnScroll && isOpened) {
      window.addEventListener("scroll", collapse, true);

      return () => {
        window.removeEventListener("scroll", collapse, true);
      };
    }
  }, [collapseOnScroll, onScroll, isOpened, isMounted]);
};
