import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export function TabTitle({ className, children }) {
  return <h2 className={classnames("tab-title", className)}>{children}</h2>;
}

TabTitle.propTypes = {
  children: PropTypes.node.isRequired,
};
