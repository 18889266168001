import { useCallback, useMemo } from "react";
import _ from "lodash";

import { useBarchartTooltip } from "../useBarchartTooltip";
import { useReportContext } from "../ReportProvider";

const tooltipWidth = 200;

export function useTooltipContent(container) {
  const { data } = useReportContext();
  const index = useBarchartTooltip(state => state.index);

  //prepare x axis coordinate for positioning the tooltip
  const getTooltipCoordinate = useCallback((barXPos, barWidth) => {
    if (!container.current) return { x: barXPos };
    const box = container.current.getBoundingClientRect();
    const tooltipOffset = 2.3 * barWidth;

    if (box.right - barXPos - barWidth - tooltipOffset < tooltipWidth) {
      return { x: barXPos - tooltipOffset - tooltipWidth };
    }

    return { x: barXPos + tooltipOffset };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //prepare data for the tooltip content
  return useMemo(() => {
    if (_.isNil(index) || _.isEmpty(data) || _.isEmpty(data[index])) {
      return null;
    }

    const {
      time,
      data: sliceData,
      position: { x, width },
    } = data[index];

    return {
      activeSlice: time,
      activePayload: sliceData,
      activeCoordinate: getTooltipCoordinate(x, width),
    };
  }, [getTooltipCoordinate, data, index]);
}
