import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Loader } from "./Loader";
import { getIsLoaderShown } from "../../store/loader";

export const LoaderContainer = memo(() => {
  const isShown = useSelector(getIsLoaderShown);

  return isShown && <Loader />;
});
