import { makeStore } from "./store";
import { makePersistentStorage } from "./store/shared/persistentStorage";
import { makeHttpService, makeApi } from "./api";
import { makeAppConfigService } from "./api/appConfigService";
import { createTempNotification } from "./components/notifications";
import { applyResponseInterceptors } from "./api/httpService";

export async function initialize() {
  global.nominum = process.env.NOMINUM_INFO;

  const http = makeHttpService();

  const appConfigService = makeAppConfigService(http);
  const configResult = await appConfigService.get();
  if (!configResult.success) {
    return false;
  }
  const { data: config } = configResult;

  const storage = makePersistentStorage(window.sessionStorage);
  const logoutReason = storage.getItem(storage.Keys.logoutReason);
  storage.removeItem(storage.Keys.logoutReason);
  document.title = config.appTitle;

  const location = window.location;
  const api = makeApi(http, config);
  const store = makeStore({ api, storage, location }, { config });

  applyResponseInterceptors(http, store, config);

  if (logoutReason) {
    store.dispatch(
      createTempNotification({
        variant: "error",
        description: logoutReason,
      })
    );
  }

  return { store, api, storage };
}
