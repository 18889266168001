import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./root.reducer";
import thunk from "redux-thunk";
import { createAsyncMiddleware, loaderMiddleware } from "./middlewares";

export const makeStore = (deps, state) =>
  createStore(
    rootReducer,
    state,
    composeWithDevTools(
      applyMiddleware(
        loaderMiddleware,
        thunk.withExtraArgument(deps),
        createAsyncMiddleware(deps)
      )
    )
  );
