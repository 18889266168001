import { HttpService, HttpServiceResponse } from "../shared";
import { ThreatsServerData } from "./threats.types";

export class ThreatsProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/threat`;
  }

  public get(locale: string): Promise<HttpServiceResponse<ThreatsServerData>> {
    return this.http.get(`${this.url}?locale=${locale}`);
  }
}
