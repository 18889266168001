import React, { VFC } from "react";
import { useSelector } from "react-redux";

import { DeviceTypeTooltip } from "../../devices/deviceTypeTooltip";
import {
  getLogicalDeviceDetailsType,
  isAssignedDeviceMerged,
} from "../../../store/devices";

interface ProfileDeviceTypeTooltipProps {
  identifiers: string[];
}
export const ProfileDeviceTypeTooltip: VFC<ProfileDeviceTypeTooltipProps> = ({
  identifiers,
}) => {
  const firstDeviceType = useSelector(
    getLogicalDeviceDetailsType(identifiers[0])
  );

  return (
    <DeviceTypeTooltip
      isMerged={isAssignedDeviceMerged({ identifiers })}
      type={firstDeviceType}
    />
  );
};
