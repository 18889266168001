import React, { Children } from "react";
import classnames from "classnames";

import "./DeviceGroupHeader.scss";

import { Icon, useCollapseContext } from "@sportal/cdk";

export const DeviceGroupHeader = ({ className, children }) => {
  const { opened, getToggleProps } = useCollapseContext();

  const [title, ...content] = Children.toArray(children);

  return (
    <div
      className={classnames("device-group-header", className)}
      {...getToggleProps({ type: "div" })}
    >
      <div className="device-group-header__title">{title}</div>
      <div className="device-group-header__content">{content}</div>
      <div className="device-group-header__state-indicator">
        <Icon
          icon={classnames("far", opened ? "fa-angle-up" : "fa-angle-down")}
        />
      </div>
    </div>
  );
};
