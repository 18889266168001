import { NormalizedCollection } from "../shared/normalizeCollection";
import { Category } from "@sportal/api/protections/protection.types";

export const LOAD_CATEGORIES = "[CATEGORIES] LOAD_CATEGORIES";
export const LOAD_CATEGORIES_SUCCESS = "[CATEGORIES] LOAD_CATEGORIES_SUCCESS";
export const LOAD_CATEGORIES_FAILURE = "[CATEGORIES] LOAD_CATEGORIES_FAILURE";

interface loadCategoriesAction {
  type: typeof LOAD_CATEGORIES;
}

interface loadCategoriesSuccessAction {
  type: typeof LOAD_CATEGORIES_SUCCESS;
  payload: NormalizedCollection<Category>;
}

interface loadCategoriesFailureAction {
  type: typeof LOAD_CATEGORIES_FAILURE;
  payload: object;
}

export type CategoriesActionTypes =
  | loadCategoriesAction
  | loadCategoriesSuccessAction
  | loadCategoriesFailureAction;
