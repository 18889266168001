import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { SegmentedButton } from "./segmentedButton/SegmentedButton";

import "./SegmentedButtonsGroup.scss";

export const SegmentedButtonsGroup = ({
  label,
  className,
  buttonSize = "large",
  groupName = "segmented-button-group",
  buttons,
  selectedValue,
  onChange,
  ariaGroupLabel = "",
  hideLegendFromScreenReader = false,
}) => (
  <div
    role={"group"}
    aria-label={ariaGroupLabel}
    className={classNames("segmented-buttons-group", className)}
  >
    {label && (
      <legend
        aria-hidden={hideLegendFromScreenReader}
        className="segmented-buttons-group__label"
      >
        {label}
      </legend>
    )}
    <div className="segmented-buttons-group__block" role={"radiogroup"}>
      {buttons.map(({ value, node }) => (
        <SegmentedButton
          id={value}
          key={value}
          value={value}
          className={`segmented-button--${value} segmented-button--${buttonSize}`}
          groupName={groupName}
          checked={value === selectedValue}
          onChange={onChange}
        >
          {node}
        </SegmentedButton>
      ))}
    </div>
  </div>
);

SegmentedButtonsGroup.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  buttonSize: PropTypes.oneOf(["large", "medium", "small"]),
  groupName: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      node: PropTypes.node,
    })
  ).isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
