import { HttpService, HttpServiceResponse } from "../shared";
import { InfectionsServerData } from "./infections.types";

export class InfectionsProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url() {
    return `${this.baseUrl}/ssm/series/ss/infections`;
  }

  public get(
    subscriberId: string,
    duration: string
  ): Promise<HttpServiceResponse<InfectionsServerData>> {
    return this.http.get(
      `${this.url}?view=${subscriberId}&duration=${duration}`
    );
  }
}
