import _ from "lodash";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { ModalDialog } from "@sportal/cdk";
import { DeviceDialogInput } from "../deviceForm";
import {
  CommonModalFooter,
  CommonModalHeader,
  useForm,
} from "../../../../../components";
import { useDeviceNameValidator } from "../deviceForm";

const prepareDeviceName = _.trim;

export function RenameDeviceDialog({ modal, devices, device }) {
  const form = useForm({
    name: device.name,
  });

  const changeName = name => {
    form.set("name", name);
    form.setError("name", null);
  };

  const otherDevices = _.reject(devices, { name: device.name });
  const validateName = useDeviceNameValidator(otherDevices);
  const submit = useCallback(
    modal => {
      const name = prepareDeviceName(form.values.name);
      const nameError = validateName(name);

      if (!_.isEmpty(nameError)) {
        form.setError("name", nameError);
      } else {
        modal.resolve({ name });
      }
    },
    [form, validateName]
  );

  const close = () => modal.reject();

  const hasChanges =
    prepareDeviceName(form.values.name) !== prepareDeviceName(device.name);
  const isSubmitDisabled =
    _.isEmpty(prepareDeviceName(form.values.name)) || !hasChanges;

  return (
    <ModalDialog
      submitHandler={() => submit(modal)}
      rejectHandler={close}
      disabled={isSubmitDisabled}
    >
      <CommonModalHeader>
        <FormattedMessage id="rename_device" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <DeviceDialogInput
          label="device_name"
          placeholder="enter_device_name"
          value={form.values.name}
          onChange={changeName}
          error={form.errors.name}
          autoFocus
          maxLength="30"
          autoCapitalize="none"
          autoCorrect="off"
          spellCheck="false"
        />
      </ModalDialog.Body>
      <CommonModalFooter />
    </ModalDialog>
  );
}
