import _ from "lodash";
import {
  isLoaded,
  useConditionalLoadableSlice,
  useLoadableSlice,
} from "../../store/shared/withLoadingState";
import { getProfiles } from "../../store/root.selectors";
import {
  loadCustomProtections,
  loadSharedProtections,
} from "../../store/protection";
import {
  getCustomProtections,
  getSharedProtections,
} from "../../store/root.selectors";
import { getCategories, loadCategories } from "../../store/categories";

export const useProtections = () => {
  const categories = useLoadableSlice(loadCategories(), getCategories);
  const sharedProtections = useLoadableSlice(
    loadSharedProtections(),
    getSharedProtections
  );

  const customProtections = useConditionalLoadableSlice(
    loadCustomProtections(),
    getCustomProtections,
    _.flow([getProfiles, state => !isLoaded(state)])
  );

  return _.every([categories, sharedProtections, customProtections], Boolean)
    ? { categories, sharedProtections, customProtections }
    : null;
};
