import {
  CHANGE_PROFILE_NAME_SUCCESS,
  CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS,
  CHANGE_PROFILE_SAFE_SEARCH_SUCCESS,
  ProfileActionTypes,
} from "./profile.types";
import { SAVE_PROFILE_PROTECTION_SUCCESS } from "../protection";
import { Profile } from "@sportal/api";

export type ProfileState = Profile;

// TODO: make action type for SAVE_PROFILE_PROTECTION_SUCCESS
type ProfileReducerActionTypes =
  | ProfileActionTypes
  | {
      type: typeof SAVE_PROFILE_PROTECTION_SUCCESS;
      payload: {
        profile: Profile;
        protection: { name: string; blocked: string[] };
      };
    };

export const profileReducer = (
  state: ProfileState,
  action: ProfileReducerActionTypes
) => {
  switch (action.type) {
    case CHANGE_PROFILE_NAME_SUCCESS:
    case CHANGE_PROFILE_SAFE_SEARCH_SUCCESS:
    case CHANGE_PROFILE_PROTECTION_ENABLED_SUCCESS: {
      return {
        ...state,
        ...action.payload.updates,
      };
    }
    case SAVE_PROFILE_PROTECTION_SUCCESS: {
      return {
        ...state,
        protection: action.payload.protection.name,
      };
    }
    default:
      return state;
  }
};
