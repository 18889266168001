import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { useSelector } from "react-redux";

import { HomePage, SettingsPage, ProfilePage } from "../pages";
import { usePermanentNotifications } from "../store/shared/usePermanentNotifications/usePermanentNotifications";
import { isSS } from "../store/info";

export const AppRouter = () => {
  usePermanentNotifications();

  const isUserSS = useSelector(isSS);

  return (
    <Switch>
      <Route exact path="/home" component={HomePage} />
      <Route path="/settings" component={SettingsPage} />
      {!isUserSS && <Route path="/profile/:id" component={ProfilePage} />}
      <Redirect to="/home" />
    </Switch>
  );
};
