import _ from "lodash";
import { Protection } from "@sportal/api";

export const emptyCustom = { name: Protection.Custom, blocked: [] };

export const getProfileProtection = (
  { sharedProtections, customProtections },
  profile
) => {
  if (_.isEmpty(profile)) return;

  if (profile.protection !== Protection.Custom) {
    return sharedProtections.list[profile.protection];
  }

  return customProtections.list[profile.id] || emptyCustom;
};

export const findProtectionByCategories = ({ sharedProtections }, blocked) =>
  _.find(
    sharedProtections.list,
    ({ blocked: blockedByLevel }) => _.xor(blockedByLevel, blocked).length === 0
  );
