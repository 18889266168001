import { combineReducers } from "redux";

import infoReducer from "./info/info.reducer";
import settingsReducer from "./settings/settings.reducer";
import accountReducer from "./account/account.reducer";
import permanentNotificationsReducer from "../components/notifications/permanentNotifications/pernamentNotifications.reducer";
import temporaryNotificationsReducer from "../components/notifications/temporaryNotifications/temporaryNotifications.reducer";
import attributesReducer from "./attributes/attributes.reducer";
import {
  linesReducer,
  logicalDevicesReducer,
  requestsReducer,
  logicalDeviceDetailsReducer,
} from "./devices";
import { categoriesReducer } from "./categories";
import {
  sharedProtectionsReducer,
  customProtectionsReducer,
} from "./protection";
import { timezonesReducer } from "./timezones";
import { threatsReducer } from "./threats";
import { infectionsReducer } from "./infections";
import { infectedDevicesReducer } from "./infectedDevices";
import { LOGIN_SUCCESS } from "../pages/login/login.actions";
import { loaderReducer } from "./loader";
import { profilesReducer } from "./profiles/profiles.reducer";
import { schedulesReducer } from "./schedules";
import { wizardReducer } from "../pages/wizard";
import { reportsReducer } from "./reports";
import { listsReducer } from "./lists/lists.reducer";
import { bannersReducer } from "./banners";

const featureReducers = combineReducers({
  config: (state = {}) => state,
  info: infoReducer,
  settings: settingsReducer,
  account: accountReducer,
  categories: categoriesReducer,
  sharedProtections: sharedProtectionsReducer,
  customProtections: customProtectionsReducer,
  permanentNotifications: permanentNotificationsReducer,
  temporaryNotifications: temporaryNotificationsReducer,
  attributes: attributesReducer,
  logicalDevices: logicalDevicesReducer,
  logicalDeviceDetails: logicalDeviceDetailsReducer,
  lines: linesReducer,
  requests: requestsReducer,
  timezones: timezonesReducer,
  loader: loaderReducer,
  schedules: schedulesReducer,
  profiles: profilesReducer,
  wizard: wizardReducer,
  reports: reportsReducer,
  infections: infectionsReducer,
  infectedDevices: infectedDevicesReducer,
  threats: threatsReducer,
  lists: listsReducer,
  banners: bannersReducer,
});

type FeatureState = ReturnType<typeof featureReducers>;

export const rootReducer = (state: FeatureState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        info: infoReducer(undefined, action),
      };
    }
    default:
      return {
        ...state,
        ...featureReducers(state, action),
      };
  }
};

export type RootState = ReturnType<typeof rootReducer>;
