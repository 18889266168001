import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { MultipleWizardPage } from "../MultipleWizardPage";
import { NextStepButton } from "../nextStepButton/NextStepButton";
import { useWizardNavigationContext } from "../../WizardNavigationContext";

import { getProfilesList } from "../../store/profiles";
import { WizardProfileProtectionCards } from "./WizardProfileProtectionCards";
import { useDataForSingleWizard } from "../../single/useDataForSingleWizard";
import { WizardProfileProtection } from "./WizardProfileProtection";

import "../../wizardLayout.scss";
import "./MultipleWizardProtectionPage.scss";
import { FormattedMessage } from "react-intl";
import { WizardPageTitle } from "../wizardPageTitle/WizardPageTitle";

export const MultipleWizardProtectionPage = () => {
  const { goToNextStep } = useWizardNavigationContext();
  const data = useDataForSingleWizard();

  const profiles = useSelector(getProfilesList);
  const [openedProfile, setOpenedProfile] = useState(null);

  if (!data) return null;

  return (
    <MultipleWizardPage>
      <div className="wizard-protection">
        {!openedProfile && (
          <Fragment>
            <WizardPageTitle>
              <FormattedMessage id={"protection_setup"} />
            </WizardPageTitle>
            <WizardProfileProtectionCards
              profiles={profiles}
              onClick={profile => setOpenedProfile(profile)}
            />
          </Fragment>
        )}
        {openedProfile && (
          <WizardProfileProtection
            profile={openedProfile}
            goBack={() => setOpenedProfile()}
          />
        )}
        {!openedProfile && (
          <NextStepButton doNext={goToNextStep} nextTab={"assign_devices"} />
        )}
      </div>
    </MultipleWizardPage>
  );
};
