import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "@sportal/cdk";
import { Accordion } from "../accordion/Accordion";
import "./InfectedDevicesAccordion.scss";

export const InfectedDevicesAccordion = ({ items }) => (
  <div className="infected-devices__accordion">
    <Accordion
      items={items.map(item => ({
        header: (
          <div className="infected-device">
            <div className="infected-device__header">
              <Icon icon="fas fa-bug" />
              {item.threatName}
              {item.threatDescription && <Icon icon="fas fa-caret-down" />}
            </div>
            {item.deviceId && (
              <Fragment>
                <div className="infected-device__label">
                  <FormattedMessage id="infected_device" />
                </div>
                <div className="infected-device__name">{item.name}</div>
              </Fragment>
            )}
          </div>
        ),
        content: (
          <div
            className="infected-device__description"
            dangerouslySetInnerHTML={{ __html: item.threatDescription }}
          />
        ),
      }))}
    />
  </div>
);
