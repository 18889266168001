import React, { VFC, Fragment, useEffect, useRef, useState } from "react";
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from "downshift";
import classNames from "classnames";
import _ from "lodash";

import { IComboboxItem } from "./components/comboboxItem/ComboboxItem";
import { ComboboxLabel, ComboboxSearch, ComboboxItem } from "./components";

import "./Combobox.scss";

const findSelectedItem = (
  selected: string,
  items: IComboboxItem[]
): IComboboxItem => _.find(items, { value: selected }) || null;

interface StateReducerFunction {
  (
    state: UseComboboxState<IComboboxItem>,
    actionAndChanges: UseComboboxStateChangeOptions<IComboboxItem>
  ): Partial<UseComboboxState<IComboboxItem>>;
}

const stateReducer: StateReducerFunction = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEscape: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case useCombobox.stateChangeTypes.InputBlur: {
      const { selectedItem } = state;

      return {
        ...state,
        inputValue: selectedItem ? selectedItem.content : "",
        isOpen: false,
      };
    }
    default:
      return changes;
  }
};

export interface ComboboxProps {
  selected: string;
  onChange: (value: string) => void;
  items: IComboboxItem[];
  className?: string;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  searchInputPlaceholder?: string;
  noResultsMessage?: string;
  comboboxSearchWrapperAriaLabel: string;
  toggleButtonAriaLabel: string;
}

export const Combobox: VFC<ComboboxProps> = ({
  items,
  selected,
  className,
  onChange,
  ariaLabel,
  label,
  disabled,
  searchInputPlaceholder,
  noResultsMessage,
  comboboxSearchWrapperAriaLabel,
  toggleButtonAriaLabel,
}) => {
  const [allItems, setAllItems] = useState<IComboboxItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<IComboboxItem[]>([]);
  const isDataLoaded = useRef(false);
  const pristineInput = useRef(true);

  useEffect(() => {
    setFilteredItems(items);
    setAllItems(items);
  }, [items]);

  const selectedItem = findSelectedItem(selected, allItems);

  const {
    isOpen,
    highlightedIndex,
    getInputProps,
    getComboboxProps,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    openMenu,
  } = useCombobox<IComboboxItem>({
    items: filteredItems,
    selectedItem,
    stateReducer,
    itemToString: item => (item ? item.content : ""),
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen && pristineInput.current) {
        setFilteredItems(allItems);
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem.value);
      pristineInput.current = true;
    },
    onInputValueChange: ({ inputValue }) => {
      if (!isDataLoaded.current && selectedItem) {
        isDataLoaded.current = true;
        pristineInput.current = false;

        return;
      }

      const newItems =
        pristineInput.current || !inputValue
          ? allItems
          : allItems.filter(item =>
              item.content
                .toLowerCase()
                .includes(_.trim(inputValue.toLowerCase()))
            );

      setFilteredItems(newItems);
    },
    circularNavigation: true,
  });

  return (
    <div className={classNames("combobox-wrapper", className)}>
      <ComboboxLabel
        getLabelProps={getLabelProps}
        ariaLabel={ariaLabel}
        value={label}
      />

      <div
        className={classNames("combobox", {
          "combobox--opened": isOpen,
          "combobox--disabled": disabled,
        })}
      >
        <ComboboxSearch
          getInputProps={getInputProps}
          getToggleButtonProps={getToggleButtonProps}
          getComboboxProps={getComboboxProps}
          onInputClick={openMenu}
          onInputChange={() => {
            pristineInput.current = false;
          }}
          onInputBlur={() => {
            pristineInput.current = true;
          }}
          disabled={disabled}
          placeholder={searchInputPlaceholder}
          comboboxSearchWrapperAriaLabel={comboboxSearchWrapperAriaLabel}
          toggleButtonAriaLabel={toggleButtonAriaLabel}
        />

        <div className="combobox__menu-wrapper">
          <ul
            {...getMenuProps({
              onKeyDown: e => {
                e.stopPropagation();
              },
            })}
            className="combobox-menu"
          >
            {isOpen && (
              <Fragment>
                {filteredItems.map((item, index) => (
                  <ComboboxItem
                    item={item}
                    key={item.value}
                    index={index}
                    getItemProps={getItemProps}
                    selected={filteredItems.indexOf(selectedItem) === index}
                    highlighted={highlightedIndex === index}
                  />
                ))}

                {_.isEmpty(filteredItems) && (
                  <NoResultsFound message={noResultsMessage} />
                )}
              </Fragment>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const NoResultsFound = ({ message = "No results found" }) => (
  <li className="combobox-menu__item combobox-menu__item--no-results">
    {message}
  </li>
);
