import { AttributesData } from "@sportal/api";

export const LOAD_ATTRIBUTES = "[AUTH] LOAD_ATTRIBUTES";
export const LOAD_ATTRIBUTES_SUCCESS = "[AUTH] LOAD_ATTRIBUTES_SUCCESS";
export const LOAD_ATTRIBUTES_FAILURE = "[AUTH] LOAD_ATTRIBUTES_FAILURE";

interface loadAttributesSuccessAction {
  type: typeof LOAD_ATTRIBUTES_SUCCESS;
  payload: AttributesData;
}

export type AttributesActionTypes = loadAttributesSuccessAction;
