import React from "react";
import { FormattedMessage } from "react-intl";

import "./UnsavedChanges.scss";

export const UnsavedChanges = () => (
  <span className="profile-section__unsaved-changes">
    <FormattedMessage id="unsaved_changes" />
  </span>
);
