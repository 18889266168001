import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import PropTypes from "prop-types";

import { DeviceRow } from "../deviceRow/DeviceRow";
import { FormattedDateTime } from "../../formattedDateTime/FormattedDateTime";

import "./DeviceCardDesktop.scss";

const unknown = (
  <span className="disabled-text">
    <FormattedMessage id="unknown" />
  </span>
);

const missedValue = "—"; // em dash

export const DeviceCardDesktop = ({
  deviceName,
  code,
  manufacturer,
  networkId,
  lastSeen,
  variant,
  isAdvancedView,
  menu,
}) => {
  return (
    <div
      className={classnames(
        "device-card-desktop",
        `device-card-desktop--${variant}`
      )}
    >
      <DeviceRow isAdvancedView={isAdvancedView}>
        {deviceName || unknown}
        {code || missedValue}
        {manufacturer || missedValue}
        {networkId || missedValue}
        {lastSeen ? <FormattedDateTime value={lastSeen} /> : missedValue}
        {menu}
      </DeviceRow>
    </div>
  );
};

DeviceCardDesktop.propTypes = {
  variant: PropTypes.oneOf(["dark", "light"]).isRequired,
  isAdvancedView: PropTypes.bool,
  deviceName: PropTypes.string,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  manufacturer: PropTypes.string,
  networkId: PropTypes.node,
  lastSeen: PropTypes.number,
  menu: PropTypes.node,
};
