import React from "react";
import { useHistory } from "react-router-dom";
import { BackArrowButton } from "../backArrowButton/BackArrowButton";

export const GoHomeButton = props => {
  const history = useHistory();
  return (
    <BackArrowButton
      messageId="back_to_dashboard"
      {...props}
      onClick={() => history.push("/home")}
    />
  );
};
