import _ from "lodash";

import { AgeGroup, Protection, SafeSearchService } from "@sportal/api";
import { getProfiles } from "../root.selectors";
import { denormalizeCollection } from "../shared/normalizeCollection";
import { getInspectStreamAgeGroups } from "../config";
import { getProfileScheduleByName, isScheduleActive } from "../schedules";
import { getProfileDevices } from "../devices";

export const protectionByAgeGroup = {
  [AgeGroup.Kids]: Protection.Strict,
  [AgeGroup.Teens]: Protection.Medium,
  [AgeGroup.Adults]: Protection.Light,
  default: Protection.Light,
};

export const getProfilesList = _.flow([getProfiles, denormalizeCollection]);

export const getProfile = (state, id) => getProfiles(state).list[id];
export const getProfilesWithDevicesCount = state => {
  const profiles = getProfilesList(state);

  return _.map(profiles, profile => ({
    ...profile,
    devicesCount: getProfileDevices(profile.name)(state).length,
  }));
};

export const getProfilesFactory = state => {
  const inspectStreamAgeGroups = getInspectStreamAgeGroups(state);
  const defaultsByAgeGroup = {
    [AgeGroup.Kids]: {
      protection: protectionByAgeGroup[AgeGroup.Kids],
      "inspect-stream": _.includes(inspectStreamAgeGroups, AgeGroup.Kids),
    },
    [AgeGroup.Teens]: {
      protection: protectionByAgeGroup[AgeGroup.Teens],
      "inspect-stream": _.includes(inspectStreamAgeGroups, AgeGroup.Teens),
    },
    [AgeGroup.Adults]: {
      protection: protectionByAgeGroup[AgeGroup.Adults],
      "inspect-stream": _.includes(inspectStreamAgeGroups, AgeGroup.Adults),
    },
  };
  const defaults = {
    default: false,
    "internet-access": true,
    "protection-enabled": true,
    "safe-search": true,
    "safe-search-services": {
      [SafeSearchService.YouTube]: true,
      [SafeSearchService.Google]: true,
      [SafeSearchService.Bing]: true,
    },
  };

  return (id, name, ageGroup) => ({
    ...defaults,
    id,
    name,
    "age-group": ageGroup,
    ...defaultsByAgeGroup[ageGroup],
  });
};

export const getInternetPauseSchedule = (state, profileId) => {
  return getProfileScheduleByName(state, profileId, "internet-pause");
};

export const isProfilePaused = (state, profileId) => {
  return isScheduleActive(state, profileId, "internet-pause");
};

export const getDefaultProfile = _.flow(
  getProfilesList,
  profiles => {
    return _.find(profiles, ["default", true]);
  }
);

export const fromProfiles = {
  getProfilesIds: profiles => profiles.keys,
  getProfilesCount: profiles => profiles.keys.length,
};
