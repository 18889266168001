import { useParams } from "react-router";

export const useUrlFromRoute = () => {
  const { url64 = "" } = useParams();
  let url = "";
  let urlInvalid = false;

  if (url64) {
    try {
      url = atob(url64);
    } catch (e) {
      urlInvalid = true;
    }
  }

  return [url, urlInvalid];
};
