import { InfectedDevicesServerData } from "@sportal/api";

import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_INFECTED_DEVICES,
  LOAD_INFECTED_DEVICES_FAILURE,
  LOAD_INFECTED_DEVICES_SUCCESS,
  InfectedDevicesActionTypes,
} from "./infectedDevices.types";

export interface InfectedDevicesState {
  list: InfectedDevicesServerData;
}

export const initialState = {
  list: {},
};

const reducer = (
  state: InfectedDevicesState = initialState,
  action: InfectedDevicesActionTypes
) => {
  switch (action.type) {
    case LOAD_INFECTED_DEVICES_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export const infectedDevicesReducer = withLoadingState({
  loadActionType: LOAD_INFECTED_DEVICES,
  successActionType: LOAD_INFECTED_DEVICES_SUCCESS,
  failureActionType: LOAD_INFECTED_DEVICES_FAILURE,
})(reducer);
