import _ from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Select,
  CommonModalHeader,
  ReadOnlyInput,
  CommonModalFooter,
} from "../../../../../components";
import { ModalDialog } from "@sportal/cdk";

export const DevicePicker = ({ items, selected, onChange }) => (
  <Select
    items={items}
    name="merge_with"
    label={<FormattedMessage id="merge_with" />}
    selected={selected}
    onChange={onChange}
  />
);

export const MergeDeviceDialog = ({ modal, mergeableDevices, device }) => {
  const devicePickerItems = _.map(mergeableDevices, device => ({
    id: device.name,
    value: device.name,
    content: device.name,
  }));

  const [deviceToMerge, setDeviceToMerge] = useState(
    devicePickerItems[0].value
  );

  const submit = () =>
    modal.resolve(_.find(mergeableDevices, { name: deviceToMerge }));
  const close = () => modal.reject();

  return (
    <ModalDialog
      className="merge-device-modal"
      submitHandler={submit}
      rejectHandler={close}
    >
      <CommonModalHeader>
        <FormattedMessage id="merge_devices" />
      </CommonModalHeader>
      <ModalDialog.Body>
        <FormattedMessage id="unknown">
          {placeholder => (
            <ReadOnlyInput
              value={device.name}
              placeholder={placeholder}
              id="device_name"
              label={<FormattedMessage id="device_name" />}
            />
          )}
        </FormattedMessage>

        <DevicePicker
          items={devicePickerItems}
          selected={deviceToMerge}
          onChange={setDeviceToMerge}
        />
      </ModalDialog.Body>
      <CommonModalFooter submitMessage={<FormattedMessage id="merge" />} />
    </ModalDialog>
  );
};
