import React from "react";

import { TimePicker as BaseTimePicker } from "@sportal/cdk";
import { useIntlTranslate } from "../../hooks";

import "./TimePicker.scss";

const TimePicker = props => {
  const translate = useIntlTranslate();
  const ariaMinutes = translate("aria_minutes");
  const ariaHours = translate("aria_hours");
  const ariaTimeFormat = translate("aria_select_am_or_pm_time");

  return (
    <BaseTimePicker
      {...props}
      ariaMinutes={ariaMinutes}
      ariaHours={ariaHours}
      ariaTimeFormat={ariaTimeFormat}
    />
  );
};

export { TimePicker };
