import React, { VFC } from "react";
import classnames from "classnames";
import { GetItemPropsOptions } from "downshift";

import "./SelectItem.scss";

export interface ISelectItem {
  value: string;
  content: string;
  disabled?: boolean;
}

interface SelectItemProps {
  item: ISelectItem;
  index: number;
  getItemProps: (options: GetItemPropsOptions<ISelectItem>) => any;
  selected: boolean;
  highlighted: boolean;
}

export const SelectItem: VFC<SelectItemProps> = ({
  item,
  index,
  getItemProps,
  selected,
  highlighted,
}) => {
  return (
    <li
      {...getItemProps({
        item,
        index,
        disabled: item.disabled,
      })}
      className={classnames("select-menu__item", {
        "select-menu__item--disabled": item.disabled,
        "select-menu__item--highlighted": highlighted,
        "select-menu__item--selected": selected,
      })}
      data-value={item.value}
    >
      {item.content}
    </li>
  );
};
