import React, { Fragment } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icon, useCollapseContext } from "@sportal/cdk";

import { Button } from "../../../components";
import { Mobile, NotMobile } from "../../../common/Responsive";

import "./DetectedInfections.scss";

const DetectedInfectionsDescription = () => (
  <div className="infections-detected__description">
    <Icon icon="fas fa-exclamation-circle" />
    <FormattedMessage id="suspicious_internet_traffic_detected" />
  </div>
);

export function DetectedInfections() {
  const { opened, getToggleProps } = useCollapseContext();

  return (
    <Fragment>
      <NotMobile>
        <div className="infections-detected">
          <DetectedInfectionsDescription />
          <Button
            size="small"
            color="secondary"
            variant="inverted"
            {...getToggleProps({ type: "button" })}
          >
            <span aria-hidden="true">
              <FormattedMessage id={opened ? "hide_details" : "see_details"} />
            </span>
            <span className="sr-only">
              <FormattedMessage id={"see_details"} />
            </span>
          </Button>
        </div>
      </NotMobile>
      <Mobile>
        <div
          className="infections-detected infections-detected--mobile"
          {...getToggleProps({ type: "div" })}
        >
          <DetectedInfectionsDescription />
          <Icon
            icon={classnames(
              "far fa-lg",
              opened ? "fa-angle-up" : "fa-angle-down"
            )}
          />
        </div>
      </Mobile>
    </Fragment>
  );
}
