import { HttpService, HttpServiceResponse } from "../shared";
import { ListServerData, ListType, UpdateResponse } from "./lists.types";

export class ListsProvider {
  constructor(
    private http: HttpService,
    private baseUrl: string,
    private appCode: string
  ) {}

  private url(
    subscriberId: string,
    listType: ListType,
    profileId: string = ""
  ): string {
    const profilePath = profileId && `/profile/${profileId}`;

    return `${this.baseUrl}/ssm/${this.appCode}/${subscriberId}${profilePath}/${listType}`;
  }

  get(
    subscriberId: string,
    listType: ListType,
    profileId?: string
  ): Promise<HttpServiceResponse<ListServerData>> {
    return this.http.get<ListServerData>(
      this.url(subscriberId, listType, profileId)
    );
  }

  remove(
    subscriberId: string,
    listType: ListType,
    items: string[],
    profileId: string
  ) {
    return this.update(subscriberId, listType, profileId, { remove: items });
  }

  add(
    subscriberId: string,
    listType: ListType,
    items: string[],
    profileId: string
  ) {
    return this.update(subscriberId, listType, profileId, { add: items });
  }

  check(url: string) {
    return this.http.post(`${this.baseUrl}/ssm/check/list/node`, { url });
  }

  private update(subscriberId: string, listType: ListType, profileId, changes) {
    return this.http.post<UpdateResponse>(
      this.url(subscriberId, listType, profileId),
      changes
    );
  }
}
