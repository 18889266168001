import {
  REMOVE_TEMP_NOTIFICATION,
  ADD_TEMP_NOTIFICATION_TO_PENDING,
  DISPLAY_TEMP_NOTIFICATION,
  DISPLAY_PENDING_TEMP_NOTIFICATION,
} from "./temporaryNotifications.consts";
import {
  getDisplayedTempNotifications,
  getPendingTempNotifications,
} from "./temporaryNotifications.selectors";
import { uuid } from "@sportal/lib";

let timeouts = [];
export const MAX_DISPLAYED_AT_ONCE = 3;
export const AUTO_DISMISS_TIMEOUT = 5000;

// This is just for testing purpose
export const setRemoveTimeouts = timeoutsArr => {
  timeouts = timeoutsArr;
};
export const resetRemoveTimeouts = () => {
  timeouts.forEach(item => {
    clearTimeout(item.timer);
  });
  setRemoveTimeouts([]);
};
export const getRemoveTimeouts = () => timeouts;

// region actions creators

export const justDisplayNotification = notification => ({
  type: DISPLAY_TEMP_NOTIFICATION,
  notification,
});

export const justRemoveNotification = id => ({
  type: REMOVE_TEMP_NOTIFICATION,
  id,
});

export const addNotificationToPending = notification => ({
  type: ADD_TEMP_NOTIFICATION_TO_PENDING,
  notification,
});

export const displayPendingNotificationBase = notification => ({
  type: DISPLAY_PENDING_TEMP_NOTIFICATION,
  notification,
});

// endregion action creators

const canBeDisplayedImmediately = getState => {
  const currentlyDisplayed = getDisplayedTempNotifications(getState());
  return currentlyDisplayed.length !== MAX_DISPLAYED_AT_ONCE;
};

const anythingPending = getState => {
  const waitingForDisplay = getPendingTempNotifications(getState());
  return waitingForDisplay.length > 0;
};

export const addRemoveTimeout = (id, dispatch) => {
  const timeout = {
    id,
    timer: setTimeout(() => {
      dispatch(removeTempNotification(id));
    }, AUTO_DISMISS_TIMEOUT),
  };

  timeouts.push(timeout);
};

const removeTimeout = id => {
  const timeoutToReset = timeouts.find(timeout => timeout.id === id);
  clearTimeout(timeoutToReset.timer);
  timeouts = timeouts.filter(timeout => timeout.id !== id);
};

const displayNotification = (dispatch, notification) => {
  dispatch(justDisplayNotification(notification));
  addRemoveTimeout(notification.id, dispatch);
};

const removeNotification = (dispatch, id) => {
  dispatch(justRemoveNotification(id));
  removeTimeout(id);
};

const displayPendingNotification = (getState, dispatch) => {
  const waitingForDisplay = getPendingTempNotifications(getState());
  const firstInLine = waitingForDisplay[0];

  dispatch(displayPendingNotificationBase(firstInLine));
  addRemoveTimeout(firstInLine.id, dispatch);
};

export const createTempNotification = notification => (dispatch, getState) => {
  const newNotification = { ...notification, id: uuid() };

  canBeDisplayedImmediately(getState)
    ? displayNotification(dispatch, newNotification)
    : dispatch(addNotificationToPending(newNotification));
};

export const removeTempNotification = id => (dispatch, getState) => {
  removeNotification(dispatch, id);
  setTimeout(() => {
    anythingPending(getState) && displayPendingNotification(getState, dispatch);
  }, 300); // after disappearing animation ends
};
