import { useMemo } from "react";
import _ from "lodash";

export const DEVICE_NAME_MAX_LENGTH = 30;

export const useDeviceNameValidator = devices =>
  useMemo(() => {
    const existingNames = new Set(_.map(devices, ({ name }) => name));
    return _.cond([
      [name => _.isEmpty(name), _.constant("name_required")],
      [
        name => name.length > DEVICE_NAME_MAX_LENGTH,
        _.constant("name_exceed_length"),
      ],
      [
        name => _.includes([".", ".."], name),
        _.constant("device_name_invalid"),
      ],
      [name => existingNames.has(name), _.constant("name_duplicated")],
    ]);
  }, [devices]);
