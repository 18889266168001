import _ from "lodash";

import { getDefault24hFormat } from "../config";
import { getAttributes } from "../root.selectors";

const getAttribute24hFormat = state => getAttributes(state).is24hourFormat;
export const getAttributeLanguage = state => getAttributes(state).language;

export const getIs24hourFormat = state => {
  const attr24hFormat = getAttribute24hFormat(state);

  return _.isBoolean(attr24hFormat)
    ? attr24hFormat
    : getDefault24hFormat(state);
};
