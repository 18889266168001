import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { CollapsibleSection } from "@sportal/cdk";
import { useEventListener } from "@sportal/cdk/hooks";
import { useStickyContext, useUuid } from "./StickyProvider";
import { ProfileSectionHeader } from "./ProfileSectionHeader";
import { ProfileSectionFooter } from "./ProfileSectionFooter";

import "./ProfileSection.scss";

function getVisibleHeight(el, top, bottom) {
  const elementHeight = el.offsetHeight;
  const windowHeight = window.innerHeight;

  return Math.max(
    0,
    top > 0
      ? Math.min(elementHeight, windowHeight - top)
      : Math.min(bottom, windowHeight)
  );
}

const getSticky = (sectionRef, headerRef, footerRef) => {
  const { top, bottom } = sectionRef.current.getBoundingClientRect();
  const section = {
    top,
    bottom,
    visibleHeight: getVisibleHeight(sectionRef.current, top, bottom),
  };

  return {
    header: {
      height: headerRef.current.getBoundingClientRect().height,
      sticky: isHeaderSticky(section),
    },
    footer: {
      height: footerRef.current.getBoundingClientRect().height,
      sticky: isFooterSticky(section),
    },
  };
};

const isHeaderSticky = section => {
  const MINIMUM_COLLAPSIBLE_AREA_HEIGHT_TO_SHOW_HEADER = 320;
  if (section.visibleHeight < MINIMUM_COLLAPSIBLE_AREA_HEIGHT_TO_SHOW_HEADER) {
    return false;
  }
  return section.top < 0;
};

const isFooterSticky = section => {
  const MINIMUM_COLLAPSIBLE_AREA_HEIGHT_TO_SHOW_FOOTER = 100;
  if (section.visibleHeight < MINIMUM_COLLAPSIBLE_AREA_HEIGHT_TO_SHOW_FOOTER) {
    return false;
  }
  return section.bottom > window.innerHeight;
};

export const ProfileSection = ({
  className,
  description,
  toggle,
  header,
  content,
  footer,
}) => {
  const id = useUuid();
  const { stickySections, setStickySections } = useStickyContext();
  const isOpened = stickySections[id];

  const sectionRef = useRef(null);
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [state, setState] = useState({});

  const updateStickyElements = useCallback(() => {
    if (!isOpened) return;

    const update = getSticky(sectionRef, headerRef, footerRef);

    if (!_.isEqual(update, state)) {
      setState(update);
    }
  }, [state, isOpened]);

  const updateStickyElementsThrottled = _.throttle(updateStickyElements, 10);

  useEventListener("scroll", updateStickyElementsThrottled);
  useEventListener("resize", updateStickyElementsThrottled);
  useEventListener("orientationchange", updateStickyElements);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateStickyElements();
    }, 200); //$react-collapse-transition-duration
    return () => clearTimeout(timeoutId);
  }, [updateStickyElements, stickySections]);

  const updateSection = (toggleSection, isOpened) => {
    toggleSection();
    setStickySections(id, !isOpened);
  };

  return (
    <div ref={sectionRef} className={className}>
      <CollapsibleSection
        className="profile-section"
        header={
          <ProfileSectionHeader
            headerContent={header}
            toggleContent={toggle}
            header={state.header}
            description={description}
            headerRef={headerRef}
            updateSection={updateSection}
          />
        }
        content={content}
        headerClassName="profile-section__header"
        contentClassName="profile-section__content"
        footer={
          <ProfileSectionFooter
            toggleContent={toggle}
            footerContent={footer}
            footer={state.footer}
            footerRef={footerRef}
            updateSection={updateSection}
          />
        }
      />
    </div>
  );
};

ProfileSection.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node.isRequired,
  toggle: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
};
