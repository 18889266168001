import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { CellDetailsMobile } from "../../../../../../../components";
import { DeviceIds } from "../deviceIds";

export const MobileNonRoamingDetails = ({ device }) => {
  return (
    <Fragment>
      <CellDetailsMobile
        id="network-id"
        header={<FormattedMessage id="network_id" />}
        value={
          <DeviceIds
            identifiers={device.identifiers}
            phoneNumber={device.phoneNumber}
            networkId={device.networkId}
          />
        }
      />
      <CellDetailsMobile
        id={"manufacturer"}
        header={<FormattedMessage id="manufacturer_or_device_info" />}
        value={device.manufacturer}
      />
    </Fragment>
  );
};
