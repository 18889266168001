import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loadThreats,
  getThreatsBySelectedLanguage,
} from "../../../store/threats";
import { loadLogicalDevices } from "../../../store/devices";
import { loadInfections } from "../../../store/infections";
import { loadInfectedDevices } from "../../../store/infectedDevices";
import {
  getInfections,
  getLogicalDevices,
  getInfectedDevices,
} from "../../../store/root.selectors";
import { isSubscriberSafetyEnabled } from "../../../store/account";
import { getSelectedLanguage } from "../../../i18n/language.selectors";
import {
  isSuccess,
  useLoadableSlice,
} from "../../../store/shared/withLoadingState";
import { InfectedDevices } from "../../../components/infectedDevices/InfectedDevices";
import { getInfectedDevicesWithThreats } from "./infectedDevicesPanel.selectors";
import { DetectedInfections } from "./DetectedInfections";
import { isSSReportingLicensed } from "../../../store/settings";
import { CollapsibleSection } from "@sportal/cdk";

const useInfectedDevicesPanelData = () => {
  const dispatch = useDispatch();

  const { key: language } = useSelector(getSelectedLanguage);

  useEffect(() => {
    dispatch(loadThreats(language));
  }, [language, dispatch]);

  return [
    useSelector(getThreatsBySelectedLanguage),
    useLoadableSlice(loadInfections(), getInfections),
    useLoadableSlice(loadInfectedDevices(), getInfectedDevices),
    useLoadableSlice(loadLogicalDevices(), getLogicalDevices),
  ].every(slice => slice && isSuccess(slice));
};

function InfectedDevicesCollapsiblePanel() {
  const dataLoaded = useInfectedDevicesPanelData();

  const infectedDevices = useSelector(getInfectedDevicesWithThreats);

  if (!dataLoaded || infectedDevices.length === 0) {
    return null;
  }

  return (
    <div>
      <CollapsibleSection
        className="infected-devices-panel"
        header={<DetectedInfections />}
        content={<InfectedDevices items={infectedDevices} />}
      />
    </div>
  );
}

export function InfectedDevicesPanel() {
  const isSSAvailable = useSelector(isSubscriberSafetyEnabled);
  const isSSReportAllowed = useSelector(isSSReportingLicensed);

  if (!isSSAvailable || !isSSReportAllowed) {
    return null;
  }

  return <InfectedDevicesCollapsiblePanel />;
}
