import React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@sportal/cdk";
import { Button } from "../../button/Button";

export const RefreshRequestsButton = ({ onClick }) => (
  <Button
    className="devices-control-bar__control"
    size="medium"
    color="secondary"
    onClick={onClick}
    iconRight={<Icon icon="far fa-sync" />}
  >
    <FormattedMessage id="refresh" />
  </Button>
);
