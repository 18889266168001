import { normalizeCollection } from "../shared/normalizeCollection";
import { createTempNotification } from "../../components/notifications";
import {
  LOAD_CATEGORIES,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORIES_FAILURE,
  CategoriesActionTypes,
} from "./categories.types";
import { Category } from "@sportal/api/protections/protection.types";
import { Api } from "../../api";
import { isFailure } from "@sportal/api";

export const loadCategories = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_CATEGORIES });

  const result = await api.protection.getCategories();

  if (isFailure(result)) {
    dispatch(loadCategoriesFailure(result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "failed_load_categories",
      })
    );
  } else {
    dispatch(loadCategoriesSuccess(result.data));
  }
};

export const loadCategoriesSuccess = (
  data: Category[]
): CategoriesActionTypes => ({
  type: LOAD_CATEGORIES_SUCCESS,
  payload: normalizeCollection(data, "name"),
});

export const loadCategoriesFailure = (error): CategoriesActionTypes => ({
  type: LOAD_CATEGORIES_FAILURE,
  payload: error,
});
