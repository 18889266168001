import React from "react";
import { FormattedMessage } from "react-intl";

import { InfoSection, TabTitle, withLayout } from "../../../components";
import { defaultProfile } from "../store/profiles";
import { Mobile, NotMobile } from "../../../common/Responsive";
import { WizardSidebar } from "./wizardSidebar/WizardSidebar";
import { useDataForSingleWizard } from "./useDataForSingleWizard";
import { SingleWizardProtections } from "./SingleWizardProtections";
import { WizardHeader } from "../wizardHeader/WizardHeader";
import { useWizardNavigationContext } from "../WizardNavigationContext";
import { GoPrevStep } from "../goPrevStepButton/GoPrevStepButton";
import { WizardMobileTitle } from "../wizardMobileTitle/WizardMobileTitle";

import "../wizardLayout.scss";
import { WizardPageTitle } from "../multiple/wizardPageTitle/WizardPageTitle";

export const SingleWizardPageComponent = () => {
  const { goToPrevStep } = useWizardNavigationContext();
  const data = useDataForSingleWizard();

  if (!data) return null;

  const { categories, sharedProtections } = data;

  return (
    <div className="single-wizard-page">
      <Mobile>
        <WizardMobileTitle>
          <TabTitle>
            <FormattedMessage id="protection_setup" />
          </TabTitle>
          <p>
            <FormattedMessage id="same_level_of_protection" />
          </p>
        </WizardMobileTitle>
      </Mobile>
      <NotMobile>
        <InfoSection>
          <GoPrevStep onClick={goToPrevStep} />
        </InfoSection>
      </NotMobile>
      <div className="wizard-page__content">
        <NotMobile>
          <div className="wizard-page__sidebar">
            <div className="wizard-page__sidebar-container">
              <WizardSidebar />
            </div>
          </div>
        </NotMobile>
        <div className="wizard-page__tabs-content">
          <div className="wizard-page__tabs-content-container">
            <Mobile>
              <div className="profile-info">
                <i className="profile-image default" />
                <span className="protection-header-content__title">
                  <FormattedMessage id="single_profile" />
                </span>
              </div>
            </Mobile>
            <NotMobile>
              <WizardPageTitle>
                <FormattedMessage id="protection_setup" />
              </WizardPageTitle>
            </NotMobile>
            <div>
              <FormattedMessage id={"default_protection"} />:
              <span className="protection-editor__default-level">
                <FormattedMessage id={defaultProfile.protection} />
              </span>
            </div>
            {!categories.error && !sharedProtections.error && (
              <SingleWizardProtections profile={defaultProfile} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SingleWizardPage = withLayout({
  customHeader: () => <WizardHeader />,
})(SingleWizardPageComponent);
