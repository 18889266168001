import { createTempNotification } from "../../components/notifications";

export const notifyUser = (success, notifySuccess = true) => dispatch => {
  if (success) {
    if (!notifySuccess) {
      return;
    }

    dispatch(
      createTempNotification({
        variant: "success",
        description: "successfully_saved",
      })
    );
    return;
  }

  dispatch(
    createTempNotification({
      variant: "error",
      description: "couldnt_save_changes",
    })
  );
};
