import React, { cloneElement, ReactElement, VFC } from "react";
import classnames from "classnames";
import { useSelect } from "downshift";

import "./Dropdown.scss";

import {
  defaultOnSelectedItemChange,
  defaultRenderItems,
  OnSelectedItemChange,
  RenderItems,
} from "./dropdown.helpers";
import { DropdownItemInterface } from "./DropdownItem";
import { useCollapseOnScroll } from "../hooks";
import { usePopperAPI, PopperOptions } from "../popper";
import { isDownArrowPressed, isUpArrowPressed } from "../helpers";

export interface DropdownProps {
  label?: string | ReactElement;
  items: DropdownItemInterface[] | ReactElement[];
  renderItems?: RenderItems;
  onSelectedItemChange?: OnSelectedItemChange;
  hiddenLabel?: string | ReactElement;
  toggleButton: ReactElement;
  className?: string;
  onDropdownToggle?: (isOpen: boolean) => void;
  menuAriaLabel?: string;
  popperOptions?: PopperOptions;
  collapseOnScroll?: boolean;
}

const defaultPopperOptions: PopperOptions = {
  strategy: "fixed",
  placement: "bottom-end",
};

export const Dropdown: VFC<DropdownProps> = ({
  className,
  label,
  hiddenLabel,
  items,
  toggleButton,
  onDropdownToggle,
  menuAriaLabel,
  renderItems = defaultRenderItems,
  onSelectedItemChange = defaultOnSelectedItemChange,
  popperOptions,
  collapseOnScroll,
}) => {
  const {
    isOpen: isDropdownOpen,
    getToggleButtonProps,
    getMenuProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    reset,
    openMenu,
  } = useSelect<DropdownItemInterface | ReactElement>({
    items,
    circularNavigation: true,
    onSelectedItemChange: ({ selectedItem }) => {
      onSelectedItemChange(selectedItem);
      reset();
    },
    onIsOpenChange: ({ isOpen }) =>
      onDropdownToggle && onDropdownToggle(isOpen),
  });

  const {
    setAnchorElement,
    setPopperElement,
    styles,
    attributes,
  } = usePopperAPI({ ...defaultPopperOptions, ...popperOptions });

  useCollapseOnScroll(collapseOnScroll, closeMenu, isDropdownOpen);

  const menuAttributes = {
    ...getMenuProps({
      onKeyDown: e => {
        e.stopPropagation();
      },
    }),
    className: "dropdown__menu",
    "aria-label": menuAriaLabel,
  };

  return (
    <div className={classnames("dropdown", className)}>
      <label
        {...getLabelProps()}
        className={classnames("dropdown__label", { "sr-only": hiddenLabel })}
      >
        {label || hiddenLabel}
      </label>

      <div ref={setAnchorElement} className="dropdown__toggle">
        {cloneElement<ReactElement>(toggleButton, {
          ...getToggleButtonProps(),
          onKeyDown: e => {
            if (isDownArrowPressed(e) || isUpArrowPressed(e)) {
              e.preventDefault();
              openMenu();
            }
          },
        })}
      </div>

      <div
        ref={isDropdownOpen ? setPopperElement : null}
        className={classnames("dropdown-menu-container", {
          "dropdown-menu-container--visible": isDropdownOpen,
        })}
        style={styles.popper}
        {...attributes.popper}
      >
        <ul {...menuAttributes}>
          {isDropdownOpen &&
            renderItems({ items, highlightedIndex, getItemProps })}
        </ul>
      </div>
    </div>
  );
};
