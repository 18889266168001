import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { NotMobile, useMobileValue } from "../../../common/Responsive";
import { UnsavedChanges } from "./UnsavedChanges";
import { Button } from "../../../components";

import "./ProfileSectionFooterFormControls.scss";

export const ProfileSectionFooterFormControls = ({
  hasChanges,
  discardDisabled,
  submitDisabled,
  discardChanges,
  submitChanges,
}) => {
  const buttonSize = useMobileValue("large", "medium");

  return (
    <div className="profile-section__footer-form-controls">
      <NotMobile>{hasChanges && <UnsavedChanges />}</NotMobile>
      <div className="profile-section__form-controls-buttons">
        <Button
          size={buttonSize}
          color="secondary"
          disabled={discardDisabled}
          onClick={discardChanges}
        >
          <FormattedMessage id="discard" />
        </Button>
        <Button
          size={buttonSize}
          color="primary"
          disabled={submitDisabled}
          onClick={submitChanges}
        >
          <FormattedMessage id="save" />
        </Button>
      </div>
    </div>
  );
};

ProfileSectionFooterFormControls.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  discardDisabled: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  discardChanges: PropTypes.func.isRequired,
  submitChanges: PropTypes.func.isRequired,
};
