import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Select } from "..";
import { useIntlTranslate } from "../../hooks";

import "./ProtectionPicker.scss";

export const ProtectionPicker = ({ protections, selected, onChange }) => {
  const translate = useIntlTranslate();

  const items = protections.map(({ name }) => ({
    content: translate(name),
    value: name,
  }));

  return (
    <Select
      className={"protection-picker"}
      label={<FormattedMessage id={"protection_level"} />}
      items={items}
      selected={selected}
      onChange={onChange}
    />
  );
};

ProtectionPicker.defaultProps = {
  protections: [],
  onChange: () => {},
};

ProtectionPicker.propTypes = {
  protections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      blocked: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  selected: PropTypes.string,
  onChange: PropTypes.func,
};
