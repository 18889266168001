import React from "react";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useCollapseContext } from "@sportal/cdk";

const transitionStyles = {
  entering: { display: "none" },
  entered: { display: "block" },
  exiting: { display: "none" },
  exited: { display: "none" },
};

export const ProfileSectionHeader = ({
  headerContent,
  toggleContent,
  header,
  description,
  headerRef,
  updateSection,
}) => {
  const { opened, toggleOpened, getToggleProps } = useCollapseContext();

  const placeholderStyles = header && {
    height: header.sticky ? header.height : "auto",
  };

  const stickyClass = classNames("profile-section__title", {
    "profile-section__title--fixed": opened && header && header.sticky,
  });

  return (
    <div className="profile-section__header">
      <div style={placeholderStyles}>
        <div className={stickyClass} ref={headerRef}>
          {headerContent}
        </div>
      </div>
      <div className="profile-section__description">{description}</div>
      <Transition in={!opened} timeout={200}>
        {state => (
          <div
            style={transitionStyles[state]}
            className="profile-section__header__toggle"
          >
            <div
              {...getToggleProps({
                onToggle: () => updateSection(toggleOpened, opened),
                type: "div",
              })}
            >
              {toggleContent}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

ProfileSectionHeader.propTypes = {
  headerContent: PropTypes.node.isRequired,
  toggleContent: PropTypes.node.isRequired,
  header: PropTypes.object,
  description: PropTypes.node.isRequired,
  headerRef: PropTypes.object.isRequired,
  updateSection: PropTypes.func.isRequired,
};
