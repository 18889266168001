import React from "react";
import PropTypes from "prop-types";

import { CrossButton, useModalDialogContext } from "@sportal/cdk";
import { ModalDialog } from "../Modal";

export const CommonModalHeader = ({ children }) => {
  const { reject } = useModalDialogContext();

  return (
    <ModalDialog.Header>
      <h2>{children}</h2>
      <CrossButton
        className="modal__header__cross-button"
        size="medium"
        onClick={reject}
      />
    </ModalDialog.Header>
  );
};

CommonModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
};
