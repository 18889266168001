import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./InlineNotification.scss";

export const InlineNotification = ({ items }) => {
  const messageItems = _.map(items, ({ id, values }) => (
    <span key={id} className="notification__inline__item">
      <FormattedMessage id={id} values={values} />
    </span>
  ));

  return (
    <div className="notification__description notification__inline">
      <p>{messageItems}</p>
    </div>
  );
};

InlineNotification.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      values: PropTypes.object,
    })
  ),
};
