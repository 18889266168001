import _ from "lodash";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useMount, useForceUpdate } from "@sportal/cdk/hooks";
import { useLoadableSlice } from "../../../store/shared/withLoadingState";
import { loadLogicalDevices } from "../../../store/devices/logical";
import { getLines, getLogicalDevices } from "../../../store/root.selectors";
import { loadLines } from "../../../store/devices/lines";
import { loadRequests } from "../../../store/devices/requests";
import { getNewRequests } from "../../../store/devices";
import { Notification } from "../notification/Notification";
import { hasMultipleSupport } from "../../../store/info";
import { useWizardNavigationContext } from "../../../pages/wizard/WizardNavigationContext";

const NewRequestsNotificationContent = ({ onClick }) => {
  const dispatch = useDispatch();
  useLoadableSlice(loadLogicalDevices(), getLogicalDevices);
  useLoadableSlice(loadLines(), getLines);
  useMount(() => {
    dispatch(loadRequests());
  });

  const { withName, withCode, discovered } = useSelector(
    getNewRequests,
    _.isEqual
  );

  const requestsAmount = _.size(withName);
  const devicesAmount = _.size(withCode) + _.size(discovered);

  if (requestsAmount === 0 && devicesAmount === 0) {
    return null;
  }

  return (
    <Notification
      key="new-requests-notification"
      id="new-requests-notification"
      variant="info"
      description={{
        id: "new_requests_notification",
        values: {
          requestsAmount: requestsAmount,
          devicesAmount: devicesAmount,
          hasBoth: !!(requestsAmount && devicesAmount),
          a: text => <Link to="/settings/devices">{text}</Link>,
        },
      }}
      onClick={onClick}
    />
  );
};

const makeDismissable = () => {
  let isDismissed = false;

  return ({ children }) => {
    const forceUpdate = useForceUpdate();
    const dismiss = () => {
      isDismissed = true;
      forceUpdate();
    };

    return isDismissed ? null : children(dismiss);
  };
};

const Dismissable = makeDismissable();

const ShowOnPath = ({ children, path }) => {
  // Hackish workaround for EPORTAL-7557 that uses a fact that the notification
  // shows up only for UserModes with multiple support and we have
  // a router context only in this case as well
  const isWizard = !_.isEmpty(useWizardNavigationContext());
  const match = useRouteMatch(path);

  return !isWizard && match && match.isExact ? children : null;
};

export const NewRequestsNotification = () => {
  const shouldShow = useSelector(hasMultipleSupport);
  if (!shouldShow) {
    return null;
  }

  return (
    <Dismissable>
      {dismiss => (
        <ShowOnPath path="/home">
          <NewRequestsNotificationContent onClick={dismiss} />
        </ShowOnPath>
      )}
    </Dismissable>
  );
};
