import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Icon } from "@sportal/cdk";
import { BaseHeader } from "../../components/layout/headerComponents/baseHeader/BaseHeader";
import { Footer } from "../../components/layout/footer/Footer";
import { Logout } from "../../router/Logout";
import { getSubscriberId } from "../../store/info";

import "./ErrorPage.scss";

export const shouldShowErrorPage = (result, serviceCodes) => {
  const statusCode = result.error && result.error.code;

  return statusCode === serviceCodes.GENERIC;
};

const ErrorPageComponent = () => (
  <div className="layout">
    <BaseHeader>
      <img src="images/logo_header.svg" alt="Logo" />
    </BaseHeader>
    <div className="layout__container">
      <div className="error-page">
        <div className="error-page__title">
          <Icon icon="fas fa-exclamation-circle" />
          <span className="error-page__title__text">
            <FormattedMessage id="error" />
          </span>
        </div>
        <div className="error-page__description">
          <FormattedMessage id="something_went_wrong_on_our_end" />
        </div>
        <div className="error-page__description">
          <FormattedMessage
            id="in_mean_time_refresh_the_page"
            values={{
              refreshThePage: (
                <button
                  className="error-page__custom-button"
                  type="button"
                  onClick={() => window.location.reload()}
                >
                  <FormattedMessage id="refresh_the_page" />
                </button>
              ),
            }}
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export const ErrorPage = () => {
  const isUserAuthenticated = !!useSelector(getSubscriberId);

  return (
    <Fragment>
      <ErrorPageComponent />
      {isUserAuthenticated && <Logout doReload={false} />}
    </Fragment>
  );
};
