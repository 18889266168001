import React, { FC } from "react";
import classNames from "classnames";

import { Icon } from "@sportal/cdk";

import "./SortButton.scss";

interface SortButtonProps {
  isSorted: boolean;
  isSortedDesc: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const SortButton: FC<SortButtonProps> = ({
  isSorted,
  isSortedDesc,
  children,
  onClick,
  disabled,
  ...attrs
}) => {
  return (
    <button
      {...attrs}
      onClick={onClick}
      type="button"
      className="sort-button"
      disabled={disabled}
    >
      <span className="sort-button__content">{children}</span>

      <Icon
        aria-hidden={true}
        icon={classNames("sort-button__icon", {
          "far fa-sort-alt sort-button__icon--unsorted": !isSorted,
          "far fa-long-arrow-alt-down": isSorted && isSortedDesc,
          "far fa-long-arrow-alt-up": isSorted && !isSortedDesc,
        })}
      />
    </button>
  );
};
