import { getThreatsBySelectedLanguage } from "./threats.selectors";
import { isLoading, isSuccess } from "../shared/withLoadingState";
import { createTempNotification } from "../../components/notifications";
import {
  LOAD_THREATS,
  LOAD_THREATS_SUCCESS,
  LOAD_THREATS_FAILURE,
  ThreatsActionTypes,
} from "./threats.types";
import { isFailure, ThreatsData } from "@sportal/api";
import { Api } from "../../api";

export const loadThreats = language => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const slice = getThreatsBySelectedLanguage(getState());
  if (isLoading(slice) || isSuccess(slice)) {
    return;
  }

  dispatch({ type: LOAD_THREATS, payload: { language } } as ThreatsActionTypes);

  const result = await api.threats.get(language);

  if (isFailure(result)) {
    dispatch(loadThreatsFailure(language, result.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "failed_to_load_infections",
      })
    );
    return;
  }

  dispatch(loadThreatsSuccess(language, result.data));
};

export const loadThreatsSuccess = (
  language: string,
  threats: ThreatsData
): ThreatsActionTypes => ({
  type: LOAD_THREATS_SUCCESS,
  payload: { language, threats },
});

export const loadThreatsFailure = (
  language: string,
  error
): ThreatsActionTypes => ({
  type: LOAD_THREATS_FAILURE,
  payload: { language, error },
});
