import _ from "lodash";

import { hasMultipleSupport, isSS } from "../info";
import { isHideDevicesTab } from "../config";

const TABS = {
  account: {
    id: "account",
    url: "/account",
    route: "/account",
    translationKey: "account",
  },
  lists: {
    id: "lists",
    url: "/lists",
    route: "/lists/:url64?",
    translationKey: "black_white_list",
  },
  block: {
    id: "block",
    url: "/block-page",
    route: "/block-page",
    translationKey: "block_page",
  },
  devices: {
    id: "devices",
    url: "/devices",
    route: "/devices",
    translationKey: "devices",
  },
};

const TABS_COLLECTION = [TABS.account, TABS.lists, TABS.block, TABS.devices];
const SAFETY_TABS_COLLECTION = [TABS.account, TABS.block, TABS.devices];

export const getNavigationSettings = state => {
  if (hasMultipleSupport(state)) {
    return TABS_COLLECTION;
  }

  const tabs = isSS(state) ? SAFETY_TABS_COLLECTION : TABS_COLLECTION;

  return isHideDevicesTab(state) ? _.reject(tabs, { id: "devices" }) : tabs;
};

export const hasDevicesSupport = state =>
  getNavigationSettings(state).includes(TABS.devices);
