import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { RoamingDeviceStatus } from "@sportal/api";

import { Mobile, NotMobile } from "../../../../../../../../common/Responsive";
import { Popover } from "../../../../../../../../components/popover/Popover";
import { ProtectionStatus } from "../protectionStatus";
import { MobileModal, ModalService } from "../../../../../../../../components";

import "./ProtectionStatusInfo.scss";

const header = <FormattedMessage id={"protection_status_info_header"} />;
export const ProtectionStatusNotProtectedInfo = () => (
  <div>
    <p className={"protection-status-popover__description"}>
      <FormattedMessage id={"protection_status_info_list_label"} />
    </p>
    <ul className={"protection-status-popover__list"}>
      <li className={"protection-status-popover__list-item"}>
        <FormattedMessage id={"protection_status_info_list_item1"} />
      </li>
      <li className={"protection-status-popover__list-item"}>
        <FormattedMessage id={"protection_status_info_list_item2"} />
      </li>
      <li className={"protection-status-popover__list-item"}>
        <FormattedMessage id={"protection_status_info_list_item3"} />
      </li>
      <li className={"protection-status-popover__list-item"}>
        <FormattedMessage id={"protection_status_info_list_item4"} />
      </li>
    </ul>
  </div>
);

const ProtectionStatusModal = ({ modal }) => {
  return (
    <MobileModal
      modal={modal}
      header={header}
      content={<ProtectionStatusNotProtectedInfo />}
    />
  );
};

const openProtectionStatusModal = () =>
  ModalService.show(modal => ({
    dialog: <ProtectionStatusModal modal={modal} />,
  }))
    .then(_.noop)
    .catch(_.noop);

export const ProtectionStatusInfo = ({ status }) => {
  if (status === RoamingDeviceStatus.Enabled) {
    return <ProtectionStatus status={status} />;
  }

  return (
    <Fragment>
      <NotMobile>
        <Popover
          placement={"right-start"}
          popoverToggle={<ProtectionStatus status={status} />}
          popoverTitle={"protection_status_info_header"}
          popoverBody={<ProtectionStatusNotProtectedInfo />}
          popoverClassName={"protection-status-popover"}
        />
      </NotMobile>
      <Mobile>
        <ProtectionStatus status={status} onClick={openProtectionStatusModal} />
      </Mobile>
    </Fragment>
  );
};
