import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { BannerInline } from "../../bannerInline/BannerInline";
import { getRoamingDevicesLimits } from "../../../store/devices";
import { isSpsonMaxLimitWarningVisible } from "../../../store/banners";
import { hideSpsonMaxLimitWarning } from "../../../store/banners";

import "./RoamingLimitWarning.scss";

export const RoamingLimitWarning = () => {
  const dispatch = useDispatch();

  const isWarningVisible = useSelector(isSpsonMaxLimitWarningVisible);
  const {
    isRoamingLimitReached,
    roamingDevicesCount,
    roamingDevicesLimit,
    isRoamingLimitApproaching,
  } = useSelector(getRoamingDevicesLimits);

  const handleClick = () => dispatch(hideSpsonMaxLimitWarning());

  if (
    (!isRoamingLimitApproaching && !isRoamingLimitReached) ||
    !isWarningVisible
  ) {
    return null;
  }

  const reachingLimitMessage = (
    <FormattedMessage
      id="roaming_reaching_limit_info"
      values={{
        b: chunks => <span className="strong">{chunks}</span>,
        roamingDevicesCount,
        roamingDevicesLimit,
      }}
    />
  );

  const limitReachedMessage = (
    <FormattedMessage
      id="roaming_reached_limit_info"
      values={{
        b: chunks => <span className="strong">{chunks}</span>,
        roamingDevicesCount,
        roamingDevicesLimit,
      }}
    />
  );
  const message = isRoamingLimitReached
    ? limitReachedMessage
    : reachingLimitMessage;

  return (
    <BannerInline
      onClick={handleClick}
      className={"roaming-limit-warning"}
      message={message}
    />
  );
};
