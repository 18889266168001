import * as _ from "lodash";
import * as base32 from "hi-base32";

import { HttpService } from "../../shared";
import {
  DiscoveredDevicesServerData,
  IDeviceRequestsProvider,
  ReportDuration,
  RequestsWithCodeServerData,
  RequestsWithNameServerData,
} from "./deviceRequests.types";

export class DeviceRequestsProvider implements IDeviceRequestsProvider {
  get url(): string {
    return `${this.baseUrl}/ssm/series/device`;
  }

  constructor(private http: HttpService, private baseUrl: string) {}

  public async getRequestsWithName(
    subscriberId: string,
    duration: ReportDuration
  ) {
    const { data } = await this.http.get<RequestsWithNameServerData>(
      `${this.url}/registration?duration=${duration}&view=${subscriberId}`
    );
    return _.flatMap(data, ({ value }) => value)
      .map(([id, name, lastSeen]) => ({
        id,
        name: this.decodeDeviceName(name),
        lastSeen,
      }))
      .filter(({ name }) => name);
  }

  public async getRequestsWithCode(
    subscriberId: string,
    duration: ReportDuration
  ) {
    const { data } = await this.http.get<RequestsWithCodeServerData>(
      `${this.url}/blocks?duration=${duration}&view=${subscriberId}`
    );
    return _.flatMap(data, ({ value }) => value).map(
      ([id, code, lastSeen]) => ({
        id,
        code: code && code.replace(".bb.nom-proxy", "").slice(-4),
        lastSeen,
      })
    );
  }

  public async getDiscovered(subscriberId: string, duration: ReportDuration) {
    const { data } = await this.http.get<DiscoveredDevicesServerData>(
      `${this.url}/discovered?duration=${duration}&view=${subscriberId}`
    );
    return _.flatMap(data, ({ value }) => value).map(([id, lastSeen]) => ({
      id,
      lastSeen,
    }));
  }

  private decodeDeviceName(name: string): string {
    try {
      const encUriRegex = /\?enc=uri$/;
      return encUriRegex.test(name)
        ? decodeURIComponent(name.replace(encUriRegex, ""))
        : base32.decode(name.toUpperCase());
    } catch (e) {
      return name;
    }
  }
}
