import React, { VFC } from "react";
import { useSelect } from "downshift";
import classNames from "classnames";
import _ from "lodash";

import {
  ISelectItem,
  SelectButton,
  SelectItem,
  SelectLabel,
} from "./components";

import "./Select.scss";

export interface SelectProps {
  items: ISelectItem[];
  className?: string;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  ariaDescribedBy?: string;
  selected: string;
  onChange: (value: string) => void;
}

export const Select: VFC<SelectProps> = ({
  items,
  selected,
  onChange,
  className,
  label,
  ariaLabel,
  disabled,
  ariaDescribedBy,
}) => {
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect<ISelectItem>({
    items,
    itemToString: item => (item ? item.content : ""),
    selectedItem: _.find(items, { value: selected }) || null,
    onStateChange: changes => {
      changes.selectedItem && onChange(changes.selectedItem.value);
    },
    circularNavigation: true,
  });

  return (
    <div className={classNames("select-wrapper", className)}>
      <SelectLabel
        getLabelProps={getLabelProps}
        ariaLabel={ariaLabel}
        value={label}
      />

      <div
        className={classNames("select", {
          "select--opened": isOpen,
          "select--disabled": disabled,
        })}
      >
        <SelectButton
          getToggleButtonProps={getToggleButtonProps}
          disabled={disabled}
          selectedItem={selectedItem}
          ariaDescribedBy={ariaDescribedBy}
        />

        <div className="select__menu-wrapper">
          <ul
            {...getMenuProps({
              onKeyDown: e => {
                e.stopPropagation();
              },
            })}
            className="select-menu"
          >
            {isOpen &&
              items.map((item, index) => (
                <SelectItem
                  item={item}
                  key={item.value}
                  index={index}
                  getItemProps={getItemProps}
                  selected={items.indexOf(selectedItem) === index}
                  highlighted={highlightedIndex === index}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
