import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./SettingsNavBar.scss";

export const SettingsNavBar = ({ tabs }) => {
  return (
    <div className="settings-navigation">
      {_.map(tabs, ({ id, url, translationKey }) => (
        <NavLink
          key={id}
          to={`/settings${url}`}
          className="settings-navigation__button"
          activeClassName="settings-navigation__button--active"
        >
          <span className="settings-navigation__tab-title">
            <FormattedMessage id={translationKey} />
          </span>
          <span className={`settings-navigation__image image__${id}`} />
        </NavLink>
      ))}
    </div>
  );
};
