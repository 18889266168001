import {
  InfoProvider,
  AuthService,
  AuthProvider,
  UserMode,
  HttpService,
} from "@sportal/api";

const allowedUserModes = [
  UserMode.Household,
  UserMode.PerDevice,
  UserMode.PIProfile,
  UserMode.PISingle,
  UserMode.SubscriberSafety,
];

export const makeAuthService = (
  http: HttpService,
  baseUrl: string
): AuthService =>
  new AuthService(
    new InfoProvider(http, baseUrl),
    new AuthProvider(http, baseUrl),
    allowedUserModes
  );
