import { getBackendUrl } from "../../store/config";
import { AuthServiceErrorCode } from "@sportal/api";
import { createTempNotification } from "../../components/notifications";

const errorMessages = {
  [AuthServiceErrorCode.UNAUTHORIZED]: "login_error",
};

const notificationTemplates = {
  [AuthServiceErrorCode.UNAUTHORIZED]: {
    variant: "error",
    description: errorMessages[AuthServiceErrorCode.UNAUTHORIZED],
  },
};

export const LOGIN = "[AUTH] LOGIN";
export const login = (username, password) => (dispatch, getState, { api }) => {
  dispatch({ type: LOGIN });

  return api.auth.login(username, password).then(result => {
    const { success, error } = result;

    if (!success) {
      const errorCode = error.code;
      const message = errorMessages[errorCode];
      // TODO: it may make sense to add the /info loading
      //  as a part of the login process since login success/failure
      //  do not affect state directly
      //  (atm info is reset on LoginSuccess to reload the slice)
      dispatch(loginFailure({ ...error, message }));

      if (notificationTemplates[errorCode]) {
        dispatch(createTempNotification(notificationTemplates[errorCode]));
      }
    } else {
      dispatch(loginSuccess());
    }
  });
};

export const LOGIN_SUCCESS = "[AUTH] LOGIN_SUCCESS";
export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const LOGIN_FAILURE = "[AUTH] LOGIN_FAILURE";
export const loginFailure = error => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const SSO_LOGIN = "[AUTH] SSO_LOGIN";
export const ssoLogin = () => (dispatch, getState, { location }) => {
  dispatch({ type: SSO_LOGIN });

  const backendUrl = getBackendUrl(getState());

  location.assign(
    `${backendUrl}/saml/login?action=` +
      encodeURIComponent(location.origin + location.pathname + "#home")
  );
};
