import React, { Children, cloneElement } from "react";
import classnames from "classnames";

import "./DevicesControlBar.scss";

export const DevicesControlBar = ({ children, className }) => {
  return (
    <div className={classnames("devices-control-bar", className)}>
      {Children.map(
        children,
        child =>
          child &&
          cloneElement(child, {
            className: "devices-control-bar__control",
          })
      )}
    </div>
  );
};
