import React, { Fragment } from "react";
import { Column } from "react-table";
import { FormattedMessage } from "react-intl";

import {
  AdvancedColumn,
  AriaAdvancedColumn,
  defaultHiddenColumnConfig,
  FormattedDateTime,
  NO_VALUE_PLACEHOLDER,
  showValue,
} from "../../../../../../../components";
import { ProfileDeviceMenu } from "../../ProfileDeviceMenu";
import { ProfileDeviceTypeTooltip } from "../../../../../../../components/profileDevices/profileDeviceTypeTooltip";
import { DeviceTypeHeader } from "../../../tableComponents";
import { alphanumeric } from "../../../../../../../components/table/helpers/table-sorting.helpers";
import { isRoamingType } from "../profileDeviceTable.helpers";
import { ProtectionStatusTranslateMap } from "../roamingComponents";
import { DeviceIds } from "../deviceIds";
import { ProtectionStatusNotProtectedInfo } from "../roamingComponents/protectionStatusInfo/ProtectionStatusInfo";

const ROAMING_COLUMNS: AdvancedColumn[] = [
  {
    header: <FormattedMessage id="roaming_uuid" />,
    renderValue: device => device.deviceDetails[0].identifier,
  },
  {
    header: <FormattedMessage id="roaming_full_name" />,
    renderValue: device => device.deviceDetails[0].username,
  },
  {
    header: <FormattedMessage id="roaming_protection_status" />,
    renderValue: device => (
      <Fragment>
        <FormattedMessage
          id={ProtectionStatusTranslateMap[device.deviceDetails[0].status]}
        />
        <ProtectionStatusNotProtectedInfo />
      </Fragment>
    ),
  },
  {
    header: <FormattedMessage id="roaming_app_status" />,
    renderValue: device => device.deviceDetails[0].status,
  },
  {
    header: <FormattedMessage id="roaming_app_version" />,
    renderValue: device => device.deviceDetails[0]["software-version"],
  },
  {
    header: <FormattedMessage id="roaming_os" />,
    renderValue: device => device.deviceDetails[0].os,
  },
];

const NON_ROAMING_COLUMNS = [
  {
    header: <FormattedMessage id="network_id" />,
    renderValue: device => {
      return (
        <DeviceIds
          identifiers={device.identifiers}
          phoneNumber={device.phoneNumber}
          networkId={device.networkId}
        />
      );
    },
  },
];

export const ADVANCED_DETAILS_COLUMN: Column<any> = {
  ...defaultHiddenColumnConfig,
  id: "details",
  Header: <FormattedMessage id="details" />,
  Cell: ({ row }) => {
    const device = row && row.original;
    const isRoaming = isRoamingType(
      device.identifiers[0],
      device.deviceDetails
    );
    const columns = isRoaming ? ROAMING_COLUMNS : NON_ROAMING_COLUMNS;

    return <AriaAdvancedColumn columns={columns} device={device} />;
  },
};

export const COLUMNS: Column<any>[] = [
  {
    Header: <DeviceTypeHeader />,
    id: "deviceType",
    Cell: ({ row }) => (
      <ProfileDeviceTypeTooltip identifiers={row.original.identifiers} />
    ),
    width: 12,
    disableSortBy: true,
  },
  {
    Header: <FormattedMessage id="device_name" />,
    headerTranslationId: "device_name",
    accessor: "name",
    width: "minmax(200px, 1fr)",
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="manufacturer_or_device_info" />,
    headerTranslationId: "manufacturer_or_device_info",
    accessor: "manufacturer",
    Cell: ({ value }) => <span>{showValue<string>(value)}</span>,
    maxWidth: 200,
    sortType: alphanumeric,
  },
  {
    Header: <FormattedMessage id="last_seen" />,
    headerTranslationId: "last_seen",
    accessor: "lastSeen",
    Cell: ({ value }) =>
      value ? <FormattedDateTime value={value} /> : NO_VALUE_PLACEHOLDER,
    maxWidth: 170,
  },
  {
    Header: <FormattedMessage id="actions" />,
    id: "actions",
    // @ts-ignore
    Cell: ({ row }) => <ProfileDeviceMenu device={row.original} />,
    width: 70,
    disableSortBy: true,
  },
];
