import React from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./Tooltip.scss";

export const Tooltip = ({
  className,
  setPopperElement,
  setArrowElement,
  styles,
  attributes,
  children,
}) => {
  const rootElement = document.querySelector("#root") || document.body;

  return createPortal(
    <div
      className={classNames("tooltip", className)}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="tooltip__content">{children}</div>
      <div
        className="tooltip__arrow"
        ref={setArrowElement}
        style={styles.arrow}
      />
    </div>,
    rootElement
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  setPopperElement: PropTypes.func.isRequired,
  setArrowElement: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
