import React, { createContext, useContext } from "react";

export const AppContext = createContext({});

export function AppProvider({ children, value }) {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
function useGlobal(kind) {
  const context = useContext(AppContext);
  return context[kind];
}

export function useApi() {
  return useGlobal("api");
}
