import { Line } from "@sportal/api";
import { NormalizedCollection } from "../../shared/normalizeCollection";

export const LOAD_LINES = "[DEVICES] LOAD_LINES";
export const LOAD_LINES_SUCCESS = "[DEVICES] LOAD_LINES_SUCCESS";
export const LOAD_LINES_FAILURE = "[DEVICES] LOAD_LINES_FAILURE";

interface LoadLinesAction {
  type: typeof LOAD_LINES;
}

interface LoadLinesSuccessAction {
  type: typeof LOAD_LINES_SUCCESS;
  payload: NormalizedCollection<Line>;
}

interface LoadLinesFailureAction {
  type: typeof LOAD_LINES_FAILURE;
  payload: object;
}

export type LinesActionTypes =
  | LoadLinesAction
  | LoadLinesSuccessAction
  | LoadLinesFailureAction;
