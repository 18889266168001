import React from "react";
import { FormattedMessage } from "react-intl";

import {
  SAVE_PROFILE_PROTECTION,
  SAVE_PROFILE_PROTECTION_SUCCESS,
  SAVE_PROFILE_PROTECTION_FAILURE,
  ProtectionsActionTypes,
} from "./protections.types";
import { isFailure, Protection } from "@sportal/api";
import { getSubscriberId } from "../../info";
import { getProfile } from "../../profiles";
import { createTempNotification } from "../../../components/notifications";
import { Api } from "../../../api";

export const saveProfileProtection = (
  id,
  { name: protectionName, blocked = [] }
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: SAVE_PROFILE_PROTECTION });

  const state = getState();
  const subscriberId = getSubscriberId(state);
  const profile = getProfile(state, id);

  if (profile.protection !== protectionName) {
    const saveProfileResult = await api.profiles.update(
      subscriberId,
      profile.name,
      { protection: protectionName }
    );

    if (isFailure(saveProfileResult)) {
      dispatch(saveProfileProtectionFailure(saveProfileResult.error));
      dispatch(
        createTempNotification({
          variant: "error",
          title: "error",
          description: "couldnt_save_changes",
        })
      );
      return;
    }
  }

  if (protectionName === Protection.Custom) {
    const saveCustomResult = await api.protection.saveCustomProtection(
      subscriberId,
      profile.name,
      blocked
    );

    if (isFailure(saveCustomResult)) {
      dispatch(saveProfileProtectionFailure(saveCustomResult.error));
      dispatch(
        createTempNotification({
          variant: "error",
          title: "error",
          description: "couldnt_save_changes",
        })
      );
      return;
    }
  }

  dispatch(
    saveProfileProtectionSuccess(profile, { name: protectionName, blocked })
  );
  dispatch(
    createTempNotification({
      variant: "success",
      title: "changes_saved",
      description: {
        id: "protection_level_message",
        values: { message: <FormattedMessage id={protectionName} /> },
      },
    })
  );
};

export const saveProfileProtectionSuccess = (
  profile,
  protection
): ProtectionsActionTypes => ({
  type: SAVE_PROFILE_PROTECTION_SUCCESS,
  payload: { profile, protection },
});

export const saveProfileProtectionFailure = (
  error
): ProtectionsActionTypes => ({
  type: SAVE_PROFILE_PROTECTION_FAILURE,
  payload: error,
});
