import { NEW_SCHEDULES, WEEK_DAYS } from "./scheduler.consts";
import { getFirstDayOfWeek, getShowToggles } from "../../store/config";

export const getSchedule = (schedules, scheduleType) => {
  const schedule = findSchedule(schedules, scheduleType);

  return schedule
    ? { schedule }
    : {
        schedule: findSchedule(NEW_SCHEDULES, scheduleType),
        isNewSchedule: true,
      };
};

export const preparePayload = values => {
  const newSchedule = { ...values };

  const sortDays = (a, b) => {
    a = WEEK_DAYS.indexOf(a);
    b = WEEK_DAYS.indexOf(b);

    return a < b ? -1 : 1;
  };

  return {
    ...values,
    day: newSchedule.day.sort(sortDays),
  };
};

export const getWeekdays = state => {
  const firstDayOfWeek = getFirstDayOfWeek(state);
  const startIndex = WEEK_DAYS.indexOf(firstDayOfWeek);

  return [...WEEK_DAYS.slice(startIndex), ...WEEK_DAYS.slice(0, startIndex)];
};

export const getToggles = state =>
  getShowToggles(state).map(toggle =>
    toggle.replace("homework-time", "homework")
  );

export const findSchedule = (schedules, scheduleType) =>
  schedules.find(({ type }) => type === scheduleType);
