import React from "react";
import _ from "lodash";

import { useIntlTranslate } from "../../../hooks";
import { Bars } from "../Bars";
import { ReportTypes } from "../../../store/reports";
import { useReportContext } from "../ReportProvider";

import "./ChartComponents.scss";

export const ActivityBars = () => <Bars type={ReportTypes.Activity} />;

export const BlocksBars = () => <Bars type={ReportTypes.Blocks} />;

export const SSBars = () => <Bars type={ReportTypes.SS} />;

export const ChartMessage = () => {
  const { data } = useReportContext();
  const translate = useIntlTranslate();

  if (!_.isEmpty(data)) return null;
  return (
    <g className={"message"}>
      <text x={"50%"} y={"50%"}>
        {translate("no_available_data")}
      </text>
    </g>
  );
};
