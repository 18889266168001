import React from "react";
import _ from "lodash";

import { Select } from "../../../../../components";

export const makeProfilePickerItems = (profiles, translate) =>
  _.map(profiles, ({ profile, isFull }) => ({
    value: profile.name,
    content: profile.default ? translate("default") : profile.name,
    disabled: isFull,
  }));

export const ProfilesPicker = ({ items, selected, onChange, label }) => {
  return (
    <Select
      items={items}
      name="profile"
      label={label}
      selected={selected}
      onChange={onChange}
      disabled={items.length === 1}
    />
  );
};
