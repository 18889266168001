import { Protection } from "@sportal/api";
import { withLoadingState } from "../../shared/withLoadingState";
import {
  LOAD_CUSTOM_PROTECTIONS,
  LOAD_CUSTOM_PROTECTIONS_SUCCESS,
  LOAD_CUSTOM_PROTECTIONS_FAILURE,
  SAVE_CUSTOM_PROTECTION_SUCCESS,
  CustomProtectionsActionTypes,
} from "./customProtections.types";
import {
  SAVE_PROFILE_PROTECTION_SUCCESS,
  SaveProfileProtectionSuccessAction,
} from "..";
import {
  addOrUpdateItem,
  removeItem,
} from "../../shared/collection.operations";

type AllCustomProtectionsActionTypes =
  | CustomProtectionsActionTypes
  | SaveProfileProtectionSuccessAction;

export const reducer = (
  state = {},
  action: AllCustomProtectionsActionTypes
) => {
  switch (action.type) {
    case LOAD_CUSTOM_PROTECTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_CUSTOM_PROTECTION_SUCCESS: {
      return {
        ...state,
        ...addOrUpdateItem(state, action.payload, "profile"),
      };
    }
    case SAVE_PROFILE_PROTECTION_SUCCESS:
      return {
        ...state,
        ...(action.payload.protection.name === Protection.Custom
          ? addOrUpdateItem(
              state,
              {
                ...action.payload.protection,
                profile: action.payload.profile.id,
              },
              "profile"
            )
          : removeItem(state, action.payload.profile.id)),
      };
    default:
      return state;
  }
};

export const customProtectionsReducer = withLoadingState({
  loadActionType: LOAD_CUSTOM_PROTECTIONS,
  successActionType: LOAD_CUSTOM_PROTECTIONS_SUCCESS,
  failureActionType: LOAD_CUSTOM_PROTECTIONS_FAILURE,
})(reducer);
