import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { HorizontalMenu } from "../../../components/horizontalMenu/HorizontalMenu";

import "./SettingsNavBar.scss";

export const MobileSettingsNavBar = ({ tabs }) => {
  const navigationTabs = _.map(tabs, ({ id, url, translationKey }) => (
    <NavLink
      key={id}
      to={`/settings${url}`}
      className="mb-settings-navigation__button"
      activeClassName="mb-settings-navigation__button--active hm__item--active"
    >
      <span className={`mb-settings-navigation__image image__${id}`} />

      <span className="mb-settings-navigation__tab-title">
        <FormattedMessage id={translationKey} />
      </span>
    </NavLink>
  ));

  return (
    <div className="settings-navbar-wrapper">
      <HorizontalMenu
        className="mb-settings-navigation"
        menuItems={navigationTabs}
      />
    </div>
  );
};
