/**
 * Transforms passed locale string to BCP-47 language tag form so that it is compatible with Intl
 * @param locale string
 * @returns string
 */
export function normalizeLocale(locale) {
  // The value we use as default in appConfig, it is not correct language so we have to replace it
  if (locale === "us_EG") {
    return "en-US";
  }

  return locale && locale.replace("_", "-");
}
