import React from "react";
import PropTypes from "prop-types";

export const BaseButton = React.forwardRef(function BaseButton(
  { children, type = "button", onClick, disabled, ...attrs },
  ref
) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      ref={ref}
      {...attrs}
    >
      {children}
    </button>
  );
});

BaseButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
