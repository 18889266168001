import React, { useState, useEffect } from "react";
import { WizardStep } from "./wizardStep/WizardStep";

import "./WizardSteps.scss";

const initialSteps = [
  {
    stepNumber: 1,
    title: "create_profiles",
  },
  {
    stepNumber: 2,
    title: "protection_setup",
  },
  {
    stepNumber: 3,
    title: "assign_devices",
  },
];

const calculateSteps = currentStep =>
  initialSteps.map(step => ({
    ...step,
    isActive: step.stepNumber === currentStep,
    isCompleted: step.stepNumber <= currentStep - 1,
  }));

export function WizardSteps({ currentStep }) {
  const [steps, setSteps] = useState(calculateSteps(currentStep));

  useEffect(() => {
    setSteps(calculateSteps(currentStep));
  }, [currentStep]);

  return (
    <ul className="wizard-steps">
      {steps &&
        steps.map(({ title, stepNumber, isActive, isCompleted }) => (
          <WizardStep
            key={title}
            title={title}
            stepNumber={stepNumber}
            isActive={isActive}
            isCompleted={isCompleted}
          />
        ))}
    </ul>
  );
}
