import React from "react";
import _ from "lodash";

import { withAuthGuard } from "./guards/AuthGuard";
import { withSettingsGuard } from "./guards/SettingsGuard";
import { withAccountGuard } from "./guards/AccountGuard";
import { withAttributesGuard } from "./guards/AttributesGuard";
import { withWizardGuard } from "./guards/WizardGuard";
import { AppRouter } from "./AppRouter";

const withInitialData = _.flowRight([
  withAuthGuard,
  withSettingsGuard,
  withAccountGuard,
  withAttributesGuard,
  withWizardGuard,
]);

export const AuthRouter = withInitialData(() => <AppRouter />);
