import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { SwitchBase } from "../switchBase/SwitchBase";
import MaskSwitchIcon from "../maskSwitchIcon/MaskSwitchIcon";

import "./Radio.scss";

const RadioGroupContext = React.createContext({ unwrapped: true });

export function Radio({ label, ...attrs }) {
  const {
    name,
    selectedValue,
    onChange,
    disabled,
    error,
    unwrapped,
  } = React.useContext(RadioGroupContext);

  if (unwrapped) {
    // eslint-disable-next-line no-console
    console.error("Radio must have RadioGroup ancestor");
    return null;
  }

  const optional = {};

  if (selectedValue !== undefined) {
    optional.checked = attrs.value === selectedValue;
  }

  if (typeof onChange === "function") {
    optional.onChange = onChange.bind(null, attrs.value);
  }

  return (
    <SwitchBase
      {...attrs}
      aria-checked={optional.checked}
      label={label}
      name={name}
      disabled={disabled}
      type={"radio"}
      {...optional}
    >
      <MaskSwitchIcon
        className={classNames("radio-switch", {
          "radio-switch--checked": optional.checked,
          "radio-switch--error": error,
          "radio-switch--disabled": disabled,
        })}
      />
    </SwitchBase>
  );
}

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.bool,
  warning: PropTypes.bool,
};

export function RadioGroup({
  Component,
  name,
  selectedValue,
  onChange,
  children,
  disabled,
  error,
  ...rest
}) {
  return (
    <RadioGroupContext.Provider
      value={{
        name,
        selectedValue,
        onChange,
        disabled,
        error,
      }}
    >
      <Component role="radiogroup" {...rest}>
        {children}
      </Component>
    </RadioGroupContext.Provider>
  );
}

RadioGroup.defaultProps = {
  Component: "div",
};

RadioGroup.propTypes = {
  Component: PropTypes.node,
  name: PropTypes.string.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};
