import React, { memo, VFC } from "react";
import { useSelector } from "react-redux";

import { LogicalDevice } from "@sportal/api";
import { isRoaming } from "../../../../../store/devices";
import { ProfileRoamingDeviceMenu } from "./ProfileRoamingDeviceMenu";
import { ProfileNonRoamingDeviceMenu } from "./ProfileNonRoamingDeviceMenu";

import "./ProfileDeviceMenu.scss";

export interface ProfileDeviceMenuProps {
  device: LogicalDevice & {
    networkId?: string;
    phoneNumber?: string;
  };
}

export const ProfileDeviceMenu: VFC<ProfileDeviceMenuProps> = memo(
  function ProfileDeviceMenu({ device }) {
    const isRoamingDevice = useSelector(isRoaming(device));

    return isRoamingDevice ? (
      <ProfileRoamingDeviceMenu device={device} />
    ) : (
      <ProfileNonRoamingDeviceMenu device={device} />
    );
  }
);
