import React from "react";

import { BaseHeader } from "../headerComponents/baseHeader/BaseHeader";
import { HeaderLogo } from "../headerComponents/headerLogo/HeaderLogo";
import { HeaderTitle } from "../headerComponents/headerTitle/HeaderTitle";
import { CommonHeaderUserMenu } from "../headerComponents/headerUserMenu/CommonHeaderUserMenu";
import { Mobile, NotMobile } from "../../../common/Responsive";
import { GoHomeButton } from "../../goHomeButton/GoHomeButton";

import "./Header.scss";

export const Header = () => (
  <BaseHeader className="common-header">
    <NotMobile>
      <div className="common-header__content-left">
        <HeaderLogo />
        <HeaderTitle />
      </div>
    </NotMobile>
    <Mobile>
      <div className="common-header__content-left">
        <GoHomeButton />
      </div>
    </Mobile>
    <div className="common-header__content-right">
      <CommonHeaderUserMenu />
    </div>
  </BaseHeader>
);
