import { useState, useMemo } from "react";
import { usePopper } from "react-popper";

export const getOffset = ({ placement }) => {
  if (placement === "top" || placement === "bottom") {
    return [0, 4];
  } else {
    return [0, 8];
  }
};

export const useTooltipPosition = (
  placement = "top",
  modifiers = [],
  referrerElement = null
) => {
  const [referenceElement, setReferenceElement] = useState(referrerElement);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement, padding: 10 } },
      { name: "offset", options: { offset: getOffset } },
      ...modifiers,
    ],
  });

  return useMemo(
    () => ({
      setReferenceElement,
      setPopperElement,
      setArrowElement,
      styles,
      attributes,
    }),
    [styles, attributes]
  );
};
