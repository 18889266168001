import React from "react";
import _ from "lodash";

import {
  LOAD_REPORTS,
  LOAD_REPORTS_FAILURE,
  LOAD_REPORTS_SUCCESS,
  LoadReportsPayload,
  ReportsActionTypes,
} from "./reports.types";
import { getSubscriberId } from "../info";
import {
  Endpoint,
  EndpointsTranslationsMap,
  ReportTypes,
} from "./reports.constants";
import {
  createTempNotification,
  ListNotification,
} from "../../components/notifications";
import { Api } from "../../api";
import { Durations } from "@sportal/api/reports";

const mergeData = data => {
  return _.chain(data)
    .mapValues("data")
    .flatMap((data, type) =>
      _.map(data, ({ value, time }) => ({ [type]: value, time }))
    )
    .groupBy("time")
    .map(data => _.reduce(data, (memo, obj) => ({ ...memo, ...obj }), {}))
    .value();
};

export const loadReports = (
  types: ReportTypes[],
  duration: Durations,
  profileName: string
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: LOAD_REPORTS });

  const subscriberId = getSubscriberId(getState());
  const endpoints = getEndpoints(types);

  const data = await Promise.all(
    endpoints.map(
      async endpoint =>
        await api.reports.get(endpoint, subscriberId, duration, profileName)
    )
  );

  const result = _.zipObject(types, data);

  handleFailures(dispatch, result, types);

  dispatch(
    loadReportsSuccess({
      data: mergeData(result),
      ...(profileName && { profile: profileName }),
      duration,
    })
  );
};

const getEndpoints = types => _.map(types, type => Endpoint[type]);

const handleFailures = (dispatch, result, types) => {
  const failures = _.chain(result)
    .pickBy(value => !value.success)
    .keys()
    .value();

  if (_.isEmpty(failures)) return;

  const isAllFailed = failures.length === types.length;

  if (isAllFailed) {
    dispatch(loadReportsFailure({ message: "failed to load resources" }));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description: "failed_load_reports_data",
      })
    );
  } else {
    const translatedItems = _.map(
      failures,
      type => EndpointsTranslationsMap[type]
    );
    dispatch(
      createTempNotification({
        variant: "error",
        custom: (
          <ListNotification
            title={"error"}
            description={"failed_load_reports_data"}
            items={translatedItems}
          />
        ),
      })
    );
  }
};

export const loadReportsSuccess = (
  data: LoadReportsPayload
): ReportsActionTypes => ({
  type: LOAD_REPORTS_SUCCESS,
  payload: data,
});

export const loadReportsFailure = (error): ReportsActionTypes => ({
  type: LOAD_REPORTS_FAILURE,
  payload: error,
});
