import _ from "lodash";

import { withLoadingState } from "../shared/withLoadingState";
import {
  ADD_URL_SUCCESS,
  CHECK_URL_FAILURE,
  CHECK_URL_REQUEST,
  CHECK_URL_SUCCESS,
  LOAD_LISTS_FAILURE,
  LOAD_LISTS_REQUEST,
  LOAD_LISTS_SUCCESS,
  REMOVE_URL_SUCCESS,
  RESET_URL_OPTIONS,
} from "./lists.actions";

const initialState = {
  profiles: {},
  urlOptions: {},
};

const profileListsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_LISTS_SUCCESS: {
      return {
        ...state,
        ...action.payload.lists,
      };
    }
    case ADD_URL_SUCCESS: {
      const { listType, url } = action.payload;
      const content = state[listType].content
        ? [...state[listType].content, url]
        : [url];

      return {
        ...state,
        [listType]: {
          ...state[listType],
          content,
        },
      };
    }
    case REMOVE_URL_SUCCESS: {
      const { listType, urls: removedUrls } = action.payload;
      const oldUrls = state[listType].content;
      const newUrls = _.differenceWith(oldUrls, removedUrls, _.isEqual);

      return {
        ...state,
        [listType]: {
          ...state[listType],
          content: newUrls,
        },
      };
    }
    default:
      return state;
  }
};

const profileListsReducerWithLoadingState = withLoadingState({
  loadActionType: LOAD_LISTS_REQUEST,
  successActionType: LOAD_LISTS_SUCCESS,
  failureActionType: LOAD_LISTS_FAILURE,
  dynamicSlice: true,
})(profileListsReducer);

const urlCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_URL_SUCCESS:
      return {};
    case RESET_URL_OPTIONS:
      return {
        ...state,
        list: undefined,
      };
    case CHECK_URL_REQUEST: {
      return {
        ...state,
        url: action.payload.url,
        error: undefined,
      };
    }
    case CHECK_URL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case CHECK_URL_SUCCESS: {
      return {
        ...state,
        list: action.payload.options,
        error: undefined,
      };
    }
    default:
      return state;
  }
};

export const listsReducer = (state = initialState, action) => {
  const profilesSliceReducer = (state, action) => {
    if (!action.payload || !action.payload.profileId) {
      return state;
    }

    return {
      ...state,
      [action.payload.profileId]: profileListsReducerWithLoadingState(
        state && state[action.payload.profileId],
        _.omit(action, ["payload.profileId"])
      ),
    };
  };
  return {
    profiles: profilesSliceReducer(state.profiles, action),
    urlOptions: urlCheckReducer(state.urlOptions, action),
  };
};
