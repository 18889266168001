export function makePersistentStorage(storage) {
  const proxy = {
    setItem: (key, value) => storage.setItem(key, value),
    getItem: key => storage.getItem(key),
    removeItem: key => storage.removeItem(key),
  };

  proxy.Keys = {
    logoutReason: "logoutReason",
  };

  return proxy;
}
