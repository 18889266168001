export enum Attribute {
  LANGUAGE = "language",
  TIME_FORMAT = "is24hourFormat",
  EMAIL = "address",
  EMAIL_FREQUENCY = "frequency",
  BUSINESS_NAME = "businessName",
}

export interface AttributesData {
  [Attribute.LANGUAGE]: string | null;
  [Attribute.TIME_FORMAT]: boolean | null;
  [Attribute.BUSINESS_NAME]: string | null;
  [Attribute.EMAIL]: string | null;
  [Attribute.EMAIL_FREQUENCY]: string | null;
}

export type RawAttribute =
  | { name: "nom.pi.language"; value: string | null }
  | { name: "nom.pi.format24h"; value: string | null }
  | { name: "nom.smb.language"; value: string | null }
  | { name: "nom.smb.format24h"; value: string | null }
  | { name: "nom.smb.email"; value: string | null }
  | { name: "nom.smb.email.frequency"; value: string | null }
  | { name: "nom.smb.businessname"; value: string | null };

export interface AttributesServerData {
  content: RawAttribute[];
  total: number;
  limit: number;
}

// TODO: It is possible to infer keys in the AttributesData based on
//  keys of the passed map, so that it can be defined on instantiation
//  after that the following type can check for exhaustiveness
export type AttributesMap = { [key in Attribute]?: string };
