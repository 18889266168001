import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { getSubscriberId } from "../../../../store/info";
import { getIs24hourFormat } from "../../../../store/attributes";
import {
  Checkbox,
  Form,
  ReadOnlyInput,
  Select,
  SubmitButton,
  useForm,
  TabHeader,
} from "../../../../components";
import { getLanguageList } from "../../../../store/config";
import { getSelectedLanguage } from "../../../../i18n/language.selectors";
import { TimezonePicker } from "./TimezonePicker";
import { getAPRStatus, getTimezone } from "../../../../store/account";
import { isBypassPrivateDNSFeatureEnabled } from "../../../../store/settings";
import { saveAccountSettings } from "./accountTab.actions";
import { SettingsTabTitle } from "../../settingsTabTitle/SettingsTabTitle";
import { useMobileValue } from "../../../../common/Responsive";
import { TabSection } from "../tabSection";

import "./AccountTab.scss";

function SubscriberInput() {
  const subscriberId = useSelector(getSubscriberId);

  return (
    <ReadOnlyInput
      id="subscriberId"
      label={<FormattedMessage id="subscriber_id" />}
      value={subscriberId}
    />
  );
}

function LanguagesSelect({ value, onChange }) {
  const languageList = useSelector(getLanguageList);

  const languageOptions = languageList.map(lang => ({
    value: lang.key,
    content: lang.name,
  }));

  return (
    <Select
      className="account-tab__row"
      items={languageOptions}
      label={<FormattedMessage id="language" />}
      selected={value}
      onChange={onChange}
    />
  );
}

export const AccountTab = () => {
  const dispatch = useDispatch();
  const currentTimezone = useSelector(getTimezone);
  const { key: language } = useSelector(getSelectedLanguage);
  const is24hourFormat = useSelector(getIs24hourFormat);
  const shouldShowPrivacy = useSelector(isBypassPrivateDNSFeatureEnabled);
  const aprOn = useSelector(getAPRStatus);

  const form = useForm({
    timezone: currentTimezone,
    language,
    is24hourFormat,
    ...(!_.isUndefined(aprOn) && { aprOn }),
  });

  const handleSubmit = useCallback(() => {
    dispatch(saveAccountSettings(form.getChanges()));
  }, [form, dispatch]);

  const buttonSize = useMobileValue("large", "medium");

  return (
    <div className="account-tab">
      <TabHeader>
        <SettingsTabTitle tabName="account" />
      </TabHeader>
      <Form
        onSubmit={handleSubmit}
        formProps={form}
        disabled={!form.didFormChange()}
      >
        <TabSection
          className="account-tab__fieldset"
          header={<FormattedMessage id="general_account_section_title" />}
        >
          <SubscriberInput />
          <TimezonePicker
            currentTimezone={form.values.timezone}
            onChange={value => form.set("timezone", value)}
          />
          <Checkbox
            id={"account-tab-24-hour-format"}
            className="account-tab__row"
            name="is24hourFormat"
            label={<FormattedMessage id="24_hour_format" />}
            checked={!!form.values.is24hourFormat}
            onChange={event => form.set("is24hourFormat", event.target.checked)}
          />
          <LanguagesSelect
            value={form.values.language}
            onChange={value => form.set("language", value)}
          />
        </TabSection>

        {shouldShowPrivacy && (
          <TabSection
            header={<FormattedMessage id="privacy_account_section_title" />}
            description={
              <FormattedMessage id="privacy_account_section_description" />
            }
          >
            <Checkbox
              id="apr_on"
              name="aprOn"
              label={<FormattedMessage id="apr_checkbox_account" />}
              checked={form.values.aprOn}
              onChange={event => form.set("aprOn", event.target.checked)}
            />
          </TabSection>
        )}

        <div className="settings-page__footer">
          <SubmitButton color="primary" size={buttonSize}>
            <FormattedMessage id="save" />
          </SubmitButton>
        </div>
      </Form>
    </div>
  );
};
