import React, { FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";

import {
  CellDetailsMobile,
  TableMobile,
} from "../../../../../../../components";
import { COLUMNS_MOBILE } from "./RequestsTableMobile.consts";
import { RenderRowDetailsMobileInterface } from "../../../../../../../components/table/tableMobile/components";

const INITIAL_SORT_BY = [{ id: "lastSeen", desc: true }];

const renderDeviceDetails: RenderRowDetailsMobileInterface = ({ row }) => {
  const {
    original: { networkId, phoneNumber, manufacturer },
  } = row;

  return (
    <Fragment>
      <CellDetailsMobile
        id="network-id"
        header={<FormattedMessage id="network_id" />}
        value={networkId || phoneNumber}
      />
      <CellDetailsMobile
        id={"manufacturer"}
        header={<FormattedMessage id="manufacturer" />}
        value={manufacturer}
      />
    </Fragment>
  );
};

interface RequestsTableMobileProps {
  devices: any[];
  isAdvancedView: boolean;
  tableName: string;
}

export const RequestsTableMobile: FC<RequestsTableMobileProps> = ({
  devices,
  isAdvancedView,
  tableName,
}) => {
  return (
    <TableMobile
      data={devices}
      columns={COLUMNS_MOBILE}
      isAdvancedView={isAdvancedView}
      tableName={tableName}
      renderRowDetails={renderDeviceDetails}
      emptyTableContent={<FormattedMessage id="press_refresh_button" />}
      initialSortBy={INITIAL_SORT_BY}
    />
  );
};
