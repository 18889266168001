import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { useIntlTranslate } from "../../hooks";
import { CommonModalHeader, ModalDialog } from "../modal";
import { Icon } from "../icon";
import { Button } from "../button/Button";
import { Copyable } from "../copyable/Copyable";
import { ButtonLink } from "../buttonLink/ButtonLink";
import { RoamingLimitReachedWarningReadonly } from "../spson";

import "./DeeplinkDialog.scss";

const DeeplinkGenerationStatus = ({ icon, messageId }) => (
  <div className="deeplink-modal__status">
    <Icon
      data-testid={"status-icon"}
      icon={classnames(icon, "deeplink-modal__icon")}
    />
    <FormattedMessage id={messageId} />
  </div>
);

const DeeplinkDialog = ({ className, onSubmit, onClose, children }) => (
  <ModalDialog
    className={classnames("deeplink-modal", className)}
    submitHandler={onSubmit}
    rejectHandler={onClose}
  >
    <CommonModalHeader>
      <FormattedMessage id="instalation_link" />
    </CommonModalHeader>
    {children}
  </ModalDialog>
);

export const DeeplinkErrorDialog = ({ modal }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  return (
    <DeeplinkDialog
      className="deeplink-modal--error"
      onSubmit={submit}
      onClose={close}
    >
      <ModalDialog.Body>
        <DeeplinkGenerationStatus
          icon="fas fa-exclamation-triangle"
          messageId="deeplink_generation_status_error"
        />
        <div className="deeplink-modal__text">
          <FormattedMessage id="deeplink_generation_failure" />
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <Button size="large" color="primary" onClick={submit}>
          <FormattedMessage id="deeplink_generation_try_again" />
        </Button>
      </ModalDialog.Footer>
    </DeeplinkDialog>
  );
};

export const DeeplinkSuccessDialog = ({ modal, link, expires }) => {
  const close = () => modal.reject();
  const submit = () => modal.resolve();

  const translate = useIntlTranslate();
  const history = useHistory();

  const handleLinkClick = () => {
    modal.reject();
    history.push("/settings/devices");
  };

  return (
    <DeeplinkDialog
      className="deeplink-modal--success"
      onSubmit={submit}
      onClose={close}
    >
      <ModalDialog.Body>
        <div className={"status-content"}>
          <DeeplinkGenerationStatus
            icon="fas fa-check-circle"
            messageId="deeplink_generation_status_success"
          />
          <div className="deeplink-modal__text">
            <FormattedMessage
              id="deeplink_generation_instructions"
              values={{
                link: (
                  <ButtonLink onClick={handleLinkClick} role="link">
                    <FormattedMessage id={"default_profile"} />
                  </ButtonLink>
                ),
              }}
            />
          </div>
        </div>
        <RoamingLimitReachedWarningReadonly />
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <div className={"deeplink-modal__footer-content"}>
          <Copyable text={link}>
            <Button size={"large"} color="primary">
              <FormattedMessage id="copy_link" />
            </Button>
          </Copyable>
          <div className="deeplink-modal__copy-with-instructions">
            <Copyable
              text={translate("copy_link_with_instructions_content", {
                link,
                expires,
              })}
            >
              <ButtonLink>
                <Icon icon="fas fa-copy icon-copy" />
                <FormattedMessage id="copy_link_with_instructions" />
              </ButtonLink>
            </Copyable>
            <div className="deeplink-modal__copy-with-instructions__text">
              <FormattedMessage
                id="copy_link_instructions"
                values={{
                  b: chunks => <b>{chunks}</b>,
                  expires,
                }}
              />
            </div>
          </div>
        </div>
      </ModalDialog.Footer>
    </DeeplinkDialog>
  );
};
