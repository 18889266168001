import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import _ from "lodash";

import { CrossButton, Icon } from "@sportal/cdk";
import {
  NotMobile,
  Mobile,
  useMobileValue,
} from "../../../../common/Responsive";
import { Button, Select } from "../../../../components";
import { AgeGroup } from "@sportal/api";
import { ProfileNameInput } from "../../../../components/profileModals/ProfileModalParts";
import { useIntlTranslate } from "../../../../hooks";

const ageGroups = [
  { value: AgeGroup.Kids, content: "kids" },
  { value: AgeGroup.Teens, content: "teens" },
  { value: AgeGroup.Adults, content: "adults" },
];

export const WizardProfilesHint = ({
  profilesLimit,
  profilesLength,
  limitReached,
  name,
}) => {
  const message = useMemo(() => {
    if (limitReached) {
      return "maximum_number_of_profiles";
    } else if (profilesLength === 1) {
      return "hint_maximum_profiles";
    } else if (profilesLength === 2 && !name) {
      return "hint_created_first_profile";
    } else if (profilesLength === 2 && name) {
      return "hint_creating_another_profile";
    } else if (profilesLength > 2) {
      return "hint_did_you_cover_everyone";
    }
  }, [profilesLength, limitReached, name]);

  return (
    <div className="wizard-profiles-hint">
      <FormattedMessage id="hint" />{" "}
      <FormattedMessage
        id={message}
        values={{
          count: profilesLimit,
          newLine: <br />,
        }}
      />
    </div>
  );
};

WizardProfilesHint.propTypes = {
  profilesLimit: PropTypes.number.isRequired,
  profilesLength: PropTypes.number.isRequired,
  limitReached: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export const AddProfileControls = ({ profilesLeft, disabled, addProfile }) => {
  const buttonProps = useMobileValue({ variant: "outlined" }, {});

  return (
    <div className="add-profile-controls">
      <p className="add-profile-controls__title">
        <FormattedMessage
          id="you_can_create_profiles"
          values={{ count: profilesLeft }}
        />
      </p>
      <Button
        className="add-profile-controls__button"
        color="primary"
        size="medium"
        disabled={disabled}
        onClick={addProfile}
        {...buttonProps}
        iconLeft={<Icon icon="fas fa-plus" />}
      >
        <FormattedMessage id="add_new_profile" />
      </Button>
    </div>
  );
};

AddProfileControls.propTypes = {
  profilesLeft: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  addProfile: PropTypes.func.isRequired,
};

export const AddProfileCard = ({ onCancel, onGroupSelect }) => (
  <div className="wizard-card wizard-profile wizard-profile-add">
    <div className="wizard-profile-add__title">
      <h3>
        <FormattedMessage id="choose_profile_age_range" />
      </h3>
    </div>
    <div className="wizard-profile-add__age-group">
      {_.map(ageGroups, ({ value, content }) => (
        <Button
          key={value}
          className="age-group-button"
          color="primary"
          size="medium"
          onClick={() => onGroupSelect(value)}
        >
          <FormattedMessage id={content} />
        </Button>
      ))}

      <Button
        className="age-group-cancel"
        size="medium"
        color="secondary"
        onClick={onCancel}
      >
        <FormattedMessage id="cancel" />
      </Button>
    </div>
  </div>
);

AddProfileCard.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
};

const DefaultProfileCard = ({ name }) => (
  <div>
    <h3 className="wizard-profile-card__title-default">{name}</h3>
    <p>
      <FormattedMessage id="default_settings_for_all_devices" />
    </p>
    <p>
      <FormattedMessage id="default_assign_new_devices" />
    </p>
  </div>
);

export const WizardProfileCard = ({
  profile,
  errorMessage,
  onProfileUpdate,
  onProfileRemove,
}) => {
  const translate = useIntlTranslate();
  const items = useMemo(
    () =>
      ageGroups.map(group => ({ ...group, content: translate(group.content) })),
    [translate]
  );
  const buttonSize = useMobileValue("medium", "large");
  const ageGroup = profile["age-group"] || "default";
  const { name } = profile;

  return (
    <div className="wizard-card wizard-profile wizard-profile-card">
      <div className={`profile-image ${ageGroup}`} />
      {profile.default ? (
        <DefaultProfileCard name={name} />
      ) : (
        <div className="wizard-profile-card__form">
          <div className="wizard-profile-card__form-fields">
            <ProfileNameInput
              name={name}
              errorMessage={errorMessage}
              handleUpdate={(key, value) =>
                onProfileUpdate(profile.id, key, value)
              }
            />
            <Select
              className="wizard-profile-card__form-field"
              label={<FormattedMessage id="age_group" />}
              items={items}
              selected={ageGroup}
              onChange={value =>
                onProfileUpdate(profile.id, "age-group", value)
              }
            />
          </div>
          <Mobile>
            <Button
              size={buttonSize}
              color="danger"
              onClick={() => onProfileRemove(profile.id)}
              iconLeft={<Icon icon="far fa-times" />}
            >
              <FormattedMessage id="delete" />
            </Button>
          </Mobile>
          <NotMobile>
            <CrossButton
              className="wizard-profile-card__remove-profile"
              size="medium"
              onClick={() => onProfileRemove(profile.id)}
            />
          </NotMobile>
        </div>
      )}
    </div>
  );
};

WizardProfileCard.propTypes = {
  profile: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  onProfileUpdate: PropTypes.func.isRequired,
  onProfileRemove: PropTypes.func.isRequired,
};
