import React, { Fragment, cloneElement, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { useCopyToClipboard, cleanup } from "@sportal/cdk/hooks";
import { Tooltip } from "../tooltip/Tooltip";
import { Icon } from "../icon/Icon";

import "./Copyable.scss";

export const Copyable = ({ text, timeoutMs, children }) => {
  const [copyToClipboard, isTooltipVisible] = useCopyToClipboard(timeoutMs);

  function handleCopy() {
    copyToClipboard(text);
  }

  useEffect(() => cleanup, []);

  return (
    <div className="copyable">
      <Tooltip
        showTooltip={isTooltipVisible}
        disableEvents
        className="tooltip--copyable"
        content={
          <Fragment>
            <Icon icon="fas fa-copy copyable-tooltip__icon" />
            <FormattedMessage id="copied_to_clipboard" />
          </Fragment>
        }
      >
        {cloneElement(children, {
          onClick: handleCopy,
        })}
      </Tooltip>
    </div>
  );
};

Copyable.defaultProps = {
  timeoutMs: 3000,
};

Copyable.propTypes = {
  text: PropTypes.string.isRequired,
  timeoutMs: PropTypes.number,
  children: PropTypes.node.isRequired,
};
