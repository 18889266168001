import React, {
  cloneElement,
  useRef,
  VFC,
  useCallback,
  ReactElement,
} from "react";

import { OnSelectedItemChange, RenderItems } from "./dropdown.helpers";
import { Dropdown, DropdownProps } from "./Dropdown";
import { DropdownItemInterface } from "./DropdownItem";

function isReactElement(
  item: DropdownItemInterface | ReactElement
): item is ReactElement {
  return (item as DropdownItemInterface).content === undefined;
}

export const LazyLoadDropdown: VFC<DropdownProps> = props => {
  const actionCallbackRef = useRef(null);

  const handleSelectedItemChange = useCallback<OnSelectedItemChange>(
    (selectedItem?) => {
      const selectedAction = actionCallbackRef.current;

      selectedItem && selectedAction && selectedAction();
    },
    []
  );

  const renderItems = useCallback<RenderItems>(
    ({ items, highlightedIndex, getItemProps }) => {
      return items.map((item, index) => {
        if (!isReactElement(item)) {
          throw new Error("Item should be a ReactElement");
        }

        return cloneElement(item, {
          item,
          key: index,
          index,
          highlighted: highlightedIndex === index,
          onHighlightedChange: itemActionCallback => {
            actionCallbackRef.current = itemActionCallback;
          },
          getItemProps,
        });
      });
    },
    []
  );

  return (
    <Dropdown
      {...props}
      renderItems={renderItems}
      onSelectedItemChange={handleSelectedItemChange}
    />
  );
};
