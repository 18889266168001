import { useMemo } from "react";
import _ from "lodash";

import { isValidMac, normalizeMac } from "@sportal/lib";

export const useDeviceIdValidator = devices => {
  return useMemo(() => {
    const existingIds = new Set(
      _.flatMap(devices, ({ identifiers }) => identifiers).map(normalizeMac)
    );
    return _.cond([
      [mac => !isValidMac(mac), _.constant("invalid_mac")],
      [mac => existingIds.has(normalizeMac(mac)), _.constant("mac_duplicated")],
    ]);
  }, [devices]);
};
