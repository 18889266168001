import { HttpService, HttpServiceResponse } from "../shared";
import { Schedule } from "./schedules.types";

export class SchedulesProvider {
  constructor(
    private http: HttpService,
    private baseUrl: string,
    private appCode: string
  ) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/${this.appCode}`;
  }

  public create(
    subscriberId: string,
    profileName: string,
    schedule: Schedule
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(
      `${this.url}/${subscriberId}/profile/${profileName}/schedule`,
      schedule
    );
  }

  public update(
    subscriberId: string,
    profileName: string,
    scheduleName: string,
    schedule: Schedule
  ): Promise<HttpServiceResponse<void>> {
    return this.http.put(
      `${this.url}/${subscriberId}/profile/${profileName}/schedule/${scheduleName}`,
      schedule
    );
  }

  public delete(
    subscriberId: string,
    profileName: string,
    scheduleName: string
  ): Promise<HttpServiceResponse<void>> {
    return this.http.delete(
      `${this.url}/${subscriberId}/profile/${profileName}/schedule/${scheduleName}`
    );
  }
}
