import {
  ClearHistoryProvider,
  ClearHistoryService,
  HttpService,
} from "@sportal/api";

export const makeClearHistoryService = (
  http: HttpService,
  baseUrl: string
): ClearHistoryService =>
  new ClearHistoryService(new ClearHistoryProvider(http, baseUrl));
