import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";

import "./UrlList.scss";

export function UrlList({ className, children }) {
  return <div className={classnames("url-list", className)}>{children}</div>;
}

UrlList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export function UrlListHeader({ title, renderBulkActionComponent }) {
  return (
    <header className="url-list__header">
      <div>{title}</div>
      {_.isFunction(renderBulkActionComponent) && renderBulkActionComponent()}
    </header>
  );
}

UrlListHeader.propTypes = {
  title: PropTypes.node.isRequired,
  renderBulkActionComponent: PropTypes.func,
};

export function UrlListContent({ items, renderRow, emptyListMessage }) {
  if (_.isEmpty(items)) {
    return (
      <span className="url-list__content__empty-list">{emptyListMessage}</span>
    );
  }
  return <div className="url-list__content">{items.map(renderRow)}</div>;
}

UrlListContent.propTypes = {
  items: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  emptyListMessage: PropTypes.node.isRequired,
};
