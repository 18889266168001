import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import _ from "lodash";

import { NetworkId } from "../networkId";
import { ProfileDeviceTypeTooltip } from "../profileDeviceTypeTooltip";

import "./ProfileDevicesTable.scss";

interface ProfileTableColumn {
  id: string;
  header: ReactElement;
  headerHidden?: boolean;
  cell: (device: any) => string | ReactElement;
  cellHidden?: boolean;
}

const COLUMNS: ProfileTableColumn[] = [
  {
    id: "device_type",
    header: <FormattedMessage id="device_type" />,
    headerHidden: true,
    cell: device => (
      <ProfileDeviceTypeTooltip identifiers={device.identifiers} />
    ),
  },
  {
    id: "device_name",
    header: <FormattedMessage id="device_name" />,
    cell: device => device.name,
  },
  {
    id: "network_id",
    header: <FormattedMessage id="network_id" />,
    cell: device => (
      <NetworkId
        identifiers={device.identifiers}
        manufacturer={device.manufacturer}
        phoneNumber={device.phoneNumber}
        networkId={device.networkId}
      />
    ),
  },
  {
    id: "manufacturer",
    header: <FormattedMessage id="manufacturer" />,
    headerHidden: true,
    cell: device => device.manufacturer,
    cellHidden: true,
  },
];

export const ProfileDevicesTable = ({ devices }) => {
  return (
    <table className="profile-devices-table">
      <caption className="sr-only">
        <FormattedMessage id="devices_in_profile" />
      </caption>
      <thead>
        <tr className="profile-devices-table__header-row">
          {COLUMNS.map(column => (
            <th
              key={column.id}
              scope="col"
              className={classNames(
                `profile-devices-table__header profile-devices-table__header--${column.id}`,
                { "profile-devices-table__header--hidden": column.headerHidden }
              )}
            >
              {column.headerHidden ? (
                <span className="sr-only">{column.header}</span>
              ) : (
                column.header
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="profile-devices-table__body">
        {_.map(devices, (device, index) => (
          <tr className="profile-devices-table__row" key={index}>
            {COLUMNS.map(column => (
              <td
                key={column.id}
                className={classNames(
                  `profile-devices-table__cell profile-devices-table__cell--${column.id}`,
                  { "profile-devices-table__cell--hidden": column.cellHidden }
                )}
              >
                {column.cellHidden ? (
                  <span className="sr-only">{column.cell(device)}</span>
                ) : (
                  column.cell(device)
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
