import { RootState } from "../root.reducer";

const getBanners = (state: RootState) => state.banners;

export const isAllowListOverrideWarningVisible = (state: RootState): boolean =>
  getBanners(state).allowListOverrideWarningVisible;

export const isSpsonPanelVisible = (state: RootState): boolean =>
  getBanners(state).spsonPanelVisible;

export const isSpsonMaxLimitWarningVisible = (state: RootState): boolean =>
  getBanners(state).spsonMaxLimitWarningVisible;
