import React from "react";
import { Form, useForm, useFormContext } from "@sportal/cdk";
import { Button } from "../button/Button";

function SubmitButton(props) {
  const formState = useFormContext();

  return <Button {...props} type="submit" disabled={formState.disabled} />;
}

export { Form, SubmitButton, useForm, useFormContext };
