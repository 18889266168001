import { HttpService, HttpServiceResponse } from "../shared";

export class ClearHistoryProvider {
  constructor(private http: HttpService, private baseUrl: string) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/account`;
  }

  public clear(
    subscriberId: string,
    metrics: string[]
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(`${this.url}/${subscriberId}/history:clear`, {
      metrics,
    });
  }
}
