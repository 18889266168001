import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const useExternalLink = () => {
  const target = "_blank";
  const openExternalLink = url => window.open(url, target);

  return [openExternalLink, target];
};

export const ExternalLink = ({ className, url, children }) => {
  const [, target] = useExternalLink();

  return (
    <a
      className={classnames("link", className)}
      target={target}
      href={url}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
