import React, { Fragment, memo, VFC } from "react";

import { Mobile, NotMobile } from "../../../../../common/Responsive";
import { DeviceActionsToggle, DeviceMenu } from "../tableComponents";
import { ProfileDeviceMenuProps } from "./ProfileDeviceMenu";
import {
  RenameDeviceDropdownItem,
  RenameDeviceModalItem,
  MoveDeviceDropdownItem,
  MoveDeviceModalItem,
  MergeAssignedDeviceDropdownItem,
  MergeAssignedDeviceModalItem,
  RemoveDeviceDropdownItem,
  RemoveDeviceModalItem,
  UnmergeDeviceDropdownItem,
  UnmergeDeviceModalItem,
} from "../deviceActionItems";

export const ProfileNonRoamingDeviceMenu: VFC<ProfileDeviceMenuProps> = memo(
  function ProfileNonRoamingDeviceMenu({ device }) {
    const dropdownItems = [
      <RenameDeviceDropdownItem device={device} />,
      <MoveDeviceDropdownItem device={device} />,
      <MergeAssignedDeviceDropdownItem device={device} />,
      <UnmergeDeviceDropdownItem device={device} />,
      <RemoveDeviceDropdownItem device={device} />,
    ];

    const modalItems = [
      <RenameDeviceModalItem device={device} />,
      <MoveDeviceModalItem device={device} />,
      <MergeAssignedDeviceModalItem device={device} />,
      <UnmergeDeviceModalItem device={device} />,
      <RemoveDeviceModalItem device={device} />,
    ];

    return (
      <Fragment>
        <Mobile>
          <DeviceActionsToggle
            deviceName={device.name || device.networkId}
            menuItems={modalItems}
          />
        </Mobile>

        <NotMobile>
          <DeviceMenu className="profile-device-menu" items={dropdownItems} />
        </NotMobile>
      </Fragment>
    );
  }
);
