/** @deprecated Please use thunk mdlw */
export function createAsyncMiddleware(extraArgument) {
  return ({ dispatch, getState }) => next => action => {
    const nextAction = next(action);
    if (typeof action.payload === "function") {
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.warn(
          "An action with a function as a payload was caught. " +
            "Please turn it into thunk."
        );
      }
      action.payload(dispatch, getState, extraArgument);
    }
    return nextAction;
  };
}
