import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";

import { hasMultipleSupport, isSS } from "../store/info";
import {
  SafetyWizardPage,
  ProtectionChoicePage,
  MultipleWizardProfilesPage,
  SingleWizardPage,
} from "../pages/wizard";
import {
  MultipleWizardDevicesPage,
  MultipleWizardProtectionPage,
} from "../pages/wizard/multiple";
import {
  WizardNavigationContextProvider,
  useWizardNavigationContext,
  WizardPaths,
} from "../pages/wizard/WizardNavigationContext";

const subscriberSafety = (
  <Switch>
    <Route path={"/wizard/safety"} component={SafetyWizardPage} />
    <Redirect to="/wizard/safety" />
  </Switch>
);

const getStepPath = ({ props: { path } }) => path;
const Steps = ({ children }) => {
  const { step } = useWizardNavigationContext();
  const [child] = React.Children.toArray(children).filter(
    child => step === getStepPath(child)
  );
  return child || null;
};
const Step = ({ children }) => {
  return children;
};

const Wizard = ({ isMultipleSupported }) => {
  return (
    <Steps>
      <Step path={WizardPaths.ProtectionChoice}>
        <ProtectionChoicePage hasMultipleSupport={isMultipleSupported} />
      </Step>
      <Step path={WizardPaths.Single}>
        <SingleWizardPage />
      </Step>
      <Step path={WizardPaths.Multiple}>
        <MultipleWizardProfilesPage />
      </Step>
      <Step path={WizardPaths.Protection}>
        <MultipleWizardProtectionPage />
      </Step>
      <Step path={WizardPaths.Devices}>
        <MultipleWizardDevicesPage />
      </Step>
    </Steps>
  );
};

export const WizardRouter = () => {
  const isSafety = useSelector(isSS);
  const isMultipleSupported = useSelector(hasMultipleSupport);

  return isSafety ? (
    subscriberSafety
  ) : (
    <WizardNavigationContextProvider>
      <Wizard isMultipleSupported={isMultipleSupported} />
    </WizardNavigationContextProvider>
  );
};
