import {
  AttributesService,
  AttributesProvider,
  Attribute,
  HttpService,
} from "@sportal/api";

const attributesMap = {
  [Attribute.LANGUAGE]: "nom.pi.language",
  [Attribute.TIME_FORMAT]: "nom.pi.format24h",
};

export const makeAttributesService = (
  http: HttpService,
  baseUrl: string
): AttributesService =>
  new AttributesService(new AttributesProvider(http, baseUrl, attributesMap));
