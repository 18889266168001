import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import {
  CellDetails,
  Table,
  RenderRowDetailsInterface,
  DeviceCodeHint,
} from "../../../../../../../components";
import { COLUMNS, HIDDEN_COLUMNS } from "./RequestsTable.consts";
import { CommonAdvancedViewGrid } from "../../../tableComponents";

const INITIAL_SORT_BY = [{ id: "lastSeen", desc: true }];

const renderDeviceDetails: RenderRowDetailsInterface = ({ row }) => {
  const {
    original: { networkId, phoneNumber },
  } = row;

  return (
    <CommonAdvancedViewGrid>
      <CellDetails
        id="network-id"
        header={<FormattedMessage id="network_id" />}
        value={networkId || phoneNumber}
      />
    </CommonAdvancedViewGrid>
  );
};

interface RequestsTableProps {
  devices: any[];
  isAdvancedView: boolean;
  tableName: string;
}

export const RequestsTable: FC<RequestsTableProps> = ({
  devices,
  isAdvancedView,
  tableName,
}) => {
  return (
    <Table
      data={devices}
      columns={COLUMNS}
      hiddenColumns={HIDDEN_COLUMNS}
      isAdvancedView={isAdvancedView}
      tableName={tableName}
      renderRowDetails={renderDeviceDetails}
      emptyTableContent={<FormattedMessage id="press_refresh_button" />}
      footerContent={<DeviceCodeHint />}
      initialSortBy={_.isEmpty(devices) ? [] : INITIAL_SORT_BY}
    />
  );
};
