import { withLoadingState } from "../shared/withLoadingState";
import {
  LOAD_TIMEZONES,
  LOAD_TIMEZONES_FAILURE,
  LOAD_TIMEZONES_SUCCESS,
  TimezonesActionTypes,
} from "./timezones.types";

const reducer = (state = {}, action: TimezonesActionTypes) => {
  switch (action.type) {
    case LOAD_TIMEZONES_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export const timezonesReducer = withLoadingState({
  loadActionType: LOAD_TIMEZONES,
  successActionType: LOAD_TIMEZONES_SUCCESS,
  failureActionType: LOAD_TIMEZONES_FAILURE,
})(reducer);
