import _ from "lodash";

import {
  ADD_LOGICAL_DEVICE_SUCCESS,
  EDIT_LOGICAL_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  LOAD_LOGICAL_DEVICES_SUCCESS,
  MERGE_LOGICAL_DEVICE_SUCCESS,
  REMOVE_LOGICAL_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_SUCCESS,
} from "../logical/logicalDevices.types";
import { normalizeCollection } from "../../shared/normalizeCollection";
import {
  LogicalDeviceDetailsActionTypes,
  LogicalDeviceDetailsReducerState,
} from "./logicalDeviceDetails.types";

const initialState: LogicalDeviceDetailsReducerState = {
  list: {},
  keys: [],
};

export const logicalDeviceDetailsReducer = (
  state = initialState,
  action: LogicalDeviceDetailsActionTypes
): LogicalDeviceDetailsReducerState => {
  switch (action.type) {
    case LOAD_LOGICAL_DEVICES_SUCCESS: {
      const deviceDetails = _.flatten(
        action.payload.devices.map(device => device.deviceDetails)
      );

      return normalizeCollection(deviceDetails, "identifier");
    }
    case ADD_LOGICAL_DEVICE_SUCCESS: {
      const { deviceDetails } = action.payload;
      const { list, keys } = normalizeCollection(deviceDetails, "identifier");

      return {
        keys: [...state.keys, ...keys],
        list: { ...state.list, ...list },
      };
    }
    case REVOKE_ROAMING_DEVICE_SUCCESS:
    case REMOVE_LOGICAL_DEVICE_SUCCESS: {
      const { identifiers } = action.payload;

      return {
        keys: _.without(state.keys, ...identifiers),
        list: _.omit(state.list, ...identifiers),
      };
    }
    case EDIT_LOGICAL_DEVICE_SUCCESS:
    case MERGE_LOGICAL_DEVICE_SUCCESS: {
      const { changed } = action.payload;
      const { identifiers, deviceDetails: mergedDeviceDetails } = changed;
      const detailsWithoutMergedDevices = _.values(
        _.omit(state.list, ...identifiers)
      );

      return normalizeCollection(
        [...detailsWithoutMergedDevices, ...mergedDeviceDetails],
        "identifier"
      );
    }
    case EDIT_ROAMING_DEVICE_SUCCESS: {
      const { identifier, changes } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          [identifier]: { ...state.list[identifier], ...changes },
        },
      };
    }
    default: {
      return state;
    }
  }
};
