import React, {
  Fragment,
  forwardRef,
  memo,
  useRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";

const TimePickerInput = forwardRef((props, ref) => {
  const { value, name, ...attrs } = props;

  const inputRef = useRef();

  function focusInput() {
    inputRef.current.focus();
  }

  useImperativeHandle(ref, () => ({
    focus: focusInput,
  }));

  return (
    <Fragment>
      <input
        ref={inputRef}
        className="time-picker__input"
        name={name}
        value={value}
        maxLength="3"
        autoComplete="off"
        data-testid="time-picker-input"
        {...attrs}
      />
      <div
        className="time-picker__input-value"
        onMouseUp={focusInput}
        data-testid="time-picker-value"
      >
        {value}
      </div>
    </Fragment>
  );
});

TimePickerInput.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(TimePickerInput);
