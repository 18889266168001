import * as _ from "lodash";

import { failure, Result, success } from "../../shared";
import { Line, ILinesProvider } from "./lines.types";

export enum LineServiceErrorCode {
  GENERIC,
}

export class LinesService {
  constructor(private linesProvider: ILinesProvider) {}

  public async get(subscriberId: string): Promise<Result<Line[]>> {
    try {
      const {
        data: { content },
      } = await this.linesProvider.get(subscriberId);

      const lines = _.filter(content, { "is-device": true }).map(
        ({ name }) => ({
          id: name,
        })
      );
      return success(lines);
    } catch (error) {
      return failure(LineServiceErrorCode.GENERIC);
    }
  }
}
