import React from "react";
import PropTypes from "prop-types";

import { ProfileCard } from "./profileCard/ProfileCard";
import { NewProfileCard } from "./newProfileCard/NewProfileCard";

import "./ProfileCards.scss";

export const ProfileCards = ({ profiles, availableProfilesNumber }) => (
  <div className="profile-cards">
    {profiles.map(
      ({
        id,
        name,
        "age-group": ageGroup,
        protection,
        devicesCount,
        default: isDefault,
        "protection-enabled": protectionEnabled,
      }) => (
        <ProfileCard
          key={id}
          profileId={id}
          profileName={name}
          ageGroup={ageGroup}
          protectionLevel={protectionEnabled ? protection : "off"}
          devicesCount={devicesCount}
          className="profile-card"
          isDefault={isDefault}
        />
      )
    )}
    {availableProfilesNumber > 0 && (
      <NewProfileCard
        availableProfilesNumber={availableProfilesNumber}
        className="profile-card"
      />
    )}
  </div>
);

ProfileCards.propTypes = {
  profiles: PropTypes.array.isRequired,
  availableProfilesNumber: PropTypes.number.isRequired,
};
