import { HttpService, HttpServiceResponse } from "../shared";
import { Profile } from "./profiles.types";

interface ProfilesCollection {
  content: Profile[];
  limit: number;
  total: number;
}

export class ProfilesProvider {
  constructor(
    private http: HttpService,
    private baseUrl: string,
    private appCode: string
  ) {}

  private get url(): string {
    return `${this.baseUrl}/ssm/${this.appCode}`;
  }

  public get(
    subscriberId: string
  ): Promise<HttpServiceResponse<ProfilesCollection>> {
    return this.http.get(`${this.url}/${subscriberId}/profile`);
  }

  public create(
    subscriberId: string,
    profiles: Profile[]
  ): Promise<HttpServiceResponse<Profile[]>> {
    return this.http.post(`${this.url}/${subscriberId}/profile`, profiles);
  }

  public delete(
    subscriberId: string,
    profileName: string
  ): Promise<HttpServiceResponse<Profile>> {
    return this.http.delete(
      `${this.url}/${subscriberId}/profile/${profileName}`
    );
  }

  public update(
    subscriberId: string,
    profileName: string,
    changedFields: object
  ): Promise<HttpServiceResponse> {
    return this.http.patch(
      `${this.url}/${subscriberId}/profile/${profileName}`,
      changedFields
    );
  }
}
