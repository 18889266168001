import { LogicalDevice, SpsonDeviceUpdateAttributes } from "@sportal/api";

export const LOAD_LOGICAL_DEVICES = "[DEVICES] LOAD_LOGICAL_DEVICES";
export const LOAD_LOGICAL_DEVICES_SUCCESS =
  "[DEVICES] LOAD_LOGICAL_DEVICES_SUCCESS";
export const LOAD_LOGICAL_DEVICES_FAILURE =
  "[DEVICES] LOAD_LOGICAL_DEVICES_FAILURE";
export const ADD_LOGICAL_DEVICE = "[DEVICES] ADD_LOGICAL_DEVICE";
export const ADD_LOGICAL_DEVICE_SUCCESS =
  "[DEVICES] ADD_LOGICAL_DEVICE_SUCCESS";
export const ADD_LOGICAL_DEVICE_FAILURE =
  "[DEVICES] ADD_LOGICAL_DEVICE_FAILURE";
export const REMOVE_LOGICAL_DEVICE = "[DEVICES] REMOVE_LOGICAL_DEVICE";
export const REMOVE_LOGICAL_DEVICE_SUCCESS =
  "[DEVICES] REMOVE_LOGICAL_DEVICE_SUCCESS";
export const REMOVE_LOGICAL_DEVICE_FAILURE =
  "[DEVICES] REMOVE_LOGICAL_DEVICE_FAILURE";
export const EDIT_LOGICAL_DEVICE = "[DEVICES] EDIT_LOGICAL_DEVICE";
export const EDIT_LOGICAL_DEVICE_SUCCESS =
  "[DEVICES] EDIT_LOGICAL_DEVICE_SUCCESS";
export const EDIT_LOGICAL_DEVICE_FAILURE =
  "[DEVICES] EDIT_LOGICAL_DEVICE_FAILURE";
export const MERGE_LOGICAL_DEVICE = "[DEVICES] MERGE_LOGICAL_DEVICE";
export const MERGE_LOGICAL_DEVICE_SUCCESS =
  "[DEVICES] MERGE_LOGICAL_DEVICE_SUCCESS";
export const MERGE_LOGICAL_DEVICE_FAILURE =
  "[DEVICES] MERGE_LOGICAL_DEVICE_FAILURE";
export const UNMERGE_LOGICAL_DEVICE = "[DEVICES] UNMERGE_LOGICAL_DEVICE";
export const UNMERGE_LOGICAL_DEVICE_SUCCESS =
  "[DEVICES] UNMERGE_LOGICAL_DEVICE_SUCCESS";
export const UNMERGE_LOGICAL_DEVICE_FAILURE =
  "[DEVICES] UNMERGE_LOGICAL_DEVICE_FAILURE";
export const REVOKE_ROAMING_DEVICE = "[DEVICES] REVOKE_ROAMING_DEVICE";
export const REVOKE_ROAMING_DEVICE_SUCCESS =
  "[DEVICES] REVOKE_ROAMING_DEVICE_SUCCESS";
export const REVOKE_ROAMING_DEVICE_FAILURE =
  "[DEVICES] REVOKE_ROAMING_DEVICE_FAILURE";
export const EDIT_ROAMING_DEVICE = "[DEVICES] EDIT_ROAMING_DEVICE";
export const EDIT_ROAMING_DEVICE_SUCCESS =
  "[DEVICES] EDIT_ROAMING_DEVICE_SUCCESS";
export const EDIT_ROAMING_DEVICE_FAILURE =
  "[DEVICES] EDIT_ROAMING_DEVICE_FAILURE";
export const FETCH_ROAMING_LIMIT = "[DEVICES] FETCH_ROAMING_LIMIT";
export const FETCH_ROAMING_LIMIT_SUCCESS =
  "[DEVICES]  FETCH_ROAMING_LIMIT_SUCCESS";
export const FETCH_ROAMING_LIMIT_FAILURE =
  "[DEVICES]  FETCH_ROAMING_LIMIT_FAILURE";

interface LoadLogicalDevicesAction {
  type: typeof LOAD_LOGICAL_DEVICES;
}

export interface LoadLogicalDevicesSuccessAction {
  type: typeof LOAD_LOGICAL_DEVICES_SUCCESS;
  payload: { devices: LogicalDevice[]; limit: number };
}

interface LoadLogicalDevicesFailureAction {
  type: typeof LOAD_LOGICAL_DEVICES_FAILURE;
  payload: object;
}

interface FetchRoamingLimitAction {
  type: typeof FETCH_ROAMING_LIMIT;
}

export interface FetchRoamingLimitSuccessAction {
  type: typeof FETCH_ROAMING_LIMIT_SUCCESS;
  payload: number;
}

export interface FetchRoamingLimitFailureAction {
  type: typeof FETCH_ROAMING_LIMIT_FAILURE;
  payload: object;
}

interface AddLogicalDeviceAction {
  type: typeof ADD_LOGICAL_DEVICE;
}

export interface AddLogicalDeviceSuccessAction {
  type: typeof ADD_LOGICAL_DEVICE_SUCCESS;
  payload: LogicalDevice;
}

interface AddLogicalDeviceFailureAction {
  type: typeof ADD_LOGICAL_DEVICE_FAILURE;
  payload: object;
}

interface RemoveLogicalDeviceAction {
  type: typeof REMOVE_LOGICAL_DEVICE;
}

export interface RemoveLogicalDeviceSuccessAction {
  type: typeof REMOVE_LOGICAL_DEVICE_SUCCESS;
  payload: LogicalDevice;
}

interface RemoveLogicalDeviceFailureAction {
  type: typeof REMOVE_LOGICAL_DEVICE_FAILURE;
  payload: object;
}

interface EditLogicalDeviceAction {
  type: typeof EDIT_LOGICAL_DEVICE;
}

export interface EditLogicalDeviceSuccessAction {
  type: typeof EDIT_LOGICAL_DEVICE_SUCCESS;
  payload: {
    device: LogicalDevice | DeviceFromRequest;
    changed: LogicalDevice;
  };
}

interface EditLogicalDeviceFailureAction {
  type: typeof EDIT_LOGICAL_DEVICE_FAILURE;
  payload: object;
}

interface MergeLogicalDeviceAction {
  type: typeof MERGE_LOGICAL_DEVICE;
}

export interface MergeLogicalDeviceSuccessAction {
  type: typeof MERGE_LOGICAL_DEVICE_SUCCESS;
  payload: {
    device: LogicalDevice | DeviceFromRequest;
    changed: LogicalDevice;
  };
}

interface MergeLogicalDeviceFailureAction {
  type: typeof MERGE_LOGICAL_DEVICE_FAILURE;
  payload: object;
}

interface UnmergeLogicalDeviceAction {
  type: typeof UNMERGE_LOGICAL_DEVICE;
}

interface UnmergeLogicalDeviceSuccessAction {
  type: typeof UNMERGE_LOGICAL_DEVICE_SUCCESS;
  payload: [LogicalDevice, LogicalDevice];
}

interface UnmergeLogicalDeviceFailureAction {
  type: typeof UNMERGE_LOGICAL_DEVICE_FAILURE;
  payload: object;
}

interface RevokeRoamingDeviceAction {
  type: typeof REVOKE_ROAMING_DEVICE;
}

export interface RevokeRoamingDeviceSuccessAction {
  type: typeof REVOKE_ROAMING_DEVICE_SUCCESS;
  payload: LogicalDevice;
}

interface RevokeRoamingDeviceFailureAction {
  type: typeof REVOKE_ROAMING_DEVICE_FAILURE;
  payload: object;
}

interface EditRoamingDeviceAction {
  type: typeof EDIT_ROAMING_DEVICE;
}

export interface EditRoamingDeviceSuccessAction {
  type: typeof EDIT_ROAMING_DEVICE_SUCCESS;
  payload: {
    device: LogicalDevice;
    identifier: string;
    changes: SpsonDeviceUpdateAttributes;
  };
}

interface EditRoamingDeviceFailureAction {
  type: typeof EDIT_ROAMING_DEVICE_FAILURE;
  payload: object;
}

export type LogicalDevicesActionTypes =
  | LoadLogicalDevicesAction
  | LoadLogicalDevicesSuccessAction
  | LoadLogicalDevicesFailureAction
  | AddLogicalDeviceAction
  | AddLogicalDeviceSuccessAction
  | AddLogicalDeviceFailureAction
  | RemoveLogicalDeviceAction
  | RemoveLogicalDeviceSuccessAction
  | RemoveLogicalDeviceFailureAction
  | EditLogicalDeviceAction
  | EditLogicalDeviceSuccessAction
  | EditLogicalDeviceFailureAction
  | MergeLogicalDeviceAction
  | MergeLogicalDeviceSuccessAction
  | MergeLogicalDeviceFailureAction
  | UnmergeLogicalDeviceAction
  | UnmergeLogicalDeviceSuccessAction
  | UnmergeLogicalDeviceFailureAction
  | RevokeRoamingDeviceAction
  | RevokeRoamingDeviceSuccessAction
  | RevokeRoamingDeviceFailureAction
  | EditRoamingDeviceAction
  | EditRoamingDeviceSuccessAction
  | EditRoamingDeviceFailureAction
  | FetchRoamingLimitAction
  | FetchRoamingLimitSuccessAction
  | FetchRoamingLimitFailureAction;

export type DeviceFromRequest = Omit<LogicalDevice, "profile">;
