import React, { Component, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { ModalHeader } from "./ModalHeader";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalControl } from "./ModalControl";
import {
  isEnterPressed,
  isEscapePressed,
} from "../helpers/keyboardEvents.helper";

const ModalDialogContext = React.createContext({});

const ModalDialogContextProvider = ({ children, disabled, submit, reject }) => {
  const value = useMemo(
    () => ({
      disabled,
      submit,
      reject,
    }),
    [disabled, submit, reject]
  );

  return (
    <ModalDialogContext.Provider value={value}>
      {children}
    </ModalDialogContext.Provider>
  );
};

export const useModalDialogContext = () => useContext(ModalDialogContext);

export class ModalDialog extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = e => {
    const { rejectHandler, submitHandler, disabled } = this.props;
    if (isEscapePressed(e) && rejectHandler) {
      rejectHandler(e);
    }

    if (isEnterPressed(e) && !disabled && submitHandler) {
      submitHandler();
    }
  };

  render() {
    const {
      disabled,
      className,
      children,
      rejectHandler,
      submitHandler,
    } = this.props;

    return (
      <ModalDialogContextProvider
        disabled={disabled}
        submit={submitHandler}
        reject={rejectHandler}
      >
        <div
          className={classnames("modal__dialog", className)}
          role="document"
          onClick={e => e.stopPropagation()}
        >
          {children}
        </div>
      </ModalDialogContextProvider>
    );
  }
}

ModalDialog.Header = ModalHeader;
ModalDialog.Body = ModalBody;
ModalDialog.Footer = ModalFooter;
ModalDialog.Control = ModalControl;

ModalDialog.propTypes = {
  submitHandler: PropTypes.func,
  rejectHandler: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // todo: some components form SB are passing strings, it would be nice to restrict it to boolean
  children: PropTypes.node,
};
