import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { ModalService } from "@sportal/cdk";
import { MoveRoamingDeviceDialog } from "../moveRoamingDeviceDialog/MoveRoamingDeviceDialog";
import { editLogicalDevice } from "../../../../../store/devices";
import { useDevicesTabContext } from "../DevicesTabContext";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { MobileModalListItem } from "../tableComponents";

const useMoveRoamingDeviceInfo = device => {
  const dispatch = useDispatch();
  const { devices, devicesProfiles, getProfileByName } = useDevicesTabContext();

  const moveRoaming = () => {
    ModalService.show(modal => ({
      dialog: (
        <MoveRoamingDeviceDialog
          modal={modal}
          devices={devices}
          device={device}
          profiles={devicesProfiles}
        />
      ),
    }))
      .then(changes => dispatch(editLogicalDevice(device, changes)))
      .catch(() => {});
  };

  const profile = getProfileByName(device.profile);
  const areOtherProfilesFull = _.every(_.without(devicesProfiles, profile), {
    isFull: true,
  });

  return {
    content: <FormattedMessage id="move" />,
    action: moveRoaming,
    id: "move",
    disabled: areOtherProfilesFull,
  };
};

export const MoveRoamingDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, id, disabled } = useMoveRoamingDeviceInfo(device);

  return (
    <LazyLoadDropdownItem
      action={action}
      disabled={disabled}
      id={id}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const MoveRoamingDeviceModalItem = ({ device }) => {
  const { content, action, id, disabled } = useMoveRoamingDeviceInfo(device);

  return (
    <MobileModalListItem
      content={content}
      action={action}
      id={id}
      disabled={disabled}
    />
  );
};
