import _ from "lodash";

import { withLoadingState } from "../../shared/withLoadingState";
import { LogicalDevice, Profile } from "@sportal/api";
import {
  ADD_LOGICAL_DEVICE_SUCCESS,
  EDIT_LOGICAL_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_SUCCESS,
  FETCH_ROAMING_LIMIT_SUCCESS,
  LOAD_LOGICAL_DEVICES,
  LOAD_LOGICAL_DEVICES_FAILURE,
  LOAD_LOGICAL_DEVICES_SUCCESS,
  LogicalDevicesActionTypes,
  MERGE_LOGICAL_DEVICE_SUCCESS,
  REMOVE_LOGICAL_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_SUCCESS,
  UNMERGE_LOGICAL_DEVICE_SUCCESS,
} from "./logicalDevices.types";
import {
  CHANGE_PROFILE_NAME_SUCCESS,
  ChangeProfileNameSuccessAction,
  REMOVE_PROFILE_SUCCESS,
  RemoveProfileSuccessAction,
} from "../../profiles";
import {
  defaultProfile,
  SUBMIT_PROFILES_UPDATE,
} from "../../../pages/wizard/store/profiles";
import { normalizeCollection } from "../../shared/normalizeCollection";

export interface LogicalDevicesState {
  list: { [name: string]: LogicalDevice };
  keys: string[];
  limit: number;
  roamingDevicesLimit?: number;
}

const initialState: LogicalDevicesState = {
  list: {},
  keys: [],
  limit: null,
};

// TODO: move
type SubmitProfilesPayload = {
  add: Profile[];
  update: { profile: Profile; updates: Partial<Profile> }[];
  remove: Profile[];
};

type LogicalDevicesReducerActionTypes =
  | LogicalDevicesActionTypes
  | ChangeProfileNameSuccessAction
  | RemoveProfileSuccessAction
  | { type: typeof SUBMIT_PROFILES_UPDATE; payload: SubmitProfilesPayload }; // TODO: add type

function reducer(
  state: LogicalDevicesState = initialState,
  action: LogicalDevicesReducerActionTypes
): LogicalDevicesState {
  switch (action.type) {
    case LOAD_LOGICAL_DEVICES_SUCCESS: {
      const logicalDevices = action.payload.devices.map(omitDeviceDetails);

      return {
        ...state,
        ...normalizeCollection(logicalDevices, "name"),
        limit: action.payload.limit,
      };
    }
    case ADD_LOGICAL_DEVICE_SUCCESS: {
      const device = action.payload;
      return {
        ...state,
        keys: [...state.keys, device.name],
        list: { ...state.list, [device.name]: omitDeviceDetails(device) },
      };
    }
    case REVOKE_ROAMING_DEVICE_SUCCESS:
    case REMOVE_LOGICAL_DEVICE_SUCCESS: {
      const device = action.payload;
      return {
        ...state,
        keys: _.without(state.keys, device.name),
        list: _.omit(state.list, device.name),
      };
    }
    case EDIT_LOGICAL_DEVICE_SUCCESS: {
      const { device, changed } = action.payload;

      return {
        ...state,
        keys: [..._.without(state.keys, device.name), changed.name],
        list: {
          ..._.omit(state.list, device.name),
          [changed.name]: omitDeviceDetails(changed),
        },
      };
    }

    case EDIT_ROAMING_DEVICE_SUCCESS: {
      const { device, changes } = action.payload;

      if (!changes.name) {
        return state;
      }

      return {
        ...state,
        keys: [..._.without(state.keys, device.name), changes.name],
        list: {
          ..._.omit(state.list, device.name),
          [changes.name]: { ...device, name: changes.name },
        },
      };
    }

    case MERGE_LOGICAL_DEVICE_SUCCESS: {
      const { device, changed } = action.payload;
      // Cannot omit by name since requests may have duplicated names
      const list = {
        ..._.omitBy(state.list, ({ identifiers }) =>
          _.includes(identifiers, _.first(device.identifiers))
        ),
        [changed.name]: omitDeviceDetails(changed),
      };

      return {
        ...state,
        list,
        keys: _.keys(list),
      };
    }
    case UNMERGE_LOGICAL_DEVICE_SUCCESS: {
      const [updated, added] = action.payload;
      return {
        ...state,
        keys: [...state.keys, added.name],
        list: {
          ...state.list,
          [updated.name]: omitDeviceDetails(updated),
          [added.name]: omitDeviceDetails(added),
        },
      };
    }
    case CHANGE_PROFILE_NAME_SUCCESS: {
      const { profile, updates } = action.payload;
      return {
        ...state,
        list: changeDevicesProfile(state.list, profile.name, updates.name),
      };
    }
    case REMOVE_PROFILE_SUCCESS: {
      return {
        ...state,
        list: changeDevicesProfile(
          state.list,
          action.payload.name,
          defaultProfile.name
        ),
      };
    }
    case SUBMIT_PROFILES_UPDATE: {
      const { update, remove } = action.payload;
      let newList = _.mapValues(state.list, device => {
        const deviceProfileUpdate = _.find(update, {
          profile: { name: device.profile },
        });
        if (deviceProfileUpdate && _.has(deviceProfileUpdate.updates, "name")) {
          return { ...device, profile: deviceProfileUpdate.updates.name };
        }

        const removedProfile = _.find(remove, { name: device.profile });
        if (removedProfile) {
          return { ...device, profile: defaultProfile.name };
        }

        return device;
      });
      return {
        ...state,
        keys: _.keys(newList),
        list: newList,
      };
    }
    case FETCH_ROAMING_LIMIT_SUCCESS: {
      const roamingLimit = action.payload;

      return {
        ...state,
        roamingDevicesLimit: roamingLimit,
      };
    }
    default:
      return state;
  }
}

const omitDeviceDetails = (device: LogicalDevice): LogicalDevice =>
  _.omit(device, "deviceDetails");

function changeDevicesProfile(
  list: { [name: string]: LogicalDevice },
  prev: string,
  next: string
): { [name: string]: LogicalDevice } {
  return _.mapValues(list, device =>
    device.profile === prev ? { ...device, profile: next } : device
  );
}

export const logicalDevicesReducer = withLoadingState({
  loadActionType: LOAD_LOGICAL_DEVICES,
  successActionType: LOAD_LOGICAL_DEVICES_SUCCESS,
  failureActionType: LOAD_LOGICAL_DEVICES_FAILURE,
})(reducer);
