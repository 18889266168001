import { Profile } from "@sportal/api";
import { NormalizedCollection } from "../shared/normalizeCollection";

export const LOAD_PROFILES = "[PROFILES] LOAD_PROFILES";
export const LOAD_PROFILES_SUCCESS = "[PROFILES] LOAD_PROFILES_SUCCESS";
export const LOAD_PROFILES_FAILURE = "[PROFILES] LOAD_PROFILES_FAILURE";
export const ADD_PROFILE = "[PROFILES] ADD_PROFILE";
export const ADD_PROFILE_SUCCESS = "[PROFILES] ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_FAILURE = "[PROFILES] ADD_PROFILE_FAILURE";
export const REMOVE_PROFILE = "[PROFILES] REMOVE_PROFILE";
export const REMOVE_PROFILE_SUCCESS = "[PROFILES] REMOVE_PROFILE_SUCCESS";
export const REMOVE_PROFILES_FAILURE = "[PROFILES] REMOVE_PROFILE_FAILURE";

interface LoadProfilesAction {
  type: typeof LOAD_PROFILES;
}

interface LoadProfilesSuccessAction {
  type: typeof LOAD_PROFILES_SUCCESS;
  payload: { profiles: NormalizedCollection<Profile>; schedules: object };
}

interface LoadProfilesFailureAction {
  type: typeof LOAD_PROFILES_FAILURE;
  payload: object;
}

interface AddProfileAction {
  type: typeof ADD_PROFILE;
}

interface AddProfileSuccessAction {
  type: typeof ADD_PROFILE_SUCCESS;
  payload: Profile;
}

interface AddProfileFailureAction {
  type: typeof ADD_PROFILE_FAILURE;
  payload: object;
}

interface RemoveProfileAction {
  type: typeof REMOVE_PROFILE;
}

export interface RemoveProfileSuccessAction {
  type: typeof REMOVE_PROFILE_SUCCESS;
  payload: Profile;
}

interface RemoveProfileFailureAction {
  type: typeof REMOVE_PROFILES_FAILURE;
  payload: object;
}

export type ProfilesActionTypes =
  | LoadProfilesAction
  | LoadProfilesSuccessAction
  | LoadProfilesFailureAction
  | AddProfileAction
  | AddProfileSuccessAction
  | AddProfileFailureAction
  | RemoveProfileAction
  | RemoveProfileSuccessAction
  | RemoveProfileFailureAction;
