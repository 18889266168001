import _ from "lodash";

import {
  isFailure,
  LogicalDevice,
  LogicalDevicesData,
  SpsonDeviceUpdateAttributes,
} from "@sportal/api";
import { getSubscriberId, getUserMode } from "../../info";
import { createTempNotification } from "../../../components/notifications";
import { Api } from "../../../api";
import {
  ADD_LOGICAL_DEVICE,
  ADD_LOGICAL_DEVICE_FAILURE,
  ADD_LOGICAL_DEVICE_SUCCESS,
  EDIT_LOGICAL_DEVICE,
  EDIT_LOGICAL_DEVICE_FAILURE,
  EDIT_LOGICAL_DEVICE_SUCCESS,
  LOAD_LOGICAL_DEVICES,
  LOAD_LOGICAL_DEVICES_FAILURE,
  LOAD_LOGICAL_DEVICES_SUCCESS,
  MERGE_LOGICAL_DEVICE,
  MERGE_LOGICAL_DEVICE_FAILURE,
  MERGE_LOGICAL_DEVICE_SUCCESS,
  REMOVE_LOGICAL_DEVICE,
  REMOVE_LOGICAL_DEVICE_FAILURE,
  REMOVE_LOGICAL_DEVICE_SUCCESS,
  UNMERGE_LOGICAL_DEVICE,
  UNMERGE_LOGICAL_DEVICE_FAILURE,
  UNMERGE_LOGICAL_DEVICE_SUCCESS,
  LogicalDevicesActionTypes,
  DeviceFromRequest,
  REVOKE_ROAMING_DEVICE_SUCCESS,
  REVOKE_ROAMING_DEVICE_FAILURE,
  REVOKE_ROAMING_DEVICE,
  EDIT_ROAMING_DEVICE,
  EDIT_ROAMING_DEVICE_SUCCESS,
  EDIT_ROAMING_DEVICE_FAILURE,
  FETCH_ROAMING_LIMIT_FAILURE,
  FETCH_ROAMING_LIMIT_SUCCESS,
} from "./logicalDevices.types";
import { getLogicalDevices } from "../../root.selectors";
import { isSPSONAvailable } from "../../account";

export const loadLogicalDevices = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_LOGICAL_DEVICES });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const isSpsonAvailable = isSPSONAvailable(getState());
  const result = await api.logicalDevices(userMode).get(subscriberId);

  if (isFailure(result)) {
    dispatch(loadLogicalDevicesFailure(result.error));
  } else {
    dispatch(loadLogicalDevicesSuccess(result.data));
    if (isSpsonAvailable) {
      dispatch(fetchRoamingLimit());
    }
  }
};

export const loadLogicalDevicesSuccess = ({
  content,
  limit,
}: LogicalDevicesData): LogicalDevicesActionTypes => ({
  type: LOAD_LOGICAL_DEVICES_SUCCESS,
  payload: {
    devices: content,
    // Logical device may have up to 2 identifiers (UI logic)
    // but each of these identifiers is considered as a separate device (BE logic)
    // The BE limit actually limits the number of identifiers user can add.
    // As for UI - we reduce the limit by 2 times so even when every device has 2
    // identifiers they do not exceed the BE limit
    limit: Math.floor(limit / 2),
  },
});

export const loadLogicalDevicesFailure = error => dispatch => {
  dispatch({ type: LOAD_LOGICAL_DEVICES_FAILURE, payload: error });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "failed_fetch_devices_data",
    })
  );
};

export const addLogicalDevice = ({ name, id, profileName }) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: ADD_LOGICAL_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api.logicalDevices(userMode).create(subscriberId, {
    name,
    id,
    profile: profileName,
  });

  if (isFailure(result)) {
    dispatch(addLogicalDeviceFailure(result.error));
  } else {
    dispatch(addLogicalDeviceSuccess(result.data));
  }
};

export const addLogicalDeviceSuccess = (
  device: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: ADD_LOGICAL_DEVICE_SUCCESS,
  payload: device,
});

export const addLogicalDeviceFailure = error => dispatch => {
  dispatch({ type: ADD_LOGICAL_DEVICE_FAILURE, payload: error });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const removeLogicalDevice = (device: LogicalDevice) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: REMOVE_LOGICAL_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api
    .logicalDevices(userMode)
    .remove(subscriberId, device);
  if (isFailure(result)) {
    dispatch(removeLogicalDeviceFailure(result.error));
  } else {
    dispatch(removeLogicalDeviceSuccess(device));
  }
};

export const removeLogicalDeviceSuccess = (
  device: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: REMOVE_LOGICAL_DEVICE_SUCCESS,
  payload: device,
});

export const removeLogicalDeviceFailure = error => dispatch => {
  dispatch({
    type: REMOVE_LOGICAL_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const revokeRoamingDevice = (device: LogicalDevice) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: REVOKE_ROAMING_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api
    .logicalDevices(userMode)
    .revoke(subscriberId, device.identifiers[0]);
  if (isFailure(result)) {
    dispatch(revokeRoamingDeviceFailure(result.error));
  } else {
    dispatch(revokeRoamingDeviceSuccess(device));
  }
};

export const revokeRoamingDeviceSuccess = (
  device: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: REVOKE_ROAMING_DEVICE_SUCCESS,
  payload: device,
});

export const revokeRoamingDeviceFailure = error => dispatch => {
  dispatch({
    type: REVOKE_ROAMING_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "could_not_revoke_device_access",
    })
  );
};

export const editLogicalDevice = (
  device: LogicalDevice,
  changes: { name: string; profile: string }
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: EDIT_LOGICAL_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api
    .logicalDevices(userMode)
    .update(subscriberId, device, {
      name: changes.name || device.name,
      profile: changes.profile || device.profile,
      identifiers: device.identifiers,
    });

  if (isFailure(result)) {
    dispatch(editLogicalDeviceFailure(result.error));
  } else {
    dispatch(editLogicalDeviceSuccess(device, result.data));
  }
};

export const editLogicalDeviceSuccess = (
  device: LogicalDevice | DeviceFromRequest,
  changed: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: EDIT_LOGICAL_DEVICE_SUCCESS,
  payload: { device, changed },
});

export const editLogicalDeviceFailure = error => dispatch => {
  dispatch({
    type: EDIT_LOGICAL_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const editRoamingDevice = (
  device: LogicalDevice & { networkId?: string },
  changes: SpsonDeviceUpdateAttributes
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: EDIT_ROAMING_DEVICE });
  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api
    .logicalDevices(userMode)
    .updateSpsonDevice(subscriberId, device.networkId, changes);

  if (isFailure(result)) {
    dispatch(editRoamingDeviceFailure(result.error));
  } else {
    dispatch(editRoamingDeviceSuccess(device, device.networkId, changes));
  }
};

export const editRoamingDeviceSuccess = (
  device: LogicalDevice,
  identifier: string,
  changes: SpsonDeviceUpdateAttributes
): LogicalDevicesActionTypes => ({
  type: EDIT_ROAMING_DEVICE_SUCCESS,
  payload: { device, identifier, changes },
});

export const editRoamingDeviceFailure = error => dispatch => {
  dispatch({
    type: EDIT_ROAMING_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const mergeLogicalDevice = (
  device: LogicalDevice | DeviceFromRequest,
  destinationDevice: LogicalDevice
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: MERGE_LOGICAL_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const result = await api
    .logicalDevices(userMode)
    .update(subscriberId, destinationDevice, {
      name: destinationDevice.name,
      profile: destinationDevice.profile,
      identifiers: [destinationDevice.identifiers[0], device.identifiers[0]],
    });

  if (isFailure(result)) {
    dispatch(mergeLogicalDeviceFailure(result.error));
  } else {
    dispatch(mergeLogicalDeviceSuccess(device, result.data));
  }
};

const mergeLogicalDeviceSuccess = (
  device: LogicalDevice | DeviceFromRequest,
  changed: LogicalDevice
): LogicalDevicesActionTypes => ({
  type: MERGE_LOGICAL_DEVICE_SUCCESS,
  payload: { device, changed },
});

const mergeLogicalDeviceFailure = error => dispatch => {
  dispatch({
    type: MERGE_LOGICAL_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const unmergeLogicalDevice = (
  device: LogicalDevice,
  lineId: string
) => async (dispatch, getState, { api }: { api: Api }) => {
  dispatch({ type: UNMERGE_LOGICAL_DEVICE });

  const userMode = getUserMode(getState());
  const subscriberId = getSubscriberId(getState());
  const updateResult = await api
    .logicalDevices(userMode)
    .update(subscriberId, device, {
      name: device.name,
      profile: device.profile,
      identifiers: _.without(device.identifiers, lineId),
    });

  if (isFailure(updateResult)) {
    dispatch(unmergeLogicalDeviceFailure(updateResult.error));
    return;
  }

  const generateNewName = lineId => {
    const devices = getLogicalDevices(getState()).list;
    let name = lineId;
    let i = 2;
    while (_.has(devices, name)) {
      name = `${lineId} (${i++})`;
    }
    return name;
  };

  const addResult = await api.logicalDevices(userMode).create(subscriberId, {
    name: generateNewName(lineId),
    id: lineId,
    profile: device.profile,
  });

  if (isFailure(addResult)) {
    dispatch(unmergeLogicalDeviceFailure(addResult.error));
  } else {
    dispatch(unmergeLogicalDeviceSuccess([updateResult.data, addResult.data]));
  }
};

const unmergeLogicalDeviceSuccess = (devices: LogicalDevice[]) => ({
  type: UNMERGE_LOGICAL_DEVICE_SUCCESS,
  payload: devices,
});

const unmergeLogicalDeviceFailure = error => dispatch => {
  dispatch({
    type: UNMERGE_LOGICAL_DEVICE_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "couldnt_save_changes",
    })
  );
};

export const fetchRoamingLimit = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const state = getState();
  const subscriberId = getSubscriberId(state);
  const userMode = getUserMode(state);

  const result = await api.logicalDevices(userMode).getSpsonLimit(subscriberId);

  if (isFailure(result)) {
    dispatch(fetchRoamingLimitFailure(result.error));
  } else {
    dispatch(fetchRoamingLimitSuccess(result.data));
  }
};

export const fetchRoamingLimitSuccess = roamingLimit => ({
  type: FETCH_ROAMING_LIMIT_SUCCESS,
  payload: roamingLimit,
});

export const fetchRoamingLimitFailure = error => dispatch => {
  dispatch({
    type: FETCH_ROAMING_LIMIT_FAILURE,
    payload: error,
  });
  dispatch(
    createTempNotification({
      variant: "error",
      title: "error",
      description: "failed_fetch_roaming_limit",
    })
  );
};
