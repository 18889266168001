import { InfectedDeviceServerData } from "@sportal/api";

export const LOAD_INFECTED_DEVICES = "[INFECTED_DEVICES] LOAD_INFECTED_DEVICES";
export const LOAD_INFECTED_DEVICES_SUCCESS =
  "[INFECTED_DEVICES] LOAD_INFECTED_DEVICES_SUCCESS";
export const LOAD_INFECTED_DEVICES_FAILURE =
  "[INFECTED_DEVICES] LOAD_INFECTED_DEVICES_FAILURE";

interface LoadInfectedDevicesAction {
  type: typeof LOAD_INFECTED_DEVICES;
}

interface LoadInfectedDevicesSuccessAction {
  type: typeof LOAD_INFECTED_DEVICES_SUCCESS;
  payload: InfectedDeviceServerData;
}

interface LoadInfectedDevicesFailureAction {
  type: typeof LOAD_INFECTED_DEVICES_FAILURE;
  payload: object;
}

export type InfectedDevicesActionTypes =
  | LoadInfectedDevicesAction
  | LoadInfectedDevicesSuccessAction
  | LoadInfectedDevicesFailureAction;
