import React from "react";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { getSubscriberId } from "../../../../store/info";
import { Service } from "@sportal/api";
import { isSubscriberSafetyActivated } from "../../../../store/account";
import {
  isPersonalInternetBypassPinAllowed,
  isSubscriberSafetyBypassPinAllowed,
} from "../../../../store/settings";
import {
  createTempNotification,
  StandardNotification,
  InlineNotification,
} from "../../../../components/notifications";
import { LinkToDefaultProfile } from "./LinkToDefaultProfile";

function getPinChange(key, changes) {
  const result = {};

  if (key in changes) {
    result.enabled = changes[key];
  }

  if (_.has(changes, "pin") && !_.isEmpty(changes.pin)) {
    result.value = changes.pin;
  }

  return result;
}

const SAVE_BLOCK_PAGE_SETTINGS =
  "[BLOCK_PAGE_TAB_TAB] SAVE_BLOCK_PAGE_SETTINGS";
export const saveBlockPageSettings = changes => async (
  dispatch,
  getState,
  { api }
) => {
  dispatch({ type: SAVE_BLOCK_PAGE_SETTINGS });

  const state = getState();

  const subscriberId = getSubscriberId(state);

  const isSSActivated = isSubscriberSafetyActivated(state);

  const isPIPinVisible = isPersonalInternetBypassPinAllowed(state);
  const isSSPinVisible = isSubscriberSafetyBypassPinAllowed(state);

  const pinUpdate = {};

  if (isPIPinVisible) {
    const PIPin = getPinChange("isPIPinOn", changes);

    if (!_.isEmpty(PIPin)) {
      pinUpdate[Service.PersonalInternet] = PIPin;
    }
  }

  if (isSSActivated && isSSPinVisible) {
    // We have to handle pin values separately since they can be desynced if SS was not activated initially
    // see EPORTAL-6706 for details
    const SSPin = getPinChange("isSSPinOn", changes);

    if (!_.isEmpty(SSPin)) {
      pinUpdate[Service.SubscriberSafety] = SSPin;
    }
  }

  const updates = _.omit(changes, ["pin", "isPIPinOn", "isSSPinOn"]);

  if (!_.isEmpty(pinUpdate)) {
    updates.pinUpdate = pinUpdate;
  }

  const results = await doSaveBlockPageSettings({
    subscriberId,
    api,
    changes: updates,
    isSSActivated,
  });

  const saveNotificationSpec = buildSaveNotificationSpec(results);

  if (saveNotificationSpec) {
    dispatch(createTempNotification(saveNotificationSpec));
  }

  const savedFields = _.keys(_.pickBy(results, { success: true }));
  dispatch(saveBlockPageSettingsSuccess(_.pick(results, savedFields)));
};

async function doSaveBlockPageSettings({
  subscriberId,
  api,
  changes,
  isSSActivated,
}) {
  const saveField = (field, value) =>
    ({
      pinUpdate: updates => api.account.updateBypassPin(subscriberId, updates),
      personalInternet: enabled => api.account.togglePI(subscriberId, enabled),
      subscriberSafety: enabled => {
        if (enabled) {
          return isSSActivated
            ? api.account.toggleSS(subscriberId, true)
            : api.account
                .activateSS(subscriberId)
                .then(result => ({ ...result, activateService: true }));
        }

        return api.account.toggleSS(subscriberId, false);
      },
    }[field](value));

  const results = {};

  for (const [field, value] of _.toPairs(changes)) {
    results[field] = await saveField(field, value);
  }

  return results;
}

const buildStandardNotification = message => {
  return {
    variant: "error",
    custom: <StandardNotification title="error" description={message} />,
  };
};

export const getInlineNotificationParts = savedFields => {
  const parts = [];

  if (savedFields.pinUpdate) {
    // any changes for pin section
    parts.push({
      id: "bypass_pin_was_saved",
    });
  }

  /**
   * This check comes from message list from described on link https://jira.nominum.com/jira/browse/EPORTAL-6673
   * all those messages are covered with unit tests in `blockPageTab.actions.test.js`
   */
  const didPIJustTurnedOffAndPinUpdated =
    _.has(savedFields, "personalInternet") &&
    !savedFields.personalInternet &&
    savedFields.pinUpdate;

  if (
    _.has(savedFields, "subscriberSafety") &&
    !didPIJustTurnedOffAndPinUpdated
  ) {
    parts.push({
      id: savedFields.subscriberSafety ? "your_ss_is_on" : "your_ss_is_off",
    });
  }

  if (_.has(savedFields, "personalInternet") && savedFields.personalInternet) {
    parts.push({
      id: "your_pi_is_on",
      values: {
        link: (
          <LinkToDefaultProfile>
            <FormattedMessage id="profile_settings" />
          </LinkToDefaultProfile>
        ),
      },
    });
  }

  return parts;
};

const buildSaveNotificationSpec = results => {
  const failedFields = _.keys(
    _.pickBy(results, {
      success: false,
    })
  );

  if (_.isEmpty(failedFields)) {
    const savedFields = _.mapValues(results, "data");

    const inlineMessages = getInlineNotificationParts(savedFields);

    if (inlineMessages.length === 0) {
      return null;
    }

    return {
      variant: "success",
      custom: <InlineNotification title="success" items={inlineMessages} />,
    };
  }

  return buildStandardNotification("couldnt_save_changes");
};

export const SAVE_BLOCK_PAGE_SETTINGS_SUCCESS =
  "[BLOCK_PAGE_TAB_TAB] SAVE_BLOCK_PAGE_SETTINGS_SUCCESS";
export const saveBlockPageSettingsSuccess = savedChanges => ({
  type: SAVE_BLOCK_PAGE_SETTINGS_SUCCESS,
  payload: savedChanges,
});
