import _ from "lodash";
import React from "react";
import { add } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

import { isSessionExpired } from "@sportal/api";
import { sleep } from "@sportal/cdk";

import { DeeplinkSuccessDialog, DeeplinkErrorDialog } from "./DeeplinkDialog";
import { getIs24hourFormat } from "../../store/attributes";
import { getTimezone } from "../../store/account";
import { ModalService } from "../modal/Modal";
import { getSubscriberId } from "../../store/info";

export const generateDeeplink = ({ delayMs } = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  if (delayMs) {
    await sleep(delayMs);
  }
  const state = getState();
  const id = getSubscriberId(state);

  try {
    const {
      data: { link, "deeplink-ttl": expirationDuration },
    } = await api.account.generateDeeplink(id);

    const is24 = getIs24hourFormat(state);
    const tz = getTimezone(state);

    ModalService.show(modal => {
      const now = utcToZonedTime(Date.now(), tz);
      const expires = format(
        add(now, { seconds: expirationDuration }),
        is24 ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy hh:mm aaa"
      );

      return {
        dialog: (
          <DeeplinkSuccessDialog modal={modal} link={link} expires={expires} />
        ),
      };
    }).then(_.noop, _.noop);
  } catch (e) {
    if (isSessionExpired(e)) {
      dispatch({ payload: e, type: GENERATE_DEEPLINK_FAILURE });
      return;
    }

    const onTryAgain = () => {
      dispatch(generateDeeplink({ delayMs: 1000 }));
    };

    ModalService.show(modal => ({
      dialog: <DeeplinkErrorDialog modal={modal} />,
    })).then(() => onTryAgain(), _.noop);
  }
};

export const GENERATE_DEEPLINK_FAILURE = "[SPSON] GENERATE_DEEPLINK_FAILURE";
