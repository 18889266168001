import React from "react";
import { useSelector } from "react-redux";

import { isSubscriberActivated } from "../../store/account";
import { WizardRouter } from "../WizardRouter";

export const withWizardGuard = Component => () => {
  const shouldShowWizard = !useSelector(isSubscriberActivated);

  // TODO: actually a subscriber gets activated after the protection choice step
  //  so after refresh the page you cannot access wizard anymore
  //  It means that we should distinguish 'wizard' session somehow
  //  and do not react to such activation

  return shouldShowWizard ? <WizardRouter /> : <Component />;
};
