import { InfectionsServerData, isFailure } from "@sportal/api";

import { getSubscriberId } from "../info";
import { Api } from "../../api";
import {
  LOAD_INFECTIONS,
  LOAD_INFECTIONS_FAILURE,
  LOAD_INFECTIONS_SUCCESS,
  InfectionsActionTypes,
} from "./infections.types";

export const loadInfections = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const subscriberId = getSubscriberId(getState());

  dispatch({ type: LOAD_INFECTIONS });

  const result = await api.infections.get(subscriberId);

  if (isFailure(result)) {
    dispatch(loadInfectionsFailure(result.error));
    return;
  }

  dispatch(loadInfectionsSuccess(result.data));
};

export const loadInfectionsSuccess = (
  infections: InfectionsServerData
): InfectionsActionTypes => ({
  type: LOAD_INFECTIONS_SUCCESS,
  payload: infections,
});

export const loadInfectionsFailure = (error): InfectionsActionTypes => ({
  type: LOAD_INFECTIONS_FAILURE,
  payload: error,
});
