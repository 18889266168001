import React, { Fragment } from "react";
import { CollapsibleSection, useCollapseContext } from "@sportal/cdk";
import { TimePeriodButtons } from "./auxiliaryComponents/TimePeriodButtons";
import { BarChart } from "../barchart/BarChart";
import { ActivityCheckboxes } from "./auxiliaryComponents/ActivityCheckboxes";
import { ReportHeader } from "./auxiliaryComponents/ReportHeader";

import "./ReportMobile.scss";

export const ReportMobile = ({ profileName }) => (
  <CollapsibleSection
    className={"report-panel"}
    header={<ReportMobileHeader profileName={profileName} />}
    contentClassName={"report-panel__content"}
    content={
      <Fragment>
        <div className={"report__controls"}>
          <TimePeriodButtons className={"report__time-selector"} />
        </div>
        <BarChart />
        <ActivityCheckboxes className={"report__toggles"} />
      </Fragment>
    }
  />
);

const ReportMobileHeader = ({ profileName }) => {
  const { getToggleProps } = useCollapseContext();

  return (
    <div className="report-panel__header" {...getToggleProps({ type: "div" })}>
      <div className={"report-panel__title"}>
        <ReportHeader profileName={profileName} />
      </div>
      <div className={"report-panel__icon"} />
    </div>
  );
};
