import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../../../components";

import "./SafetyWizardPage.scss";
import { useDispatch } from "react-redux";
import { chooseProtection } from "../store/wizard.actions";

const SafetyWizardContent = ({ onGetProtectionClick }) => (
  <div className="safety-wizard">
    <div className="safety-wizard__item-top">
      <header className="safety-wizard__header">
        <h1 className="safety-wizard__title">
          <FormattedMessage id="protect_yourself_online" />
        </h1>
        <p className="safety-wizard__subtitle">
          <FormattedMessage id="with_subscriber_safety" />
        </p>
      </header>
      <img
        className="safety-wizard__image-top"
        src="images/safety-top.svg"
        alt="Safety wizard top"
      />
    </div>
    <div className="safety-wizard__item-bottom">
      <img
        className="safety-wizard__image-bottom"
        src="images/safety-bottom.svg"
        alt="Safety wizard bottom"
      />
      <div className="safety-wizard__description">
        <p>
          <FormattedMessage id="get_internet_protection_from_the_source" />
        </p>
        <p>
          <FormattedMessage id="prevent_botnet_malware_phishing" />
        </p>
      </div>
      <Button
        size="medium"
        color="primary"
        className="safety-wizard__button"
        onClick={onGetProtectionClick}
      >
        <FormattedMessage id="get_protection_now" />
      </Button>
    </div>
  </div>
);

SafetyWizardContent.propTypes = {
  onGetProtectionClick: PropTypes.func.isRequired,
};

export const SafetyWizardPage = () => {
  const dispatch = useDispatch();
  return (
    <SafetyWizardContent
      onGetProtectionClick={() => dispatch(chooseProtection())}
    />
  );
};
