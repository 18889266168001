import React, { VFC } from "react";
import { FormattedMessage } from "react-intl";

import { LogicalDeviceDetailsTypes } from "@sportal/api";
import { Tooltip } from "../../tooltip/Tooltip";
import { DeviceIcon, DeviceIconDescription, DeviceTypeMap } from "../icons";

import "./DeviceTypeTooltip.scss";

interface DeviceTypeTooltipProps {
  isNewRequest?: boolean;
  isMerged?: boolean;
  type?: LogicalDeviceDetailsTypes;
}

export const DeviceTypeTooltip: VFC<DeviceTypeTooltipProps> = ({
  isNewRequest,
  isMerged,
  type,
}) => {
  const deviceType = isNewRequest
    ? DeviceTypeMap.NewRequest
    : isMerged
    ? DeviceTypeMap.Merged
    : DeviceTypeMap[type];

  const icon = DeviceIcon[deviceType];
  const iconDescription = DeviceIconDescription[deviceType];

  return (
    //@ts-ignore
    <Tooltip
      tooltipRootClassName="device-type-tooltip"
      placement="top"
      content={<FormattedMessage id={iconDescription} />}
    >
      <span className="sr-only">
        <FormattedMessage id={iconDescription} />
      </span>
      {icon}
    </Tooltip>
  );
};
