import React, { createContext, useContext, useCallback } from "react";
import PropTypes from "prop-types";

export { useForm } from "./useForm";

const FormContext = createContext({});

export function useFormContext() {
  return useContext(FormContext);
}

// this is just an experimental idea how we can handle form submits
export function Form({
  formProps,
  method,
  onSubmit,
  disabled,
  children,
  className,
}) {
  const { resetErrors } = formProps;

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      if (!disabled) {
        resetErrors();

        onSubmit();
      }
    },
    [disabled, resetErrors, onSubmit]
  );

  return (
    <FormContext.Provider
      value={{
        disabled,
        form: formProps,
      }}
    >
      <form
        method={method}
        onSubmit={handleSubmit}
        className={className}
        data-testid="form"
      >
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  method: "post",
  disabled: false,
};

Form.propTypes = {
  formProps: PropTypes.shape({
    resetErrors: PropTypes.func.isRequired,
  }),
  method: PropTypes.oneOf(["post", "get"]),
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
