import _ from "lodash";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import "./ListNotification.scss";

import { StandardNotification } from "..";

export const ListNotification = ({ title, description, items }) => (
  <Fragment>
    <StandardNotification title={title} description={description} />
    <ul className="list-notification">
      {_.map(items, item => (
        <li key={item} className="list-notification__item">
          <FormattedMessage id={item} />
        </li>
      ))}
    </ul>
  </Fragment>
);

ListNotification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};
