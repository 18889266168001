import React from "react";
import { useSelector } from "react-redux";

import { HomeServicePanel, withLayout } from "../../components";
import { homePageContentSelector } from "./homePageContentSelector";
import { HomeHeader } from "./homeHeader/HomeHeader";
import { Report } from "../../components/report/Report";
import { isReportVisible } from "../../store/settings";
import { isSPSONAvailable } from "../../store/account";
import { InfectedDevicesPanel } from "./infectedDevicesPanel/InfectedDevicesPanel";
import { isSpsonPanelVisible } from "../../store/banners";
import { SpsonPanel } from "../../components";

import "./HomePage.scss";

const HomePage = () => {
  const showReport = useSelector(isReportVisible);
  const Component = useSelector(homePageContentSelector);
  const showSPSON = useSelector(isSPSONAvailable);
  const isPanelVisible = useSelector(isSpsonPanelVisible);

  return (
    <div className="home-page">
      <HomeServicePanel />
      {showReport && <Report />}
      <InfectedDevicesPanel />
      <div className="home-page__content">
        {showSPSON && isPanelVisible && <SpsonPanel />}
        <Component />
      </div>
    </div>
  );
};

export default withLayout({
  customHeader: () => <HomeHeader />,
})(HomePage);
