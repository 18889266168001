import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { zonedTimeToUtc } from "date-fns-tz";

import { useForceUpdate, useInterval } from "@sportal/cdk/hooks";
import {
  getInternetPauseSchedule,
  isProfilePaused,
} from "../../store/profiles";
import { getTimezone } from "../../store/account";
import { getInternetPauseOptions } from "../../store/config";
import { pauseInternet, unpauseInternet } from "./pauseInternet.actions";
import { isValidPauseOption, getTimeLeft } from "./pauseInternet.helpers";

export const UPDATE_PAUSE_STATE_INTERVAL_MS = 15000;

export const PauseInternetContext = createContext({
  isInternetPaused: false,
  timeLeft: null,
  pauseInternet: _.noop,
  unpauseInternet: _.noop,
  pauseInternetOptions: [],
  showSeparateUnpauseButton: false,
});

function useTimeLeft(isInternetPaused, profileId, calcTimeLeft) {
  const timezone = useSelector(getTimezone);
  const schedule = useSelector(state =>
    getInternetPauseSchedule(state, profileId)
  );

  if (!isInternetPaused) {
    return null;
  }

  const now = zonedTimeToUtc(Date.now(), timezone);

  return calcTimeLeft(schedule, now);
}

function usePauseActions(profileId) {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      pauseInternet: ({ amount, units }) => {
        dispatch(pauseInternet(profileId, { [units]: amount }));
      },
      unpauseInternet: () => dispatch(unpauseInternet(profileId)),
    }),
    [dispatch, profileId]
  );
}

export function PauseInternetProvider({
  profileId,
  showSeparateUnpauseButton,
  calcTimeLeft,
  getIsProfilePaused,
  children,
}) {
  const forceUpdate = useForceUpdate();

  const { pauseInternet, unpauseInternet } = usePauseActions(profileId);

  const pauseInternetOptions = useSelector(state => {
    const options = getInternetPauseOptions(state);
    return _.filter(options, isValidPauseOption);
  }, _.isEqual);

  const isInternetPaused = useSelector(state =>
    getIsProfilePaused(state, profileId)
  );

  useInterval(
    forceUpdate,
    isInternetPaused ? UPDATE_PAUSE_STATE_INTERVAL_MS : null
  );

  const timeLeft = useTimeLeft(isInternetPaused, profileId, calcTimeLeft);

  const contextValue = useMemo(
    () => ({
      isInternetPaused,
      timeLeft,
      pauseInternet,
      unpauseInternet,
      pauseInternetOptions,
      showSeparateUnpauseButton,
    }),
    [
      isInternetPaused,
      timeLeft,
      pauseInternet,
      unpauseInternet,
      pauseInternetOptions,
      showSeparateUnpauseButton,
    ]
  );

  return (
    <PauseInternetContext.Provider value={contextValue}>
      {children}
    </PauseInternetContext.Provider>
  );
}

PauseInternetProvider.defaultProps = {
  calcTimeLeft: getTimeLeft,
  getIsProfilePaused: isProfilePaused,
};

PauseInternetProvider.propTypes = {
  profileId: PropTypes.string.isRequired,
  showSeparateUnpauseButton: PropTypes.bool.isRequired,
  calcTimeLeft: PropTypes.func,
  getIsProfilePaused: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export function usePauseInternet() {
  return useContext(PauseInternetContext);
}
