import { withLoadingState } from "../../shared/withLoadingState";
import {
  LOAD_SHARED_PROTECTIONS,
  LOAD_SHARED_PROTECTIONS_SUCCESS,
  LOAD_SHARED_PROTECTIONS_FAILURE,
  SharedProtectionsActionTypes,
} from "./sharedProtections.types";

export const initialState = {
  keys: [],
  list: {},
};

const reducer = (
  state = initialState,
  action: SharedProtectionsActionTypes
) => {
  switch (action.type) {
    case LOAD_SHARED_PROTECTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const sharedProtectionsReducer = withLoadingState({
  loadActionType: LOAD_SHARED_PROTECTIONS,
  successActionType: LOAD_SHARED_PROTECTIONS_SUCCESS,
  failureActionType: LOAD_SHARED_PROTECTIONS_FAILURE,
})(reducer);
