import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";

const moveOtherToEnd = array => {
  const other = _.find(array, ({ name }) => name.endsWith("others"));
  const withoutOther = _.without(array, other);

  return [...withoutOther, other];
};

export const transformCategories = (intl, categories) => {
  const transform = items =>
    _(items)
      .map(category => ({
        ...category,
        displayName: intl.formatMessage({
          id: `category_${category.name}`,
          defaultMessage: category.name,
        }),
        description: (
          <FormattedMessage id={`category_${category.name}.description`} />
        ),
        categories: category.categories
          ? _.flow(
              transform,
              moveOtherToEnd
            )(category.categories)
          : undefined,
      }))
      .orderBy("displayName")
      .value();

  return transform(categories);
};
