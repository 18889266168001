import { useSelector } from "react-redux";

import { Service } from "@sportal/api";
import { UserMode } from "@sportal/api";
import { getUserMode } from "../../store/info";
import {
  getAccount,
  getInfections,
  getInfectedDevices,
} from "../../store/root.selectors";
import { getThreatsBySelectedLanguage } from "../../store/threats";
import { isSubscriberSafetyEnabled } from "../../store/account";
import isEmpty from "lodash/isEmpty";

const translations = {
  [Service.SubscriberSafety]: {
    title: "malware_and_phishing_protection",
    description: "malware_and_phishing_protection_description",
  },
  [Service.PersonalInternet]: {
    title: "personal_internet",
    description: "personal_internet_description",
  },
};

const services = {
  [UserMode.SubscriberSafety]: [Service.SubscriberSafety],
  [UserMode.Household]: [Service.SubscriberSafety, Service.PersonalInternet],
  [UserMode.PerDevice]: [Service.SubscriberSafety, Service.PersonalInternet],
  [UserMode.PISingle]: [Service.PersonalInternet],
  [UserMode.PIProfile]: [Service.PersonalInternet],
};

const getInfectionStatus = (infections, error) => {
  if (error) {
    return "failed";
  }

  return !isEmpty(infections.list) ? "infected" : "clean";
};

export const useServicePanel = () => {
  const infections = useSelector(getInfections);
  const infectedDevices = useSelector(getInfectedDevices);
  const threats = useSelector(getThreatsBySelectedLanguage);
  const isSSEnabled = useSelector(isSubscriberSafetyEnabled);

  const userMode = useSelector(getUserMode);
  const account = useSelector(getAccount);
  const availableServices = services[userMode];

  const isLoading =
    infections.isLoading || infectedDevices.isLoading || threats.isLoading;
  const error = infections.error || infectedDevices.error || threats.error;

  return availableServices.map(service => {
    let infectionStatus;
    if (!isLoading && isSSEnabled && service === Service.SubscriberSafety) {
      infectionStatus = getInfectionStatus(infectedDevices, error);
    }

    return {
      service,
      ...translations[service],
      enabled: account[service].enabled,
      infectionStatus,
    };
  });
};
