import React, { forwardRef, HTMLProps } from "react";

import { Icon } from "@sportal/cdk";

import "./ToggleActionsButton.scss";

interface PropsToggleActionsButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick?: () => void;
}

export const ToggleActionsButton = forwardRef<
  HTMLButtonElement,
  PropsToggleActionsButtonProps
>((props, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      type="button"
      className="toggle-actions-button"
    >
      <Icon icon="far fa-ellipsis-v" />
    </button>
  );
});
