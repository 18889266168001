import { getSubscriberId } from "../info";
import { Api } from "../../api";
import {
  CLEAR_HISTORY,
  CLEAR_HISTORY_FAILURE,
  CLEAR_HISTORY_SUCCESS,
  ClearHistoryActionTypes,
} from "./clearHistory.types";
import { createTempNotification } from "../../components/notifications";
import { isFailure } from "@sportal/api";

export const clearHistory = metrics => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const subscriberId = getSubscriberId(getState());

  dispatch({ type: CLEAR_HISTORY });

  const result = await api.clearHistory.clear(subscriberId, metrics);

  if (isFailure(result)) {
    dispatch(clearHistoryFailure(result.error));

    dispatch(
      createTempNotification({
        variant: "error",
        description: "failed_to_delete_data",
      })
    );
  } else {
    dispatch(clearHistorySuccess(metrics));

    dispatch(
      createTempNotification({
        variant: "success",
        title: "remove_data_success",
        description: "remove_data_note",
      })
    );
  }
};

export const clearHistorySuccess = (metrics): ClearHistoryActionTypes => ({
  type: CLEAR_HISTORY_SUCCESS,
  payload: metrics,
});

export const clearHistoryFailure = (error): ClearHistoryActionTypes => ({
  type: CLEAR_HISTORY_FAILURE,
  payload: error,
});
