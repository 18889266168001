import React from "react";
import { FormattedMessage } from "react-intl";
import { Carousel, Icon } from "@sportal/cdk";
import { Desktop, Mobile, Tablet } from "../../common/Responsive";
import { InfectedDevicesAccordion } from "../infectedDevicesAccordion/InfectedDevicesAccordion";
import "./InfectedDevices.scss";

const CHUNK_SIZE_FOR_DESKTOP = 4;
const CHUNK_SIZE_FOR_TABLET = 3;

function renderItem(item) {
  return (
    <div className="infection">
      <div className="infection__header">
        <Icon icon="fas fa-bug" />
        {item.threatName}
      </div>
      {item.deviceId && (
        <div className="infected-device">
          <div className="infected-device__label">
            <FormattedMessage id="infected_device" />
          </div>
          <div className="infected-device__name">{item.name}</div>
        </div>
      )}
      <div
        className="infection__description"
        dangerouslySetInnerHTML={{ __html: item.threatDescription }}
      />
    </div>
  );
}

export function InfectedDevices({ items }) {
  return (
    <div className="infected-devices">
      <div className="infected-devices__container">
        <p className="infected-devices__container-description">
          <FormattedMessage id="infected_devices_message" />
        </p>
        <Mobile>
          <InfectedDevicesAccordion items={items} />
        </Mobile>
        <Tablet>
          <Carousel
            items={items}
            renderItem={renderItem}
            chunkSize={CHUNK_SIZE_FOR_TABLET}
          />
        </Tablet>
        <Desktop>
          <Carousel
            items={items}
            renderItem={renderItem}
            chunkSize={CHUNK_SIZE_FOR_DESKTOP}
          />
        </Desktop>
      </div>
      <div className="infected-devices__footer">
        <p>
          <FormattedMessage id="infected_devices_footer_1" />
        </p>
        <p>
          <FormattedMessage id="infected_devices_footer_2" />
        </p>
      </div>
    </div>
  );
}
