import { getSubscriberId } from "../info";
import { getProfilesFactory } from "./profiles.selectors";
import { normalizeCollection } from "../shared/normalizeCollection";
import { isFailure, Profile, ProfilesServiceErrorCode } from "@sportal/api";
import { createTempNotification } from "../../components/notifications";
import {
  LOAD_PROFILES,
  LOAD_PROFILES_SUCCESS,
  LOAD_PROFILES_FAILURE,
  ADD_PROFILE,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAILURE,
  REMOVE_PROFILE,
  REMOVE_PROFILE_SUCCESS,
  REMOVE_PROFILES_FAILURE,
  ProfilesActionTypes,
} from "./profiles.types";
import { Api } from "../../api";
import { ProfileSchedules } from "../schedules";
import { sortProfiles } from "./profiles.helpers";

export const loadProfiles = () => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: LOAD_PROFILES });

  const subscriberId = getSubscriberId(getState());
  const result = await api.profiles.get(subscriberId);

  if (isFailure(result)) {
    dispatch(loadProfilesFailure(result.error));
  } else {
    const { data } = result;
    const profiles = [];
    const schedules = {};

    data.forEach(({ schedules: profileSchedules, ...profile }) => {
      const id = profile.id;

      profiles.push(profile);
      schedules[id] = profileSchedules;
    });

    const sortedProfiles = sortProfiles(profiles);

    dispatch(loadProfilesSuccess(sortedProfiles, schedules));
  }
};

export const loadProfilesSuccess = (
  profiles: Profile[],
  schedules: ProfileSchedules
): ProfilesActionTypes => ({
  type: LOAD_PROFILES_SUCCESS,
  payload: { profiles: normalizeCollection(profiles, "id"), schedules },
});

export const loadProfilesFailure = (error): ProfilesActionTypes => ({
  type: LOAD_PROFILES_FAILURE,
  payload: error,
});

export const addProfile = ({ id, name, ageGroup }) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  const errorMessages = {
    [ProfilesServiceErrorCode.GENERIC]: "cannot_create_new_profile",
  };

  dispatch({ type: ADD_PROFILE });

  const state = getState();
  const subscriberId = getSubscriberId(state);

  const profile = getProfilesFactory(state)(id, name, ageGroup);
  const result = await api.profiles.create(subscriberId, [profile]);

  if (isFailure(result)) {
    const message = errorMessages[result.error.code];

    dispatch(addProfileFailure({ ...result.error, message }));
    dispatch(
      createTempNotification({
        variant: "error",
        description: message,
      })
    );
  } else {
    const [createdProfile] = result.data;

    // replace client generated id with the real one
    dispatch(addProfileSuccess({ ...profile, id: createdProfile.id }));
    dispatch(
      createTempNotification({
        variant: "success",
        description: {
          id: "profile_name_was_added_successfully",
          values: { name },
        },
      })
    );
  }
};

export const addProfileSuccess = (profile: Profile): ProfilesActionTypes => ({
  type: ADD_PROFILE_SUCCESS,
  payload: profile,
});

export const addProfileFailure = (error): ProfilesActionTypes => ({
  type: ADD_PROFILE_FAILURE,
  payload: error,
});

export const removeProfile = (profile, history) => async (
  dispatch,
  getState,
  { api }: { api: Api }
) => {
  dispatch({ type: REMOVE_PROFILE });

  const state = getState();
  const subscriberId = getSubscriberId(state);
  const result = await api.profiles.delete(subscriberId, profile.name);

  if (isFailure(result)) {
    dispatch(removeProfilesFailure(result.error));

    dispatch(
      createTempNotification({
        variant: "error",
        description: "couldnt_delete_profile",
      })
    );
  } else {
    history && history.push("/home");
    dispatch(removeProfileSuccess(profile));

    dispatch(
      createTempNotification({
        variant: "success",
        description: {
          id: "profile_deleted",
          values: { profileName: profile.name },
        },
      })
    );
  }
};

export const removeProfileSuccess = (
  profile: Profile
): ProfilesActionTypes => ({
  type: REMOVE_PROFILE_SUCCESS,
  payload: profile,
});

export const removeProfilesFailure = (error): ProfilesActionTypes => ({
  type: REMOVE_PROFILES_FAILURE,
  payload: error,
});
