import React, { Fragment, useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import { ModalService } from "../modal";
import { ConfirmationDialog } from "../confirmationDialog/ConfirmationDialog";
import { NotMobile, Mobile } from "../../common/Responsive";
import { Button } from "../button/Button";
import { PausedInternetButton } from "./PausedInternetButton";
import { PauseInternetDropdown } from "./PauseInternetDropdown";
import { PauseInternetModal } from "./PauseInternetModal";
import { usePauseInternet } from "./PauseInternetProvider";
import { humanizePrecisely } from "./pauseInternet.helpers";

const PauseOptionsTrigger = forwardRef(function PauseOptionsTrigger(
  { label, icon, onClick, size, variant, children, ...props },
  forwardedRef
) {
  return (
    <Fragment>
      {label && <span className="paused-internet__label">{label}</span>}
      <Button
        ref={forwardedRef}
        className="pause-internet__trigger"
        onClick={onClick}
        color="secondary"
        size={size}
        variant={variant}
        iconRight={icon}
        {...props}
      >
        {children}
      </Button>
    </Fragment>
  );
});

export function PauseInternetOptions({
  onPauseInternet,
  options,
  variant,
  label,
  hiddenLabel,
  size,
}) {
  const pauseOptions = useMemo(
    () =>
      _.map(options, (option, index) => ({
        content: <div>{humanizePrecisely(option.amount, option.units)}</div>,
        action: () => {
          onPauseInternet(option);
        },
        id: `pause-internet-${index}`,
      })),
    [onPauseInternet, options]
  );

  if (pauseOptions.length === 0) {
    return (
      <PauseOptionsTrigger label={label} size={size} variant={variant}>
        <FormattedMessage id="pause_internet" />
      </PauseOptionsTrigger>
    );
  }

  const renderToggle = ({ label, onClick, icon }) => (
    <PauseOptionsTrigger
      label={label}
      onClick={onClick}
      size={size}
      variant={variant}
      icon={icon}
    >
      <FormattedMessage id="pause_internet" />
    </PauseOptionsTrigger>
  );

  return (
    <Fragment>
      <NotMobile>
        <PauseInternetDropdown
          items={pauseOptions}
          label={label}
          hiddenLabel={hiddenLabel}
          renderToggle={renderToggle}
        />
      </NotMobile>
      <Mobile>
        <PauseInternetModal
          items={pauseOptions}
          label={label}
          renderToggle={renderToggle}
        />
      </Mobile>
    </Fragment>
  );
}

PauseInternetOptions.defaultProps = {
  size: "large",
};

PauseInternetOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node,
      action: PropTypes.func,
    })
  ),
  variant: PropTypes.oneOf(["outlined", "inverted"]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(["medium", "large"]),
};

function UnpauseInternetModal({ modal, profileName }) {
  return (
    <ConfirmationDialog
      modal={modal}
      title={<FormattedMessage id="unpause_profile" values={{ profileName }} />}
      content={
        <FormattedMessage id="unpause_modal_text" values={{ profileName }} />
      }
      cancelMessage={<FormattedMessage id="cancel" />}
      submitMessage={<FormattedMessage id="unpause" />}
    />
  );
}

export function PauseInternet({
  profileName,
  variant,
  label,
  hiddenLabel,
  size,
}) {
  const {
    isInternetPaused,
    timeLeft,
    pauseInternet,
    unpauseInternet,
    pauseInternetOptions,
    showSeparateUnpauseButton,
  } = usePauseInternet();

  if (isInternetPaused && timeLeft) {
    const handleUnpauseClick = () => {
      ModalService.show(modal => ({
        dialog: (
          <UnpauseInternetModal modal={modal} profileName={profileName} />
        ),
      }))
        .then(() => {
          unpauseInternet();
        })
        .catch(() => {});
    };

    return (
      <PausedInternetButton
        timeLeft={timeLeft}
        onUnpause={handleUnpauseClick}
        showSeparateUnpauseButton={showSeparateUnpauseButton}
        label={label}
      />
    );
  }

  return (
    <div className="pause-internet">
      <PauseInternetOptions
        onPauseInternet={pauseInternet}
        options={pauseInternetOptions}
        variant={variant}
        label={label}
        hiddenLabel={hiddenLabel}
        size={size}
      />
    </div>
  );
}
