import { Schedule } from "@sportal/api";

export interface ProfileSchedules {
  [id: string]: Schedule[];
}

export interface AddOrUpdateSchedulePayload {
  profileId: string;
  schedule: Schedule;
}
export interface DeleteSchedulePayload {
  profileId: string;
  scheduleName: string;
}

export const ADD_SCHEDULE = "[SCHEDULES] ADD_SCHEDULE";
export const ADD_SCHEDULE_SUCCESS = "[SCHEDULES] ADD_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE = "[SCHEDULES] UPDATE_SCHEDULE";
export const UPDATE_SCHEDULE_SUCCESS = "[SCHEDULES] UPDATE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE = "[SCHEDULES] DELETE_SCHEDULE";
export const DELETE_SCHEDULE_SUCCESS = "[SCHEDULES] DELETE_SCHEDULE_SUCCESS";

interface AddScheduleSuccessAction {
  type: typeof ADD_SCHEDULE_SUCCESS;
  payload: AddOrUpdateSchedulePayload;
}

interface UpdateScheduleSuccessAction {
  type: typeof UPDATE_SCHEDULE_SUCCESS;
  payload: AddOrUpdateSchedulePayload;
}

interface DeleteScheduleSuccessAction {
  type: typeof DELETE_SCHEDULE_SUCCESS;
  payload: DeleteSchedulePayload;
}

export type SchedulesActionTypes =
  | AddScheduleSuccessAction
  | UpdateScheduleSuccessAction
  | DeleteScheduleSuccessAction;
