import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { Icon } from "@sportal/cdk";
import { addProfile } from "../../../store/profiles";
import { Button } from "../../button/Button";
import { AddProfileModal } from "../../profileModals/addProfileModal/AddProfileModal";
import { ModalService } from "../../modal";

import "./NewProfileCard.scss";

export const NewProfileCard = ({ availableProfilesNumber, className }) => {
  const dispatch = useDispatch();

  return (
    <div className={classnames("new-profile-card", className)}>
      <div className="new-profile-card__content">
        <p>
          <FormattedMessage id="create_profiles_first" />
        </p>
        <p className="strong">
          <FormattedMessage
            id="create_profiles_second"
            values={{ availableProfilesNumber }}
          />
        </p>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          className="new-profile-card__button"
          onClick={() => {
            ModalService.show(modal => ({
              dialog: <AddProfileModal modal={modal} />,
            }))
              .then(profile => dispatch(addProfile(profile)))
              .catch(() => {});
          }}
          iconLeft={<Icon icon="fas fa-plus" />}
        >
          <span className="new-profile-card__button__text">
            <FormattedMessage id="add_new_profile" />
          </span>
        </Button>
      </div>
    </div>
  );
};

NewProfileCard.propTypes = {
  availableProfilesNumber: PropTypes.number.isRequired,
  className: PropTypes.string,
};
