import React, { InputHTMLAttributes, ReactElement, useRef, VFC } from "react";
import _ from "lodash";
import classNames from "classnames";

import { BaseInput } from "../baseInput/BaseInput";
import { Icon } from "../icon/Icon";
import { CrossButton } from "../crossButton/CrossButton";
import { InputLabel } from "./InputLabel";
import { InputError } from "./InputError";
import { randomId } from "../helpers";

import "./Input.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  label?: string | ReactElement;
  icon?: string;
  error?: string[] | ReactElement[] | string | ReactElement;
  hint?: ReactElement;
  showLimit?: boolean;
  clearButtonAriaLabel?: string;
  onClear?: () => void;
}

export const Input: VFC<InputProps> = ({
  id,
  value,
  label,
  icon,
  hint,
  error,
  onClear,
  maxLength,
  className,
  showLimit,
  disabled,
  readOnly,
  clearButtonAriaLabel,
  ...inputAttrs
}) => {
  const inputId = useRef(id || (label && `input${randomId()}`));
  const errorDescribedById = useRef(`error${randomId()}`);
  const hasLabel = label || showLimit;
  const limitExceeded = showLimit && value.trim().length > maxLength;
  const hasErrorMessage = !_.isEmpty(error);

  const hasError = limitExceeded || hasErrorMessage;

  const inputWrapperClass = classNames("input", className, {
    "input--disabled": disabled,
    "input--readonly": readOnly,
    "input--has-label": hasLabel,
    "input--has-icon": icon,
    "input--has-hint": hint,
    "input--has-error": hasError,
    "input--has-clear-icon": onClear,
  });

  return (
    <div className={inputWrapperClass}>
      {hasLabel && (
        <InputLabel
          id={inputId.current}
          label={label}
          value={value}
          showLimit={showLimit}
          maxLength={maxLength}
        />
      )}

      <div className="input__field">
        {icon && <Icon icon={icon} />}

        <BaseInput
          id={inputId.current}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          {...(hasError && {
            "aria-invalid": true,
            "aria-describedby": errorDescribedById.current,
          })}
          {...inputAttrs}
        />

        {!disabled && onClear && value && (
          <CrossButton
            size="medium"
            onClick={onClear}
            aria-label={clearButtonAriaLabel}
          />
        )}
      </div>

      {hint && <div className="input__hint">{hint}</div>}

      {hasErrorMessage && (
        <InputError
          error={error}
          ariaDescribedById={errorDescribedById.current}
        />
      )}
    </div>
  );
};
