import { Line } from "@sportal/api";
import { withLoadingState } from "../../shared/withLoadingState";
import {
  LinesActionTypes,
  LOAD_LINES,
  LOAD_LINES_FAILURE,
  LOAD_LINES_SUCCESS,
} from "./lines.types";

interface LinesState {
  list: { [name: string]: Line };
  keys: string[];
  limit: number;
}

const initialState = {
  list: {},
  keys: [],
  limit: null,
};

const reducer = (
  state: LinesState = initialState,
  action: LinesActionTypes
) => {
  switch (action.type) {
    case LOAD_LINES_SUCCESS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export const linesReducer = withLoadingState({
  loadActionType: LOAD_LINES,
  successActionType: LOAD_LINES_SUCCESS,
  failureActionType: LOAD_LINES_FAILURE,
})(reducer);
