import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ServiceStatus.scss";

export const ServiceStatus = ({ active }) => (
  <div
    className={classNames("service-status", {
      "service-status--active": active,
    })}
  >
    <FormattedMessage id={active ? "active" : "service_off"} />
  </div>
);

ServiceStatus.propTypes = {
  active: PropTypes.bool.isRequired,
};
