import { InfectionsProvider } from "./infections.provider";
import { Result, success, failure } from "../shared";
import { InfectionsServerData } from "./infections.types";
import { Durations } from "../reports";

export enum InfectionsServiceErrorCode {
  GENERIC,
}

export class InfectionsService {
  constructor(private provider: InfectionsProvider) {}

  public async get(
    subscriberId: string
  ): Promise<Result<InfectionsServerData>> {
    try {
      const { data } = await this.provider.get(subscriberId, Durations.Month);
      return success(data);
    } catch (error) {
      return failure(InfectionsServiceErrorCode.GENERIC);
    }
  }
}
