import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ModalService } from "@sportal/cdk";
import { AssignDeviceDialog } from "../assignDeviceDialog/AssignDeviceDialog";
import { addLogicalDevice } from "../../../../../store/devices";
import { useDevicesTabContext } from "../DevicesTabContext";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { makeDeviceOutOfRequest } from "./deviceActions.helpers";
import { MobileModalListItem } from "../tableComponents";

const useAssignDeviceInfo = request => {
  const device = makeDeviceOutOfRequest(request);
  const dispatch = useDispatch();

  const { allProfilesFull, devices, devicesProfiles } = useDevicesTabContext();

  const assign = () => {
    ModalService.show(modal => ({
      dialog: (
        <AssignDeviceDialog
          modal={modal}
          device={device}
          devices={devices}
          profiles={devicesProfiles}
        />
      ),
    }))
      .then(({ name, profileName }) =>
        dispatch(
          addLogicalDevice({
            name,
            id: request.networkId,
            profileName,
          })
        )
      )
      .catch(() => {});
  };

  return {
    content: <FormattedMessage id="assign_to_profile" />,
    action: assign,
    disabled: allProfilesFull,
    id: "assign",
  };
};

export const AssignDeviceDropdownItem = ({ request, ...props }) => {
  const { content, action, disabled, id } = useAssignDeviceInfo(request);

  return (
    <LazyLoadDropdownItem
      action={action}
      disabled={disabled}
      id={id}
      {...props}
    >
      {content}
    </LazyLoadDropdownItem>
  );
};

export const AssignDeviceModalItem = ({ request }) => {
  const { content, action, disabled, id } = useAssignDeviceInfo(request);

  return (
    <MobileModalListItem
      content={content}
      action={action}
      id={id}
      disabled={disabled}
    />
  );
};
