import _ from "lodash";

import { getInfectionList } from "../../../store/infections";
import { getInfectedDeviceList } from "../../../store/infectedDevices";
import { getLogicalDevicesList } from "../../../store/devices";
import { getThreatsById } from "../../../store/threats";

function getSortedThreats(threatsById, threatIds) {
  const unnamedThreats = _.chain(threatIds)
    .filter(threatId => !_.has(threatsById, threatId))
    .map(threatId => ({
      id: threatId,
      name: threatId,
    }))
    .value();

  const threats = _.chain(threatIds)
    .map(threatId => threatsById[threatId])
    .compact()
    .sortBy("name")
    .value();

  return _.concat(threats, unnamedThreats);
}

const getInfections = state => {
  const infections = getInfectionList(state);
  const threatsById = getThreatsById(state);

  const threatIds = _.flatMap(infections, "value");

  return getSortedThreats(threatsById, _.uniq(threatIds));
};

const getInfectedDevices = state => {
  const infectedDevices = getInfectedDeviceList(state);
  const logicalDevices = getLogicalDevicesList(state);
  const threatsById = getThreatsById(state);

  return _.map(infectedDevices, (threatsInfo, deviceId) => {
    const device = _.find(logicalDevices, device =>
      _.includes(device.identifiers, deviceId)
    );

    const name = device ? device.name : deviceId;

    return {
      name,
      device: deviceId,
      threats: getSortedThreats(threatsById, threatsInfo),
    };
  });
};

export const getInfectedDevicesWithThreats = state => {
  const infectedDevices = getInfectedDevices(state);

  const mappedInfectedDevices = _.flatMap(infectedDevices, infectedDevice => {
    return _.map(infectedDevice.threats, threat => ({
      deviceId: infectedDevice.device,
      name: infectedDevice.name,
      threatName: threat.name,
      threatDescription: threat.description,
    }));
  });

  const infections = getInfections(state);

  const mappedInfections = _.map(infections, threat => ({
    threatName: threat.name,
    threatDescription: threat.description,
  }));

  return _.concat(mappedInfectedDevices, mappedInfections);
};
