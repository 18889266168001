import { profileNameErrors } from "../../pages/profile/profileNameValidation";

const profileNameErrorsMap = {
  [profileNameErrors.MISSING]: "please_provide_a_name",
  [profileNameErrors.INVALID]: "invalid_name",
  [profileNameErrors.EXISTS]: "profile_already_exist",
  [profileNameErrors.TOO_LONG]: "name_exceed_length",
};

export const errorTranslation = key => profileNameErrorsMap[key];
