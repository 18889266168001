import { uuid } from "@sportal/lib";
import { Protection, SafeSearchService } from "@sportal/api";

const id = uuid();

export const defaultProfile = {
  id: id,
  name: "Default",
  default: true,
  "internet-access": true,
  "protection-enabled": true,
  protection: Protection.Light,
  "safe-search": true,
  "safe-search-services": {
    [SafeSearchService.YouTube]: true,
    [SafeSearchService.Google]: true,
    [SafeSearchService.Bing]: true,
  },
};
