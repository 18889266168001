import React, { VFC } from "react";
import { FormattedMessage } from "react-intl";

import { RoamingDeviceStatus } from "@sportal/api";
import { Icon } from "@sportal/cdk";

import "./ProtectionStatus.scss";

const classNameMap = {
  [RoamingDeviceStatus.Enabled]: "protection-status--protected",
  [RoamingDeviceStatus.Disabled]: "protection-status--not-protected",
};
const iconMap = {
  [RoamingDeviceStatus.Enabled]: "far fa-check-circle",
  [RoamingDeviceStatus.Disabled]: "far fa-times-circle",
};
export const translateMap = {
  [RoamingDeviceStatus.Enabled]: "roaming_status_protected",
  [RoamingDeviceStatus.Disabled]: "roaming_status_not_protected",
};

interface ProtectionStatusProps {
  status: RoamingDeviceStatus;
  onClick?: () => void;
}

export const ProtectionStatus: VFC<ProtectionStatusProps> = ({
  status,
  onClick,
}) => {
  return (
    <div
      className={`protection-status ${classNameMap[status]}`}
      onClick={onClick}
    >
      <Icon icon={iconMap[status]} />
      <span className="protection-status__value">
        <FormattedMessage id={translateMap[status]} />
      </span>
    </div>
  );
};
