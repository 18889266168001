import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { logout } from "../store/shared/logout.actions";

export const Logout = ({ reason, doReload }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout(reason, doReload));
  }, [dispatch, reason, doReload]);

  return null;
};
