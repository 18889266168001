import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ModalService } from "@sportal/cdk";
import { EditInfoDeviceDialog } from "../editInfoDeviceDialog/EditInfoDeviceDialog";
import { editRoamingDevice } from "../../../../../store/devices";
import { useDevicesTabContext } from "../DevicesTabContext";
import { LazyLoadDropdownItem } from "@sportal/cdk/dropdown/LazyLoadDropdownItem";
import { MobileModalListItem } from "../tableComponents";

const useEditRoamingDeviceInfo = device => {
  const dispatch = useDispatch();
  const { devices } = useDevicesTabContext();

  const editRoaming = () => {
    ModalService.show(modal => ({
      dialog: (
        <EditInfoDeviceDialog
          modal={modal}
          devices={devices}
          deviceId={device.networkId}
        />
      ),
    }))
      .then(changes => dispatch(editRoamingDevice(device, changes)))
      .catch(() => {});
  };

  return {
    content: <FormattedMessage id="edit_info" />,
    action: editRoaming,
    id: "edit_info",
  };
};

export const EditRoamingDeviceDropdownItem = ({ device, ...props }) => {
  const { content, action, id } = useEditRoamingDeviceInfo(device);

  return (
    <LazyLoadDropdownItem action={action} id={id} {...props}>
      {content}
    </LazyLoadDropdownItem>
  );
};

export const EditRoamingDeviceModalItem = ({ device }) => {
  const { content, action, id } = useEditRoamingDeviceInfo(device);

  return <MobileModalListItem content={content} action={action} id={id} />;
};
