import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import "./LearnMoreDialog.scss";

import { ModalDialog } from "@sportal/cdk";
import { CommonModalHeader, MobileModal, ModalService } from "../../../modal";
import { ExternalLink } from "../../../externalLink/ExternalLink";
import { Button } from "../../../button/Button";
import { Mobile, NotMobile } from "../../../../common/Responsive";

function LearnMoreDialog({ modal }) {
  const close = () => modal.close();

  const headerMessage = <FormattedMessage id="assign_using_code" />;
  const body = (
    <Fragment>
      <p className="learn-more-dialog__text-block">
        <FormattedMessage
          id="visit_page_with_code"
          values={{
            a: ([value]) => <ExternalLink url={value}>{value}</ExternalLink>,
          }}
        />
      </p>
      <p className="learn-more-dialog__text-block">
        <FormattedMessage id="wait_for_code" />
      </p>
      <p className="learn-more-dialog__text-block">
        <FormattedMessage id="device_corresponds_with_code" />
      </p>
    </Fragment>
  );

  return (
    <Fragment>
      <Mobile>
        <MobileModal modal={modal} header={headerMessage} content={body} />
      </Mobile>

      <NotMobile>
        <ModalDialog
          className="learn-more-dialog"
          submitHandler={close}
          rejectHandler={close}
        >
          <CommonModalHeader>{headerMessage}</CommonModalHeader>
          <ModalDialog.Body>{body}</ModalDialog.Body>
          <ModalDialog.Footer>
            <Button size="medium" color="secondary" onClick={close}>
              <FormattedMessage id="close" />
            </Button>
          </ModalDialog.Footer>
        </ModalDialog>
      </NotMobile>
    </Fragment>
  );
}

export const openLearnMoreDialog = () =>
  ModalService.show(modal => ({
    dialog: <LearnMoreDialog modal={modal} />,
  }))
    .then(() => {})
    .catch(() => {});
