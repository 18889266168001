import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  hideAllowListOverrideWarning,
  isAllowListOverrideWarningVisible,
} from "../../store/banners";
import { BannerInline } from "../bannerInline/BannerInline";

import "./AllowListOverrideWarning.scss"

export const AllowListOverrideWarning = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isAllowListOverrideWarningVisible);
  const hideWarning = () => dispatch(hideAllowListOverrideWarning());

  if (!isVisible) return null;

  return (
    <BannerInline
      className={"allow-list-override-warning"}
      onClick={hideWarning}
      header={<FormattedMessage id="allow_list_override_security_domains" />}
      message={
        <FormattedMessage id="allow_list_override_security_domains_note" />
      }
    />
  );
};
