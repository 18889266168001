import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { getProfileSchedulerSchedules } from "../../store/schedules";
import { getSchedule, getWeekdays } from "./scheduler.helpers";
import { SchedulerForm } from "./schedulerForm/SchedulerForm";
import { SchedulerContext } from "./SchedulerContext";
import { getIs24hourFormat } from "../../store/attributes";

export const Scheduler = ({ profileId, type }) => {
  const { schedule, isNewSchedule } = useSelector(state => {
    const schedules = getProfileSchedulerSchedules(state, profileId);

    return getSchedule(schedules, type);
  }, _.isEqual);

  const timeFormat24h = useSelector(getIs24hourFormat);
  const weekdays = useSelector(getWeekdays, shallowEqual);

  const scheduleContext = useMemo(
    () => ({ profileId, type, timeFormat24h, weekdays }),
    [profileId, type, timeFormat24h, weekdays]
  );

  return (
    <SchedulerContext.Provider value={scheduleContext}>
      <SchedulerForm initSchedule={schedule} isNewSchedule={isNewSchedule} />
    </SchedulerContext.Provider>
  );
};

Scheduler.propTypes = {
  profileId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
