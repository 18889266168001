import * as React from "react";
import { createContext, useContext } from "react";
import { makeUseScreenSizes } from "./makeUseScreenSizes";

enum ScreenSize {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

export const sizes = {
  mobileMax: 767,
  tabletMin: 768,
  tabletMax: 991,
  desktopMin: 992,
};

const appScreenSizeSpec = {
  [ScreenSize.Mobile]: { maxWidth: sizes.mobileMax },
  [ScreenSize.Tablet]: { minWidth: sizes.tabletMin, maxWidth: sizes.tabletMax },
  [ScreenSize.Desktop]: { minWidth: sizes.desktopMin },
};

const useScreenSizes = makeUseScreenSizes<ScreenSize>(appScreenSizeSpec);

const ResponsiveContext = createContext<ScreenSize>(null);

export const ResponsiveContextProvider = ({ children }) => {
  const screenSize = useScreenSizes();

  return (
    <ResponsiveContext.Provider value={screenSize}>
      {children}
    </ResponsiveContext.Provider>
  );
};

const useScreenSize = (screenSize: ScreenSize): boolean =>
  useContext(ResponsiveContext) === screenSize;

export const useIsDesktop = () => useScreenSize(ScreenSize.Desktop);
export const useIsTablet = () => useScreenSize(ScreenSize.Tablet);
export const useIsMobile = () => useScreenSize(ScreenSize.Mobile);
export const useIsTabletMobile = () => !useIsDesktop();
export const useIsNotMobile = () => !useIsMobile();

export const Desktop = ({ children }) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useIsTablet();
  return isTablet ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useIsMobile();
  return isMobile ? children : null;
};
export const TabletMobile = ({ children }) => {
  const isTabletMobile = useIsTabletMobile();
  return isTabletMobile ? children : null;
};
export const NotMobile = ({ children }) => {
  const isNotMobile = useIsNotMobile();
  return isNotMobile ? children : null;
};

export function useMobileValue<T>(value: T, fallbackValue: T): T {
  const isMobile: boolean = useIsMobile();
  return isMobile ? value : fallbackValue;
}
