import _ from "lodash";

import { getSubscriberId } from "../info";
import { createTempNotification } from "../../components/notifications";
import { getProfile } from "../profiles";
import { getListsByProfileId, getUrlOptions } from "./lists.selectors";
import {
  ListsServiceErrorCode,
  ListType,
  UpdateFailureReason,
} from "@sportal/api/lists";

export const GLOBAL_URL_FILTERS = "global";

export const LOAD_LISTS_REQUEST = "[URL_FILTERS] LOAD_LISTS_REQUEST";
export const loadListsRequest = profileId => ({
  type: LOAD_LISTS_REQUEST,
  payload: { profileId },
});

export const loadLists = profileId => async (dispatch, getState, { api }) => {
  const subscriberId = getSubscriberId(getState());
  const profileName = getProfileName(getState(), profileId);

  dispatch(loadListsRequest(profileId));

  const lists = await api.lists.get(subscriberId, profileName);

  if (lists.success === false) {
    dispatch(loadListsFailure(profileId, lists.error));
    dispatch(
      createTempNotification({
        variant: "error",
        description: "failed_fetch_data",
      })
    );
    return;
  }

  dispatch(loadListsSuccess(profileId, lists.data));
};

const getProfileName = (state, profileId) => {
  if (profileId === GLOBAL_URL_FILTERS) {
    return "";
  }

  return getProfile(state, profileId).name;
};

export const LOAD_LISTS_SUCCESS = "[URL_FILTERS] LOAD_LISTS_SUCCESS";
export const loadListsSuccess = (profileId, lists) => ({
  type: LOAD_LISTS_SUCCESS,
  payload: { profileId, lists },
});

export const LOAD_LISTS_FAILURE = "[URL_FILTERS] LOAD_LISTS_FAILURE";
export const loadListsFailure = (profileId, error) => ({
  type: LOAD_LISTS_FAILURE,
  payload: { profileId, error },
});

export const REMOVE_URL_REQUEST = "[URL_FILTERS] REMOVE_URL_REQUEST";
export const removeUrlRequest = profileId => ({
  type: REMOVE_URL_REQUEST,
  payload: { profileId },
});

export const removeUrl = (
  listType,
  urls,
  profileId = GLOBAL_URL_FILTERS
) => async (dispatch, getState, { api }) => {
  const subscriberId = getSubscriberId(getState());
  const profileName = getProfileName(getState(), profileId);

  dispatch(removeUrlRequest(profileId));

  const urlList = _.castArray(urls);
  const response = await api.lists.remove(
    subscriberId,
    listType,
    urlList,
    profileName
  );

  if (response.success === false) {
    dispatch(removeUrlFailure(profileId, response.error));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description:
          urlList.length > 1
            ? "failed_to_clean_list"
            : "failed_to_remove_site_from_list",
      })
    );
    return;
  }

  dispatch(removeUrlSuccess(profileId, listType, urlList));
};

export const REMOVE_URL_SUCCESS = "[URL_FILTERS] REMOVE_URL_SUCCESS";
export const removeUrlSuccess = (profileId, listType, urls) => ({
  type: REMOVE_URL_SUCCESS,
  payload: { profileId, listType, urls },
});

export const REMOVE_URL_FAILURE = "[URL_FILTERS] REMOVE_URL_FAILURE";
export const removeUrlFailure = (profileId, error) => ({
  type: REMOVE_URL_FAILURE,
  payload: { profileId, error },
});

export const CHECK_URL_REQUEST = "[URL_FILTERS] CHECK_URL_REQUEST";
export const checkUrlRequest = url => ({
  type: CHECK_URL_REQUEST,
  payload: { url },
});

export const checkUrl = url => async (dispatch, getState, { api }) => {
  dispatch(checkUrlRequest(url));
  const response = await api.lists.check(url);

  if (response.success === false) {
    dispatch(checkUrlFailure("parse_url_error"));
    return;
  }

  dispatch(checkUrlSuccess(response.data));
};

export const CHECK_URL_SUCCESS = "[URL_FILTERS] CHECK_URL_SUCCESS";
export const checkUrlSuccess = options => ({
  type: CHECK_URL_SUCCESS,
  payload: { options },
});

export const CHECK_URL_FAILURE = "[URL_FILTERS] CHECK_URL_FAILURE";
export const checkUrlFailure = error => ({
  type: CHECK_URL_FAILURE,
  payload: { error },
});

export const RESET_URL_OPTIONS = "[URL_FILTERS] RESET_URL_OPTIONS";
export const resetUrlOptions = () => ({
  type: RESET_URL_OPTIONS,
});

export const ADD_URL_REQUEST = "[URL_FILTERS] ADD_URL_REQUEST";
export const addUrlRequest = profileId => ({
  type: ADD_URL_REQUEST,
  payload: { profileId },
});

const ADD_URL_ERRORS = {
  [ListsServiceErrorCode.GENERIC]: "failed_to_save_site",
  [UpdateFailureReason.ACCOUNT_LIST_LIMIT_REACHED]: "can_not_add_more_sites",
  [UpdateFailureReason.ALREADY_EXISTS]: "already_in_another_list",
};

const ADD_URL_NOTIFICATIONS = {
  LIST_LIMIT_REACHED: {
    variant: "error",
    description: ADD_URL_ERRORS.ACCOUNT_LIST_LIMIT_REACHED,
  },
  ALREADY_IN_LIST: {
    variant: "warning",
    description: "already_in_list",
  },
  ALREADY_IN_ANOTHER_LIST: {
    variant: "error",
    description: ADD_URL_ERRORS.ALREADY_EXISTS,
  },
};

const getValidationNotifications = (lists, url, listType) => {
  const currentList = lists[listType];
  const isLimitReached = currentList.limit <= currentList.content.length;

  if (isLimitReached) {
    return ADD_URL_NOTIFICATIONS.LIST_LIMIT_REACHED;
  }

  const isInCurrentList = _.includes(currentList.content, url);

  if (isInCurrentList) {
    return ADD_URL_NOTIFICATIONS.ALREADY_IN_LIST;
  }

  const isInOtherList =
    (listType === ListType.Allow &&
      _.includes(lists[ListType.Block].content, url)) ||
    (listType === ListType.Block &&
      _.includes(lists[ListType.Allow].content, url));

  if (isInOtherList) {
    return ADD_URL_NOTIFICATIONS.ALREADY_IN_ANOTHER_LIST;
  }
};

const getLists = (getState, profileId) => {
  const state = getState();
  const lists = getListsByProfileId(profileId)(state);
  const hasListsLoaded = _.every(_.values(lists), Boolean);

  return { state, lists, hasListsLoaded };
};

export const addUrl = (listType, url, profileId = GLOBAL_URL_FILTERS) => async (
  dispatch,
  getState,
  { api }
) => {
  let { state, lists, hasListsLoaded } = getLists(getState, profileId);

  if (!hasListsLoaded) {
    await loadLists(profileId)(dispatch, getState, { api });

    const update = getLists(getState, profileId);
    state = update.state;
    lists = update.lists;
    hasListsLoaded = update.hasListsLoaded;
  }

  const notification =
    hasListsLoaded && getValidationNotifications(lists, url, listType);

  if (notification) {
    dispatch(createTempNotification(notification));
    return;
  }

  const subscriberId = getSubscriberId(state);
  const profileName = getProfileName(state, profileId);
  const urlOptions = getUrlOptions(state);
  const selectedOption = _.find(urlOptions, ({ node }) => node === url);

  dispatch(addUrlRequest(profileId));

  const response = await api.lists.add(
    subscriberId,
    listType,
    [selectedOption],
    profileName
  );

  if (response.success === false) {
    dispatch(addUrlFailure(profileId, response.error));
    dispatch(
      createTempNotification({
        variant: "error",
        title: "error",
        description: ADD_URL_ERRORS[response.error.code],
      })
    );
    return;
  }

  dispatch(addUrlSuccess(profileId, listType, selectedOption.node));
};

export const ADD_URL_SUCCESS = "[URL_FILTERS] ADD_URL_SUCCESS";
export const addUrlSuccess = (profileId, listType, url) => ({
  type: ADD_URL_SUCCESS,
  payload: { profileId, listType, url },
});

export const ADD_URL_FAILURE = "[URL_FILTERS] ADD_URL_FAILURE";
export const addUrlFailure = (profileId, error) => ({
  type: ADD_URL_FAILURE,
  payload: { profileId, error },
});
