import _ from "lodash";
import { CATEGORY_STATUS } from "../categoryToggle/CategoryToggle";

export const extendCategories = (all, blocked, block, allow, protection) => {
  const forBlocked = {
    status: CATEGORY_STATUS.BLOCKED,
    onChange: allow,
  };

  const forAllowed = {
    status: CATEGORY_STATUS.ALLOWED,
    onChange: block,
  };

  const forCustom = {
    status: CATEGORY_STATUS.CUSTOM,
    onChange: block,
  };

  function extendSimpleCategory(category) {
    return {
      ...category,
      ...(blocked.includes(category.name) ? forBlocked : forAllowed),
    };
  }

  const categories = all.map(category => {
    const { categories: children } = category;

    if (_.isEmpty(children)) {
      return extendSimpleCategory(category);
    }

    const extendedChildren = children.map(child => extendSimpleCategory(child));
    const allChildrenBlocked = _.every(extendedChildren, {
      status: CATEGORY_STATUS.BLOCKED,
    });
    const allChildrenAllowed = _.every(extendedChildren, {
      status: CATEGORY_STATUS.ALLOWED,
    });

    return {
      ...category,
      categories: extendedChildren,
      ...(allChildrenBlocked
        ? forBlocked
        : allChildrenAllowed
        ? forAllowed
        : forCustom),
    };
  });

  return addProtectionInfo(categories, protection);
};

const addProtectionInfo = (categories, protection) => {
  if (!protection || _.isEmpty(protection.blocked)) return categories;

  return _.map(categories, category => {
    if (_.isEmpty(category.categories)) {
      return {
        ...category,
        protectionName:
          protection.blocked.includes(category.name) && protection.name,
      };
    }

    const subcategories = addProtectionInfo(category.categories, protection);

    return {
      ...category,
      protectionName:
        _.every(subcategories, "protectionName") && protection.name,
      categories: subcategories,
    };
  });
};
