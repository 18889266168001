import React, { ReactElement, VFC } from "react";
import classnames from "classnames";

import { Icon } from "@sportal/cdk/icon/Icon";
import { CrossButton } from "@sportal/cdk/crossButton/CrossButton";
import { useIntlTranslate } from "../../hooks";

import "./BannerInline.scss";

interface BannerInlineProps {
  onClick?: () => void | null;
  className?: string;
  header?: string | ReactElement;
  message: string | ReactElement;
}

export const BannerInline: VFC<BannerInlineProps> = ({
  onClick,
  className = "",
  header,
  message,
}) => {
  const translate = useIntlTranslate();

  return (
    <div className={classnames("banner-inline", className)}>
      <Icon icon={"far fa-exclamation-triangle"} />
      <div className={"banner-inline__content"}>
        {header && <div className={"banner-inline__header"}>{header} </div>}
        {message && <div className={"banner-inline__message"}>{message}</div>}
      </div>
      {onClick && (
        <div className={"banner-inline__close"}>
          <CrossButton
            size={"small"}
            onClick={onClick}
            aria-label={translate("aria_close")}
          />
        </div>
      )}
    </div>
  );
};
