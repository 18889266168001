import {
  SettingsProvider,
  SettingsService,
  Configuration,
  UserMode,
  HttpService,
} from "@sportal/api";

const { PersonalInternet, SubscriberSafety } = Configuration;

const mandatoryServicesConfigurations = {
  [UserMode.SubscriberSafety]: [SubscriberSafety.Standard],
  [UserMode.Household]: [SubscriberSafety.Standard, PersonalInternet.Single],
  [UserMode.PerDevice]: [SubscriberSafety.Standard, PersonalInternet.Multiple],
  [UserMode.PISingle]: [PersonalInternet.Single],
  [UserMode.PIProfile]: [PersonalInternet.Multiple],
};

export const makeSettingsService = (http: HttpService, baseUrl: string) => (
  userMode: UserMode
): SettingsService =>
  new SettingsService(
    new SettingsProvider(http, baseUrl),
    mandatoryServicesConfigurations[userMode]
  );
