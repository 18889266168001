import React from "react";

import { DetailedServicePanel } from "./detailedServicePanel/DetailedServicePanel";
import { useServicePanel } from "./useServicePanel";

import "./HomeServicePanel.scss";

export const HomeServicePanel = () => {
  const services = useServicePanel();

  return (
    <div className="home-service-panel">
      <div className="home-service-panel__container">
        {services.map(
          ({ service, title, description, enabled, infectionStatus }) => (
            <DetailedServicePanel
              key={service}
              title={title}
              description={description}
              serviceStatus={enabled}
              infectionStatus={infectionStatus}
            />
          )
        )}
      </div>
    </div>
  );
};
