import React, { Children } from "react";

import { CollapsibleSection } from "@sportal/cdk";

export function DeviceGroup({ defaultExpanded = true, children }) {
  const [header, ...restChildren] = Children.toArray(children);

  return (
    <CollapsibleSection
      defaultOpened={defaultExpanded}
      header={header}
      content={restChildren}
    />
  );
}
