import { InfectionsServerData } from "@sportal/api";

export const LOAD_INFECTIONS = "[INFECTIONS] LOAD_INFECTIONS";
export const LOAD_INFECTIONS_SUCCESS = "[INFECTIONS] LOAD_INFECTIONS_SUCCESS";
export const LOAD_INFECTIONS_FAILURE = "[INFECTIONS] LOAD_INFECTIONS_FAILURE";

interface LoadInfectionsAction {
  type: typeof LOAD_INFECTIONS;
}

interface LoadInfectionsSuccessAction {
  type: typeof LOAD_INFECTIONS_SUCCESS;
  payload: InfectionsServerData;
}

interface LoadInfectionsFailureAction {
  type: typeof LOAD_INFECTIONS_FAILURE;
  payload: object;
}

export type InfectionsActionTypes =
  | LoadInfectionsAction
  | LoadInfectionsSuccessAction
  | LoadInfectionsFailureAction;
