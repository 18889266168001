import _ from "lodash";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, TabTitle } from "../../../../components";
import { ProtectionEditor } from "../../../../components/protectionEditor/ProtectionEditor";
import { getCategories } from "../../../../store/categories";
import { getSharedProtections } from "../../../../store/root.selectors";
import { useProtectionState } from "../../../../components/protectionEditor/useProtectionState";
import { saveProfileProtection } from "../../store/profiles";
import {
  ProtectionCardContent,
  ProtectionHeaderContent,
} from "./WizardProfileProtectionCards";
import { protectionByAgeGroup } from "../../../../store/profiles";
import { Mobile, NotMobile } from "../../../../common/Responsive";
import { WizardMobileTitle } from "../../wizardMobileTitle/WizardMobileTitle";
import { WizardProtectionTitle } from "./WizardProtectionTitle";

export const WizardProfileProtection = ({ profile, goBack }) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);
  const sharedProtections = useSelector(getSharedProtections);

  const { currentProtection, setLevel, block, allow } = useProtectionState(
    profile
  );

  return (
    <Fragment>
      <Mobile>
        <div className="wizard-protection-header">
          <WizardMobileTitle>
            <TabTitle>
              <FormattedMessage id="protection_setup" />
            </TabTitle>
          </WizardMobileTitle>
          <ProtectionHeaderContent
            profile={{
              ...profile,
              protection:
                protectionByAgeGroup[profile["age-group"] || "default"],
            }}
          />
        </div>
      </Mobile>
      <NotMobile>
        <WizardProtectionTitle title={"protection_setup"} goBack={goBack} />
        <div className="wizard-card wizard-protection-card">
          <ProtectionCardContent
            profile={{
              ...profile,
              protection:
                protectionByAgeGroup[profile["age-group"] || "default"],
            }}
          />
        </div>
      </NotMobile>
      <div className="wizard-protection__content">
        <ProtectionEditor
          categories={_.values(categories.list)}
          protections={_.values(sharedProtections.list)}
          selectedProtection={currentProtection}
          changeProtection={setLevel}
          block={block}
          allow={allow}
        />
        <div className="wizard-protection__buttons">
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              dispatch(saveProfileProtection(profile, currentProtection));
              goBack();
            }}
          >
            <FormattedMessage id="save_my_settings" />
          </Button>
          <Mobile>
            <Button size="medium" color="secondary" onClick={goBack}>
              <FormattedMessage id={"protections_back_to_all"} />
            </Button>
          </Mobile>
          <NotMobile>
            <Button size="medium" color="secondary" onClick={goBack}>
              <FormattedMessage id="cancel" />
            </Button>
          </NotMobile>
        </div>
      </div>
    </Fragment>
  );
};
