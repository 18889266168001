import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getHours } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { Icon } from "@sportal/cdk";
import { TimeIndicator } from "./timeIndicator/TimeIndicator";
import { TRANSLATIONS, SCHEDULE_TYPES } from "../scheduler.consts";
import { getTimezone } from "../../../store/account";
import { Mobile, NotMobile } from "../../../common/Responsive";
import { useSchedulerContext } from "../SchedulerContext";

import "./Schedule.scss";

const timeOfDay = [
  {
    id: "morning",
    slice: [6, 12],
  },
  {
    id: "afternoon",
    slice: [12, 18],
  },
  {
    id: "evening",
    slice: [18, 24],
  },
  {
    id: "night",
    slice: [0, 6],
  },
];

export const ScheduleMobile = props => {
  const { timeFormat24h } = useSchedulerContext();

  const currentTimezone = useSelector(getTimezone);

  const [currentTab, setCurrentTab] = useState(0);
  const currentHoursRange = useMemo(() => timeOfDay[currentTab].slice, [
    currentTab,
  ]);

  const previousTab = () => {
    currentTab === 0 ? setCurrentTab(3) : setCurrentTab(current => current - 1);
  };

  const nextTab = () => {
    currentTab === 3 ? setCurrentTab(0) : setCurrentTab(current => current + 1);
  };

  const ampm = useMemo(() => {
    return currentTab === 1 || currentTab === 2 ? "pm" : "am";
  }, [currentTab]);

  useEffect(() => {
    // sets the currentTab based on current time - only once
    // hint: currentHour - it's being set on Interval so it's not reliable to do it only once
    const initHour = getHours(utcToZonedTime(Date.now(), currentTimezone));

    const initIndex = timeOfDay.findIndex(
      ({ slice }) => slice[0] <= initHour && initHour < slice[1]
    );

    setCurrentTab(initIndex);
  }, [currentTimezone]);

  return (
    <div className="schedule__mobile">
      <div className="schedule__tab-interface">
        <div>
          <span onClick={previousTab}>
            <Icon icon="far fa-chevron-left" />
          </span>
          <span className="tab__name">
            <FormattedMessage id={timeOfDay[currentTab].id} />
          </span>
          <span onClick={nextTab}>
            <Icon icon="far fa-chevron-right" />
          </span>
        </div>
      </div>
      <Schedule
        placeholder={
          <span className="day-time">
            {timeFormat24h ? "" : <FormattedMessage id={ampm} />}
          </span>
        }
        currentHoursRange={currentHoursRange}
        {...props}
      />
    </div>
  );
};

export const ScheduleDesktop = props => {
  return (
    <Schedule
      {...props}
      placeholder={<div className="schedule__empty-cell" />}
    />
  );
};

const ScheduleLegend = () => (
  <div className="schedule__legend">
    {Object.values(SCHEDULE_TYPES).map(type => (
      <div key={type} className={`legend__item legend__item--${type}`}>
        <FormattedMessage id={TRANSLATIONS[type].title} />
      </div>
    ))}
  </div>
);

/**
 * default value of `ampm` is needed for safari, otherwise it crashes on resize. See more at https://jira.nominum.com/jira/browse/EPORTAL-7480
 */
export const Schedule = ({
  placeholder,
  currentHoursRange = [0, 24],
  gridData,
  columnHours,
}) => {
  const { timeFormat24h, weekdays } = useSchedulerContext();

  return (
    <div className="schedule">
      <div className="schedule__table">
        <div className="schedule__days">
          {placeholder}
          {weekdays.map(day => (
            <div className="schedule__cell schedule__cell__day" key={day}>
              <FormattedMessage id={`${day}_short`} />
            </div>
          ))}
        </div>

        <div className="schedule__grid">
          {!timeFormat24h && (
            <NotMobile>
              <span className="period-indicator period-indicator--am">
                <FormattedMessage id="am" />
              </span>
              <span className="period-indicator period-indicator--pm">
                <FormattedMessage id="pm" />
              </span>
            </NotMobile>
          )}
          <div className="schedule__tab">
            <div className="schedule__hours">
              {columnHours.slice(...currentHoursRange).map((hour, index) => (
                <div className="schedule__cell" key={index}>
                  {hour}
                </div>
              ))}
            </div>

            <div className="schedule__grid__body">
              {gridData.map(({ row, cells }) => (
                <div className="schedule__data" key={row}>
                  {cells
                    .slice(...currentHoursRange)
                    .map(({ hour, scheduled }) => (
                      <div
                        className="schedule__cell schedule__cell__data"
                        key={hour}
                      >
                        {scheduled.map((hourScheduled, index) => (
                          <span
                            key={hourScheduled + index}
                            className={`scheduled scheduled--${hourScheduled}`}
                          />
                        ))}
                      </div>
                    ))}
                </div>
              ))}

              <TimeIndicator currentHoursRange={currentHoursRange} />
            </div>
          </div>

          <NotMobile>
            <ScheduleLegend />
          </NotMobile>
        </div>
      </div>
      <Mobile>
        <ScheduleLegend />
      </Mobile>
    </div>
  );
};

Schedule.propTypes = {
  ampm: PropTypes.string,
  currentHoursRange: PropTypes.arrayOf(PropTypes.number),
  gridData: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.string.isRequired,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          hour: PropTypes.number,
          scheduled: PropTypes.arrayOf(
            PropTypes.oneOf(["homework", "internet-off"])
          ).isRequired,
        })
      ).isRequired,
    })
  ),
  columnHours: PropTypes.arrayOf(PropTypes.number).isRequired,
};
