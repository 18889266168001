import React, { useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  loadLines,
  loadLogicalDevices,
  loadRequests,
} from "../../../../store/devices";
import { getProfilesList } from "../../../../store/profiles";
import { SettingsTabTitle } from "../../settingsTabTitle/SettingsTabTitle";
import {
  Checkbox,
  DevicesControlBar,
  RefreshRequestsButton,
  SpsonCompactPanel,
  TabHeader,
  withProfiles,
} from "../../../../components";
import { useMount, useToggle } from "@sportal/cdk";
import { RequestsSection } from "./requestsSection";
import { Mobile, NotMobile } from "../../../../common/Responsive";
import { AddDeviceButton } from "./addDeviceButton/AddDeviceButton";
import { DevicesTabContextProvider } from "./DevicesTabContext";
import { ProfilesDevices } from "./profilesDevices/ProfilesDevices";
import { hasMultipleSupport, isSS } from "../../../../store/info";
import { defaultProfile } from "../../../wizard/store/profiles";
import { isSPSONAvailable } from "../../../../store/account";
import { RoamingLimitWarning } from "../../../../components/spson";

import "./DevicesTab.scss";

const BaseDevicesTab = ({ profilesSelector }) => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(loadLogicalDevices());
    dispatch(loadLines());
  });

  const [isAdvancedView, toggleAdvancedView] = useToggle(false);

  const refresh = useCallback(() => dispatch(loadRequests()), [dispatch]);

  const shouldShowRequests = useSelector(hasMultipleSupport);
  const spsonFeatureEnabled = useSelector(isSPSONAvailable);

  return (
    <div className="devices-tab">
      <DevicesTabContextProvider profilesSelector={profilesSelector}>
        <TabHeader>
          <SettingsTabTitle tabName="devices" />
          <NotMobile>
            <AddDeviceButton />
          </NotMobile>
        </TabHeader>

        {spsonFeatureEnabled && (
          <Fragment>
            <SpsonCompactPanel />
            <NotMobile>
              <RoamingLimitWarning />
            </NotMobile>
          </Fragment>
        )}

        <Mobile>
          <AddDeviceButton />
        </Mobile>
        <DevicesControlBar className="devices-tab__control-bar">
          <h3>
            <FormattedMessage id="manage_devices" />
          </h3>

          {spsonFeatureEnabled && (
            <Mobile>
              <RoamingLimitWarning />
            </Mobile>
          )}

          <Checkbox
            id={"devices-tab-advanced-view"}
            onChange={toggleAdvancedView}
            checked={isAdvancedView}
            label={<FormattedMessage id="advanced_view" />}
          />
          {shouldShowRequests && <RefreshRequestsButton onClick={refresh} />}
        </DevicesControlBar>
        {shouldShowRequests && (
          <RequestsSection isAdvancedView={isAdvancedView} />
        )}
        <ProfilesDevices isAdvancedView={isAdvancedView} />
      </DevicesTabContextProvider>
    </div>
  );
};

const getSSProfilesList = () => [defaultProfile];
const SSDevicesTab = () => (
  <BaseDevicesTab profilesSelector={getSSProfilesList} />
);
const NonSSDeviceTab = withProfiles(() => (
  <BaseDevicesTab profilesSelector={getProfilesList} />
));

const getDevicesTabComponent = state =>
  isSS(state) ? SSDevicesTab : NonSSDeviceTab;

export const DevicesTab = () => {
  const Component = useSelector(getDevicesTabComponent);
  return <Component />;
};
