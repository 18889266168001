import { useRef } from "react";
import { useCallbackInstance } from "./useCallbackInstance";
import { useEventListener } from "./useEventListener";

export const useOutsideClick = (handler, isOpen = true) => {
  const ref = useRef(null);
  const savedCallbackRef = useCallbackInstance(handler);

  function handleClick(event) {
    if (isOpen && ref.current && !ref.current.contains(event.target)) {
      savedCallbackRef.current(event);
    }
  }

  useEventListener("click", handleClick, document, true);
  useEventListener("touchstart", handleClick, document, true);

  return ref;
};
