import { Modifier } from "react-popper";

export const sameWidthAsParent: Modifier<any> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    // @ts-ignore
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};
