import React, { FC, ReactElement } from "react";

import "./CellDetailsMobile.scss";

interface CellDetailsMobile {
  id: string;
  header: ReactElement | string;
  value?: ReactElement | string;
}

export const CellDetailsMobile: FC<CellDetailsMobile> = ({
  id,
  header,
  value,
}) => {
  if (!value) return null;

  return (
    <td role="cell" className={`cell-details-mobile td td--details td--${id}`}>
      <div className="cell-details-mobile__header">{header}</div>
      <div className="cell-details-mobile__value">{value}</div>
    </td>
  );
};
