import { useEffect, useReducer, useRef } from "react";
import _ from "lodash";

import { reducer } from "./schedule.reducer";
import {
  updateValue,
  addValues,
  removeValues,
  scheduleToggled,
  setNewState,
} from "./schedule.actions";

const hasMatchingCollection = (arr1, arr2) => {
  const sortCopy = arr => [...arr].sort();

  return _.isEqual(sortCopy(arr1), sortCopy(arr2));
};

const NON_PRIMITIVE_KEYS = ["day", "categories"];
const hasChanges = (schedule, initialSchedule) => {
  const matchingPrimitives = _.isEqual(
    _.omit(schedule, NON_PRIMITIVE_KEYS),
    _.omit(initialSchedule, NON_PRIMITIVE_KEYS)
  );

  const matchingNonPrimitives = NON_PRIMITIVE_KEYS.map(key =>
    hasMatchingCollection(schedule[key], initialSchedule[key])
  ).every(Boolean);

  return !matchingPrimitives || !matchingNonPrimitives;
};

export const useSchedule = initialSchedule => {
  const [schedule, dispatch] = useReducer(reducer, initialSchedule);
  const scheduleToggleClicked = useRef(false);

  function setScheduleToggleClicked(value) {
    scheduleToggleClicked.current = value;
  }

  useEffect(() => {
    if (scheduleToggleClicked.current) {
      dispatch(scheduleToggled(initialSchedule));
    } else {
      dispatch(setNewState(initialSchedule));
    }
  }, [initialSchedule]);

  return {
    schedule,
    updateValue: (key, value) => dispatch(updateValue(key, value)),
    addValues: (key, values) => dispatch(addValues(key, values)),
    removeValues: (key, values) => dispatch(removeValues(key, values)),
    discardChanges: () => dispatch(setNewState(initialSchedule)),
    setScheduleToggleClicked,
    scheduleChanged: hasChanges(schedule, initialSchedule),
  };
};
