import React, { ReactElement, useEffect, VFC } from "react";
import { FormattedMessage } from "react-intl";

import { ModalDialog } from "../Modal";
import { useIsMobile } from "../../../common/Responsive";

import "./MobileModal.scss";

interface MobileModalProps {
  modal: any;
  header: ReactElement | string;
  content: ReactElement;
  footer?: ReactElement;
}

export const MobileModal: VFC<MobileModalProps> = ({
  modal,
  header,
  content,
  footer,
}) => {
  const isMobile = useIsMobile();
  const { resolve, reject } = modal;

  useEffect(() => {
    if (!isMobile) {
      reject();
    }
  }, [isMobile, reject]);

  return (
    <ModalDialog
      className="mobile-modal"
      submitHandler={resolve}
      rejectHandler={reject}
    >
      <ModalDialog.Header>{header}</ModalDialog.Header>
      <ModalDialog.Body>{content}</ModalDialog.Body>
      <ModalDialog.Footer>
        <ModalDialog.Control>
          <button type="button" onClick={reject}>
            {footer ? footer : <FormattedMessage id={"dismiss"} />}
          </button>
        </ModalDialog.Control>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};
