import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./AddDeviceButton.scss";

import { Icon } from "@sportal/cdk";
import { Button } from "../../../../../components";
import { AddDeviceDialog } from "../addDeviceDialog/AddDeviceDialog";
import { addLogicalDevice } from "../../../../../store/devices/logical";
import { useDevicesTabContext } from "../DevicesTabContext";
import { ModalService } from "../../../../../components/modal";

export const AddDeviceButton = () => {
  const dispatch = useDispatch();
  const { allProfilesFull, devices, devicesProfiles } = useDevicesTabContext();

  const openDialog = () =>
    ModalService.show(modal => ({
      dialog: (
        <AddDeviceDialog
          modal={modal}
          devices={devices}
          profiles={devicesProfiles}
        />
      ),
    }))
      .then(({ name, mac, profileName }) => {
        dispatch(
          addLogicalDevice({
            name,
            id: mac,
            profileName,
          })
        );
      })
      .catch(() => {});

  return (
    <Button
      className="add-device-button"
      size="large"
      color="primary"
      variant="outlined"
      onClick={openDialog}
      disabled={allProfilesFull}
      iconLeft={<Icon icon="fas fa-plus" />}
    >
      <FormattedMessage id="add_device_manually" />
    </Button>
  );
};
