import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { Button } from "../../../button/Button";
import { PauseInternet } from "../../../pauseInternet/PauseInternet";
import { usePauseInternet } from "../../../pauseInternet/PauseInternetProvider";

import "./ProfileCardDesktop.scss";

export function ProfileCardDesktop({
  profileId,
  profileName,
  ageGroup,
  protectionLevel,
  devicesCount,
  className,
  isDefault,
}) {
  const { isInternetPaused } = usePauseInternet(usePauseInternet);

  const themeKey = isDefault ? "default" : ageGroup;
  const cardTheme = classnames(
    "desktop-card",
    `${themeKey}__theme`,
    className,
    {
      "desktop-card__internet-paused": isInternetPaused,
    }
  );
  const buttonTheme = classnames("desktop-card__button", `button--${themeKey}`);

  return (
    <div className={cardTheme}>
      <header className="desktop-card__header">
        <h3 className="desktop-card__title">{profileName}</h3>
        <p>{ageGroup && <FormattedMessage id={ageGroup} />}</p>
        <div className="desktop-card__image-container">
          <span className="desktop-card__image" />
        </div>
      </header>
      <div className="desktop-card__content">
        <p className="desktop-card__protection-level">
          <FormattedMessage
            id="protection_level_message"
            values={{
              message: (
                <span className="strong">
                  <FormattedMessage id={protectionLevel} />
                </span>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="devices_count"
            values={{
              message: <span className="strong">{devicesCount}</span>,
            }}
          />
        </p>
        <Link to={`/profile/${profileId}`}>
          <Button size="large" className={buttonTheme}>
            <FormattedMessage id="see_details" />
          </Button>
        </Link>
        <div className="desktop-card__button">
          <PauseInternet
            profileName={profileName}
            hiddenLabel={
              <FormattedMessage
                id="profile_name_internet_pause_settings"
                values={{ profileName }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

ProfileCardDesktop.defaultProps = {
  isInternetPaused: false,
};

ProfileCardDesktop.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  protectionLevel: PropTypes.string.isRequired,
  devicesCount: PropTypes.number.isRequired,
  ageGroup: PropTypes.oneOf(["default", "adults", "teens", "kids"]),
  className: PropTypes.string,
  isDefault: PropTypes.bool,
};
