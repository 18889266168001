import { HttpService, HttpServiceResponse } from "../shared";
import {
  ProtectionCategoriesServerData,
  SharedProtectionsServerData,
  CustomProtectionServerData,
} from "./protection.types";

export class ProtectionProvider {
  constructor(
    private http: HttpService,
    private baseUrl: string,
    private appCode: string
  ) {}

  public getCategories(): Promise<
    HttpServiceResponse<ProtectionCategoriesServerData>
  > {
    return this.http.get(
      `${this.baseUrl}/ssm/service/${this.appCode}/protection`
    );
  }

  public getSharedProtections(): Promise<
    HttpServiceResponse<SharedProtectionsServerData>
  > {
    return this.http.get(
      `${this.baseUrl}/ssm/service/${this.appCode}/protection-level`
    );
  }

  public getCustomProtection(
    subscriberId: string,
    profileName: string
  ): Promise<HttpServiceResponse<CustomProtectionServerData>> {
    return this.http.get(
      `${this.baseUrl}/ssm/${this.appCode}/${subscriberId}/profile/${profileName}/protection`
    );
  }

  public saveCustomProtection(
    subscriberId: string,
    profileName: string,
    payload: string[]
  ): Promise<HttpServiceResponse<void>> {
    return this.http.post(
      `${this.baseUrl}/ssm/pi/${subscriberId}/profile/${profileName}/protection`,
      payload
    );
  }
}
