import { useEffect } from "react";

import { useCallbackInstance } from "./useCallbackInstance";

export function useInterval(callback, delay) {
  const savedCallback = useCallbackInstance(callback);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
}
