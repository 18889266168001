import _ from "lodash";

import {
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  SchedulesActionTypes,
  AddOrUpdateSchedulePayload,
  DeleteSchedulePayload,
} from "./schedules.types";
import { Schedule } from "@sportal/api/schedules";
import { getSubscriberId } from "../info";
import { getSchedules } from "../root.selectors";
import { SCHEDULE_TYPES } from "../../components/scheduler/scheduler.consts";
import { createTempNotification } from "../../components/notifications";
import { notifyUser } from "../shared/notifyUser";
import { getProfile } from "../profiles";

export const homeworkSchedule = type => type === SCHEDULE_TYPES.homework;

export const submitSchedule = (profileId: string, schedule: Schedule) => (
  dispatch,
  getState
) => {
  const profileName = getProfile(getState(), profileId).name;

  if (_.isEmpty(schedule.day)) {
    return dispatch(deleteSchedule(profileName, profileId, schedule.name));
  }

  const categorySelected = !_.isEmpty(schedule.categories);

  if (homeworkSchedule(schedule.type) && !categorySelected) {
    return dispatch(
      createTempNotification({
        variant: "error",
        description: "please_select_categories",
      })
    );
  }

  return dispatch(addOrUpdateSchedule(profileName, profileId, schedule));
};

export const addOrUpdateSchedule = (
  profileName: string,
  profileId: string,
  schedule: Schedule,
  notifySuccess: boolean = true
) => (dispatch, getState) => {
  const schedules = getSchedules(getState());
  const isNewSchedule = !schedules[profileId].some(
    ({ name }) => name === schedule.name
  );

  if (isNewSchedule) {
    dispatch(addSchedule(profileName, profileId, schedule, notifySuccess));
  } else {
    dispatch(updateSchedule(profileName, profileId, schedule, notifySuccess));
  }
};

export const addSchedule = (
  profileName: string,
  profileId: string,
  schedule: Schedule,
  notifySuccess: boolean = true
) => async (dispatch, getState, { api }) => {
  dispatch({ type: ADD_SCHEDULE });

  const subscriberId = getSubscriberId(getState());

  const newSchedule = { ...schedule, enabled: true };

  const { success } = await api.schedules.create(
    subscriberId,
    profileName,
    newSchedule
  );

  if (success) {
    dispatch(addScheduleSuccess({ profileId, schedule: newSchedule }));
  }

  dispatch(notifyUser(success, notifySuccess));
};

export const addScheduleSuccess = (
  payload: AddOrUpdateSchedulePayload
): SchedulesActionTypes => ({
  type: ADD_SCHEDULE_SUCCESS,
  payload,
});

export const updateSchedule = (
  profileName,
  profileId,
  schedule,
  notifySuccess = true
) => async (dispatch, getState, { api }) => {
  dispatch({ type: UPDATE_SCHEDULE });

  const { name: scheduleName } = schedule;
  const subscriberId = getSubscriberId(getState());

  const { success } = await api.schedules.update(
    subscriberId,
    profileName,
    scheduleName,
    schedule
  );

  if (success) {
    dispatch(updateScheduleSuccess({ profileId, schedule }));
  }

  dispatch(notifyUser(success, notifySuccess));
};

export const updateScheduleSuccess = (
  payload: AddOrUpdateSchedulePayload
): SchedulesActionTypes => ({
  type: UPDATE_SCHEDULE_SUCCESS,
  payload,
});

export const deleteSchedule = (
  profileName,
  profileId,
  scheduleName,
  notifySuccess = true
) => async (dispatch, getState, { api }) => {
  dispatch({ type: DELETE_SCHEDULE });

  const subscriberId = getSubscriberId(getState());

  const { success } = await api.schedules.delete(
    subscriberId,
    profileName,
    scheduleName
  );

  if (success) {
    dispatch(deleteScheduleSuccess({ profileId, scheduleName }));
  }

  dispatch(notifyUser(success, notifySuccess));
};

export const deleteScheduleSuccess = (
  payload: DeleteSchedulePayload
): SchedulesActionTypes => ({
  type: DELETE_SCHEDULE_SUCCESS,
  payload,
});
