export const getConfig = ({ config }) => config;
export const getInfo = ({ info }) => info;
export const getAccount = ({ account }) => account;
export const getLogicalDevices = ({ logicalDevices }) => logicalDevices;
export const getLogicalDeviceDetails = ({ logicalDeviceDetails }) =>
  logicalDeviceDetails;
export const getLines = ({ lines }) => lines;
export const getTimezones = ({ timezones }) => timezones;
export const getLoader = ({ loader }) => loader;
export const getProfiles = ({ profiles }) => profiles;
export const getSchedules = ({ schedules }) => schedules;
export const getWizard = ({ wizard }) => wizard;
export const getRequests = ({ requests }) => requests;
export const getReports = ({ reports }) => reports;
export const getThreats = ({ threats }) => threats;
export const getCustomProtections = ({ customProtections }) =>
  customProtections;
export const getSharedProtections = ({ sharedProtections }) =>
  sharedProtections;
export const getInfections = ({ infections }) => infections;
export const getInfectedDevices = ({ infectedDevices }) => infectedDevices;
export const getAttributes = ({ attributes }) => attributes;
