import { NormalizedCollection } from "../../shared/normalizeCollection";
import { SharedProtection } from "@sportal/api";

export const LOAD_SHARED_PROTECTIONS = "[PROTECTION] LOAD_SHARED_PROTECTIONS";
export const LOAD_SHARED_PROTECTIONS_SUCCESS =
  "[PROTECTION] LOAD_SHARED_PROTECTIONS_SUCCESS";
export const LOAD_SHARED_PROTECTIONS_FAILURE =
  "[PROTECTION] LOAD_SHARED_PROTECTIONS_FAILURE";

interface LoadSharedProtectionsAction {
  type: typeof LOAD_SHARED_PROTECTIONS;
}

interface LoadSharedProtectionsSuccessAction {
  type: typeof LOAD_SHARED_PROTECTIONS_SUCCESS;
  payload: NormalizedCollection<SharedProtection>;
}

interface LoadSharedProtectionsFailureAction {
  type: typeof LOAD_SHARED_PROTECTIONS_FAILURE;
  payload: object;
}

export type SharedProtectionsActionTypes =
  | LoadSharedProtectionsAction
  | LoadSharedProtectionsSuccessAction
  | LoadSharedProtectionsFailureAction;
