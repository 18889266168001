import React, { VFC, HTMLAttributes } from "react";
import { GetToggleButtonPropsOptions } from "downshift";

import { ISelectItem } from "../selectItem";

import "./SelectButton.scss";

interface SelectButtonProps extends HTMLAttributes<HTMLButtonElement> {
  getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any;
  disabled?: boolean;
  selectedItem?: ISelectItem;
  ariaDescribedBy?: string;
}

export const SelectButton: VFC<SelectButtonProps> = ({
  getToggleButtonProps,
  disabled,
  selectedItem,
  ariaDescribedBy,
}) => {
  return (
    <button
      {...getToggleButtonProps({ disabled })}
      type="button"
      className="select__button"
      aria-describedby={ariaDescribedBy}
    >
      <span className="select__button-content">
        {selectedItem ? selectedItem.content : ""}
      </span>
      <span className="select__button-arrow" />
    </button>
  );
};
