import React from "react";

import "./Loader.scss";

export function Loader() {
  return loaderContent;
}

const loaderContent = (
  <div className="loader">
    <div className="loader__dot loader__dot--1" />
    <div className="loader__dot loader__dot--2" />
    <div className="loader__dot loader__dot--3" />
  </div>
);
