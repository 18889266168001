import _ from "lodash";
import { useLoadableSlice } from "../../../store/shared/withLoadingState";
import { getCategories, loadCategories } from "../../../store/categories";
import {
  loadCustomProtectionsSuccess,
  loadSharedProtections,
} from "../../../store/protection";
import {
  getSharedProtections,
  getCustomProtections,
} from "../../../store/root.selectors";

export const useDataForSingleWizard = () => {
  const categories = useLoadableSlice(loadCategories(), getCategories);
  const sharedProtections = useLoadableSlice(
    loadSharedProtections(),
    getSharedProtections
  );

  const customProtections = useLoadableSlice(
    loadCustomProtectionsSuccess([]),
    getCustomProtections
  );

  return _.every([categories, sharedProtections, customProtections], Boolean)
    ? { categories, sharedProtections, customProtections }
    : null;
};
