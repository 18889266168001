import React, { ReactElement, VFC } from "react";
import classnames from "classnames";
import { UseSelectGetItemPropsOptions } from "downshift";

export interface DropdownItemInterface {
  id: string;
  content: string | ReactElement;
  action?: () => void;
  disabled?: boolean;
  className?: string;
}

interface DropdownItemProps {
  item: DropdownItemInterface;
  index: number;
  highlightedIndex: number;
  getItemProps: (
    options: UseSelectGetItemPropsOptions<DropdownItemInterface>
  ) => any;
}

export const DropdownItem: VFC<DropdownItemProps> = ({
  item,
  index,
  highlightedIndex,
  getItemProps,
}) => {
  const { action, disabled, id, content, className } = item;

  const isNonInteractive = !action || disabled;

  return (
    <div
      className={classnames("dropdown__item", className, {
        "dropdown__item--disabled": disabled,
        "dropdown__item--highlighted": highlightedIndex === index,
      })}
      {...getItemProps({
        item,
        index,
        disabled: isNonInteractive,
      })}
      data-id={id}
      aria-disabled={isNonInteractive}
    >
      {content}
    </div>
  );
};
