import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { useMount } from "@sportal/cdk/hooks";
import { NotMobile } from "../../common/Responsive";
import { getTimezone } from "../../store/account";
import { getIs24hourFormat } from "../../store/attributes";
import { XAxis } from "./XAxis";
import { useReportContext } from "./ReportProvider";
import {
  ActivityBars,
  BlocksBars,
  ChartMessage,
  SSBars,
} from "./components/ChartComponents";
import { BarChartTooltip } from "./tooltip/BarChartTooltip";
import { useBarchartTooltip } from "./useBarchartTooltip";

const HEIGHT = 110;

const BarChartComponent = ({ container }) => {
  const tz = useSelector(getTimezone);
  const is24 = useSelector(getIs24hourFormat);
  const { duration } = useReportContext();

  return (
    <div>
      <svg>
        <XAxis tz={tz} is24={is24} topOffset={HEIGHT} />
        <ActivityBars />
        <BlocksBars />
        <SSBars />
        <ChartMessage />
      </svg>
      <NotMobile>
        <BarChartTooltip
          container={container}
          is24={is24}
          tz={tz}
          duration={duration}
        />
      </NotMobile>
    </div>
  );
};

function useBarchartTooltipCleanup() {
  const unselect = useBarchartTooltip(state => state.unselect);

  useMount(() => unselect);
}

export const BarChart = () => {
  const { barchartClasses } = useReportContext();
  const container = useRef(null);

  useBarchartTooltipCleanup();

  return (
    <div className={barchartClasses} ref={container}>
      <BarChartComponent container={container} />
    </div>
  );
};
