import _ from "lodash";

import { getLogicalDevices } from "../../root.selectors";
import { denormalizeCollection } from "../../shared/normalizeCollection";
import { isLine, isNotLine } from "../lines";

export const getLogicalDevicesList = _.flow([
  getLogicalDevices,
  denormalizeCollection,
]);

export const getProfileDevices = profileName => state => {
  const devices = getLogicalDevices(state);

  return _.filter(devices.list, { profile: profileName }).map(device => ({
    ...device,
    networkId: _.find(device.identifiers, identifier =>
      isNotLine(state, identifier)
    ),
    phoneNumber: _.find(device.identifiers, identifier =>
      isLine(state, identifier)
    ),
  }));
};

export const getDevicesLimit = _.flow([
  getLogicalDevices,
  ({ limit }) => limit,
]);
