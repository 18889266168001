import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox } from "../../checkbox/Checkbox";
import { TimePicker } from "../../timePicker/TimePicker";
import { Mobile, NotMobile } from "../../../common/Responsive";
import { SchedulerGrid } from "../schedulerGrid/SchedulerGrid";
import { homeworkSchedule } from "../../../store/schedules";
import { SchedulerCategories } from "../schedulerCategories/SchedulerCategories";
import { useSchedulerContext } from "../SchedulerContext";
import { TRANSLATIONS } from "../scheduler.consts";

export const ScheduleFormDescription = () => {
  const { type } = useSchedulerContext();

  return (
    <p className="schedule-form__description">
      <FormattedMessage id={TRANSLATIONS[type].description} />
    </p>
  );
};

export const SchedulerFormContent = ({
  schedule,
  updateValue,
  addValues,
  removeValues,
}) => {
  const intl = useIntl();
  const { type, timeFormat24h, weekdays } = useSchedulerContext();

  function toggleDay(key, weekday) {
    if (schedule.day.includes(weekday)) {
      removeValues(key, weekday);
    } else {
      addValues(key, weekday);
    }
  }

  return (
    <div className="schedule-form__content">
      <Mobile>
        <ScheduleFormDescription />
      </Mobile>
      <div className="schedule-form__controls">
        <div className="time-picker-group">
          <TimePicker
            label={<FormattedMessage id="start" />}
            value={schedule.timeStart}
            timeFormat24h={timeFormat24h}
            onChange={value => updateValue("timeStart", value)}
          />
          <TimePicker
            label={<FormattedMessage id="end" />}
            value={schedule.timeEnd}
            timeFormat24h={timeFormat24h}
            onChange={value => updateValue("timeEnd", value)}
          />
        </div>
        <fieldset className="checkbox-group">
          <legend className="checkbox-group__label">
            <FormattedMessage id="days" />
          </legend>
          <div className="checkbox-group__list">
            {weekdays.map(weekday => (
              <Checkbox
                id={`${schedule.name}-weekday-${weekday}`}
                key={weekday}
                value={weekday}
                label={
                  <Fragment>
                    <Mobile>
                      <FormattedMessage id={weekday} />
                    </Mobile>
                    <NotMobile>
                      <FormattedMessage id={`${weekday}_short`} />
                    </NotMobile>
                  </Fragment>
                }
                aria-label={intl.formatMessage({ id: weekday })}
                checked={schedule.day.includes(weekday)}
                onChange={() => toggleDay("day", weekday)}
              />
            ))}
          </div>
        </fieldset>
      </div>
      <SchedulerGrid />
      {homeworkSchedule(type) && (
        <SchedulerCategories
          timeStart={schedule.timeStart}
          timeEnd={schedule.timeEnd}
          blockedCategories={schedule.categories}
          block={value => addValues("categories", value)}
          allow={value => removeValues("categories", value)}
        />
      )}
    </div>
  );
};

SchedulerFormContent.propTypes = {
  schedule: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
  addValues: PropTypes.func.isRequired,
  removeValues: PropTypes.func.isRequired,
};
