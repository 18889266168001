import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { defaultProfile } from "../../store/profiles";
import { submitProfiles, getProfilesList } from "../../store/profiles";
import { getProfilesLimit } from "../../../../store/settings";
import { uuid } from "@sportal/lib";
import { MultipleWizardPage } from "../MultipleWizardPage";
import { NextStepButton } from "../nextStepButton/NextStepButton";
import {
  useProfileErrors,
  profileNameErrors,
} from "../../../profile/profileNameValidation";
import {
  AddProfileCard,
  AddProfileControls,
  WizardProfilesHint,
  WizardProfileCard,
} from "./WizardProfileParts";
import "./WizardProfiles.scss";
import { useWizardNavigationContext } from "../../WizardNavigationContext";
import { WizardPageTitle } from "../wizardPageTitle/WizardPageTitle";

const profileNameErrorsMap = {
  [profileNameErrors.MISSING]: "please_provide_a_name",
  [profileNameErrors.INVALID]: "invalid_name",
  [profileNameErrors.EXISTS]: "names_cant_be_equal",
  [profileNameErrors.TOO_LONG]: "name_exceed_length",
};

const errorTranslation = key => profileNameErrorsMap[key];

const excludeDefaultAndPristine = profiles =>
  profiles.filter(profile => !profile.default && !profile.pristine);

const getInitialProfiles = state => {
  const profiles = getProfilesList(state);

  return _.isEmpty(profiles) ? [defaultProfile] : profiles;
};

export const MultipleWizardProfilesPage = () => {
  const initialProfiles = useSelector(getInitialProfiles, _.isEqual);
  const profilesLimit = useSelector(getProfilesLimit);
  const { goToNextStep } = useWizardNavigationContext();

  const dispatch = useDispatch();

  const [profiles, setProfiles] = useState([]);
  const [showAddProfile, setShowAddProfile] = useState(false);

  useEffect(() => setProfiles(initialProfiles), [initialProfiles]);

  const [errors, hasErrors] = useProfileErrors(
    profiles,
    excludeDefaultAndPristine(profiles)
  );

  const formDisabled = useMemo(
    () =>
      showAddProfile ||
      hasErrors ||
      profiles.some(profile => !profile.name.trim()),
    [showAddProfile, hasErrors, profiles]
  );

  const handleAddProfile = ageGroup => {
    const profile = {
      id: uuid(),
      name: "",
      "age-group": ageGroup,
      pristine: true,
    };

    setProfiles(prevState => [...prevState, profile]);
    setShowAddProfile(false);
  };

  const handleProfileUpdate = useCallback((id, property, value) => {
    setProfiles(oldProfiles =>
      oldProfiles.map(profile => {
        if (profile.id !== id) return profile;

        return {
          ..._.omit(profile, "pristine"),
          [property]: value,
        };
      })
    );
  }, []);

  const handleRemoveProfile = useCallback(id => {
    setProfiles(prevProfiles =>
      prevProfiles.filter(profile => profile.id !== id)
    );
  }, []);

  const saveChanges = useCallback(() => {
    dispatch(submitProfiles(profiles))
      .then(() => {
        goToNextStep();
      })
      .catch(() => {});
  }, [dispatch, goToNextStep, profiles]);

  const profilesLeft = profilesLimit - profiles.length;

  return _.isEmpty(profiles) ? null : (
    <MultipleWizardPage>
      <div className="wizard-profiles">
        <WizardPageTitle>
          <FormattedMessage id={"create_profile"} />
        </WizardPageTitle>
        <div className="wizard-profiles__content">
          <div className="wizard-profiles__list">
            {profiles.map(profile => (
              <WizardProfileCard
                key={profile.id}
                profile={profile}
                errorMessage={errorTranslation(errors[profile.id])}
                onProfileUpdate={handleProfileUpdate}
                onProfileRemove={handleRemoveProfile}
              />
            ))}

            {showAddProfile && (
              <AddProfileCard
                onCancel={() => setShowAddProfile(false)}
                onGroupSelect={handleAddProfile}
              />
            )}
          </div>

          <WizardProfilesHint
            profilesLimit={profilesLimit}
            profilesLength={profiles.length}
            limitReached={profilesLimit === profiles.length}
            name={profiles[profiles.length - 1].name}
          />

          {profilesLeft > 0 && (
            <AddProfileControls
              profilesLeft={profilesLeft}
              disabled={formDisabled}
              addProfile={() => setShowAddProfile(true)}
            />
          )}
        </div>
      </div>
      <NextStepButton
        disabled={formDisabled || profiles.length <= 1}
        doNext={saveChanges}
        nextTab={"protection_setup"}
      />
    </MultipleWizardPage>
  );
};
