import React, { FC } from "react";
import { HeaderGroup } from "react-table";

interface THeadMobileProps {
  headerGroups: HeaderGroup<any>[];
  isDataEmpty: boolean;
}

export const THeadMobile: FC<THeadMobileProps> = ({
  headerGroups,
  isDataEmpty,
}) => {
  return (
    <thead className="thead-mobile sr-only">
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} aria-hidden={isDataEmpty}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} id={column.id}>
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
