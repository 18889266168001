import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { getCategories } from "../../store/categories";
import { saveProfileProtection } from "../../store/protection";
import { getSharedProtections } from "../../store/root.selectors";
import { changeProfileProtectionEnabled } from "../../store/profiles";
import { Protection } from "@sportal/api";
import { useProtectionState } from "../../components/protectionEditor/useProtectionState";
import { ProtectionPicker } from "../../components/protectionEditor/ProtectionPicker";
import { ProtectionSubtitle } from "../../components/protectionEditor/ProtectionSubtitle";
import { CategoriesList } from "../../components/categoriesList/CategoriesList";
import {
  ProfileSection,
  ProfileSectionCollapseToggle,
  ProfileSectionFooterFormControls,
  ProfileSectionHeaderForm,
} from "./profileSection";

import "./ProfileProtections.scss";

export const ProfileProtections = ({ profile }) => {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);
  const sharedProtections = useSelector(getSharedProtections);

  const isProtectionEnabled = profile["protection-enabled"];

  const {
    savedProtection,
    currentProtection,
    setBlocked,
    setLevel,
    block,
    allow,
  } = useProtectionState(profile);

  const hasChanges = !_.isEqual(savedProtection, currentProtection);

  function handleChange(event) {
    dispatch(changeProfileProtectionEnabled(profile, event.target.checked));
  }

  function discardChanges() {
    setBlocked(savedProtection.blocked);
  }

  function submitChanges() {
    dispatch(saveProfileProtection(profile.id, currentProtection));
  }

  function handleProtectionChange(name) {
    setLevel(name);

    if (name !== savedProtection.name) {
      dispatch(saveProfileProtection(profile.id, { name }));
    }
  }

  return (
    <ProfileSection
      className="profile-protections"
      header={
        <ProfileSectionHeaderForm
          type="webfilters"
          title={<FormattedMessage id="protection_level" />}
          checked={isProtectionEnabled}
          hasChanges={hasChanges}
          onChange={handleChange}
        />
      }
      toggle={
        <ProfileSectionCollapseToggle hasChanges={hasChanges}>
          <FormattedMessage id="set_protection_level" />
        </ProfileSectionCollapseToggle>
      }
      description={
        <Fragment>
          <p className={"profile-section__description"}>
            <FormattedMessage id={"protection_summary"} />
          </p>
          <ProtectionPicker
            protections={_.map(sharedProtections.list)}
            selected={currentProtection.name}
            onChange={handleProtectionChange}
          />
          {currentProtection.name !== Protection.Custom && (
            <ProtectionSubtitle selectedProtection={currentProtection} />
          )}
        </Fragment>
      }
      content={
        <CategoriesList
          allCategories={_.map(categories.list)}
          blockedCategories={currentProtection.blocked}
          allowAction={allow}
          blockAction={block}
        />
      }
      footer={
        <ProfileSectionFooterFormControls
          discardDisabled={!hasChanges}
          submitDisabled={!hasChanges}
          hasChanges={hasChanges}
          discardChanges={discardChanges}
          submitChanges={submitChanges}
        />
      }
    />
  );
};
