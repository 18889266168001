import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useIntl } from "react-intl";

import { CategoryDescription, CategoryItem } from "./CategoryItem";
import { CategoryItemWithChildren } from "./CategoryItemWithChildren";
import { transformCategories } from "./transformCategories";
import { TwoColumnAccordion } from "../accordion/Accordion";
import { useSelector } from "react-redux";
import { getProfiles } from "../../store/root.selectors";
import { useProtectionState } from "../protectionEditor/useProtectionState";
import { useSchedulerContext } from "../scheduler/SchedulerContext";
import { extendCategories } from "./extendCategories";

import "./CategoriesList.scss";

const getProfile = id => state => getProfiles(state).list[id];

const useCategoriesWithProtections = () => {
  const { profileId } = useSchedulerContext();
  const profile = useSelector(getProfile(profileId));
  const { savedProtection } = useProtectionState(profile);

  return savedProtection;
};

export const CategoriesList = ({
  className,
  allCategories,
  blockedCategories,
  blockAction,
  allowAction,
}) => {
  const protection = useCategoriesWithProtections();
  const categories = extendCategories(
    allCategories,
    blockedCategories,
    blockAction,
    allowAction,
    protection
  );
  const items = transformCategories(useIntl(), categories);

  return (
    <TwoColumnAccordion
      className={classnames(className, "categories-list")}
      items={items.map(category =>
        category.categories
          ? {
              component: (
                <CategoryItemWithChildren
                  {...category}
                  className="categories-list__item"
                  key={category.name}
                />
              ),
            }
          : {
              header: <CategoryItem {...category} key={category.name} />,
              content: (
                <CategoryDescription key={`${category.name}-description`}>
                  {category.description}
                </CategoryDescription>
              ),
              className: "categories-list__item",
            }
      )}
    />
  );
};

CategoriesList.propTypes = {
  className: PropTypes.string,
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      categories: PropTypes.array,
      status: PropTypes.boolean,
    })
  ),
  blockedCategories: PropTypes.arrayOf(PropTypes.string),
  allowAction: PropTypes.func,
  blockAction: PropTypes.func,
};
