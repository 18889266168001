import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { isAssignedDeviceMerged } from "../../../store/devices";
import { MergedNetworkId } from "../networkId";
import { showValue } from "../../table";
import { ProfileDeviceTypeTooltip } from "../profileDeviceTypeTooltip";

import "./ProfileDeviceCard.scss";

const AccessibleCell = ({ header, content }) => {
  return (
    <div className="accessible-cell">
      <span className="sr-only">{header}</span>
      {content}
    </div>
  );
};

export const ProfileDeviceCard = ({
  deviceName,
  phoneNumber,
  manufacturer,
  networkId,
  identifiers,
}) => {
  const isMerged = isAssignedDeviceMerged({ identifiers });

  return (
    <div className="profile-device-card">
      <div className="profile-device-card__content-container">
        <div aria-hidden={true} className="profile-device-card__title">
          <FormattedMessage id="device_name" />
        </div>
        <div className="profile-device-card__main-info">
          <AccessibleCell
            header={<FormattedMessage id="device_type" />}
            content={<ProfileDeviceTypeTooltip identifiers={identifiers} />}
          />
          <AccessibleCell
            header={<FormattedMessage id="device_name" />}
            content={deviceName}
          />
        </div>
      </div>
      <div className="profile-device-card__content-container">
        <div className="profile-device-card__title">
          <FormattedMessage id="manufacturer" />
        </div>
        <div className="ellipsis-text">{showValue(manufacturer)}</div>
      </div>
      <div className="profile-device-card__content-container">
        <div className="profile-device-card__title">
          <FormattedMessage id="network_id" />
        </div>
        {isMerged ? (
          <MergedNetworkId phoneNumber={phoneNumber} networkId={networkId} />
        ) : (
          showValue(networkId || phoneNumber)
        )}
      </div>
    </div>
  );
};

ProfileDeviceCard.propTypes = {
  deviceName: PropTypes.string,
  phoneNumber: PropTypes.string,
  manufacturer: PropTypes.string,
  networkId: PropTypes.string,
};
