import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./DeviceCardMobile.scss";

import { FormattedDateTime } from "../../formattedDateTime/FormattedDateTime";

const unknown = (
  <span className="disabled-text">
    <FormattedMessage id="unknown" />
  </span>
);

const missedValue = "—"; // em dash

export const DeviceCardMobile = ({
  deviceName,
  code,
  manufacturer,
  networkId,
  lastSeen,
  variant,
  isAdvancedView,
  menu,
}) => (
  <div
    className={classnames(
      "device-card-mobile",
      `device-card-mobile--${variant}`
    )}
  >
    <div className="device-card-mobile__content">
      <span className="device-card-mobile__title">
        <FormattedMessage id="device_name" />
      </span>
      <span className="device-card-mobile__device-name">
        {deviceName || unknown}
      </span>
      <span className="device-card-mobile__menu">{menu}</span>
    </div>
    {isAdvancedView && (
      <Fragment>
        <div className="device-card-mobile__content device-card-mobile__code">
          <span className="device-card-mobile__title">
            <FormattedMessage id="code" />
          </span>
          {code || missedValue}
        </div>
        <div className="device-card-mobile__content">
          <span className="device-card-mobile__title">
            <FormattedMessage id="manufacturer" />
          </span>
          {manufacturer || missedValue}
        </div>
        <div className="device-card-mobile__content">
          <span className="device-card-mobile__title">
            <FormattedMessage id="network_id" />
          </span>
          {networkId}
        </div>
      </Fragment>
    )}
    <div className="device-card-mobile__content">
      <span className="device-card-mobile__title">
        <FormattedMessage id="last_seen" />
      </span>
      {lastSeen ? <FormattedDateTime value={lastSeen} /> : missedValue}
    </div>
  </div>
);

DeviceCardMobile.propTypes = {
  variant: PropTypes.oneOf(["dark", "light"]),
  deviceName: PropTypes.string,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  manufacturer: PropTypes.string,
  networkId: PropTypes.node,
  lastSeen: PropTypes.number,
  isAdvancedView: PropTypes.bool,
  menu: PropTypes.node,
};
