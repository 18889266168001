import { useRef, useCallback } from "react";

import { useMount } from "../useMount";

export function useIsMounted(): () => boolean {
  const mountedRef = useRef(false);

  useMount(() => {
    mountedRef.current = true;

    return () => (mountedRef.current = false);
  });

  return useCallback(() => mountedRef.current, []);
}
