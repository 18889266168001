import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import _ from "lodash";

import { removeUrl } from "../../store/lists/lists.actions";
import { getListsByProfileId } from "../../store/lists/lists.selectors";
import { UrlList } from "../urlList/UrlList";
import { ListType } from "@sportal/api/lists";

import "./UrlLists.scss";

const listsConfig = [
  {
    type: ListType.Block,
    title: "black_list_blocked",
    icon: "fa-ban",
  },
  {
    type: ListType.Allow,
    title: "white_list_allowed",
    icon: "fa-check",
  },
];

export const UrlLists = ({ className, profileId }) => {
  const lists = useSelector(getListsByProfileId(profileId), _.isEqual);
  const dispatch = useDispatch();

  const getUrls = type => (lists && lists[type] ? lists[type].content : []);

  const classes = classnames("url-lists", className);

  return (
    <div className={classes}>
      {listsConfig.map((list, index) => (
        <UrlList
          key={index}
          title={list.title}
          icon={`far ${list.icon}`}
          onRemove={url => dispatch(removeUrl(list.type, url, profileId))}
          onRemoveAll={() =>
            dispatch(removeUrl(list.type, getUrls(list.type), profileId))
          }
          items={getUrls(list.type)}
        />
      ))}
    </div>
  );
};
