import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { TabTitle } from "../../../components";

export function SettingsTabTitle({ tabName }) {
  return (
    <TabTitle>
      <FormattedMessage id="settings" />
      {" - "}
      <FormattedMessage id={tabName} />
    </TabTitle>
  );
}

SettingsTabTitle.propTypes = {
  tabName: PropTypes.string.isRequired,
};
